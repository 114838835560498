import React, {useState} from "react";
import Styles from "../styles/ofbstyles.module.css";

const FormCategory = (props) => {
    const [options, setOptions] = useState(props.options);

    const search_and_select_option = () => {
        //If no letters typed at all, ignore this
        if(props.category.trim().length === 0) {
            return;
        }

        //Get the options to check Through
        let options = document.getElementById('datalist').options;

        //Try to find an option and select it
        for(let i = 0; i < options.length; i++) {
            if(options[i].value.toLowerCase().indexOf(props.category.toLowerCase()) !== -1) {
                props.onChange(options[i].value);
                return;
            }
        }

        //If no option, then it must be a new category, handle it
        props.category_add_new_handler(props.category, props.name);
    }
    return (
        <>
            <input
                type='text'
                name={props.name}
                id={props.name}
                className={Styles.ofb_text_input}
                value={props.category}
                onChange={event => props.onChange(event.target.value)}
                list='datalist'
                autoComplete='off'
                placeholder='Category'
                onBlur={search_and_select_option}
            />
            <datalist id='datalist'>
                {props.options.map((value, index) => (
                    <option key={index} value={value} />
                ))}
            </datalist>
        </>
    )
}
export default FormCategory;