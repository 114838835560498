import React, {useEffect, useState, useContext, useRef, forwardRef} from "react";
import { load_fetch, talk } from "../ofb_util";
import Styles from "../styles/pseudos.module.css";
import GenStyles from "../styles/ofb_gen_styles.module.css"
import HealthStyles from "../styles/health.module.css";
import Widget from "./widget";
import {WidgetContext} from "./WidgetContext";

const BPWidget = forwardRef((props, ref) => {
    //DATA
    const [title, setTitle] = useState("BP");
    const [clickUrl, setClickUrl] = useState("./health");
    const [lastBP, setLastBP] = useState(false);
    const [todayBP, setTodayBP] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const baseWidgetRef = useRef();


    //USE EFFECTS
    useEffect(() => {
        get_widget_display_info();
    }, []);
    //Set the Widget Selected if It is Selected
    useEffect(() => {
        if(props.selected_widget === props.index) {
            setIsSelected(true);
        } else {
            setIsSelected(false);
        }
    }, [props.selected_widget])
    useEffect(() => {
        if(isSelected && props.go_to_click_url) {
            document.location.href='./health';
        }
    }, [props.go_to_click_url])
    useEffect(() => {
        //console.log("IN BP WIDGET - isSelected: " + isSelected + ", trigger_button: " + props.is_trigger_widget_button_set);
        if(isSelected && props.is_trigger_widget_button_set) {
            show_bp_form(null);
        }
    }, [props.is_trigger_widget_button_set])



    const blah = () => {
        console.log("Blee");
    }
    const cancel_callback = () => {
        console.log("Modal Cancel");
        props.modal_close_handler();
    }

    //Info passed to the modal in the layout level
    const show_bp_form = (event) => {
        if(event) {
            event.stopPropagation();
        }

        props.set_trigger_widget_button(true);

        props.show_modal({
            header_text: "Enter BP",
            form_name: "bp_form",
            body_content: (<>
                <div className={HealthStyles.weight_widget_form_row} id="bp_form">
                    <input type="text" onFocus={() => console.log('focusing')} onBlur={console.log("losing foucs1")} id="input_systolic" key="form_input_1" className={GenStyles.input} placeholder="Sys" autoFocus />
                    <input type="text" id="input_diastolic" className={GenStyles.input} placeholder="Dias"/>
                    <input type="text" id="input_pulse" className={GenStyles.input} placeholder="Pulse"/>
                    <input type="datetime-local" id="input_bp_datetime" className={`${GenStyles.input} ${HealthStyles.weight_widget_date_time}`} placeholder="Date" />
                </div>
                </>),
            footer_text: null,
            callback: modal_callback,
            cancel_callback: cancel_callback,
            //update_bp: setNewBP,
            submit_button_text: 'Save BP'
        });
    }

    //onBlur causes focus regain, but have to pass it when tab is hit
    //No... need to just set focus at a more appropriate time... after it was lost last time

    //AJAX FUNCTIONS
    const insert_bp = (data) => {
        talk("health_handler.php", "INSERT_NEW_BP", data, insert_bp_handler);
    }
    const insert_bp_handler = (operation, data, return_data) => {
        if(!return_data['ERROR']) {
            //Close the modal and update widget
            props.show_modal('');
            get_widget_display_info();
        }
    }
    const get_widget_display_info = () => {
        talk("health_handler.php", "GET_BP_WIDGET_DISPLAY_INFO", null,
            get_widget_display_info_handler);
    }
    const get_widget_display_info_handler = (operation, data, return_data) => {
        if(!return_data['ERROR']) {
            setTodayBP(return_data.today);
            setLastBP(return_data.last);
        }
    }

    //Call when modal does something
    const modal_callback = () => {
        props.modal_close_handler();

        //Validate data (needs bp and date)
        let sys = document.getElementById('input_systolic').value;
        let dias = document.getElementById('input_diastolic').value;
        let pulse = document.getElementById('input_pulse').value;
        let bp_date = document.getElementById('input_bp_datetime').value;

        if(!sys || !dias || !bp_date) {
            alert("Must enter a bp and a date.");
            return;
        }
        insert_bp({
            systolic: sys,
            diastolic: dias,
            pulse: pulse,
            bpdateandtime: bp_date
        });
    }
    const click_base_widget = () => {
        console.log("HERE");
        baseWidgetRef.current.click();
    }


    return (
        <div
            className={Styles.widget}
            ref={ref}
            onClick={blah}  //Put functional logic here in this function to see what key was pressed (state??)
            onKeyPress={click_base_widget}
        >
            <Widget
                ref={baseWidgetRef}
                selected_widget={props.selected_widget}
                index={props.index}
                title={title}
                click_url={clickUrl}
                other_code={(
                    <>
                        <div>
                            <button
                                className={Styles.widget_button}
                                type="button"
                                onClick={show_bp_form}
                            >ENTER BP.{props.selected_widget}</button>
                        </div>
                        <div className={HealthStyles.widget_weight_div}>
                            Last: {lastBP} Today's: {todayBP}
                        </div>
                    </>
                )}
            />
        </div>
    );
});

export default BPWidget;
