import React, {useState, useEffect} from "react";
import CycleStyles from "../styles/cycles.module.css";
import GenStyles from "../styles/ofb_gen_styles.module.css";
import {load_fetch, talk} from "../ofb_util";

const CycleSubItemForm = (props) => {
    //DATA
    const [newItemData, setNewItemData] = useState({
        new_item_title: '',
        new_page_title: '',
        new_item_frequency: null
    });

    //USEEFFECTS
    useEffect(() => {
        if(!props.is_shown) {
            clear_form();
        }
    }, [props.is_shown]);

    //AJAX FUNCTIONS
    const save_new_item = () => {
        let url = 'Cycles_Handler.php';
        let operation = 'SAVE_NEW_CYCLE_ITEM';
        let postData = {
            ...newItemData,
            parent_id: props.current_item_id
        }
        talk(url, operation, postData, save_new_item_handler);
    }
    const save_new_page = () => {
        let url = 'Cycles_Handler.php';
        let operation = 'SAVE_NEW_CYCLE_PAGE';
        let postData = {
            ...newItemData,
            parent_id: props.parent_id
        }
        talk(url, operation, postData, save_new_item_handler);
    }
    const save_new_item_handler = (operation, sent_data, return_data) => {
        if(!return_data['ERROR'] && !return_data['error']) {
            //Clear the Form
            clear_form();

            //Send the signal up to close this and update the GUI
            props.on_save(return_data);

        } else {
            console.log("FAILED TO INSERT NEW ITEM");
            console.log(return_data);
        }
    }

    //OTHER FUNCTIONS
    const clear_form = () => {
        setNewItemData({
            new_item_title: '',
            new_page_title: '',
            parent_id: props.parent_id,
            new_item_frequency:null
        });
    }
    const update_data = (event) => {
        const {name, value} = event.target;
        setNewItemData(prevData => ({
            ...prevData,
            [name]: value
        }));
    }
    const handle_key_press = (event) => {
        if(event.charCode === 13) {
            save_new_item();
        }
    }

    //RENDERING
    return (
        <div className={CycleStyles.new_form}>
            <div className={GenStyles.dashed_line_bottom}>
            </div>
            {/* ADD A PAGE OR A NEW TOP LEVEL ITEM TO THIS PAGE */}
            <div className={props.adding_page ? CycleStyles.new_form_row: GenStyles.hidden}>
                <div>New Page:</div>
                <div>
                    <input
                        type="text"
                        className={CycleStyles.new_form_text_input}
                        id="new_page_title"
                        name="new_page_title"
                        value={newItemData['new_page_title']}
                        placeholder="page name"
                        onChange={update_data}
                        onKeyPress={handle_key_press}
                    />
                </div>
                <div>
                    <button
                        className={GenStyles.button}
                        onClick={save_new_page}
                    >Save</button>
                </div>
                <div>New Top Level Item:</div>
                <div>
                    <input
                        type="text"
                        className={CycleStyles.new_form_text_input}
                        id="new_item_title"
                        name="new_item_title"
                        value={newItemData['new_item_title']}
                        placeholder="top level item name"
                        onChange={update_data}
                        onKeyPress={handle_key_press}
                    />
                </div>
                <div>
                    <button
                        className={GenStyles.button}
                        onClick={save_new_item}
                    >Save</button>
                </div>
            </div>


            {/* ADD A NEW SUBITEM FORM */}
            <div className={props.adding_page ? GenStyles.hidden : CycleStyles.new_form_row}>
                <div>{props.adding_page ? "New Page:" : "New Item:"}</div>
                <div>
                    <input
                        type="text"
                        className={CycleStyles.new_form_text_input}
                        id="new_item_title"
                        name="new_item_title"
                        value={newItemData['new_item_title']}
                        placeholder={props.adding_page ? "page name" : "task name"}
                        onChange={update_data}
                        onKeyPress={handle_key_press}
                    />
                </div>
                <div className={props.adding_page ? GenStyles.hidden : ''}>
                    <input
                        type="text"
                        className={CycleStyles.new_form_text_input}
                        id="new_item_frequency"
                        name="new_item_frequency"
                        value={newItemData['new_item_frequency'] ?? ''}
                        placeholder="frequency (in days)"
                        onChange={update_data}
                        onKeyPress={handle_key_press}
                    />
                </div>
                <div>
                    <button
                        className={GenStyles.button}
                        onClick={save_new_item}
                    >Save</button>
                </div>
            </div>
        </div>
    )
}

export default CycleSubItemForm;