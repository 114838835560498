import NavBlock from './nav_block';
import styles from "./styles/nav_section.module.css";
import { menuData } from "./menus/menu_data";

const NavSection = () => {

    return (
        <>
            <div id="navigation_blocks">
            <div className={`w3-row w3-padding-32 ${styles.nav_row}`}>
                <NavBlock nav_block_data={ menuData[0] } />
                <NavBlock nav_block_data={ menuData[1] } />
                <NavBlock nav_block_data={ menuData[2] } />
            </div>
            <div className={`w3-row w3-padding-32 ${styles.nav_row}`}>
                <NavBlock nav_block_data={ menuData[3] } />
                <NavBlock nav_block_data={ menuData[4] } />
                <NavBlock nav_block_data={ menuData[5] } />
            </div>
            </div>
        </>
    )
}

export default NavSection;