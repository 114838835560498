import React, {useState, useEffect, useContext} from 'react';
import "./styles/signin.css";
import Config from "./ofb_config.json";
import { AuthContext } from './auth_context';
import {load_fetch} from "./ofb_util";


const Signin = () => {
    //const [isAuth, setIsAuth] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(true);
    const [authStatus, setAuthStatus] = useContext(AuthContext);

    const handleUsernameInput = event => {
        setUsername(event.target.value);
    }
    const handlePasswordInput = event => {
        setPassword(event.target.value);
    }
    const handleRememberMe = event => {
        setRememberMe(!rememberMe);
    }

    function processLogin(username, password) {
        let url;

        //Manage Development vs Production Environments
        //if(window.location.hostname == "localhost") {
            //TODO: REmove this in production
            //setAuthStatus(true);
            //return;
            //url = Config.local_api_url + "auth_handler.php";
        //} else {
            url = Config.api_url + "auth_handler.php";
        //}

        const postData = {
            operation: 'LOGIN_ATTEMPT',
            username: username,
            password: password,
            rememberMe: rememberMe
        };

        /*
        const options = {
            method: 'POST',
            body: JSON.stringify(postData),
            cache: 'no-cache',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }; */

        const myCallback = (data) => {
            if(!data) {
                console.log("Login failed.");
            } else if (data) {
                //console.log("Logged in");
                setAuthStatus(true);
            }
        }
        load_fetch('auth_handler.php', postData, myCallback);

        /*
        fetch(url, options)
            .then(result => result.text())
            .then(data => {
                console.log(data);
                return;
                if (typeof(data) !== 'undefined' && data['userid']) {
                    setAuthStatus(true);
                } else {
                    console.log("NO DATA RETURNED?: " + data)
                }
            })
            .catch(error => {
                console.log('ERROR: ' + error);
            })

         */
    }

    if (authStatus === false &&
        (window.location.pathname !== "/pulse") &&
        (window.location.pathname !== "/church") &&
        (window.location.pathname !== "/bible") &&
        (window.location.pathname !== "/lang"))     //Exceptions to login page
    {
        return (
            <div className="login">
                <h4>Login</h4>
                <form>
                    <div className="text_area">
                        <input
                            type="text"
                            id="username"
                            name="username"
                            placeholder="username"
                            className="text_input"
                            autoComplete="username"
                            onChange={handleUsernameInput}
                        />
                    </div>
                    <div className="text_area">
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="password"
                            className="text_input"
                            autoComplete="current-password"
                            onChange={handlePasswordInput}
                        />
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            className="w3-check"
                            name="remember_me"
                            defaultChecked={rememberMe}
                            onChange={handleRememberMe}
                        /> Keep Me Logged In
                    </div>
                    <input
                        type="button"
                        value="LOGIN"
                        onClick={() => processLogin(username, password)}
                        className="btn"
                    />
                </form>
            </div>
        )
    } else {
        console.log(window.location.pathname);

        return (
            <></>
        )
    }
}

export default Signin;