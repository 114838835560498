import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {load_fetch} from "./ofb_util";
import ConnectionInfo from "./Pulse/connection_info";
import PulseStyles from "./styles/pulse.module.css"
import Clock from "./images/clock.gif"

const Pulse = (props) => {
    //DATA
    const query = new URLSearchParams(useLocation().search);
    const [classId, setClassId] = useState(query.get('cid'));
    const [view, setView] = useState('CLIENT');
    const [thisClassName, setThisClassName] = useState('');
    const [sessionInfo, setSessionInfo] = useState(false);
    const [passphrase, setPassphrase] = useState('');
    const [personalPin, setPersonalPin] = useState('');
    const [connectionActive, setConnectionActive] = useState(false);
    const navigate = useNavigate();

    //Connection Info Data
    const [lastHandShake, setLastHandShake] = useState(false);
    const [totalHandShakes, setTotalHandShakes] = useState(0);
    const [longestHandShakeInterval, setLongestHandShakeInterval] = useState(0);
    const [firstHandShake, setFirstHandShake] = useState(false);
    
    //Question Data
    const [questionType, setQuestionType] = useState('multiple_choice');
    const [answerCount, setAnswerCount] = useState(4);
    const [correctAnswer, setCorrectAnswer] = useState(false);
    const [deployedQuestion, setDeployedQuestion] = useState(false);
    const [studentAnswer, setStudentAnswer] = useState(false);
    const [answerOptions, setAnswerOptions] = useState(false);
    

    //USE EFFECTS
    useEffect(() => {
        setPassphrase(classId);
    }, [classId]);
    useEffect(() => {
        const interval = setInterval(() => {
            //console.log('This will be called every 2 seconds');
            keep_alive();
        }, 2000);

        return () => clearInterval(interval);
    }, []);

    //AJAX FUNCTIONS
    const talk = (operation, post_data, handler) => {
        let url = 'Pulse_Handler.php';
        let postData = {
            operation: operation,
            ...post_data
        }
        load_fetch(url, postData, (data) => {
            try {
                let json_data = JSON.parse(data);
                handler(operation, post_data, json_data);
            } catch (e) {
                console.log(e);
                console.log(data);
            }
        },'text');
    }


    //OTHER FUNCTIONS
    const switch_view = (view) => {
        navigate("/pulse-server");
        //setView(view);
    }
    const submit_class_info = () => {
        talk('REGISTER_CLASS', {class_name: thisClassName, passphrase: passphrase}, submit_class_info_handler);
    }
    const submit_class_info_handler = (operation, data, return_data) => {
        console.log(operation);
        console.log(data);
        console.log(return_data);

        //Check for Errors
        if(return_data['ERROR']) {
            console.log(return_data);
            setConnectionActive(false);
        } else {
            setConnectionActive(true);
        }
    }
    const keep_alive = () => {
        let result = talk('KEEP_ALIVE_STUDENT', null, keep_alive_handler);
    }
    const keep_alive_handler = (operation, data, return_data) => {
        console.log(return_data);
        if(return_data['ERROR'] || return_data['SESSION'] === 'INACTIVE') {
            setConnectionActive(false);
            setDeployedQuestion(false);
        } else {
			setConnectionActive(true);
            if(return_data['deployed_question']) {
                setDeployedQuestion(return_data['deployed_question']);
                if(return_data['deployed_question']['student_answer']) {
                    setStudentAnswer(return_data['deployed_question']['student_answer']);
                } else {
                    setStudentAnswer(false);
                }

                //Set the answer choices
                let answers = Array();
                switch(return_data['deployed_question']['answer_type']) {
                    case "letters":
                        var answers_array = Array.from(Array(return_data['deployed_question']['answer_count']).keys())
                        console.log(answers_array);
                        for(let i = 0; i < answers_array.length; i++) {
                            answers[i] = number_to_letter(answers_array[i] + 1);
                        }
                        setAnswerOptions(answers);
                        break;
                    case "numbers":
                        var answers_array = Array.from(Array(return_data['deployed_question']['answer_count']).keys())
                        for(let i = 0; i < answers_array.length; i++) {
                            answers[i] = answers_array[i] + 1;
                        }
                        setAnswerOptions(answers);
                        break;
                    case "customize":
                        answers = Object.values(JSON.parse(return_data['deployed_question']['customized_answers']));
                        setAnswerOptions(answers);
                        break;
                }

            } else {
                setDeployedQuestion(false);
            }
            if(!sessionInfo && return_data['session_info']) {
                setSessionInfo(return_data['session_info'])
            }
		}
    }
    const student_login = () => {
        talk(
            "STUDENT_LOGIN",
            {personal_pin: personalPin, class_passphrase: passphrase},
            student_login_handler);
    }
    const student_login_handler = (operation, data, return_data) => {
        console.log(return_data);
        if(return_data['ERROR'] || return_data['SESSION'] === 'INACTIVE') {
            setConnectionActive(false);
            setSessionInfo(false);
        } else {
            setConnectionActive(true);
            setSessionInfo(return_data);
        }
    }
    const end_class_session = () => {
        talk("END_CLASS_SESSION", {class_id:sessionInfo['class_id']}, end_class_session_handler);
    }
    const end_class_session_handler = (operation, data, return_data) => {
        console.log(return_data);
        setSessionInfo(false);
        setConnectionActive(false);
        setDeployedQuestion(false);
        setStudentAnswer(false);

        // Remove navigation prompt
        window.onbeforeunload = null;
    }
    const record_answer = (which_answer) => {
        setStudentAnswer(-1);
        let data = {
            question_id:deployedQuestion['pulse_question_id'],
            student_id: sessionInfo['student_id'],
            student_answer: which_answer,
            class_session_id: sessionInfo['class_session_id']
        }
        talk("RECORD_STUDENT_ANSWER", data, record_answer_handler);
    }
    const record_answer_handler = (operation, data, return_data) => {
        //keep_alive();
        console.log("RETURNING FROM RECORD ANSWER");
        console.log("RETURNED DATA: ");
        console.log(return_data);
    }
    const number_to_letter = (num) => {
        return String.fromCharCode(64 + num);
    }


    //RENDERING
    return (
        <div>
            <div className={connectionActive ? PulseStyles.hide : PulseStyles.student_login_div}>
                <div>
                    <input
                        type='text'
                        placeholder='Class Code'
                        value={passphrase ?? ''}
                        onChange={(event) => setPassphrase(event.target.value)}
                    />
                </div>
                <div>
                    <input
                        type='password'
                        placeholder='Personal PIN'
                        value={personalPin}
                        onChange={(event) => setPersonalPin(event.target.value)}
                    />
                </div>
                <div>
                    <button onClick={student_login} type='button'>Join Class</button>
                </div>
            </div>

            {/* CLASS BOX */}
            <div className={connectionActive ? PulseStyles.student_interaction_div : PulseStyles.hide}>
                <div>Welcome, {sessionInfo['first_name']}</div>
                <h3>{sessionInfo['class_name']}</h3>

                {/* CURRENT QUESTION DISPLAY */}
                <div className={deployedQuestion ? '' : PulseStyles.hide}>
                    <div>Current Question</div>
                    <div className={PulseStyles.student_question_text}>{deployedQuestion['question_text']}</div>
                    <div className={PulseStyles.answer_buttons}>


                    {answerOptions && answerOptions.map((item, index) => (
                        <button
                            key={index}
                            className={`${PulseStyles.simple_button} ${(studentAnswer && studentAnswer == (index + 1)) ?
                                (deployedQuestion['show_answers'] ? (deployedQuestion['success'] ? 
                                            PulseStyles.green : PulseStyles.red) : PulseStyles.gray) : ''}`}
                            onClick={() => record_answer(index + 1)}
                            disabled={studentAnswer ? true : false}
                        >{item}</button>
                    ))}
                    </div>
                    <div className={deployedQuestion && deployedQuestion['show_answers']
                    && deployedQuestion['student_answer'] ? '' : PulseStyles.hide}>
                        {deployedQuestion['success'] ? "You are correct!" : "Incorrect"}
                    </div>

                </div>

                {/* WAITING FOR QUESTION DISPLAY */}
                <div className={deployedQuestion ? PulseStyles.hide : ''}>
                    <div>... Waiting for the instructor to post a question ...</div>
                    <div><img className={PulseStyles.loading_gif} src={Clock} /></div>
                </div>
            </div>

            {/* BUTTON TO END SESSION */}
            <div className={connectionActive ? '' : PulseStyles.hide}>
                <button
                    className={PulseStyles.simple_button}
                    onClick={end_class_session}
                >End Class Session</button>
            </div>

            {/* CONNECTION INFO INDICATOR */}
            <ConnectionInfo active={connectionActive} />

        </div>
    )
}

export default Pulse;
