import React, {useEffect, useState, useContext } from "react";
import { load_fetch, talk } from "../ofb_util";
import Styles from "../styles/pseudos.module.css";
import GenStyles from "../styles/ofb_gen_styles.module.css"
import PrayerStyles from "../styles/prayer.module.css";
import Widget from "./widget";

const PrayerWidget = (props) => {
    //DATA
    const [prayerItem, setPrayerItem] = useState(false);
    const [clickUrl, setClickUrl] = useState("../prayer");

    //USE EFFECTS
    useEffect(() => {
        get_widget_display_info();
    }, []);
    useEffect(() => {
        if(props.index === props.selected_widget) {
            mark_prayer_completed(null);
        }
    }, [props.keyboard_toggler])
    useEffect(() => {
        if(props.selected_widget === props.index) {
            document.location.href=clickUrl;
        }
    }, [props.go_to_url_triggered])

    //AJAX FUNCTIONS
    const get_widget_display_info = () => {
        talk("Prayer_Handler.php", "GET_WIDGET_DISPLAY_INFO", null,
             get_widget_display_info_handler);
    }
    const get_widget_display_info_handler = (operation, data, return_data) => {
        console.log(return_data);
        if(return_data['STATUS'] === 'Up to Date') {
            setPrayerItem({prayer_text: 'Anything', prayer_id:null});
        } else {
            setPrayerItem(return_data);
        }
    }
    const mark_prayer_completed = (event) => {
        if(event) {
            event.stopPropagation();
        }
        let postData = {
            prayer_id: prayerItem['prayer_id']
        }
        talk('Prayer_Handler.php', 'MARK_COMPLETED', postData, mark_prayer_completed_handler);
    }
    const mark_prayer_completed_handler = (operation, sent_data, return_data) => {
        if(!return_data['ERROR']) {
            get_widget_display_info();
        }
    }

    //OTHER FUNCTIONS


    return (
        <div className={Styles.widget}>
            <Widget
                selected_widget={props.selected_widget}
                index={props.index}
                title="Prayer"
                click_url="../prayer"
                other_code={(
                    <>
                        <div>
                            <div className={PrayerStyles.widget_prayer_item_text}>
                                🙏 {prayerItem['prayer_text']} 🙏
                            </div>
                            <button
                                className={prayerItem['prayer_id'] ? Styles.widget_button : Styles.hidden}
                                type="button"
                                onClick={mark_prayer_completed}
                            >Mark Done</button>
                        </div>
                    </>
                )}
            />
        </div>
    );
}

export default PrayerWidget;
