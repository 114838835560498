import React, {useEffect, useState} from "react";
import {getDateInYYYYMMDD, load_fetch, talk} from "../ofb_util";
import GenStyles from "../styles/ofb_gen_styles.module.css";
import ShoppingStyles from "../styles/shopping_styles.module.css";
import CycleStyles from "../styles/cycles.module.css";
import Eraser from "../images/eraser_30x30.png";
import TrashCan from "../images/trash_can.png";
import CycleSubItemForm from "../Cycles/new_cycle_subitem_form";

//TODO:
//      Future: add quantities and stores and prices and purchase records
//      Allow moving an item to a different category
//      For now, in print view, when an item is unchecked, it stays in the list (toggle print view to update)

const Shopping = (props) => {
    //DATA
    const [masterList, setMasterList] = useState(false);
    const [archivedMasterList, setArchivedMasterList] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [editingItem, setEditingItem] = useState(false);
    const [editingItemTitle, setEditingItemTitle] = useState(false);
    const [editingItemCheckedStatus, setEditingItemCheckedStatus] = useState(false);
    const [newItemParentCategoryId, setNewItemParentCategoryId] = useState(false);
    const [newItemName, setNewItemName] = useState('');
    const [areYouSureDeleteItem, setAreYouSureDeleteItem] = useState(false);
    const [editingCategoryTitle, setEditingCategoryTitle] = useState(false);
    const [editingCategoryId, setEditingCategoryId] = useState(false);
    const [showAddNewCategoryModal, setShowAddNewCategoryModal] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [deleteCategoryName, setDeleteCategoryName] = useState('');
    const [deleteCategoryId, setDeleteCategoryId] = useState(0);
    const [showSearch, setShowSearch] = useState(false);
    const [searchKey, setSearchKey] = useState('');
    const [searchResults, setSearchResults] = useState(false);
    const [showPrintView, setShowPrintView] = useState(false);

    //USE EFFECTS
    useEffect(() => {
        get_master_list();
    }, []);
    useEffect(() => {
        //If coming off of inserting a new item, toggle that category open
        if(newItemParentCategoryId) {
            //toggle_category(newItemParentCategoryId);
            close_modal();
        }

        //If toggling an item while in search, update the search results
        if(searchResults) {
            filter_search();
        }
    }, [masterList])
    useEffect(() => {
        if(!props.authStatus) {
            setMasterList(false);
        } else {
            if(!masterList) {
                get_master_list();
            }
        }
    }, [props.authStatus]);
    useEffect(() => {
        //Clear search if box is empty
        if(searchKey.trim() == '') {
            setSearchResults(false);
        } else {
            filter_search();
        }
    }, [searchKey]);

    //AJAX FUNCTIONS
    const get_categories = () => {
        talk("Shopping_Handler.php", "GET_CATEGORIES", null, get_categories_handler);
    }
    const get_categories_handler = (operation, sent_data, return_data) => {
        console.log(return_data);
    }
    const get_master_list = () => {
        talk("Shopping_Handler.php", "GET_MASTER_LIST", null, get_master_list_handler);
    }
    const get_master_list_handler = (operation, sent_data, return_data) => {
        if(!return_data['ERROR']) {
            setMasterList(return_data);
        }
        console.log(return_data);
    }
    const toggle_shopping_item = (event) => {
        let postData = {
            item_id: editingItem['shoppinglistid'],
            checked: !editingItemCheckedStatus
        }
        talk("Shopping_Handler.php",
            "TOGGLE_ITEM_CHECKED",
            postData,
            toggle_item_handler);
    }
    const toggle_item_handler = (operation, sent_data, return_data) => {
        setEditingItemCheckedStatus(sent_data['checked'])
        if(!return_data['ERROR']) {
            //Update Master List
            let local_data = JSON.parse(JSON.stringify(masterList));
            for(let i = 0; i < local_data.length; i++) {
                if(local_data[i]['items']) {
                    for(let j = 0; j < local_data[i]['items'].length; j++) {
                        if(local_data[i]['items'][j]['shoppinglistid'] === sent_data['item_id']) {
                            local_data[i]['items'][j]['checked'] = sent_data['checked'];
                            break;
                        }
                    }
                }
            }
            setMasterList(local_data);

            //If in Print view, we need to update the archived master list, too
            if(showPrintView) {
                let local_data = JSON.parse(JSON.stringify(archivedMasterList));
                for(let i = 0; i < local_data.length; i++) {
                    if(local_data[i]['items']) {
                        for(let j = 0; j < local_data[i]['items'].length; j++) {
                            if(local_data[i]['items'][j]['shoppinglistid'] === sent_data['item_id']) {
                                local_data[i]['items'][j]['checked'] = sent_data['checked'];
                                break;
                            }
                        }
                    }
                }
                setArchivedMasterList(local_data);
            }
        }
        setEditingItem(false);
    }
    const save_item = () => {
        let url = "Shopping_Handler.php";
        let operation = newItemParentCategoryId ? "ADD_NEW_ITEM" : "ADD_NEW_CATEGORY";
        let postData = {
            item_title: newItemName,
            parent_category_id: newItemParentCategoryId
        }
        talk(url, operation, postData, save_item_handler);
    }
    const save_item_handler = (operation, sent_data, return_data) => {
        if(return_data['ERROR']) {
            alert("Failed to insert item");
            return;
        } else {
            setMasterList(return_data);
        }
    }
    const save_new_category = () => {
        let postData = {
            category_title: newCategoryName
        }
        talk(
            "Shopping_Handler.php",
            "INSERT_NEW_CATEGORY",
            postData,
            save_new_category_handler
        );
    }
    const save_new_category_handler = (operation, sent_data, return_data) => {
        if(!return_data['ERROR']) {
            setMasterList(return_data);
            close_new_category_modal()
        } else {
            alert(return_data['ERROR']);
        }
    }
    const save_item_title = () => {
        let url = "Shopping_Handler.php";
        let operation = "UPDATE_ITEM_TITLE";
        let postData = {
            item_title: editingItemTitle,
            item_id: editingItem['shoppinglistid']
        }
        talk(url, operation, postData, save_item_title_handler);
    }
    const save_item_title_handler = (operation, sent_data, return_data) => {
        //Update Master List
        if(return_data['ERROR']) {
            alert("Failed to insert item");
            return;
        } else {
            //Keep the Category Expanded
            for(let i = 0; i < return_data.length; i++) {
                if(return_data[i]['shoppingcatid'] == editingItem['categoryid']) {
                    return_data[i]['visible'] = true;
                }
            }
            setMasterList(return_data);
        }

        //Update Editing Item and Modal
        setEditingItemTitle(false);
        let local_data = JSON.parse(JSON.stringify(editingItem));
        local_data['item'] = sent_data['item_title'];
        setEditingItem(local_data);



    }
    const delete_current_shopping_item = () => {
        let postData = {
            item_id: editingItem['shoppinglistid'],
            category_id: editingItem['categoryid']
        }
        talk(
            "Shopping_Handler.php",
            "DELETE_ITEM",
            postData,
            delete_current_shopping_item_handler
        );
    }
    const delete_current_shopping_item_handler = (operation, sent_data, return_data) => {
        if(return_data['ERROR']) {
            alert(return_data['ERROR']);
            return;
        }

        setAreYouSureDeleteItem(false);
        setEditingItem(false);
        setMasterList(return_data);
    }
    const save_edit_category_title = () => {
        let postData = {
            category_id: editingCategoryId,
            category_title: editingCategoryTitle
        }
        talk("Shopping_Handler.php",
            "UPDATE_CATEGORY_TITLE",
            postData,
            save_edit_category_title_handler
        );
    }
    const save_edit_category_title_handler = (operation, sent_data, return_data) => {
        if(return_data['ERROR']) {
            alert(return_data['ERROR']);
            return;
        }

        setMasterList(return_data);

        close_editing_category_modal();
    }
    const delete_category = () => {
        let postData = {
            category_id: deleteCategoryId
        }
        talk(
            "Shopping_Handler.php",
            "DELETE_CATEGORY",
            postData,
            delete_category_handler
        );
    }
    const delete_category_handler = (operation, sent_data, return_data) => {
        if(return_data['ERROR']) {
            alert(return_data['ERROR']);
            return;
        }

        setMasterList(return_data);

        close_delete_category_modal();
    }

    //OTHER FUNCTIONS
    const show_add_new_item_modal = (event, cat_id) => {
        setNewItemParentCategoryId(cat_id);
        event.stopPropagation();
        setShowModal(true);
    }
    const show_item_modal = (item) => {
        setEditingItem(item);
        setEditingItemTitle(false);
        setEditingItemCheckedStatus(item['checked']);
    }
    const show_edit_category_modal = (event, cat_title, cat_id) => {
        event.stopPropagation();
        setEditingCategoryTitle(cat_title);
        setEditingCategoryId(cat_id);
    }
    const close_editing_category_modal = () => {
        setEditingCategoryTitle(false);
        setEditingCategoryId(false);
    }
    const render_category = (category_info) => {
        return (
            <>
            <div
                className={ShoppingStyles.category}
                onClick={() => toggle_category(category_info['shoppingcatid'])}
            >
                <div>{category_info['category']}</div>
                <div className={searchResults ? GenStyles.hidden : ''}>
                    <button
                        className={category_info['visible'] ?
                            ShoppingStyles.add_new_item_button : GenStyles.hidden}
                        onClick={(event) =>
                            show_add_new_item_modal(event, category_info['shoppingcatid'])}
                    >
                        +
                    </button>
                    <button
                        className={category_info['visible'] ?
                            ShoppingStyles.edit_category_button : GenStyles.hidden}
                        onClick={(event) =>
                            show_edit_category_modal(event,
                                category_info['category'],
                                category_info['shoppingcatid'])}
                    >
                        ✍
                    </button>
                    <button
                        className={category_info['visible'] ?
                            ShoppingStyles.edit_category_button : GenStyles.hidden}
                        onClick={(event) =>
                            show_delete_category_modal(event,
                                category_info['category'],
                                category_info['shoppingcatid'])}
                    >
                        🗑
                    </button>
                </div>
            </div>
                {category_info['visible'] ?
                    (
                        <div className={ShoppingStyles.items_holder}>
                            {category_info['visible'] && category_info['items'] && category_info['items'].map((item, index) => (
                                <div
                                    key={'ITEM_' + item['shoppinglistid']}
                                    className={ShoppingStyles.item}
                                    onClick={() => show_item_modal(item)}
                                >
                                    <div className={ShoppingStyles.checkbox_holder}>
                                        {item['checked'] ? '✔' : ''}
                                    </div>
                                    <div>{item['item']}</div>
                                </div>
                            ))}
                        </div>
                    ) : <div></div>}
            </>
        )
    }
    const toggle_category = (category_id) => {
        console.log(category_id);
        let local_data = JSON.parse(JSON.stringify(masterList));
        for(let i = 0; i < local_data.length; i++) {
            if(local_data[i]['shoppingcatid'] === category_id) {
                local_data[i]['visible'] = !local_data[i]['visible'];
                break;
            }
        }
        setMasterList(local_data);
    }
    const toggle_categories = () => {
        //Don't try to toggle if there are not items
        if(!masterList || masterList.length === 0) {
            return;
        }

        //Get the new status (collapsed or visible)
        let new_status = masterList[0].visible ? false : true;

        //Alter the data
        let local_data = JSON.parse(JSON.stringify(masterList));
        for(let i = 0; i < local_data.length; i++) {
            local_data[i]['visible'] = new_status;
        }

        //Update the state
        setMasterList(local_data);
    }
    const close_modal = () => {
        setNewItemName('');
        setNewItemParentCategoryId(false);
        setShowModal(false);
    }
    const close_shopping_item_modal = () => {
        setEditingItemCheckedStatus(false);
        setEditingItem(false);
    }
    const close_new_category_modal = () => {
        setShowAddNewCategoryModal(false);
        setNewCategoryName('');
    }
    const show_delete_category_modal = (event, category_name, category_id) => {
        setDeleteCategoryName(category_name);
        setDeleteCategoryId(category_id);
    }
    const close_delete_category_modal = () => {
        setDeleteCategoryName('');
        setDeleteCategoryId(0);
    }
    const filter_search = () => {
        let local_data = Array();
        for(let i = 0; i < masterList.length; i++) {
            let local_items = Array();
            for(let j = 0; j < masterList[i]['items'].length; j++) {
                if(masterList[i]['items'][j]['item'].toLowerCase().indexOf(searchKey.toLowerCase()) !== -1) {
                    local_items.push(JSON.parse(JSON.stringify(masterList[i]['items'][j])));
                }
            }
            if(local_items.length > 0) {
                local_data.push(JSON.parse(JSON.stringify(masterList[i])));
                local_data[local_data.length - 1]['items'] = local_items;
                local_data[local_data.length - 1]['visible'] = true;
            }
        }
        setSearchResults(local_data);
        console.log(local_data);
    }
    const toggle_search = () => {
        if(showSearch) {
            setShowSearch(false);
            setSearchResults(false);
            setSearchKey('');
        } else {
            setShowSearch(true);
        }
    }
    const toggle_print_view = () => {
        if(!showPrintView) {
            //Archive master list
            setArchivedMasterList(JSON.parse(JSON.stringify(masterList)));

            //Filter for Print View
            filter_for_print_view();

            //Set Toggle
            setShowPrintView(true);
        } else {
            //Restore Master List
            setMasterList(JSON.parse(JSON.stringify(archivedMasterList)));
            setArchivedMasterList(false);

            //Set Toggle
            setShowPrintView(false);
        }
        setShowPrintView(!showPrintView);
    }
    const filter_for_print_view = () => {
        let local_data = Array();
        for(let i = 0; i < masterList.length; i++) {
            let local_items = Array();
            for(let j = 0; j < masterList[i]['items'].length; j++) {
                if(masterList[i]['items'][j]['checked']) {
                    local_items.push(JSON.parse(JSON.stringify(masterList[i]['items'][j])));
                }
            }
            if(local_items.length > 0) {
                local_data.push(JSON.parse(JSON.stringify(masterList[i])));
                local_data[local_data.length - 1]['items'] = local_items;
                local_data[local_data.length - 1]['visible'] = true;
            }
        }
        setMasterList(local_data);
    }

    //RENDERING
    if(props.authStatus) {
        return (
            <>
                <div className={ShoppingStyles.main_content}>
                    {/* HEADER */}
                    <h3>Shopping List</h3>
                    <div className={ShoppingStyles.header_row}>
                        <button
                            className={!showSearch ? `${GenStyles.button} ${GenStyles.red}` : GenStyles.hidden}
                            onClick={toggle_categories}
                        >
                            Toggle All Categories
                        </button>
                        <button
                            className={!showSearch ? GenStyles.button : GenStyles.hidden}
                            onClick={() => setShowAddNewCategoryModal(true)}
                        >
                            +
                        </button>
                        <button
                            className={!showSearch ? GenStyles.button : GenStyles.hidden}
                            onClick={() => toggle_print_view()}
                        >
                            🖨
                        </button>
                        <div className={ShoppingStyles.search_box_holder}>
                            {/* SEARCH BOX */}
                            <div className={showSearch ? ShoppingStyles.search_results : GenStyles.hidden}>
                                <input
                                    type="text"
                                    className={ShoppingStyles.search_box}
                                    value={searchKey}
                                    placeholder="search terms here"
                                    onChange={(event) => setSearchKey(event.target.value)}
                                />
                            </div>

                            {/* SEARCH BUTTON */}
                            <button
                                className={GenStyles.button}
                                onClick={() => toggle_search()}
                            >
                                🔍
                            </button>
                        </div>

                    </div>



                    {/* SEARCH RESULTS */}
                    <div className={searchResults ? ShoppingStyles.search_results : GenStyles.hidden}>
                        {searchResults && searchResults.map((cat, index) => (
                            <div key={'CAT_' + cat['shoppingcatid']} className={ShoppingStyles.category_holder}>
                                {render_category(cat)}
                            </div>
                            // <div key={'SEARCH_CAT_' + index}>
                            //     <div>{cat['category']}</div>
                            //     {cat['items'] && cat['items'].map((item, index) => (
                            //         <div key={'SEARCH_ITEM_' + index}>{item['item']}</div>
                            //     ))}
                            // </div>
                        ))}
                    </div>

                    {/* MASTER LIST */}
                    {!searchResults && masterList && masterList.map((cat, index) => (
                        <div key={'CAT_' + cat['shoppingcatid']} className={ShoppingStyles.category_holder}>
                            {render_category(cat)}
                        </div>
                    ))}
                </div>



                {/* ADD ITEM MODAL */}
                <div className={showModal ? GenStyles.modal_shown : GenStyles.modal_hidden}>
                    <div className={GenStyles.modal_content}>
                        <div className={GenStyles.modal_header}>
                            <div>{newItemParentCategoryId ? "ADD ITEM" : "ADD CATEGORY"}</div>
                        </div>
                        <div className={GenStyles.modal_body}>
                            {/* NAME AND EDIT NAME */}
                            <div>
                                <input
                                    type="text"
                                    name="title"
                                    value={newItemName}
                                    onChange={(event) => setNewItemName(event.target.value)}
                                    className={CycleStyles.edit_title_input}
                                />
                                <button
                                    onClick={close_modal}
                                >❌</button>
                                <button
                                    onClick={save_item}
                                >💾</button>
                            </div>
                        </div>

                        {/* MODAL FOOTER WITH CLOSE BUTTON */}
                        <div className={GenStyles.modal_footer}>
                            &nbsp;
                        </div>
                    </div>
                </div>

                {/* EDIT ITEM MODAL/MARK/UNMARK MODAL */}
                <div className={editingItem ? GenStyles.modal_shown : GenStyles.modal_hidden}>
                    <div className={GenStyles.modal_content}>
                        <div className={GenStyles.modal_header}>
                            <div>ITEM:</div>
                        </div>
                        <div className={GenStyles.modal_body}>
                            {/* NAME AND EDIT NAME */}
                            <div className={editingItemTitle ? '' : GenStyles.hidden}>
                                <input
                                    type="text"
                                    name="title"
                                    value={editingItemTitle}
                                    onChange={(event) => setEditingItemTitle(event.target.value)}
                                    className={CycleStyles.edit_title_input}
                                />
                                <button
                                    onClick={() => setEditingItemTitle(false)}
                                >❌</button>
                                <button
                                    onClick={save_item_title}
                                >💾</button>
                            </div>
                            <div
                                className={editingItemTitle ? GenStyles.hidden : ShoppingStyles.itemModalRow}
                            >
                                <div className={ShoppingStyles.checkbox_container}>
                                    <input
                                        type="checkbox"
                                        className={ShoppingStyles.item_checkbox}
                                        checked={editingItemCheckedStatus}
                                        onChange={(event) =>
                                            toggle_shopping_item(event)}
                                    />
                                </div>
                                <div
                                    className={ShoppingStyles.item_title}
                                    onClick={() => setEditingItemTitle(editingItem['item'])}
                                >
                                    {editingItem['item']}
                                </div>
                            </div>
                        </div>

                        {/* MODAL FOOTER WITH CLOSE BUTTON */}
                        <div className={GenStyles.modal_footer}>
                            <div className={areYouSureDeleteItem ? ShoppingStyles.ays : GenStyles.hidden}>
                                <div className={ShoppingStyles.ays_message}>
                                    Are you sure you want to delete this?
                                </div>
                                <button
                                    className={`${GenStyles.button} ${GenStyles.black} ${GenStyles.font_white}`}
                                    onClick={() => delete_current_shopping_item()}
                                >
                                    Delete
                                </button>
                                <button
                                    className={GenStyles.button}
                                    onClick={() => setAreYouSureDeleteItem(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                            <div className={areYouSureDeleteItem ? GenStyles.hidden : ''}>
                                <button
                                    className={`${GenStyles.button} ${GenStyles.black} ${GenStyles.font_white}`}
                                    onClick={() => setAreYouSureDeleteItem(true)}
                                >
                                    Delete
                                </button>
                                <button
                                    className={GenStyles.button}
                                    onClick={close_shopping_item_modal}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* EDIT CATEGORY TITLE MODAL */}
                <div className={editingCategoryTitle ? GenStyles.modal_shown : GenStyles.modal_hidden}>
                    <div className={GenStyles.modal_content}>
                        <div className={GenStyles.modal_header}>
                            <div>EDIT CATEGORY NAME</div>
                        </div>
                        <div className={GenStyles.modal_body}>
                            {/* NAME AND EDIT NAME */}
                            <div>
                                <input
                                    type="text"
                                    name="title"
                                    value={editingCategoryTitle}
                                    onChange={(event) => setEditingCategoryTitle(event.target.value)}
                                    className={CycleStyles.edit_title_input}
                                />
                                <button
                                    onClick={close_editing_category_modal}
                                >❌</button>
                                <button
                                    onClick={save_edit_category_title}
                                >💾</button>
                            </div>
                        </div>

                        {/* MODAL FOOTER WITH CLOSE BUTTON */}
                        <div className={GenStyles.modal_footer}>
                            &nbsp;
                        </div>
                    </div>
                </div>

                {/* ADD CATEGORY MODAL */}
                <div className={showAddNewCategoryModal ? GenStyles.modal_shown : GenStyles.modal_hidden}>
                    <div className={GenStyles.modal_content}>
                        <div className={GenStyles.modal_header}>
                            <div>ADD CATEGORY</div>
                        </div>
                        <div className={GenStyles.modal_body}>
                            {/* NAME AND EDIT NAME */}
                            <div>
                                <input
                                    type="text"
                                    name="title"
                                    value={newCategoryName}
                                    onChange={(event) => setNewCategoryName(event.target.value)}
                                    className={CycleStyles.edit_title_input}
                                />
                                <button
                                    onClick={close_new_category_modal}
                                >❌</button>
                                <button
                                    onClick={save_new_category}
                                >💾</button>
                            </div>
                        </div>

                        {/* MODAL FOOTER WITH CLOSE BUTTON */}
                        <div className={GenStyles.modal_footer}>
                            &nbsp;
                        </div>
                    </div>
                </div>

                {/* DELETE CATEGORY MODAL */}
                <div className={deleteCategoryName ? GenStyles.modal_shown : GenStyles.modal_hidden}>
                    <div className={GenStyles.modal_content}>
                        <div className={GenStyles.modal_header}>
                            <div>CATEGORY: {deleteCategoryName}</div>
                        </div>
                        <div className={GenStyles.modal_body}>
                            {/* NAME AND EDIT NAME */}
                            <div>
                                Are you sure you want to delete this category and its items?
                                <button
                                    onClick={close_delete_category_modal}
                                    title="Cancel"
                                >❌</button>
                                <button
                                    onClick={delete_category}
                                    title="Delete"
                                >🗑</button>
                            </div>
                        </div>

                        {/* MODAL FOOTER WITH CLOSE BUTTON */}
                        <div className={GenStyles.modal_footer}>
                            &nbsp;
                        </div>
                    </div>
                </div>

            </>
        )
    } else {
        return <></>
    }
}

export default Shopping;