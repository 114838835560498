import Styles from "../styles/ofbstyles.module.css";
import SimpleEntry from "./SimpleEntry";
import FStyles from "../styles/finance.module.css";
import React, {useEffect, useState} from "react";
import {format_currency, talk} from "../ofb_util";
// import Form_Transaction_Part from "./Form_Transaction_Part";
// import Form_Transaction from "./Form_Transaction";
// import ConfirmModal from "../ConfirmModal";
// import confirmModal from "../ConfirmModal";
// import Transaction from "./Transaction";
// import EditTransactionModal from "./Edit_Transaction_Modal";

const AccountView = (props) => {

    return (
        <div>
            <SimpleEntry account_name="Checking-Central Bank"/>
        </div>
    )
}

export default AccountView;