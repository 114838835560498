import Styles from "../styles/ofbstyles.module.css";
import React from "react";

const DetailRow = (props) => {
    return (
        <tr id={`details_row_${props['all_data']['bookid']}`}
            className={`w3-hide ${Styles.ofb_library_details_table_row}`}>
            <td colSpan='4' className={Styles.ofb_library_details_table_row}>
                <div className={Styles.ofb_library_detail_row}>
                    <div className={Styles.ofb_library_detail_label_cell}>Series Name</div>
                    <div>{props['all_data']['series']}</div>
                </div>
                <div className={Styles.ofb_library_detail_row}>
                    <div className={Styles.ofb_library_detail_label_cell}>Series Number</div>
                    <div>{props['all_data']['seriesnumber']}</div>
                </div>
                <div className={Styles.ofb_library_detail_row}>
                    <div className={Styles.ofb_library_detail_label_cell}>Status</div>
                    <div>{props['all_data']['status']}</div>
                </div>
                <div className={Styles.ofb_library_detail_row}>
                    <div className={Styles.ofb_library_detail_label_cell}>Source</div>
                    <div>{props['all_data']['source']}</div>
                </div>
                <div className={Styles.ofb_library_detail_row}>
                    <div className={Styles.ofb_library_detail_label_cell}>Type</div>
                    <div>{props['all_data']['type']}</div>
                </div>
                <div className={Styles.ofb_library_detail_row}>
                    <div className={Styles.ofb_library_detail_label_cell}>Date Read</div>
                    <div>{props['all_data']['dateread']}</div>
                </div>
                <div className={Styles.ofb_library_detail_row}>
                    <div className={Styles.ofb_library_detail_label_cell}>Review</div>
                    <div>{props['all_data']['review']}</div>
                </div>
                <div className={Styles.ofb_library_detail_row}>
                    <div className={Styles.ofb_library_detail_label_cell}>LCN</div>
                    <div>{props['all_data']['lc_classification']}</div>
                </div>
                <div className={Styles.ofb_library_detail_row}>
                    <div className={Styles.ofb_library_detail_label_cell}>&nbsp;</div>
                    <div><button className={Styles.ofb_button} onClick={() => props.show_edit_form(props['all_data'])}>Edit</button></div>
                </div>
            </td>
        </tr>
    )
}

export default DetailRow;