import React, {useState} from "react";
import FStyles from "../styles/finance.module.css"
import {format_currency_new} from "../ofb_util";

const ImportTransactions = (props) => {
    const [importText, setImportText] = useState('');
    const [previewTable, setPreviewTable] = useState(false);

    const create_preview = () => {
        let table = [];
        let rows = importText.split('\n');
        for(let i = 0; i < rows.length; i++) {
            table.push(rows[i].split('\t'));
        }
        setPreviewTable(table);
        console.log(table);
    }

    return (<>
        <h1>Import Transactions</h1>
        <textarea
            className={FStyles.import_transactions_textbox}
            value={importText}
            onChange={(event) => setImportText(event.target.value)}
        ></textarea>
        <button
            className={FStyles.import_button}
            onClick={create_preview}
        >Import</button>
        <div className={FStyles.import_preview_holder}>
            <div><input type="checkbox" /> First Row Contains Column Headings?</div>
            <table>
                <tbody>
                    {previewTable && previewTable.map((row, index) => (
                        <tr key={index}>
                            {row && row.map((cell, cell_index) => (
                                <td key={cell_index}>{cell}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </>)
}

export default ImportTransactions;