import React from "react";
import Styles from "../styles/ofbstyles.module.css";
import {format_currency_new} from "../ofb_util";

const NetWorth = (props) => {
    console.log(props.net_worth_report);
    return (
        <>
        <div className={Styles.ofb_finance_net_worth}><b>Net Worth</b>: {format_currency_new(props.value)}</div>
        <div>
            <div><b>Cash: </b> {format_currency_new(props.net_worth_report['CASH'])}</div>
            <div><b>Credit: </b> {format_currency_new(props.net_worth_report['CREDIT'])}</div>
            <div>[<b>Loans: </b> {format_currency_new(props.net_worth_report['LOAN'])}]</div>
        </div>
        </>
    )
}
export default NetWorth;