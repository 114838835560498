import Styles from "../styles/ofbstyles.module.css";
import LibStyles from "../styles/library.module.css";
import React, {useEffect, useState} from "react";
import {load_fetch, talk} from "../ofb_util";

const Bulk_Import = (props) => {
    const [csvData, setCsvData] = useState('');
    const [testResults, setTestResults] = useState('');
    const [importResults, setImportResults] = useState('');


    const evaluate_csv_data = () => {
        setImportResults('');
        talk("Library_Handler.php",
            "EVALUATE_CSV_DATA", {data: csvData}, evaluate_csv_data_handler);
    }
    const evaluate_csv_data_handler = (operation, data, return_data) => {
        setTestResults(return_data.map(el => JSON.stringify(el)).join(",\n")
            .replaceAll(',', ',\n').replaceAll('},', '\n\n')
            .replaceAll('{', ''));
    }
    const proceed_with_import = () => {
        talk("Library_Handler.php",
            "IMPORT_CSV_DATA", {data: csvData}, proceed_with_import_handler);
    }
    const proceed_with_import_handler = (operation, data, return_data) => {
        setTestResults('');
        setImportResults(JSON.stringify(return_data, null, 2).replaceAll('", "', '",\n"'));
        console.log(return_data);
    }
    return (<>
        <form>
            <div className={LibStyles.bulk_import_form_row}>
                <textarea
                    placeholder="Paste CSV Data Here"
                    className={LibStyles.bulk_import_textarea}
                    value={csvData}
                    onChange={(event) => setCsvData(event.target.value)}
                ></textarea>
            </div>
            <div className={LibStyles.bulk_import_form_row}>
                <button
                    type="button"
                    className={Styles.ofb_button}
                    onClick={evaluate_csv_data}
                >Test Data</button>
                <div className={LibStyles.test_results_div}>
                    <pre>{testResults}</pre>
                </div>
            </div>
            <div className={testResults ? LibStyles.bulk_import_form_row : Styles.ofb_hide}>
                <button
                    type="button"
                    className={Styles.ofb_button}
                    onClick={proceed_with_import}
                    >Proceed with Import</button>
            </div>
            <div className={importResults ? LibStyles.bulk_import_form_row : Styles.ofb_hide}>
                <div className={LibStyles.test_results_div}>
                    <pre>{importResults}</pre>
                </div>
            </div>
        </form>
    </>)
}

export default Bulk_Import;