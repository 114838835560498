import React, {useEffect, useState} from "react";
import {getDateInYYYYMMDD, load_fetch} from "./ofb_util";
import Styles from "./styles/ofbstyles.module.css";
import CleaningSubItem from "./ToDo/cleaning_subitem";
import CleaningSubItemForm from "./ToDo/new_cleaning_subitem_form";
import TrashCan from "./images/trash_can.png";

const Cleaning = (props) => {
    //DATA
    const [cleaningItems, setCleaningItems] = useState({});
    const [selectedItem, setSelectedItem] = useState(false);
    const today = getDateInYYYYMMDD(new Date());
    const [editingTitle, setEditingTitle] = useState(false);
    const [editingDetails, setEditingDetails] = useState(false);
    const [editingDueDate, setEditingDueDate] = useState(false);
    const [deletingItem, setDeletingItem] = useState(false);
    const [cleaningCategories, setCleaningCategories] = useState({});

    //USEEFFECTS
    useEffect(() => {
        get_cleaning_items();
        get_cleaning_categories();
    }, []);
    useEffect(() => {
        if(editingTitle) {
            return;
        }
        if(selectedItem) {
            let nst = document.getElementById('new_subitem_title');
            if(nst) {
                nst.focus();
            }
        }
    }, [selectedItem])
    useEffect(() => {
        if(props.authStatus) {
            get_cleaning_items();
        } else {
            setCleaningItems({});
        }
    }, [props.authStatus]);


    //AJAX FUNCTIONS
    const get_cleaning_items = () => {
        let url = 'Cleaning_Handler.php';
        let postData = {
            operation: 'GET_CLEANING'
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                console.log(data);
                setCleaningItems(data);
            } else {
                console.log("CLEANING ITEMS NOT FOUND: ERROR");
                console.log(data);
                setCleaningItems({});
            }
        });
    }
    const mark_completed = () => {
        let url = 'Cleaning_Handler.php';
        let postData = {
            operation: 'MARK_COMPLETED',
            cleaning_item_id: selectedItem['cleaning_item_id']
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                get_cleaning_items();
                close_modal();
            } else {
                //setTodoItems({});
            }
        });
    }
    const get_cleaning_categories = () => {
        let url = 'Cleaning_Handler.php';
        let postData = {
            operation: 'GET_CLEANING_CATEGORIES'
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                setCleaningCategories(data);
            } else {
                setCleaningCategories({});
            }
        });
    }

    const set_selected_item = (item) => {
        console.log(item);
        setSelectedItem(item);
    }
    const close_modal = () => {
        //Clear the Form
        setEditingTitle(false);
        setEditingDetails(false);
        setEditingDueDate(false);
        setDeletingItem(false);

        //Close the Modal
        setSelectedItem(false);
    }
    function recursive_add_subitem(local_cleaning_data, new_subitem_data, parent_id) {
        for(let key in local_cleaning_data) {
            if(typeof local_cleaning_data[key] == 'object' && local_cleaning_data[key] !== null) {
                if(local_cleaning_data[key]['cleaning_item_id']) {
                    if(local_cleaning_data[key]['cleaning_item_id'] === parent_id) {
                        new_subitem_data['depth'] = local_cleaning_data[key]['depth'] + 1;
                        if(!local_cleaning_data[key]['sub_items']) {
                            local_cleaning_data[key]['sub_items'] = [];
                        }
                        local_cleaning_data[key]['sub_items'].push(new_subitem_data);
                    }
                }
                recursive_add_subitem(local_cleaning_data[key], new_subitem_data, parent_id);
            }
        }
        return local_cleaning_data;
    }
    const on_save_new_subitem = (new_subitem_data) => {
        //Update the data
        let local_data = JSON.parse(JSON.stringify(cleaningItems));
        local_data = recursive_add_subitem(local_data, new_subitem_data, new_subitem_data['parent_id']);

        //Set the State
        setCleaningItems(local_data);

        //Close the Modal and Clear Selected Item
        setSelectedItem(false);
    }
    const edit_title = () => {
    //     setEditingTitle(selectedItem['title']);
    }





    if(props.authStatus) {
        return (
            <>
                {/*<button
                    className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                    onClick={navigate_to_todo}
                >TODO Complete List
                </button>
                <button
                    className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                    onClick={toggle_active_only}
                >Toggle Active Only
                </button>
                */}

                <div className={Styles.ofb_todo_simple}>
                    {
                        cleaningItems.length > 0 && cleaningItems.map((item, index) => (
                            <div
                                className={Styles.ofb_cleaning_item_box}
                                key={item['cleaning_item_id']}
                            >
                                {/* Outputting the top level item */}
                                <div
                                    className={Styles.ofb_todo_simple_item}
                                    onClick={() => set_selected_item(item)}
                                ><b>{item['name']}</b>
                                </div>

                                {/* Mapping the subitems*/}
                                {item['sub_items'].length > 0 && item['sub_items'].map((sub_item, sub_index) => (
                                    <CleaningSubItem
                                        key={sub_item['cleaning_item_id']}
                                        data={sub_item}
                                        set_selected_item={set_selected_item}
                                        today={today}
                                    />
                                ))}
                            </div>
                        ))}
                </div>

                {/* MODAL */}
                <div className={selectedItem ? Styles.ofb_modal_shown : Styles.ofb_modal_hidden}>
                    <div className={Styles.ofb_modal_content}>
                        <div className={Styles.ofb_modal_header}>
                            <div>Cleaning Item</div>
                            <div><button
                                className={Styles.ofb_learning_close_results_button}
                                onClick={close_modal}
                            >X</button></div>
                        </div>
                        <div className={Styles.ofb_modal_body}>
                            {/* NAME AND EDIT NAME */}
                            <div
                                className={Styles.ofb_cursor_pointer}
                                onClick={edit_title}>{editingTitle ?
                                    <>
                                    <input
                                        type="text"
                                        name="name"
                                        //value={editingTitle}
                                        //onChange={update_selected_item}
                                        className={Styles.ofb_todo_edit_title_input}
                                    />
                                    <button
                                        //onClick={cancel_edit_title}
                                        >❌</button>
                                    <button
                                        //onClick={save_edit_title}
                                    >💾</button>
                                    </>
                                : selectedItem['name']}</div>

                            {/* DETAILS AND EDIT DETAILS */}
                            {/*
                            <div
                                className={`${Styles.ofb_font_size_12} ${Styles.ofb_cursor_pointer}`}
                                //onClick={edit_details}
                            >{editingDetails !== false ? <>
                                <input
                                    type="text"
                                    //value={editingDetails}
                                    name="details"
                                    //onChange={update_selected_item}
                                    />
                                <button
                                    //onClick={cancel_edit_details}
                                >❌</button>
                                <button
                                    //onClick={save_edit_details}
                                >💾</button>
                            </> : (selectedItem['details'] ?
                                selectedItem['details'] : "-NO DETAILS-")}</div>
                                */}

                            {/* DUE DATE FOR EXISTING ITEM */}
                            {/*
                            <div className={`${Styles.ofb_font_size_12} ${Styles.ofb_cursor_pointer}`}
                                 //onClick={edit_due_date}
                            >
                                <b>Due Date</b>: {editingDueDate !== false ? <>
                                    <input
                                        type="date"
                                        className={Styles.ofb_new_todo_form_text_input}
                                        name="duedate"
                                        value={editingDueDate}
                                        //onChange={update_selected_item}
                                    />
                                    <button
                                        //onClick={cancel_edit_duedate}
                                    >❌</button>
                                    <button
                                        //onClick={save_edit_duedate}
                                    >💾</button>
                                </> :
                                selectedItem['duedate']}
                            </div>
                            */}

                            {/* MARK COMPLETED BUTTONS */}
                            <div className={Styles.ofb_todo_completed_row}>
                            {selectedItem['depth'] > 0 ? (selectedItem['completed'] ? (
                                <div className={Styles.ofb_todo_mark_completed_button_div}>
                                    <button className={Styles.ofb_modal_footer_button}
                                            //onClick={toggle_completed_status}
                                        >
                                        Mark Uncompleted
                                    </button>
                                </div>
                            ) : (
                                <div className={Styles.ofb_todo_mark_completed_button_div}>
                                    <button className={Styles.ofb_modal_footer_button}
                                            onClick={mark_completed}
                                        >
                                        Mark Completed
                                    </button>
                                </div>
                            )) : ''}

                                {/* DELETE BUTTON */}
                                <div className={Styles.ofb_todo_mark_completed_button_div}>
                                    <button
                                        className={Styles.ofb_modal_footer_button}
                                        //onClick={delete_selected_item}
                                    ><img src={TrashCan} /></button>
                                </div>
                            </div>
                            <div className={deletingItem ? Styles.ofb_todo_ays : Styles.ofb_hide}>
                                <div>Are you sure you want to delete this item?</div>
                                {(selectedItem['sub_items'] && selectedItem['sub_items'].length > 0) ? <div>It has subitems.</div> : ''}
                                <div>
                                    <button
                                        className={Styles.ofb_button}
                                        //onClick={cancel_deleting}
                                    >Cancel</button>
                                    <button
                                        className={Styles.ofb_button}
                                        //onClick={handle_deleting}
                                    >Delete</button>
                                </div>
                            </div>



                            {/* NEW SUBITEM FORM */}
                                <CleaningSubItemForm
                                    is_shown={selectedItem ? true : false}
                                    parent_id={selectedItem['cleaning_item_id']}
                                    on_save={on_save_new_subitem}
                                    categories={cleaningCategories}
                                />
                        </div>

                        {/* MODAL FOOTER WITH CLOSE BUTTON */}
                        <div className={Styles.ofb_modal_footer}>
                            <button
                                className={Styles.ofb_modal_footer_button}
                                onClick={close_modal}
                            >Close
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return <></>
    }
}
export default Cleaning;