import React, {useEffect, useState} from "react";
import Wobble from "./Wobble.js";

const Games = () => {

    return <>
        <Wobble />
    </>
}

export default Games;