import React, {useEffect, useState, useContext } from "react";
import Styles from "./styles/ofbstyles.module.css";
import {format_currency, load_fetch, talk} from "./ofb_util";
import FinanceTransaction from "./Finance/Transaction";
import Accounts from "./Finance/Accounts";
import Ledger from "./Finance/Ledger";
import NetWorth from "./Finance/NetWorth";
import GivingReport from "./Finance/GivingReport";

const Finance = (props) => {
    //DATA
    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState({});
    const [transactions, setTransactions] = useState([]);
    const [payees, setPayees] = useState([]);
    const [categories, setCategories] = useState([]);
    const [navigation, setNavigation] = useState({});
    const [netWorth, setNetWorth] = useState(0);
    const [netWorthReport, setNetWorthReport] = useState([]);
    const [givingReport, setGivingReport] = useState([]);
    const [transactionLoadsCount, setTransactionLoadsCount] = useState(0);
    const [tenOrAll, setTenOrAll] = useState(10);

    //TODO: Clear up old TODOs and Notes
    //TODO: Make LEDGER view a different page, so back button works properly
    //TODO: Write code to have a button to recalculate the balance of an account and check to see if you want to update it in the DB (for recovery from an error)
    //TODO: SimpleEntry save transaction should update the "most recent transaction date"
    //TODO: Git commit


    //USE EFFECTS
    useEffect(() => {
        get_giving_report();
        get_accounts();
        get_payees();
        get_categories();
        get_navigation();
        //get_mini_net_worth_report();
    }, []);

    //AJAX FUNCTIONS
    const get_mini_net_worth_report = () => {
        talk(
            "Finance_Handler.php",
            'GET_MINI_NET_WORTH_REPORT',
            null,
            get_mini_net_worth_report_handler);
    }
    const get_mini_net_worth_report_handler = (operation, sent_data, return_data) => {
        if(!return_data['ERROR']) {
            setNetWorthReport(return_data);
        }
    }
    const get_accounts = () => {
        let url = 'Finance_Handler.php';
        let postData = {
            operation: 'GET_ACTIVE_ACCOUNTS'
        }
        load_fetch(url, postData, (data) => {
            //console.log(data);
            if(!data['ERROR'] && !data['error']) {
                setAccounts(data['ACCOUNTS']);
                setNetWorthReport(data['NET_WORTH']);
                setNetWorth(data['NET_WORTH']['CASH'] + data['NET_WORTH']['CREDIT']);
                //console.log(data['NET_WORTH']);
                //get_net_worth();

                //Calculate Net Worth
                // let nw = 0;
                // for(let i = 0; i < data.length; i++) {
                //     nw += data[i]['current_balance'];
                // }
                // setNetWorth(nw);
            } else {
                setAccounts([]);
            }
        });
    }
    const get_net_worth = () => {
        let url = 'Finance_Handler.php';
        let postData = {
            operation: 'GET_NET_WORTH'
        }
        load_fetch(url, postData, (data) => {
            //console.log(data);
            if(!data['ERROR'] && !data['error']) {
                setNetWorth(data);
            } else {
                setNetWorth(0);
            }
        });
    }
    const get_giving_report = () => {
        let url = 'Finance_Handler.php';
        let postData = {
            operation: 'GET_GIVING_REPORT'
        }
        load_fetch(url, postData, (data) => {
            setGivingReport(data);
        });
    }
    const reload_selected_account = () => {
        if(!selectedAccount) {
            return;
        }
        let url = 'Finance_Handler.php';
        let postData = {
            operation: 'GET_SELECTED_ACCOUNT',
            account_id: selectedAccount['account_id']
        }
        load_fetch(url, postData, (data) => {
            //console.log(data);
            if(!data['ERROR'] && !data['error']) {
                setSelectedAccount(data);
            } else {
                setSelectedAccount({});
            }
        });

    }
    const get_transactions = (account_id) => {
        let url = 'Finance_Handler.php';
        let postData = {
            operation: 'GET_ALL_TRANSACTIONS_FOR_ACCOUNT',
            account_id: account_id
        }
        //console.log("START: " + Date.now());
        load_fetch(url, postData, (data) => {
            //console.log("LOADING TRANSACTIONS");
            setTransactionLoadsCount(transactionLoadsCount + 1);
            console.log(data);
            if(!data['ERROR'] && !data['error']) {
                //console.log("TRANSACTIONS:");
                //console.log(data);
                setTransactions(data);
                //console.log("END: " + Date.now());
            } else {
                //console.log(data);
                setTransactions([]);
            }
        });
        let end = Date.now();

    }
    const get_payees = () => {
        let url = 'Finance_Handler.php';
        let postData = {
            operation: 'GET_PAYEES'
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                setPayees(data);
            } else {
                //console.log(data);
                //setAccounts([]);
            }
        });
    }
    const get_categories = () => {
        let url = 'Finance_Handler.php';
        let postData = {
            operation: 'GET_CATEGORIES'
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                setCategories(data);
            } else {
                //console.log(data);
                //setAccounts([]);
            }
        });
    }
    const get_navigation = () => {
        let url = 'Finance_Handler.php';
        let postData = {
            operation: 'GET_NAVIGATION'
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                //console.log("Getting navigation");
                //console.log(data);
                if(!data) {
                    //set navigation to home
                    set_navigation({place:'HOME'});
                } else {
                    //Handle Navigation
                    switch(data['place']) {
                        case 'LEDGER':
                            select_account(data['account_info']);
                            break;
                        case 'HOME':
                            break;
                    }
                }
            } else {
                console.log('Error getting navigation info.');
                console.log(data);
            }
        });
    }
    const set_navigation = (values) => {
        //console.log("Setting navigation:");
        //console.log(values);
        let url = 'Finance_Handler.php';
        let postData = {
            operation: 'SET_NAVIGATION',
            data: values
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                //console.log("Navigation was set");
                //console.log(data);
            } else {
                console.log('Error setting navigation info.');
                console.log(data);
            }
        }, 'text');
    }

    //OTHER FUNCTIONS
    const reload_all_data = () => {
        get_accounts();
        get_payees();
        get_categories();
        get_giving_report();
        if(selectedAccount) {
            get_transactions(selectedAccount['account_id']);
            reload_selected_account();
        }
    }
    const select_account = (props) => {
        //console.log(props);
        setSelectedAccount(props);
        set_navigation({place:'LEDGER', account_info:props});
        get_transactions(props['account_id']);
    }
    const navigate_to_accounts = () => {
        setSelectedAccount({});
        set_navigation({place:'HOME'});
    }
    const navigate_to_budget = () => {
        document.location.href='./budget';
    }
    const toggle_ten_or_all = () => {
        if(tenOrAll != 10) {
            setTenOrAll(10);
        } else {
            setTenOrAll(transactions.length);
        }
    }


    //RENDERING
    if(props.authStatus) {
    return (
        <div className={Styles.ofb_finance_app}>
            <h1>Finance - Transaction Loads: {transactionLoadsCount}</h1>
            <button className={`${Styles.ofb_button} ${Styles.ofb_red}`} onClick={navigate_to_budget}>Budget</button>

            {/* RENDER THE ACCOUNT LIST IF NO ACCOUNT SELECTED */}
            {Object.keys(selectedAccount).length === 0 && (
                <div className={Styles.ofb_finance_home_holder}>
                    <NetWorth value={netWorth} net_worth_report={netWorthReport} />
                    <Accounts
                        accounts={accounts}
                        handle_select_account={select_account}
                    />
                    <GivingReport data={givingReport} />
                </div>
            )}

            {/* RENDER THE LEDGER IF AN ACCOUNT IS SELECTED */}
            {Object.keys(selectedAccount).length !== 0 && (
                <>
                <button className={Styles.ofb_button} onClick={navigate_to_accounts}>Back to Accounts</button>
                <button className={Styles.ofb_button} onClick={toggle_ten_or_all}>10 or All</button>
                <Ledger
                ten_or_all={tenOrAll}
                account_id={selectedAccount['account_id']}
                account_name={selectedAccount['account_name']}
                is_parent_account={selectedAccount['parent_account_id'] === 0}
                transactions={transactions}
                update_transactions={setTransactions}
                payees={payees}
                categories={categories}
                balance={selectedAccount['current_balance']}
                parent_balance={selectedAccount['parent_balance']}
                cleared_balance={selectedAccount['cleared_balance']}
                reload_balances={reload_selected_account}
                reload={reload_all_data}
            /></>)}
        </div>
    )} else {
        return null;
    }
}

export default Finance;