import ToggleSwitchStyles from "./styles/toggle_switch_module.css";

const ToggleSwitch = (props) => {
    return (
        <label className="switch" title={props.title}>
            <input type="checkbox" onClick={() => {props.toggleClickEventHandler()}}  />
            <span className="slider round"></span>
        </label>
    );
};

export default ToggleSwitch;
