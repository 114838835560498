import React, {useEffect, useState} from "react";
import {getDateInYYYYMMDD, load_fetch, talk} from "./ofb_util";
import GenStyles from "./styles/ofb_gen_styles.module.css";
import OStyles from "./styles/overview_styles.module.css";
import TodayStyles from "./styles/today.module.css";
import TODORapidEntryModule from "./ToDo/todo_rapid_entry_module";

const Overview = (props) => {
    //DATA
    const [calendarEvents, setCalendarEvents] = useState(false);
    const [calendarEventsTomorrow, setCalendarEventsTomorrow] = useState(false);
    const [dailyToday, setDailyToday] = useState(false);
    const [dailyFinishedToday, setDailyFinishedToday] = useState(false);
    const [todoDueToday, setTodoDueToday] = useState(false);
    const [todoDueTomorrow, setTodoDueTomorrow] = useState(false);
    const [todoOverdue, setTodoOverdue] = useState(false);
    const [cycleItems, setCycleItems] = useState(false);
    const [showDailyActionsModal, setShowDailyActionsModal] = useState(false);
    const [showDueTodayActionsModal, setShowDueTodayActionsModal] = useState(false);
    const [showCyclicalActionsModal, setShowCyclicalActionsModal] = useState(false);
    const [showOverdueActionsModal, setShowOverdueActionsModal] = useState(false);
    const [dueTodaySelectedItem, setDueTodaySelectedItem] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedCyclicalItem, setSelectedCyclicalItem] = useState(false);
    const [selectedOverdueItem, setSelectedOverdueItem] = useState(false);



    //USE EFFECTS
    useEffect(() => {
        get_calendar_events("TODAY");
        get_calendar_events("TOMORROW");
        get_today_not_yet_finished();
        get_todo_due_today();
        get_todo_due_tomorrow();
        get_todo_overdue();
        get_cycle_items();
    }, []);

    //AJAX FUNCTIONS
    const get_calendar_events = (todayOrTomorrow) => {
        let url = "calendar_handler.php";
        let operation = "GET_EVENTS_ON_DAY";
        let postData = {
            today_or_tomorrow: todayOrTomorrow,
        }
        talk(url, operation, postData, get_calendar_events_handler);
    }
    const get_calendar_events_handler = (operation, sent_data, return_data) => {
        console.log(return_data);

        //If it fails, try again in one second
        if(return_data['ERROR']) {
            if(sent_data['today_or_tomorrow'] === 'TODAY') {
                setCalendarEvents(false);
            } else {
                setCalendarEventsTomorrow(false);
            }
            setTimeout(get_calendar_events(sent_data['today_or_tomorrow']), 1000);
        } else {
            if(sent_data['today_or_tomorrow'] === 'TODAY') {
                setCalendarEvents(return_data);
            } else {
                setCalendarEventsTomorrow(return_data);
            }
        }
    }
    const get_today_not_yet_finished = () => {
        let url = 'daily_handler.php';
        let operation = 'GET_TODAY_ITEMS_FOR_OVERVIEW';
        talk(url, operation, null, get_today_not_yet_finished_handler);
    }
    const get_today_not_yet_finished_handler = (operation, sent_data, return_data) => {
        console.log(return_data['NOT_DONE']);

        if(!return_data['ERROR']) {
            setDailyToday(return_data['NOT_DONE']);
            setDailyFinishedToday(return_data['DONE']);
        } else {
            setDailyToday(false);
        }
    }
    const get_todo_due_today = () => {
        let url = 'todo_handler.php';
        let operation = 'GET_TODO_DUE_TODAY';
        const postData = {
            specific_date: null
        }
        talk(url, operation, postData, get_todo_due_today_handler);
    }
    const get_todo_due_today_handler = (operation, sent_data, return_data) => {
        console.log(return_data);
        if(return_data && "error" in return_data) {
            console.log("ERROR: " + return_data['error']);
            setTodoDueToday(false);
        } else if (!return_data || return_data.length === 0) {
            setTodoDueToday(false);
        } else {
            setTodoDueToday(return_data);
        }
    }
    const get_todo_due_tomorrow = () => {
        let url = 'todo_handler.php';
        let operation = 'GET_TODO_DUE_TOMORROW';
        const postData = {
            specific_date: null
        }
        talk(url, operation, postData, get_todo_due_tomorrow_handler);
    }
    const get_todo_due_tomorrow_handler = (operation, sent_data, return_data) => {
        console.log(return_data);
        if(return_data && "error" in return_data) {
            console.log("ERROR: " + return_data['error']);
            setTodoDueTomorrow(false);
        } else if (return_data) {
            setTodoDueTomorrow(return_data);
        } else {
            setTodoDueTomorrow(false);
        }
    }
    const get_todo_overdue = () => {
        let url = 'todo_handler.php';
        let operation = 'GET_OVERDUE_ITEMS';
        talk(url, operation, null, get_todo_overdue_handler);
    }
    const get_todo_overdue_handler = (operation, sent_data, return_data) => {
        console.log(return_data);
        if(return_data && "error" in return_data || "ERROR" in return_data) {
            console.log("ERROR: " + return_data['error']);
            setTodoOverdue(false);
        } else if (!return_data || return_data.length === 0) {
            setTodoOverdue(false);
        } else {
            setTodoOverdue(return_data);
        }
    }
    const get_cycle_items = () => {
        talk("Cycles_Handler.php", "GET_CYCLE_ITEMS", null, get_cycle_items_handler);
    }
    const get_cycle_items_handler = (operation, sent_data, return_data) => {
        console.log(return_data);
        if(return_data && !return_data['ERROR']) {
            setCycleItems(return_data);
        } else {
            setCycleItems(false);
        }
    }
    const mark_todo_item_done = (todoid) => {
        //Mark Item Completed
        const postData = {
            operation: "TOGGLE_COMPLETED",
            todoid: todoid,
            completed: dueTodaySelectedItem['completed'] ? '' : 'NOW'
        }
        talk("todo_handler.php", "MARK_ITEM_COMPLETED", postData, mark_todo_item_done_handler);
    }
    const mark_todo_item_done_handler = (operation, sent_data, return_data) => {
        if(return_data === true) {
            get_todo_due_today();
            hide_due_today_actions_modal();
        } else if(return_data && "ERROR" in return_data) {
            console.log("ERROR: FAILED TO MARK ITEM COMPLETED");
            console.log(return_data);
        } else {
            console.log(return_data);
        }
    }
    const mark_daily_item_done = (dailyid) => {
        //Mark Item Completed
        const postData = {
            operation: "MARK_ITEM_DONE",
            id: dailyid,
            specific_date: null
        }
        talk("daily_handler.php", "MARK_ITEM_DONE", postData, mark_daily_item_done_handler);
    }
    const mark_daily_item_done_handler = (operation, sent_data, return_data) => {
        if(return_data && "ERROR" in return_data) {
            console.log("ERROR: FAILED TO MARK ITEM COMPLETED");
            console.log(return_data);
        } else if (return_data) {
            get_today_not_yet_finished();
            hide_daily_actions_modal();
        } else {
            console.log(return_data);
        }
    }
    const mark_daily_item_not_done = (daily_id) => {
        console.log(daily_id);
        const postData = {
            daily_id: daily_id,
            date_done_for: null
        };
        talk(
            "daily_handler.php",
            "MARK_DAILY_ITEM_NOT_DONE",
            postData,
            mark_daily_item_not_done_handler
        );
    }
    const mark_daily_item_not_done_handler = (operation, sent_data, return_data) => {
        console.log(return_data);
        if(!return_data['ERROR']) {
            get_today_not_yet_finished();
            hide_daily_actions_modal();
        }
    }
    const mark_cyclical_item_done = (cyclical_item_id) => {
        let url = 'Cycles_Handler.php';
        let postData = {
            cycle_item_id: cyclical_item_id
        }
        talk(url, 'MARK_COMPLETED', postData, mark_cyclical_item_done_handler);
    }
    const mark_cyclical_item_done_handler = (operation, sent_data, return_data) => {
        console.log(return_data);
        if(!return_data['ERROR'] && !return_data['error']) {
            get_cycle_items();
            setShowCyclicalActionsModal(false);
        }
    }
    const modify_overdue_item = (operation) => {
        let postData = {
            todoid: selectedOverdueItem['todoid']
        }
        talk("todo_handler.php", operation, postData, modify_overdue_item_handler);
    }
    const modify_overdue_item_handler = (operation, sent_data, return_data) => {
        if(return_data === true || return_data['SUCCESS']) {
            switch (operation) {
                case "MOVE_ITEM_TO_TODAY":
                    get_todo_due_today();
                    break;
                case "MOVE_ITEM_TO_TOMORROW":
                    get_todo_due_tomorrow();
                    break;
                default:
                    //
                    break;
            }
            get_todo_overdue();
            hide_overdue_item_actions_modal();

        } else {
            console.log(return_data);
        }
    }
    const move_todo_item_to_tomorrow = () => {
        let postData = {
            todoid: dueTodaySelectedItem['todoid']
        }
        console.log(postData);
        talk("todo_handler.php", "MOVE_ITEM_TO_TOMORROW", postData, move_todo_item_to_tomorrow_handler);
    }
    const move_todo_item_to_tomorrow_handler = (operation, sent_data, return_data) => {
        if(return_data === true || return_data['SUCCESS']) {
            get_todo_due_today();
            get_todo_due_tomorrow();
            setDueTodaySelectedItem(false);
            setShowDueTodayActionsModal(false);
        } else {
            console.log(return_data);
        }
    }

    //OTHER FUNCTIONS
    const move_overdue_item_to_today = () => {
        modify_overdue_item("MOVE_ITEM_TO_TODAY");
    }
    const move_overdue_item_to_tomorrow = () => {
        modify_overdue_item("MOVE_ITEM_TO_TOMORROW");
    }
    const remove_overdue_duedate = () => {
        modify_overdue_item("REMOVE_DUEDATE");
    }
    const mark_overdue_item_done = () => {
        modify_overdue_item("MARK_ITEM_COMPLETED");
    }
    const render_cycle_item = (item) => {
        let countdown = calculate_countdown(item);
        if (countdown > 0) return <></>;
        return (
            <div>
                <div>{render_item_title(item, countdown)}</div>
                {item['sub_items'] &&
                    item['sub_items'].map((sub_item, sub_item_index) => (
                    <div
                        className={OStyles.cycle_items_div}
                        key={sub_item_index}
                        onClick={(event) => show_cyclical_actions_modal(event, sub_item)}
                    >{render_cycle_item(sub_item)}</div>
                ))}
            </div>
        )
    }
    const render_item_title = (item, countdown) => {
        //let countdown = calculate_countdown(item);
        if(countdown === '') {
            return item['title'];
        } else if(countdown === '-') {
            return <span className={OStyles.never_done}>{item['title'] + ' [' + countdown + ']'}</span>
        } else if(countdown === 0) {
            return <span className={OStyles.due_now}>{item['title'] + ' [' + countdown + ']'}</span>
        } else if (countdown <= 0) {
            return <span className={OStyles.overdue}>{item['title'] + ' [' + countdown + ']'}</span>
        }
    }
    const calculate_countdown = (item) => {
        if(!item['frequency']) {
            return '';
        } else if(!item['completion_data']) {
            return '-';
        } else {
            return item['frequency'] - item['completion_data']['days_elapsed'];
        }

    }
    const hide_daily_actions_modal = () => {
        setShowDailyActionsModal(false);
    }
    const hide_due_today_actions_modal = () => {
        setShowDueTodayActionsModal(false);
    }
    const hide_cyclical_actions_modal = () => {
        setSelectedCyclicalItem(false);
        setShowCyclicalActionsModal(false);
    }
    const show_daily_actions_modal = () => {
        setShowDailyActionsModal(true);
    }
    const show_due_today_actions_modal = () => {
        setShowDueTodayActionsModal(true);
    }
    const due_today_item_click_handler = (info) => {
        setDueTodaySelectedItem(info);
        show_due_today_actions_modal();
    }
    const dailyItemClickHandler = (info) => {
        setSelectedItem(info);
        show_daily_actions_modal();
    }
    const show_cyclical_actions_modal = (event, cyclical_item) => {
        event.stopPropagation();
        setSelectedCyclicalItem(cyclical_item);
        setShowCyclicalActionsModal(true);
    }
    const select_overdue_item = (event, overdue_item) => {
        event.stopPropagation();
        setSelectedOverdueItem(overdue_item);
        setShowOverdueActionsModal(true);
    }
    const hide_overdue_item_actions_modal = () => {
        setSelectedOverdueItem(false);
        setShowOverdueActionsModal(false);
    }

    //RENDERING
    if(props.authStatus) {
        return (<>
            <div className={OStyles.main_content}>
                <div className={OStyles.section}>
                    <TODORapidEntryModule handle_add_item={get_todo_due_today} />
                </div>
                <div className={OStyles.section}>
                    <div className={OStyles.section_heading}>Calendar Items</div>
                    <div className={OStyles.subsection_heading}>Today</div>
                    <div className={OStyles.calendar_events_div}>
                        <ul>
                            {calendarEvents && calendarEvents.map((item, index) => (
                                <li key={'calendar_' + index}>
                                    {item['title']}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={OStyles.subsection_heading}>Tomorrow (maybe make this 'next 7 days'?)</div>
                    <div className={OStyles.calendar_events_div}>
                        <ul>
                            {calendarEventsTomorrow && calendarEventsTomorrow.map((item, index) => (
                                <li key={'calendar_' + index}>
                                    {item['title']}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className={OStyles.section}>
                    <div className={OStyles.section_heading}>Today Daily Items</div>
                    <div className={OStyles.subsection_heading}>Not Yet Finished Today</div>
                    <div className={OStyles.daily_events_div}>
                        <ul>
                            {dailyToday && dailyToday.map((item, index) => (
                                <li
                                    key={'daily_' + index}
                                    onClick={() => dailyItemClickHandler(item)}
                                    className={GenStyles.pointer}
                                >
                                    {item['title']}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={OStyles.subsection_heading}>Finished Today</div>
                    <div className={OStyles.daily_events_div}>
                        <ul>
                            {dailyFinishedToday && dailyFinishedToday.map((item, index) => (
                                <li
                                    key={'daily_' + index}
                                    className={GenStyles.pointer}
                                    onClick={() => mark_daily_item_not_done(item['dailyid'])}
                                >
                                    <span className={GenStyles.strike}>{item['title']}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={OStyles.subsection_heading}>Not Finished This Week</div>
                    <div>not implemented yet</div>
                </div>
                <div className={OStyles.section}>
                    <div className={OStyles.section_heading}>TODO Items</div>
                    <div className={OStyles.subsection_heading}>Due Today</div>
                    <div className={OStyles.todo_items_div}>
                        <ul>
                            {todoDueToday && todoDueToday.map((item, index) => (
                                <li
                                    key={'todo_due_today_' + index}
                                    className={`${GenStyles.pointer} ${item['completed'] ? OStyles.completed : ''}`}
                                    onClick={() => due_today_item_click_handler(item)}
                                >
                                    {item['title']}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={OStyles.subsection_heading}>Due Tomorrow</div>
                    <div className={OStyles.todo_items_div}>
                        <ul>
                            {todoDueTomorrow && todoDueTomorrow.map((item, index) => (
                                <li key={'todo_due_tomorrow_' + index}>
                                    {item['title']}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={OStyles.subsection_heading}>Overdue</div>
                    <div className={OStyles.todo_items_div}>
                        <ul>
                            {todoOverdue && todoOverdue.map((item, index) => (
                                <li
                                    key={'todo_overdue_' + index}
                                    className={GenStyles.pointer}
                                    onClick={(event) =>
                                        select_overdue_item(event, item)}
                                >
                                    {item['title']}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className={OStyles.section}>
                    <div className={OStyles.section_heading}>Cycle Items</div>
                    <div>
                        {cycleItems && cycleItems.map((item, index) => (
                            <div key={item['cycle_item_id']}>
                                {render_cycle_item(item)}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* DUE TODAY ACTIONS MODAL */}
            <div id="due_today_actions_modal"
                 className={showDueTodayActionsModal ? GenStyles.modal_shown : GenStyles.modal_hidden}>
                <div className={GenStyles.modal_content}>
                    <header className={`${GenStyles.container} ${GenStyles.red} ${GenStyles.font_white}`}>
                            <span
                                onClick={hide_due_today_actions_modal}
                                className={`${GenStyles.button} ${GenStyles.display_top_right}`}
                            >&times;</span>
                        <h3 id="show_more_header_date">Due Today Item Actions</h3>
                    </header>

                    <div
                        className={`${GenStyles.container} ${TodayStyles.actions_holder}`}
                        id="modal_actions_holder"
                    >
                        <div className={TodayStyles.actions_title}>
                            {dueTodaySelectedItem ? dueTodaySelectedItem['title'] : ""}
                        </div>
                        <button
                            className={`${GenStyles.button} ${TodayStyles.actions_button} 
                                ${TodayStyles.actions_completed}`}
                            onClick={() => mark_todo_item_done(dueTodaySelectedItem['todoid'])}
                        >
                            Completed
                        </button>
                        <button
                            className={`${GenStyles.button} ${TodayStyles.actions_button} 
                                ${TodayStyles.actions_waived}`}
                            //onClick={() => document.location.href = 'todo'}
                        >
                            View in TODO
                        </button>
                        <button
                            className={`${GenStyles.button} ${TodayStyles.actions_button} 
                                ${TodayStyles.actions_failed}`}
                            onClick={move_todo_item_to_tomorrow}
                        >
                            Move to Tomorrow
                        </button>
                    </div>

                    <footer
                        className={`${GenStyles.container} ${GenStyles.red} ${GenStyles.font_white}`}
                        id="modal_footer"
                    >
                        &nbsp;
                    </footer>
                </div>
            </div>

            {/* DAILY ACTIONS MODAL */}
            <div id="daily_actions_modal"
                 className={showDailyActionsModal ? GenStyles.modal_shown : GenStyles.modal_hidden}>
                <div className={GenStyles.modal_content}>
                    <header className={`${GenStyles.container} ${GenStyles.red} ${GenStyles.font_white}`}>
                            <span
                                onClick={hide_daily_actions_modal}
                                className={`${GenStyles.button} ${GenStyles.display_top_right}`}
                            >&times;</span>
                        <h3 id="show_more_header_date">Daily Item Actions</h3>
                    </header>

                    <div
                        className={`${GenStyles.container} ${TodayStyles.actions_holder}`}
                        id="modal_actions_holder"
                    >
                        <div className={TodayStyles.actions_title}>
                            {selectedItem ? selectedItem['title'] : ""}
                        </div>
                        <button
                            className={`${GenStyles.button} ${TodayStyles.actions_button} 
                                ${TodayStyles.actions_completed}`}
                            onClick={() => mark_daily_item_done(selectedItem['dailyid'])}
                        >
                            Completed
                        </button>
                        <button
                            className={`${GenStyles.button} ${TodayStyles.actions_button} 
                                ${TodayStyles.actions_waived}`}
                            //onClick={mark_item_waived}
                        >
                            Waived
                        </button>
                        <button
                            className={`${GenStyles.button} ${TodayStyles.actions_button}  
                                ${TodayStyles.actions_failed}`}
                            //onClick={mark_item_failed}
                        >
                            Failed
                        </button>
                    </div>
                    <footer className={`${GenStyles.container} ${GenStyles.red}`} id="modal_footer">
                        &nbsp;
                    </footer>
                </div>
            </div>

            {/* CYCLICAL ACTIONS MODAL */}
            <div id="cyclical_actions_modal"
                 className={showCyclicalActionsModal ? GenStyles.modal_shown : GenStyles.modal_hidden}>
                <div className={GenStyles.modal_content}>
                    <header className={`${GenStyles.container} ${GenStyles.red} ${GenStyles.font_white}`}>
                            <span
                                onClick={hide_cyclical_actions_modal}
                                className={`${GenStyles.button} ${GenStyles.display_top_right}`}
                            >&times;</span>
                        <h3 id="show_more_header_date">Cyclical Item Actions</h3>
                    </header>

                    <div
                        className={`${GenStyles.container} ${TodayStyles.actions_holder}`}
                        id="modal_actions_holder"
                    >
                        <div className={TodayStyles.actions_title}>
                            {selectedCyclicalItem ? selectedCyclicalItem['title'] : ""}
                        </div>
                        <button
                            className={`${GenStyles.button} ${TodayStyles.actions_button} 
                                ${TodayStyles.actions_completed}`}
                            onClick={() => mark_cyclical_item_done(selectedCyclicalItem['cycle_item_id'])}
                        >
                            Completed
                        </button>
                    </div>
                    <footer className={`${GenStyles.container} ${GenStyles.red}`} id="modal_footer">
                        &nbsp;
                    </footer>
                </div>
            </div>
            {/* OVERDUE ITEMS ACTIONS MODAL */}
            <div id="overdue_actions_modal"
                 className={showOverdueActionsModal ? GenStyles.modal_shown : GenStyles.modal_hidden}>
                <div className={GenStyles.modal_content}>
                    <header className={`${GenStyles.container} ${GenStyles.red} ${GenStyles.font_white}`}>
                            <span
                                onClick={hide_overdue_item_actions_modal}
                                className={`${GenStyles.button} ${GenStyles.display_top_right}`}
                            >&times;</span>
                        <h3 id="show_more_header_date">Cyclical Item Actions</h3>
                    </header>

                    <div
                        className={`${GenStyles.container} ${TodayStyles.actions_holder}`}
                        id="modal_actions_holder"
                    >
                        <div className={TodayStyles.actions_title}>
                            {selectedOverdueItem ? selectedOverdueItem['title'] : ""}
                        </div>
                        <button
                            className={`${GenStyles.button} ${TodayStyles.actions_button} 
                                ${TodayStyles.actions_completed}`}
                            onClick={move_overdue_item_to_today}
                        >
                            Move to Today
                        </button>
                        <button
                            className={`${GenStyles.button} ${TodayStyles.actions_button} 
                                ${TodayStyles.actions_completed}`}
                            onClick={move_overdue_item_to_tomorrow}
                        >
                            Move to Tomorrow
                        </button>
                        <button
                            className={`${GenStyles.button} ${TodayStyles.actions_button} 
                                ${TodayStyles.actions_completed}`}
                            onClick={remove_overdue_duedate}
                        >
                            Remove Due Date
                        </button>
                        <button
                            className={`${GenStyles.button} ${TodayStyles.actions_button} 
                                ${TodayStyles.actions_completed}`}
                            onClick={mark_overdue_item_done}
                        >
                            Mark Completed
                        </button>
                        <button
                            className={`${GenStyles.button} ${TodayStyles.actions_button} 
                                ${TodayStyles.actions_completed}`}
                            onClick={() => alert("Not implemented yet.")}
                        >
                            Delete
                        </button>
                    </div>
                    <footer className={`${GenStyles.container} ${GenStyles.red}`} id="modal_footer">
                        &nbsp;
                    </footer>
                </div>
            </div>
        </>)
    } else {
        return <></>
    }
}

export default Overview;