import React, {useEffect, useState} from "react";
import Styles from "../styles/pseudos.module.css";

const SideNavButton = (props) => {
    const [enabled, setEnabled] = useState(props.enabled ?? true);
    const [direction, setDirection] = useState(props.direction);

    useEffect(() => {
        setEnabled(props.enabled);
    }, [props.enabled])

    return (
        <div
            className={Styles.nav_button}
            onClick={props.handle_click}
        >
            {enabled ? (props.direction === 'left' ? "⇦" : "⇨") : ""}
        </div>
    )
}

export default SideNavButton;