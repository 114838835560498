import ToggleSwitch from "./toggle_switch";
import PageTitleStyles from "./styles/page_title.module.css";
import {load_fetch} from "./ofb_util";
import {useEffect, useState} from "react";

const PageTitle = (props) => {
    const [watts, setWatts] = useState(0);
    let timer = null;
    let countdown = 10;

    const dateValue = () => {
        return document.getElementById('daily_date_picker').value;
    }

    const timer_elapse = () => {
        countdown--;
        if(countdown <= 0) {
            countdown = 10;
            get_eletricity_data();
        }
        timer = setTimeout(() => {
            timer_elapse();
        }, 1000);
    }

    useEffect(() => {
        if(timer === null) {
            timer = setTimeout(() => {
                timer_elapse();
            }, 1000);
        }
        get_eletricity_data();
    }, []);

    const get_eletricity_data = () => {
        let url = 'current_cost_handler.php';
        let postData = {
            operation: 'GET_LATEST_USAGE'
        }
        load_fetch(url, postData, (data) => {
            console.log(data['watts']);
            setWatts(data['watts']);
        });
    }
    return (
        <div className={PageTitleStyles.page_title_holder}>
            <span className={PageTitleStyles.page_title_span}>Daily</span>
            <ToggleSwitch  toggleClickEventHandler={() => {props.toggleClickEventHandler()}} title="Edit"/>
            <input type='date'
                   id='daily_date_picker'
                   name='daily_date_picker'
                   value={props.specificDate}
                   onInput={() => {props.setDateHandler(dateValue())}} />
            <div>&nbsp; {watts}Kwh</div>
        </div>
    );
};

export default PageTitle;