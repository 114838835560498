import React, {useEffect, useState} from "react"
import Styles from "../styles/ofbstyles.module.css";
import {load_fetch} from "../ofb_util";

const VerseSelector = (props) => {
    const [selectedBook, setSelectedBook] = useState('Genesis');
    const [selectedChapter, setSelectedChapter] = useState(0);
    const [selectedVerse, setSelectedVerse] = useState(1);
    const [chapters, setChapters] = useState([]);
    const [verses, setVerses] = useState([]);
    const [bookFilter, setBookFilter] = useState('NT');
    const [gameType, setGameType] = useState('words');

    useEffect(() => {
        get_chapters_in_book(selectedBook);
    }, [selectedBook]);
    useEffect(() => {
        setSelectedChapter(1);
    }, [chapters]);
    useEffect(() => {
        get_verses_in_chapter(selectedBook, selectedChapter);
    }, [selectedChapter]);

    function get_chapters_in_book(book) {
        if(!props.books.length) {
            //Books not loaded yet, default is Genesis with 50 chapters
            let myArray = Array.from({length:50}, (v, i) => i);
            setChapters(myArray);
        }
        for(let i = 0; i < props.books.length; i++) {
            if(props.books[i].book == book) {
                let chapter_count = props.books[i].chapters;
                let myArray =Array.from({length:chapter_count}, (v, i) => i);
                setChapters(myArray);
                break;
            }
        }
    }
    function get_verses_in_chapter(book, chapter) {
        let url = 'Bible_Handler.php';
        let postData = {
            operation: 'GET_VERSES_IN_CHAPTER',
            book: book,
            chapter: chapter
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                let myArray = Array.from({length:data}, (v, i) => i);
                setVerses(myArray);
            } else {
                console.log(data);
            }
        }, 'text');
    }
    const handle_selected_verse = () => {
        props.selection_handler({book: selectedBook, chapter: selectedChapter, verse:selectedVerse, game_type: gameType, version:"gnt"});
        props.hide_verse_selector_modal();
    }
    const handle_game_type_change = (new_game_type) => {
        setGameType(new_game_type);
    }
    return (
        <div id="verse_selector_modal" className="w3-modal">
            <div className="w3-modal-content">
                <header className="w3-container w3-red">
                    <span onClick={props.hide_verse_selector_modal} className="w3-button w3-display-topright">&times;</span>
                    <h3>Select Verse</h3>
                </header>

                <div className={`w3-container ${Styles.ofb_bible_verse_selector_holder}`}>
                    <select
                        name="bible_book"
                        className={Styles.ofb_bible_verse_select}
                        id="bible_book_selector"
                        size="12"
                        value={selectedBook}
                        onChange={event => setSelectedBook(event.target.value)}
                    >
                    {bookFilter === 'ALL' || bookFilter === 'OT' ? (
                        <>
                        <optgroup label="OT Law">
                            <option value="Genesis">Genesis</option>
                            <option value="Exodus">Exodus</option>
                            <option value="Leviticus">Leviticus</option>
                            <option value="Numbers">Numbers</option>
                            <option value="Deuteronomy">Deuteronomy</option>

                        </optgroup>
                        <optgroup label="OT History">
                            <option value="Joshua">Joshua</option>
                            <option value="Judges">Judges</option>
                            <option value="Ruth">Ruth</option>
                            <option value="1 Samuel">1 Samuel</option>
                            <option value="2 Samuel">2 Samuel</option>
                            <option value="1 Kings">1 Kings</option>
                            <option value="2 Kings">2 Kings</option>
                            <option value="1 Chronicles">1 Chronicles</option>
                            <option value="2 Chronicles">2 Chronicles</option>
                            <option value="Ezra">Ezra</option>
                            <option value="Nehemiah">Nehemiah</option>
                            <option value="Esther">Esther</option>
                        </optgroup>
                        <optgroup label="OT Poetry">
                            <option value="Job">Job</option>
                            <option value="Psalms">Psalms</option>
                            <option value="Proverbs">Proverbs</option>
                            <option value="Song of Songs">Song of Songs</option>
                            <option value="Ecclesiastes">Ecclesiastes</option>
                        </optgroup>
                        <optgroup label="OT Major Prophets">
                            <option value="Isaiah">Isaiah</option>
                            <option value="Jeremiah">Jeremiah</option>
                            <option value="Lamentations">Lamentations</option>
                            <option value="Ezekiel">Ezekiel</option>
                            <option value="Daniel">Daniel</option>
                        </optgroup>
                        <optgroup label="OT Minor Prophets">
                            <option value="Hosea">Hosea</option>
                            <option value="Joel">Joel</option>
                            <option value="Amos">Amos</option>
                            <option value="Obadiah">Obadiah</option>
                            <option value="Jonah">Jonah</option>
                            <option value="Micah">Micah</option>
                            <option value="Nahum">Nahum</option>
                            <option value="Habakkuk">Habakkuk</option>
                            <option value="Zephaniah">Zephaniah</option>
                            <option value="Haggai">Haggai</option>
                            <option value="Zechariah">Zechariah</option>
                            <option value="Malachi">Malachi</option>
                        </optgroup>
                        </>
                        ) : ''}
                        {bookFilter === 'ALL' || bookFilter === 'NT' ? (
                        <>
                        <optgroup label="NT History">
                            <option value="Matthew">Matthew</option>
                            <option value="Mark">Mark</option>
                            <option value="Luke">Luke</option>
                            <option value="John">John</option>
                            <option value="Acts">Acts</option>
                        </optgroup>
                        <optgroup label="NT Paul's Letters">
                            <option value="Romans">Romans</option>
                            <option value="1 Corinthians">1 Corinthians</option>
                            <option value="2 Corinthians">2 Corinthians</option>
                            <option value="Galatians">Galatians</option>
                            <option value="Ephesians">Ephesians</option>
                            <option value="Philippians">Philippians</option>
                            <option value="Colossians">Colossians</option>
                            <option value="1 Thessalonians">1 Thessalonians</option>
                            <option value="2 Thessalonians">2 Thessalonians</option>
                            <option value="1 Timothy">1 Timothy</option>
                            <option value="2 Timothy">2 Timothy</option>
                            <option value="Titus">Titus</option>
                            <option value="Philemon">Philemon</option>
                        </optgroup>
                        <optgroup label="NT Other Letters">
                            <option value="Hebrews">Hebrews</option>
                            <option value="James">James</option>
                            <option value="1 Peter">1 Peter</option>
                            <option value="2 Peter">2 Peter</option>
                            <option value="1 John">1 John</option>
                            <option value="2 John">2 John</option>
                            <option value="3 John">3 John</option>
                            <option value="Jude">Jude</option>
                        </optgroup>
                        <optgroup label="NT Prophecy">
                            <option value="Revelation">Revelation</option>
                        </optgroup>
                        </>
                        ) : ''}
                    </select>
                    <select
                        className={Styles.ofb_bible_verse_select}
                        name="chapter_selector"
                        size="12"
                        value={selectedChapter}
                        onChange={event => setSelectedChapter(event.target.value)}
                    >
                        {chapters.length && chapters.map((option, index) => (
                            <option
                                key={'chapter_' + (index + 1)}
                                value={index + 1}
                            >{index + 1}
                            </option>
                        ))}
                    </select>
                    <select
                        className={Styles.ofb_bible_verse_select}
                        name="verse_selector"
                        size="12"
                        value={selectedVerse}
                        onChange={event => setSelectedVerse(event.target.value)}
                    >
                        {verses.length && verses.map((option, index) => (
                            <option
                                key={'verse_' + (index + 1)}
                                value={index + 1}
                            >{index + 1}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={`w3-container ${Styles.ofb_bible_game_selector}`}>
                    <div>
                        <input
                            id='words'
                            type="radio"
                            name="game_selector"
                            checked={gameType === 'words' ? true : false}
                            onChange={() => handle_game_type_change('words')}
                        />
                        <label>Word by Word</label>
                    </div>
                    <div>
                        <input
                            id='letters'
                            type="radio"
                            name="game_selector"
                            checked={gameType === 'letters' ? true : false}
                            onChange={() => handle_game_type_change('letters')}
                        />
                        <label>Letter by Letter</label>
                    </div>
                    <div>
                        <input
                            id='learn_mode'
                            type="radio"
                            name="game_selector"
                            checked={gameType==='LEARN_MODE' ? true : false}
                            onChange={() => handle_game_type_change('LEARN_MODE')}
                        />
                        <label>Learn Mode</label>
                    </div>
                </div>
                <footer className="w3-container w3-red" id="modal_footer">
                    <button
                        className={`${Styles.ofb_button} ${Styles.ofb_black}`}
                        onClick={handle_selected_verse}
                    >
                        Select
                    </button>
                </footer>
            </div>
        </div>
    )
}
export default VerseSelector;
