import React from "react";
import Styles from "../styles/ofbstyles.module.css";
import {format_currency_new, date_to_ordinal} from "../ofb_util";
import TrashCan from "../images/trash_can.png";

const BudgetEditRow = (props) => {

    const handle_delete_operation = () => {
        if(props.data.budget_id) {
            props.delete_handler('BUDGET_ITEM', props.data.budget_id);
        } else {
            props.delete_handler('BUDGET_TRANSFER_ITEM', props.data.budget_transfer_id);
        }

    }
    return (
        <div className={Styles.ofb_finance_budget_edit_row}>
            <div className={Styles.ofb_finance_budget_edit_payee}>{props.data.payee_name}</div>
            <div className={Styles.ofb_finance_budget_edit_dayofmonth}>{date_to_ordinal(props.data.day_of_month)}</div>
            <div className={Styles.ofb_finance_budget_edit_amount}>{format_currency_new(props.data.amount)}</div>
            <div>
                <button
                    className={Styles.ofb_delete_budget_row_button}
                    onClick={handle_delete_operation}
                >
                    <img src={TrashCan} alt="Delete Budget Row" />
                </button>
            </div>
        </div>
    )
}

export default BudgetEditRow;