import React, {useState, useEffect} from "react";
import Styles from "../styles/ofbstyles.module.css";
import {load_fetch} from "../ofb_util";

const NewTodoSubitemForm = (props) => {
    //DATA
    const [newItemData, setNewItemData] = useState({
        new_subitem_title: '',
        new_subitem_description: '',
        new_subitem_duedate: '',
        new_subitem_urgency: false,
        new_subitem_isproject: false,
        new_subitem_is_active: true
    });

    //USEEFFECTS
    useEffect(() => {
        if(!props.is_shown) {
            clear_form();
        }
    }, [props.is_shown]);

    //AJAX FUNCTIONS
    const save_new_subitem = () => {
        let url = 'todo_handler.php';
        let postData = {
            operation: 'SAVE_NEW_SUBITEM',
            ...newItemData,
            parentid:props.parentid
        }
        load_fetch(url, postData, (data) => {
            // console.log("Data returned from server on save subitem operation:");
            // console.log(data);
            if(!data['ERROR'] && !data['error']) {
                //Clear the Form
                clear_form();

                //Send the signal up to close this and update the GUI
                // console.log("NEWLY GENERATED SUBITEM: ");
                // console.log(data);
                props.on_save(data);

            } else {
                console.log("FAILED TO INSERT NEW SUBITEM");
                console.log(data);

            }
        });
    }

    //OTHER FUNCTIONS
    const clear_form = () => {
        setNewItemData({
            new_subitem_title: '',
            new_subitem_description: '',
            new_subitem_duedate: '',
            new_subitem_urgency: false,
            new_subitem_isproject: false,
            new_subitem_is_active: true,
            parentid: props.parentid
        });
    }
    const update_data = (event) => {
        const {name, value} = event.target;
        setNewItemData(prevData => ({
            ...prevData,
            [name]: value
        }));
    }
    const update_checkbox_data = (event) => {
        const {name, checked} = event.target;
        setNewItemData(prevData => ({
            ...prevData,
            [name]: checked
        }));
    }
    const handle_key_press = (event) => {
        if(event.charCode === 13) {
            save_new_subitem();
        }
    }

    //RENDERING
    return (
        <div className={Styles.ofb_new_todo_form}>
            <div className={Styles.ofb_dashed_line_bottom}>
            </div>
            <div>
                Add Sub Item
            </div>
            <div className={Styles.ofb_new_todo_form_row}>
                Item Title: <input
                                type="text"
                                className={Styles.ofb_new_todo_form_text_input}
                                id="new_subitem_title"
                                name="new_subitem_title"
                                value={newItemData['new_subitem_title']}
                                onChange={update_data}
                                onKeyPress={handle_key_press}
                            />
            </div>
            <div className={Styles.ofb_new_todo_form_row}>
                Item Description: <input
                type="text"
                className={Styles.ofb_new_todo_form_text_input}
                name="new_subitem_description"
                value={newItemData['new_subitem_description']}
                onChange={update_data}
            />
            </div>
            <div className={Styles.ofb_new_todo_form_row}>
                Due Date: <input
                type="date"
                className={Styles.ofb_new_todo_form_text_input}
                name="new_subitem_duedate"
                value={newItemData['new_subitem_duedate']}
                onChange={update_data}
            />
            </div>
            <div className={Styles.ofb_new_todo_form_checkbox_row}>
                <div>
                    Project?  <input
                                    type="checkbox"
                                    className={Styles.ofb_new_todo_form_checkbox_input}
                                    name="new_subitem_isproject"
                                    checked={newItemData['new_subitem_isproject']}
                                    onChange={update_checkbox_data}
                                />
                </div>
                <div>
                    Urgent?  <input
                                    type="checkbox"
                                    className={Styles.ofb_new_todo_form_checkbox_input}
                                    name="new_subitem_urgency"
                                    checked={newItemData['new_subitem_urgency']}
                                    onChange={update_checkbox_data}
                                />
                </div>
                <div>
                    Active?  <input
                                    type="checkbox"
                                    className={Styles.ofb_new_todo_form_checkbox_input}
                                    name="new_subitem_is_active"
                                    checked={newItemData['new_subitem_is_active']}
                                    onChange={update_checkbox_data}
                                />
                </div>
            </div>
            <div>
                <button
                    className={Styles.ofb_button}
                    onClick={save_new_subitem}
                >Save New Subitem</button>
            </div>
        </div>
    )
}

export default NewTodoSubitemForm;