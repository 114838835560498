import React from 'react';

const BibleVerseRandomizer = (props) => {

    const bibleVerses = [
        {
            ref: "John 10:10",
            verse: "The thief only comes to steal, kill, and destroy. I came that they may have life, and may have it abundantly."
        },
        {
            ref: "1 Peter 4:7",
            verse: "But the end of all things is near. Therefore be of sound mind, self-controlled, and sober in prayer."
        },
        {
            ref: "Galatians 2:20",
            verse: "I have been crucified with Christ, and it is no longer I who live, but Christ lives in me. That life which I now live in the flesh, I live by faith in the Son of God, who loved me, and gave himself up for me."
        }
    ];
    const chosenVerseIndex = Math.floor(Math.random() * bibleVerses.length);

    return (
        <>
            <p className="w3-center w3-wide">{bibleVerses[chosenVerseIndex].verse}</p>
            <p className="w3-right w3-wide">{bibleVerses[chosenVerseIndex].ref.toUpperCase()}</p>
        </>
    );
}

export default BibleVerseRandomizer;