import React, {useEffect, useState} from "react";
import {getDateInYYYYMMDD, load_fetch, talk} from "../ofb_util";
//import Styles from "./styles/ofbstyles.module.css";
import CycleStyles from "../styles/cycles.module.css";
import GenStyles from "../styles/ofb_gen_styles.module.css";
import CycleSubItem from "./cycle_subitem";
import CycleSubItemForm from "./new_cycle_subitem_form";
import TrashCan from "../images/trash_can.png";
import Eraser from "../images/eraser_30x30.png";

const Cycles = (props) => {
    //DATA
    const [cycleItems, setCycleItems] = useState(false);
    const [selectedPageId, setSelectedPageId] = useState(0);
    const [selectedPage, setSelectedPage] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const today = getDateInYYYYMMDD(new Date());
    const [editingTitle, setEditingTitle] = useState(false);
    const [editingDetails, setEditingDetails] = useState(false);
    const [editingDueDate, setEditingDueDate] = useState(false);
    const [deletingItem, setDeletingItem] = useState(false);
    const [addingPage, setAddingPage] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [editingFrequency, setEditingFrequency] = useState(false);
    //const [cleaningCategories, setCleaningCategories] = useState({});

    //USEEFFECTS
    useEffect(() => {
        get_cycle_items();
        //get_cleaning_categories();
    }, []);
    useEffect(() => {
        if(editingTitle) {
            return;
        }
        if(selectedItem) {
            let nst = document.getElementById('new_subitem_title');
            if(nst) {
                nst.focus();
            }
        }
    }, [selectedItem])
    useEffect(() => {
        if(props.authStatus) {
            if(!cycleItems) {
                get_cycle_items();
            }
        } else {
            setCycleItems(false);
        }
    }, [props.authStatus]);
    useEffect(() => {
        setSelectedPage(get_selected_page_item());
    }, [selectedPageId])
    useEffect(() => {
        setSelectedPage(get_selected_page_item());
    }, [cycleItems])


    //AJAX FUNCTIONS
    const get_cycle_items = () => {
        talk("Cycles_Handler.php", "GET_CYCLE_ITEMS", null, get_cycle_items_handler);
    }
    const get_cycle_items_handler = (operation, sent_data, return_data) => {
        setCycleItems(return_data);
        if(selectedPageId) {
        } else {
            if(return_data[0]) {
                setSelectedPageId(return_data[0]['cycle_item_id']);
            }
        }
    }
    const save_edit_title = (event) => {
        event.stopPropagation();
        let url = 'Cycles_Handler.php';
        let postData = {
            cycle_item_id: selectedItem['cycle_item_id'],
            title: editingTitle
        }
        talk(url, "UPDATE_TITLE", postData, save_edit_title_handler);
    }
    const save_edit_title_handler = (operation, sent_data, return_data) => {
        if (!return_data['ERROR'] && !return_data['error']) {
            //UPDATE GUI
            let local_selected_item = JSON.parse(JSON.stringify(selectedItem));
            local_selected_item = {...local_selected_item, title:sent_data['title']};
            setSelectedItem(local_selected_item);

            //UPDATE TODOITEMS
            let local_cycle_items = recursive_update_selected_item(JSON.parse(JSON.stringify(cycleItems)),
                local_selected_item);
            setCycleItems(local_cycle_items);

            setEditingTitle(false);
        } else {
            //WARN USER ABOUT FAILURE AND SUGGEST REFRESH?
            alert("Failed to save Title. You should refresh the page before continuing.");
        }
    }
    const save_edit_details = (event) => {
        event.stopPropagation();
        let url = 'Cycles_Handler.php';
        let postData = {
            cycle_item_id: selectedItem['cycle_item_id'],
            details: editingDetails
        }
        talk(url, "UPDATE_DETAILS", postData, save_edit_details_handler);
    }
    const save_edit_details_handler = (operation, sent_data, return_data) => {
        if (!return_data['ERROR'] && !return_data['error']) {
            //UPDATE GUI
            let local_selected_item = JSON.parse(JSON.stringify(selectedItem));
            local_selected_item = {...local_selected_item, details:editingDetails};
            setSelectedItem(local_selected_item);

            //UPDATE CYCLEITEMS
            let local_cycle_items = recursive_update_selected_item(JSON.parse(JSON.stringify(cycleItems)),
                local_selected_item);
            setCycleItems(local_cycle_items);

            setEditingDetails(false);
        } else {
            //WARN USER ABOUT FAILURE AND SUGGEST REFRESH?
            alert("Failed to save Details. You should refresh the page before continuing.");
        }
    }
    const save_edit_frequency = (event) => {
        event.stopPropagation();
        let url = 'Cycles_Handler.php';
        let postData = {
            cycle_item_id: selectedItem['cycle_item_id'],
            frequency: editingFrequency
        }
        talk(url, "UPDATE_FREQUENCY", postData, save_edit_frequency_handler);
    }
    const save_edit_frequency_handler = (operation, sent_data, return_data) => {
        if (!return_data['ERROR'] && !return_data['error']) {
            //UPDATE GUI
            let local_selected_item = JSON.parse(JSON.stringify(selectedItem));
            console.log(local_selected_item);
            local_selected_item = {...local_selected_item, frequency:editingFrequency};
            setSelectedItem(local_selected_item);

            //UPDATE CYCLEITEMS
            let local_cycle_items = recursive_update_selected_item(JSON.parse(JSON.stringify(cycleItems)),
                local_selected_item);
            setCycleItems(local_cycle_items);

            setEditingFrequency(false);
        } else {
            //WARN USER ABOUT FAILURE AND SUGGEST REFRESH?
            alert("Failed to save Frequency. You should refresh the page before continuing.");
        }
    }
    const save_edit_duedate = (event) => {
        event.stopPropagation();
        let url = 'Cycles_Handler.php';
        let postData = {
            operation: 'UPDATE_DUEDATE',
            todoid: selectedItem['todoid'],
            duedate: editingDueDate
        }
        talk(url, "UPDATE_DUE_DATE", postData, save_edit_duedate_handler);
    }
    const save_edit_duedate_handler = (operation, sent_data, return_data) => {
        if (!return_data['ERROR'] && !return_data['error']) {
            //UPDATE GUI
            let local_selected_item = JSON.parse(JSON.stringify(selectedItem));
            local_selected_item = {...local_selected_item, duedate:editingDueDate};
            setSelectedItem(local_selected_item);

            //UPDATE CYCLEITEMS
            let local_cycle_items = recursive_update_selected_item(JSON.parse(JSON.stringify(cycleItems)),
                local_selected_item);
            setCycleItems(local_cycle_items);

            setEditingDueDate(false);
        } else {
            //WARN USER ABOUT FAILURE AND SUGGEST REFRESH?
            alert("Failed to save Due Date. You should refresh the page before continuing.");
        }
    }
    const mark_completed = () => {
        let url = 'Cycles_Handler.php';
        let postData = {
            cycle_item_id: selectedItem['cycle_item_id']
        }
        talk(url, 'MARK_COMPLETED', postData, mark_completed_handler);
    }
    const mark_completed_handler = (operation, sent_data, return_data) => {
        console.log(return_data);
        if(!return_data['ERROR'] && !return_data['error']) {
            get_cycle_items();
            close_modal();
        } else {
        }
    }
    const mark_uncompleted = () => {
        let url = 'Cycles_Handler.php';
        let postData = {
            cycle_item_id: selectedItem['cycle_item_id']
        }
        talk(url, 'MARK_UNCOMPLETED', postData, mark_uncompleted_handler);
    }
    const mark_uncompleted_handler = (operation, sent_data, return_data) => {
        console.log(return_data);
        if(!return_data['ERROR'] && !return_data['error']) {
            get_cycle_items();
            close_modal();
        } else {
        }
    }
    const handle_deleting = () => {
        let postData = {
            cycle_item_id: selectedItem['cycle_item_id']
        }
        talk("Cycles_Handler.php", "DELETE_ITEM", postData, handle_deleting_handler);
    }
    const handle_deleting_handler = (operation, sent_data, return_data) => {
        if(return_data['SUCCESS']) {
            setDeletingItem(false);
            setSelectedItem(false);
            get_cycle_items();
            setShowModal(false);
        } else {
            console.log("Error in Deleting Item");
        }
    }


    // const get_cleaning_categories = () => {
    //     let url = 'Cleaning_Handler.php';
    //     let postData = {
    //         operation: 'GET_CLEANING_CATEGORIES'
    //     }
    //     load_fetch(url, postData, (data) => {
    //         if(!data['ERROR'] && !data['error']) {
    //             setCleaningCategories(data);
    //         } else {
    //             setCleaningCategories({});
    //         }
    //     });
    // }

    const set_selected_item = (item) => {
        setSelectedItem(item);
        setShowModal(true);
    }
    const close_modal = () => {
        //Clear the Form
        setEditingTitle(false);
        setEditingDetails(false);
        setEditingDueDate(false);
        setDeletingItem(false);

        //Close the Modal
        setSelectedItem(false);
        setAddingPage(false);
        setShowModal(false);
    }
    function recursive_update_selected_item(local_cycle_data, selected_item) {
        for(let key in local_cycle_data) {
            if(typeof local_cycle_data[key] == 'object' && local_cycle_data[key] !== null) {
                if(local_cycle_data[key]['cycle_item_id']) {
                    if(local_cycle_data[key]['cycle_item_id'] === selected_item['cycle_item_id']) {
                        local_cycle_data[key] = selected_item;
                    }
                }
                recursive_update_selected_item(local_cycle_data[key], selected_item);
            }
        }
        return local_cycle_data;
    }
    // function recursive_add_subitem(local_cycles_data, new_subitem_data, parent_id) {
    //     for(let key in local_cycles_data) {
    //         if(typeof local_cycles_data[key] == 'object' && local_cycles_data[key] !== null) {
    //             if(local_cycles_data[key]['cycle_item_id']) {
    //                 if(local_cycles_data[key]['cycle_item_id'] === parent_id) {
    //                     new_subitem_data['depth'] = local_cycles_data[key]['depth'] + 1;
    //                     if(!local_cycles_data[key]['sub_items']) {
    //                         local_cycles_data[key]['sub_items'] = [];
    //                     }
    //                     local_cycles_data[key]['sub_items'].push(new_subitem_data);
    //                 }
    //             }
    //             recursive_add_subitem(local_cycles_data[key], new_subitem_data, parent_id);
    //         }
    //     }
    //     return local_cycles_data;
    // }
    const on_save_new_item = (new_item_data) => {
        get_cycle_items();
        close_modal();
        // console.log(new_item_data);
        //
        // //Update the data
        // let local_data = JSON.parse(JSON.stringify(cycleItems));
        // local_data = recursive_add_subitem(local_data, new_item_data, new_item_data['parent_id']);
        //
        // //Set the State
        // setCycleItems(local_data);
        // console.log(local_data);
        //
        // //Close the Modal and Clear Selected Item
        // close_modal();
    }
    const edit_title = () => {
        setEditingTitle(selectedItem['title']);
    }
    const get_selected_page_item = () => {
        if(!Array.isArray(cycleItems)) return;

        for(let i = 0; i < cycleItems.length; i++) {
            if(cycleItems[i]['cycle_item_id'] == selectedPageId) {
                return cycleItems[i];
            }
        }
        return false;
    }
    const show_add_new_page_modal = () => {
        setAddingPage(true);
        setShowModal(true);
    }
    const hide_add_new_page_modal = () => {
        setAddingPage(false);
        setShowModal(false);
    }
    const get_modal_title = () => {
        if(addingPage) {
            return "Adding New Page";
        } else if(selectedItem) {
            return "Item Details";
        }
    }
    const update_selected_item = (event) => {
        const {name, value} = event.target;
        if(name === 'title') {
            setEditingTitle(value);
        } else if(name === 'details') {
            setEditingDetails(value);
        } else if(name === 'duedate') {
            setEditingDueDate(value);
        } else if(name === 'frequency') {
            setEditingFrequency(value);
        }
    }
    const delete_selected_item = () => {
        console.log(selectedItem);
        setDeletingItem(true);
    }
    const cancel_deleting = () => {
        setDeletingItem(false);
    }

    const edit_details = () => {
        setEditingDetails(selectedItem['details']);
    }
    const edit_frequency = () => {
        setEditingFrequency(selectedItem['frequency']);
    }
    const cancel_edit_title = (event) => {
        setEditingTitle(false);
        if(event) {
            event.stopPropagation();
        }
    }
    const cancel_edit_details = (event) => {
        setEditingDetails(false);
        if(event) {
            event.stopPropagation();
        }
    }
    const cancel_edit_frequency = (event) => {
        setEditingFrequency(false);
        if(event) {
            event.stopPropagation();
        }
    }










    if(props.authStatus) {
        return (
            <>
                <div className={CycleStyles.todo_simple}>
                    <div className={CycleStyles.selector_row}>
                        <select
                            className={CycleStyles.page_select}
                            onChange={(event) => setSelectedPageId(event.target.value)}
                            value={selectedPageId}
                        >
                            {cycleItems.length > 0 && cycleItems.map((item, index) => (
                                <option
                                    key={item['cycle_item_id']}
                                    value={item['cycle_item_id']}
                                >{item['title']}</option>
                            ))}
                        </select>
                        <button
                            className={CycleStyles.add_new_page_button}
                            onClick={show_add_new_page_modal}
                        >+</button>
                    </div>

                    {
                        selectedPage &&
                            selectedPage.sub_items &&
                            Array.isArray(selectedPage.sub_items) &&
                            selectedPage.sub_items.map((item, index) => (
                            <div
                                className={CycleStyles.todo_simple_box}
                                key={item['cycle_item_id']}
                            >
                                {/* Outputting the top level item */}
                                <div
                                    className={CycleStyles.todo_simple_item}
                                    onClick={() => set_selected_item(item)}
                                ><b>{item['title']}</b>
                                </div>

                                {/* Mapping the subitems*/}
                                {item['sub_items'].length > 0 && item['sub_items'].map((sub_item, sub_index) => (
                                    <CycleSubItem
                                        key={sub_item['cycle_item_id']}
                                        data={sub_item}
                                        set_selected_item={set_selected_item}
                                        today={today}
                                    />
                                ))}
                            </div>
                        ))}
                </div>

                {/* MODAL */}
                <div className={showModal ? GenStyles.modal_shown : GenStyles.modal_hidden}>
                    <div className={GenStyles.modal_content}>
                        <div className={GenStyles.modal_header}>
                            <div>{get_modal_title()}</div>
                            <div><button
                                className={CycleStyles.close_button}
                                onClick={close_modal}
                            >X</button></div>
                        </div>
                        <div className={GenStyles.modal_body}>
                            {/* NAME AND EDIT NAME */}
                            <div
                                className={addingPage ? GenStyles.hidden : GenStyles.cursor_pointer}
                                onClick={edit_title}>{editingTitle ?
                                <>
                                    <input
                                        type="text"
                                        name="title"
                                        value={editingTitle}
                                        onChange={update_selected_item}
                                        className={CycleStyles.edit_title_input}
                                    />
                                    <button
                                        onClick={cancel_edit_title}
                                    >❌</button>
                                    <button
                                        onClick={save_edit_title}
                                    >💾</button>
                                </>
                                : selectedItem['title']}</div>

                            {/* DETAILS AND EDIT DETAILS */}
                            {
                            <div
                                className={addingPage ? GenStyles.hidden : `${GenStyles.font_12} ${GenStyles.pointer}`}
                                onClick={edit_details}
                            >{editingDetails !== false ? <>
                                <input
                                    type="text"
                                    value={editingDetails}
                                    name="details"
                                    onChange={update_selected_item}
                                    />
                                <button
                                    onClick={cancel_edit_details}
                                >❌</button>
                                <button
                                    onClick={save_edit_details}
                                >💾</button>
                            </> : (selectedItem['details'] ?
                                selectedItem['details'] : "-NO DETAILS-")}</div>
                                }

                            {/* DUE DATE FOR EXISTING ITEM */}
                            {/*
                            <div className={`${Styles.ofb_font_size_12} ${Styles.ofb_cursor_pointer}`}
                                 //onClick={edit_due_date}
                            >
                                <b>Due Date</b>: {editingDueDate !== false ? <>
                                    <input
                                        type="date"
                                        className={Styles.ofb_new_todo_form_text_input}
                                        name="duedate"
                                        value={editingDueDate}
                                        //onChange={update_selected_item}
                                    />
                                    <button
                                        //onClick={cancel_edit_duedate}
                                    >❌</button>
                                    <button
                                        //onClick={save_edit_duedate}
                                    >💾</button>
                                </> :
                                selectedItem['duedate']}
                            </div>
                            */}

                            {/* FREQUENCY AND EDIT FREQUENCY */}
                            <div
                                className={addingPage ? GenStyles.hidden : `${GenStyles.font_12} ${GenStyles.pointer}`}
                                onClick={edit_frequency}
                            >
                                {editingFrequency !== false ? <>
                                    <input
                                        type="text"
                                        value={editingFrequency}
                                        name="frequency"
                                        onChange={update_selected_item}
                                    />
                                    <button
                                        onClick={cancel_edit_frequency}
                                    >❌</button>
                                    <button
                                        onClick={save_edit_frequency}
                                    >💾</button>
                                </> : selectedItem['frequency']}
                            </div>

                            {/* MARK COMPLETED BUTTONS */}
                            <div className={addingPage ? GenStyles.hidden : CycleStyles.completed_row}>
                                {selectedItem['depth'] > 1 ? (selectedItem['completed'] ? (
                                    <div className={CycleStyles.mark_completed_button_div}>
                                        <button className={GenStyles.modal_footer_button}
                                            onClick={mark_uncompleted}
                                        >
                                            Mark Uncompleted
                                        </button>
                                    </div>
                                ) : (
                                    <div className={CycleStyles.mark_completed_button_div}>
                                        <button className={GenStyles.modal_footer_button}
                                                onClick={mark_completed}
                                        >
                                            Mark Completed
                                        </button>
                                    </div>
                                )) : ''}

                                {/* ERASE LAST BUTTON */}
                                <div className={CycleStyles.mark_completed_button_div}>
                                    <button
                                        className={GenStyles.modal_footer_button}
                                        onClick={mark_uncompleted}
                                    ><img src={Eraser} /></button>
                                </div>

                                {/* DELETE BUTTON */}
                                <div className={CycleStyles.mark_completed_button_div}>
                                    <button
                                        className={GenStyles.modal_footer_button}
                                        onClick={delete_selected_item}
                                    ><img src={TrashCan} /></button>
                                </div>
                            </div>
                            <div className={deletingItem ? GenStyles.are_you_sure : GenStyles.hidden}>
                                <div>Are you sure you want to delete this item?</div>
                                {(selectedItem['sub_items'] && selectedItem['sub_items'].length > 0) ? <div>It has subitems.</div> : ''}
                                <div>
                                    <button
                                        className={GenStyles.button}
                                        onClick={cancel_deleting}
                                    >Cancel</button>
                                    <button
                                        className={GenStyles.button}
                                        onClick={handle_deleting}
                                    >Delete</button>
                                </div>
                            </div>



                            {/* NEW SUBITEM FORM */}
                            <CycleSubItemForm
                                adding_page={addingPage}
                                current_item_id={addingPage ? selectedPage['cycle_item_id'] : selectedItem['cycle_item_id']}
                                is_shown={selectedItem ? true : false}
                                parent_id={addingPage ? 0 : selectedItem['cycle_item_id']}
                                on_save={on_save_new_item}
                                /* categories={cleaningCategories} */
                            />
                        </div>

                        {/* MODAL FOOTER WITH CLOSE BUTTON */}
                        <div className={GenStyles.modal_footer}>
                            <button
                                className={GenStyles.modal_footer_button}
                                onClick={close_modal}
                            >Close
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return <></>
    }
}
export default Cycles;