import React, {useEffect, useState, useContext } from "react";
import {AuthContext} from "../auth_context";
import { load_fetch, talk } from "../ofb_util";
import Styles from "../styles/pseudos.module.css";
import SideNavButton from "../PseudOS/side_nav_button";
// import PageTitle from "../page_title";
// import TabSelector from "../tab_selector";
// import TodoDailyEditBlock from "./edit_block";
// import GenStyles from "../styles/ofb_gen_styles.module.css";
// import TodayStyles from "../styles/today.module.css";
//import TodoDailyGroup from "./daily_group";



const PseudosToday = (props) => {
    //DATA
    const [error, setError] = useState(null);
    const [specificDate, setSpecificDate] = useState(
        (new Date()).getFullYear() + "-" + ((new Date()).getMonth() + 1).toString().padStart(2, "0") +
        "-" + (new Date()).getDate().toString().padStart(2, "0")
        );
    const [isLoaded, setIsLoaded] = useState(false);
    const [dueTodaySelectedItem, setDueTodaySelectedItem] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [items, setItems] = useState(false);
    const [todoDueTodayItems, setTodoDueTodayItems] = useState([]);
    const [authStatus, setAuthStatus] = useContext(AuthContext);
    const [isEditing, setIsEditing] = useState(false);
    const [showAddNew, setShowAddNew] = useState(false);
    const [editTabShown, setEditTabShown] = useState((new Date().toLocaleString("default",
        {weekday: "long"})).toUpperCase());
    const [editTabs, setEditTabs] = useState([
        {id: 1, name: "Sun", value: "SUNDAY"},
        {id: 2, name: "Mon", value: "MONDAY"},
        {id: 3, name: "Tues", value: "TUESDAY"},
        {id: 4, name: "Wed", value: "WEDNESDAY"},
        {id: 5, name: "Thu", value: "THURSDAY"},
        {id: 6, name: "Fri", value: "FRIDAY"},
        {id: 7, name: "Sat", value: "SATURDAY"},
        {id: 8, name: "Monthly", value: "MONTHLY"},
        {id: 9, name: "End of Month", value: "END_OF_MONTH"},
        {id: 10, name: "Orphans", value: "ORPHANS"},
        {id: 11, name: "+", value: "ADD_NEW"}
    ]);
    const [doneItems, setDoneItems] = useState([]);
    const [waivedItems, setWaivedItems] = useState([]);
    const [failedItems, setFailedItems] = useState([]);

    //NEW - DATA
    const [tileItems, setTileItems] = useState([
        {index: -1, text: "Due Today"},
        {index: 0, text: "Anytime"},
        {index: 1, text: "Early Morning"},
        {index: 2, text: "Late Morning"},
        {index: 3, text: "Afternoon"},
        {index: 4, text: "Evening"},
        {index: 5, text: "Before Bed"}
    ]);
    const [currentMenuPage, setCurrentMenuPage] = useState(0);
    const [leftNavEnabled, setLeftNavEnabled] = useState(false);
    const [rightNavEnabled, setRightNavEnabled] = useState(true);

    //USE EFFECTS
    //When Editing, set the selected tab
    // useEffect(() => {
    //     for(let i = 0; i < editTabs.length; i++) {
    //         if(editTabs[i].value == editTabShown) {
    //             editTabs[i].selected = true;
    //         }
    //     }
    //     loadItems();
    // }, [editTabShown]);
    // useEffect(() => {
    //     loadItems();
    //     get_todo_due_today();
    // }, [isEditing, specificDate])

    //NEW - USE EFFECTS
    useEffect(() => {
        load_items();
    }, []);

    //NEW - AJAX FUNCTIONS
    const load_items = () => {
        let post_data = {};

        if(isEditing) {
            switch (editTabShown) {
                case "MONTHLY":
                    post_data['operation'] = 'GET_MONTHLY_ITEMS';
                    break;
                case "END_OF_MONTH":
                    post_data['operation'] = 'GET_END_OF_MONTH_ITEMS';
                    break;
                case "ORPHANS":
                    post_data['operation'] = 'ORPHANS';
                    break;
                default:
                    post_data['operation'] = 'GET_EDIT_ITEMS_FOR_TODAY';
                    post_data['dayOfWeek'] = editTabShown;
                    return;
            }
        } else {
            post_data['operation'] = 'GET_ITEMS_FOR_TODAY';
            post_data['editing'] = false;
            post_data['specific_date'] = specificDate ?? null
        }
        talk("daily_handler.php", post_data['operation'], post_data, load_items_handler);
    }
    const load_items_handler = (operation, post_data, return_data) => {
        setItems(return_data);
        console.log(return_data);
    }

    //OTHER FUNCTIONS













    const handleDeleteItem = (dailyid) => {
        const postData = {
            operation: 'DELETE_ITEM',
            dailyid: dailyid,
        }
        const myCallback = (data) => {
            if(data && "ERROR" in data) {
                console.log("ERROR: FAILED TO DELETE ITEM");
                console.log(data);
            } else if (data) {
                //console.log(data);
                loadItems();
            } else {
                console.log("Should return data, but isn't. In handleDeleteItem() in today_daily.js");
            }
        }
        load_fetch('daily_handler.php', postData, myCallback);
    }
    //TODO: JUNE - Figure this out and use load_fetch for signin.js
    const editToggleEventHandler = () => {
        setIsEditing(!isEditing);
    }
    const setDateHandler = (props) => {
        setSpecificDate(props);
    }
    const dailyItemClickHandler = (info) => {
        setSelectedItem(info);
        show_daily_actions_modal();
    }
    const due_today_item_click_handler = (info) => {
        console.log(info);
        setDueTodaySelectedItem(info);
        show_due_today_actions_modal();
    }
    const change_daily_item_status = (id, new_status) => {
        const postData = {
            operation: new_status,
            id: id,
            specific_date: specificDate
        }
        const myCallback = (data) => {
            if(data && "error" in data) {
                console.log("ERROR: FAILED TO MARK ITEM DONE");
            } else if (data) {
                console.log(data)
                setItems(data['NOT_DONE']);
                setDoneItems(data['DONE']);
                setWaivedItems(data['WAIVED']);
                setFailedItems(data['FAILED']);
                loadItems();
            } else {
                console.log("RETURNED UNDEFINED");
            }
        }
        load_fetch('daily_handler.php', postData, myCallback);
    }
    const mark_item_done = () => {
        change_daily_item_status(selectedItem['id'], 'MARK_ITEM_DONE');
        setSelectedItem(false);
        hide_daily_actions_modal();
    }
    const mark_todo_item_done = (todoid) => {
        //Do server side check for subitems and prevent completion.

        //Mark Item Completed
        const postData = {
            operation: "MARK_ITEM_COMPLETED",
            todoid: todoid
        }

        load_fetch(
            'todo_handler.php',
            postData,
            function(data) {
                if(data && "ERROR" in data) {
                    console.log("ERROR: FAILED TO MARK ITEM COMPLETED");
                    console.log(data);
                } else if (data) {
                    get_todo_due_today();
                    hide_due_today_actions_modal();
                } else {
                    console.log(data);
                }
            });
    }
    const move_todo_item_to_tomorrow = (todoid) => {
        //MOVE ITEM TO TOMORROW
        const postData = {
            operation: "MOVE_ITEM_TO_TOMORROW",
            todoid: todoid
        }
        load_fetch(
            'todo_handler.php',
            postData,
            function(data) {
                hide_due_today_actions_modal();
                get_todo_due_today();
            });
    }
    const mark_item_waived = () => {
        change_daily_item_status(selectedItem['id'], 'MARK_ITEM_WAIVED');
        setSelectedItem(false);
        hide_daily_actions_modal();
    }
    const mark_item_failed = () => {
        change_daily_item_status(selectedItem['id'], 'MARK_ITEM_FAILED');
        setSelectedItem(false);
        hide_daily_actions_modal();
    }
    const editTabHandler = (props) => {
        if(props == "ADD_NEW") {
            setShowAddNew(!showAddNew);
            return;
        }
        setEditTabShown(props);
    }
    const handleAddNew = (props) => {
        const postData = {
            operation: 'ADD_NEW_ITEM',
            itemData: props
        }

        const myCallback = (response) => {
            if(response && "error" in response) {
                console.log("ERROR: " + response['error']);
            } else if (response) {
                //Check for a notification of success?
                //console.log(response)
                loadItems();
            } else {
                console.log("NO RESPONSE");
            }
        }
        load_fetch('daily_handler.php', postData, myCallback);
    }
    const get_todo_due_today = () => {
        console.log("Getting TODO Today Items for date: " + specificDate);
        const postData = {
            operation: 'GET_TODO_DUE_TODAY',
            specific_date: specificDate
        }

        const myCallback = (response) => {
            console.log(response);
            if(response && "error" in response) {
                console.log("ERROR: " + response['error']);
            } else if (response) {
                console.log("TODO TODAY:");
                console.log(response);
                //Check for a notification of success?
                //console.log(response)
                setTodoDueTodayItems(response);
            } else {
                setTodoDueTodayItems([]);
                console.log("NO RESPONSE");
            }
        }
        load_fetch('todo_handler.php', postData, myCallback);
    }

    function loadItems() {
        console.log("loading items for: " + specificDate);
        //if(!logged In)

        let postData = {};

        if(isEditing) {
            switch (editTabShown) {
                case "SUNDAY":
                    postData['operation'] = 'GET_EDIT_ITEMS_FOR_TODAY';
                    postData['dayOfWeek'] = 'SUNDAY';
                    break;
                case "MONDAY":
                    postData['operation'] = 'GET_EDIT_ITEMS_FOR_TODAY';
                    postData['dayOfWeek'] = 'MONDAY';
                    break;
                case "TUESDAY":
                    postData['operation'] = 'GET_EDIT_ITEMS_FOR_TODAY';
                    postData['dayOfWeek'] = 'TUESDAY';
                    break;
                case "WEDNESDAY":
                    postData['operation'] = 'GET_EDIT_ITEMS_FOR_TODAY';
                    postData['dayOfWeek'] = 'WEDNESDAY';
                    break;
                case "THURSDAY":
                    postData['operation'] = 'GET_EDIT_ITEMS_FOR_TODAY';
                    postData['dayOfWeek'] = 'THURSDAY';
                    break;
                case "FRIDAY":
                    postData['operation'] = 'GET_EDIT_ITEMS_FOR_TODAY';
                    postData['dayOfWeek'] = 'FRIDAY';
                    break;
                case "SATURDAY":
                    postData['operation'] = 'GET_EDIT_ITEMS_FOR_TODAY';
                    postData['dayOfWeek'] = 'SATURDAY';
                    break;
                case "MONTHLY":
                    postData['operation'] = 'GET_MONTHLY_ITEMS';
                    break;
                case "END_OF_MONTH":
                    postData['operation'] = 'GET_END_OF_MONTH_ITEMS';
                    break;
                case "ORPHANS":
                    postData['operation'] = 'ORPHANS';
                    break;
                default:
                    return;
            }
        } else {
            postData['operation'] = 'GET_ITEMS_FOR_TODAY';
            postData['editing'] = false;
            postData['specific_date'] = specificDate ?? null
        }

        const myCallback = (data) => {
            console.log(data);
            if(data && "error" in data) {
                setIsLoaded(true);
                setError(data['error']);
            } else if (data) {
                if(isEditing) {
                    setIsLoaded(true);
                    setItems(data);
                } else {
                    setIsLoaded(true);
                    setItems(data['NOT_DONE']);
                    setDoneItems(data['DONE']);
                    setWaivedItems(data['WAIVED']);
                    setFailedItems(data['FAILED']);
                }
            } else {
                console.log("RETURNED UNDEFINED");
            }
        }
        load_fetch('daily_handler.php', postData, myCallback);
    }
    const hide_daily_actions_modal = () => {
        document.getElementById('daily_actions_modal').style.display = 'none';
    }
    const hide_due_today_actions_modal = () => {
        document.getElementById('due_today_actions_modal').style.display = 'none';
    }
    const show_daily_actions_modal = () => {
        document.getElementById('daily_actions_modal').style.display = 'block';
    }
    const show_due_today_actions_modal = () => {
        document.getElementById('due_today_actions_modal').style.display = 'block';
    }


    //NEW - GENERAL FUNCTIONS
    const handle_nav_click = (direction) => {
        if(direction === 'left') {
            shift_menu(-1);
        } else {
            shift_menu(1);
        }
    }
    const shift_menu = (direction) => {
        if(direction > 0) {
            //Block Moving Past the Outer Limit
            if(currentMenuPage >= (Math.ceil(tileItems.length / 6)) - 1) {
                return;
            }

            //Disable Right Nav if We are Moving to the Outer Limit
            if(currentMenuPage >= (Math.ceil(tileItems.length / 6)) - 2) {
                setRightNavEnabled(false);
            }

            //Perform the Movement and Reenable the left nav button if necessary
            setLeftNavEnabled(true);
            setCurrentMenuPage(currentMenuPage + 1);

        } else {
            //Block Moving Past the Start Point
            if(currentMenuPage <= 0) {
                return;
            }

            //Disable Left Nav if We are Moving to the Start point
            if(currentMenuPage <= 1) {
                setLeftNavEnabled(false);
            }

            //Perform the movement and reenable the right nav button if necessary
            setRightNavEnabled(true);
            setCurrentMenuPage(currentMenuPage - 1);
        }
    }

    //RENDERING
    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return (
            <>
            <SideNavButton
                enabled={leftNavEnabled}
                direction="left"
                handle_click={() => handle_nav_click('left')}
                />
                <div className={Styles.tile_row_holder}>
                    <div className={Styles.tile_row}>
                        {tileItems.slice((currentMenuPage * 6), (currentMenuPage * 6) + 3).map((item, index) => (
                            <div className={Styles.tile} key={item.index}>
                                <div>
                                    {item.text}
                                    <span className={Styles.tile_notification}>
                                        {item.index !== -1 && items && items['NOT_DONE'][item.index] ?
                                            ' (' + items['NOT_DONE'][item.index]['items'].length + ')': ''}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={Styles.tile_row}>
                        {tileItems.slice((currentMenuPage * 6) + 3, (currentMenuPage * 6) + 6).map((item, index) => (
                            <div className={Styles.tile} key={item.index}>
                                <div>
                                    {item.text}
                                    <span className={Styles.tile_notification}>
                                        {item.index !== -1 && items && items['NOT_DONE'][item.index] ?
                                            ' (' + items['NOT_DONE'][item.index]['items'].length + ')': ''}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            <SideNavButton
                enabled={rightNavEnabled}
                direction="right"
                handle_click={() => handle_nav_click('right')}
            />
            </>
        );
    } else if (authStatus) {
        return <div>Coming Soon</div>
        // return (
        //     <>
        //         <PageTitle
        //             toggleClickEventHandler={editToggleEventHandler}
        //             setDateHandler={setDateHandler}
        //             specificDate={specificDate}
        //         />
        //         <TabSelector
        //             clickHandler={editTabHandler}
        //             visible={isEditing}
        //             items={editTabs}
        //         />
        //         <TodoDailyEditBlock
        //             type="addNew"
        //             editingDetailsVisible={showAddNew}
        //             handleSave={handleAddNew}
        //             reloadItemsCall={loadItems}
        //         />
        //         <div>
        //             <h3 className={`${GenStyles.black} ${GenStyles.font_white}`}>TODO Items Due Today</h3>
        //             <div style={{textAlign:"left"}}>
        //             {todoDueTodayItems.length > 0 && todoDueTodayItems.map(item => (
        //                 <div key={item['todoid']}>{
        //                     item['completed'] ? (<div className={GenStyles.strike}>
        //                         {item.title}
        //                     </div>) : (<button className={(item['completed'] ? GenStyles.strike : GenStyles.button)}
        //                                        onClick={() => due_today_item_click_handler(item)}
        //                     >{item['title']}</button>)
        //                 }</div>
        //             ))}
        //             </div>
        //         </div>
        //         {items && items.map(group => (
        //             <TodoDailyGroup
        //                 handleDeleteItem={handleDeleteItem}
        //                 key={group.timeOfDay}
        //                 editing={isEditing}
        //                 clickEventHandler={dailyItemClickHandler}
        //                 group={group}
        //             /> ))
        //         }
        //         <div id="success_report">
        //             <div>
        //                 <h3>Done</h3>
        //                 {doneItems && doneItems.map((item, index) => (
        //                     <div key={'done_item_' + index}>
        //                         {item['title']}
        //                     </div>
        //                 ))}
        //             </div>
        //             <div>
        //                 <h3>Waived</h3>
        //                 {waivedItems && waivedItems.map((item, index) => (
        //                     <div key={'waived_item_' + index}>
        //                         {item['title']}
        //                     </div>
        //                 ))}
        //             </div>
        //             <div>
        //                 <h3>Failed</h3>
        //                 {failedItems && failedItems.map((item, index) => (
        //                     <div key={'failed_item_' + index}>
        //                         {item['title']}
        //                     </div>
        //                 ))}
        //             </div>
        //         </div>
        //
        //
        //         <div id="due_today_actions_modal" className={GenStyles.modal_hidden}>
        //             <div className={GenStyles.modal_content}>
        //                 <header className={`${GenStyles.container} ${GenStyles.red} ${GenStyles.font_white}`}>
        //                     <span
        //                         onClick={hide_due_today_actions_modal}
        //                         className={`${GenStyles.button} ${GenStyles.display_top_right}`}
        //                     >&times;</span>
        //                     <h3 id="show_more_header_date">Due Today Item Actions</h3>
        //                 </header>
        //
        //                 <div
        //                     className={`${GenStyles.container} ${TodayStyles.actions_holder}`}
        //                     id="modal_actions_holder"
        //                 >
        //                     <div className={TodayStyles.actions_title}>
        //                         {dueTodaySelectedItem ? dueTodaySelectedItem['title'] : ""}
        //                     </div>
        //                     <button
        //                         className={`${GenStyles.button} ${TodayStyles.actions_button}
        //                         ${TodayStyles.actions_completed}`}
        //                         onClick={() => mark_todo_item_done(dueTodaySelectedItem['todoid'])}
        //                     >
        //                         Completed
        //                     </button>
        //                     <button
        //                         className={`${GenStyles.button} ${TodayStyles.actions_button}
        //                         ${TodayStyles.actions_waived}`}
        //                         onClick={() => document.location.href='todo'}
        //                     >
        //                         View in TODO
        //                     </button>
        //                     <button
        //                         className={`${GenStyles.button} ${TodayStyles.actions_button}
        //                         ${TodayStyles.actions_failed}`}
        //                         onClick={() => move_todo_item_to_tomorrow(dueTodaySelectedItem['todoid'])}
        //                     >
        //                         Move to Tomorrow
        //                     </button>
        //                 </div>
        //
        //                 <footer
        //                     className={`${GenStyles.container} ${GenStyles.red} ${GenStyles.font_white}`}
        //                     id="modal_footer"
        //                 >
        //                     &nbsp;
        //                 </footer>
        //             </div>
        //         </div>
        //         <div id="daily_actions_modal" className={GenStyles.modal_hidden}>
        //             <div className={GenStyles.modal_content}>
        //                 <header className={`${GenStyles.container} ${GenStyles.red} ${GenStyles.font_white}`}>
        //                     <span
        //                         onClick={hide_daily_actions_modal}
        //                         className={`${GenStyles.button} ${GenStyles.display_top_right}`}
        //                     >&times;</span>
        //                     <h3 id="show_more_header_date">Daily Item Actions</h3>
        //                 </header>
        //
        //                 <div
        //                     className={`${GenStyles.container} ${TodayStyles.actions_holder}`}
        //                     id="modal_actions_holder"
        //                 >
        //                     <div className={TodayStyles.actions_title}>
        //                         {selectedItem ? selectedItem['title'] : ""}
        //                     </div>
        //                     <button
        //                         className={`${GenStyles.button} ${TodayStyles.actions_button}
        //                         ${TodayStyles.actions_completed}`}
        //                         onClick={mark_item_done}
        //                     >
        //                         Completed
        //                     </button>
        //                     <button
        //                         className={`${GenStyles.button} ${TodayStyles.actions_button}
        //                         ${TodayStyles.actions_waived}`}
        //                         onClick={mark_item_waived}
        //                     >
        //                         Waived
        //                     </button>
        //                     <button
        //                         className={`${GenStyles.button} ${TodayStyles.actions_button}
        //                         ${TodayStyles.actions_failed}`}
        //                         onClick={mark_item_failed}
        //                     >
        //                         Failed
        //                     </button>
        //                 </div>
        //
        //                 <footer className={`${GenStyles.container} ${GenStyles.red}`} id="modal_footer">
        //                     &nbsp;
        //                 </footer>
        //             </div>
        //         </div>
        //     </>
        // );
    } else {
        return <></>
    }

};

export default PseudosToday;