import React, {useEffect, useState, useContext } from "react";
import { load_fetch, talk } from "../ofb_util";
import Styles from "../styles/pseudos.module.css";
import GenStyles from "../styles/ofb_gen_styles.module.css"
import HealthStyles from "../styles/health.module.css";
import Widget from "./widget";

const WeightWidget = (props) => {
    //DATA
    const [title, setTitle] = useState("Weight");   //Displayed Text Title
    const [newWeight, setNewWeight] = useState(0);  //Not sure what this is for or if I need it
    const [lastWeight, setLastWeight] = useState(false);
    const [todayWeight, setTodayWeight] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [clickUrl, setClickUrl] = useState("./health");

    //USE EFFECTS
    useEffect(() => {
        get_widget_display_info();
    }, []);
    //Set the Widget Selected if It is Selected
    useEffect(() => {
        if(props.selected_widget === props.index) {
            setIsSelected(true);
        } else {
            setIsSelected(false);
        }
    }, [props.selected_widget])
    useEffect(() => {
        if(isSelected && props.go_to_click_url) {
            document.location.href='./health';
        }
    }, [props.go_to_click_url])
    useEffect(() => {
        //console.log("IN BP WIDGET - isSelected: " + isSelected + ", trigger_button: " + props.is_trigger_widget_button_set);
        if(isSelected && props.is_trigger_widget_button_set) {
            show_weight_form(null);
        }
    }, [props.is_trigger_widget_button_set])



    //Info passed to the modal in the layout level
    const show_weight_form = (event) => {
        if(event) {
            event.stopPropagation();
        }
        props.set_trigger_widget_button(true);
        props.show_modal({
            header_text: "Enter Weight",
            body_content: (<>
                <div className={HealthStyles.weight_widget_form_row}>
                    <input type="text" id="input_weight" className={GenStyles.input} placeholder="Wt"/>
                    <input type="text" id="input_bf" className={GenStyles.input} placeholder="BF%"/>
                    <input type="datetime-local" id="input_weight_datetime" className={`${GenStyles.input} ${HealthStyles.weight_widget_date_time}`} placeholder="Date" />
                </div>
                </>),
            footer_text: null,
            callback: modal_callback,
            cancel_callback: cancel_callback,
            update_weight: setNewWeight,
            submit_button_text: 'Save Weight'
        });
    }

    //AJAX FUNCTIONS
    const insert_weight = (data) => {
        talk("health_handler.php", "INSERT_NEW_WEIGHT", data, insert_weight_handler);
    }
    const insert_weight_handler = (operation, data, return_data) => {
        if(!return_data['ERROR']) {
            //Close the modal and update widget
            props.show_modal('');
            get_widget_display_info();
        }
    }
    const get_widget_display_info = () => {
        talk("health_handler.php", "GET_WIDGET_DISPLAY_INFO", null,
            get_widget_display_info_handler);
    }
    const get_widget_display_info_handler = (operation, data, return_data) => {
        if(!return_data['ERROR']) {
            setTodayWeight(return_data.today);
            setLastWeight(return_data.last);
        }
    }

    //Call when modal does something
    const modal_callback = () => {
        //Validate data (needs weight and date)
        let weight = document.getElementById('input_weight').value;
        let body_fat = document.getElementById('input_bf').value;
        let weight_date = document.getElementById('input_weight_datetime').value;

        if(!weight || !weight_date) {
            alert("Must enter a weight and a date.");
            return;
        }
        insert_weight({
            weight: weight,
            bodyfat: body_fat,
            weightdateandtime: weight_date
        });
    }
    const cancel_callback = () => {
        console.log("Modal Cancel");
        props.modal_close_handler();
    }

    return (
        <div className={Styles.widget}>
            <Widget
                selected_widget={props.selected_widget}
                index={props.index}
                title="Weight"
                click_url="../health"
                other_code={(
                    <>
                        <div>
                            <button
                                className={Styles.widget_button}
                                type="button"
                                onClick={show_weight_form}
                            >ENTER WT.</button>
                        </div>
                        <div className={HealthStyles.widget_weight_div}>
                            Last: {lastWeight} Today's: {todayWeight}
                        </div>
                    </>
                )}
            />
        </div>
    );
}

export default WeightWidget;
