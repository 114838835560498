import React, {useEffect, useState} from "react";
import {talk} from "../ofb_util";
import Styles from "../styles/discipleship.module.css";

const Discipleship = (props) => {
    const [utmost, setUtmost] = useState('');
    const [utmostNoTags, setUtmostNoTags] = useState('');
    const [verseRef, setVerseRef] = useState('');
    const [title, setTitle] = useState('');

    useEffect(() => {
        get_utmost();
    }, [])

    const get_utmost = () => {
        talk("Discipleship_Handler.php", "GET_UTMOST", null, get_utmost_handler);
    }
    const get_utmost_handler = (operation, data, return_data) => {
        if(!return_data['ERROR']) {
            //Close the modal and update widget
            let html = return_data;
            let dom = new DOMParser().parseFromString(html, "text/html");

            //let post_title = dom.getElementsByTagName("h1")[0].innerText;
            let parts = dom.getElementsByClassName("elementor-widget-container");
            let title = parts[13];
            let ref = parts[15];
            let text = parts[16];



            setVerseRef(ref.innerHTML);
            setTitle(title.innerHTML);
            setUtmost(text.innerHTML);
            setUtmostNoTags(title.innerText + '\n' + ref.innerText + '\n' + text.innerText);
        }
    }
    function speak(text) {
        let u = new SpeechSynthesisUtterance();
        u.text = text;
        u.lang = 'en-US';
        speechSynthesis.speak(u);
    }
    const read_utmost = () => {
        speak(utmostNoTags);
        //speak("Hello, there!");
    }
    const stop_reading = () => {
        speechSynthesis.cancel();
    }

    return (<>
        <h3>Discipleship</h3>
        <div className={Styles.title} dangerouslySetInnerHTML={{__html: title}}></div>
        <div>
            <button onClick={read_utmost}>Read Aloud</button>
            <button onClick={stop_reading}>Stop</button>
        </div>
        <div className={Styles.verse_ref} dangerouslySetInnerHTML={{__html: verseRef}}></div>
        <div className={Styles.entry_p} dangerouslySetInnerHTML={{__html: utmost}}></div>
        <div>© 2024 My Utmost For His Highest</div>
    </>);
}

export default Discipleship;