import Styles from "../styles/ofbstyles.module.css";
import React, {useState} from "react";
import Source from "./Source";
import Status from "./Status";
import Type from "./Type";
import {load_fetch} from "../ofb_util";

const EditRow = (props) => {
    const [authorFirstName, setAuthorFirstName] = useState(props['all_data']['authorfirstname'] ?? "");
    const [authorLastName, setAuthorLastName] = useState(props['all_data']['authorlastname'] ?? "");
    const [title, setTitle] = useState(props['all_data']['title'] ?? "");
    const [series, setSeries] = useState(props['all_data']['series'] ?? "");
    const [seriesNumber, setSeriesNumber] = useState(props['all_data']['seriesnumber'] ?? "");
    const [status, setStatus] = useState(props['all_data']['status']);
    const [source, setSource] = useState(props['all_data']['source']);
    const [dateread, setDateRead] = useState(props['all_data']['dateread'] ?? "");
    const [rating, setRating] = useState(props['all_data']['rating'] ?? "");
    const [review, setReview] = useState(props['all_data']['review'] ?? "");
    const [type, setType] = useState(props['all_data']['type']);
    const [errorMessage, setErrorMessage] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    function handle_update() {
        //Validate Data
        if(!authorLastName) {
            setErrorMessage("You must have an author's last name. If it is a one name author, " +
                "put the one name here.");
            setShowErrorMessage(true);
            return;
        }
        if(!title) {
            setErrorMessage("You must have a title. ");
            setShowErrorMessage(true);
            return;
        }
        if(!status) {
            setErrorMessage("You must have a reading status entered. ");
            setShowErrorMessage(true);
            return;
        }
        if(rating && rating > 5) {
            setErrorMessage("The maximum rating is 5. ");
            setShowErrorMessage(true);
            return;
        }

        setErrorMessage('');
        setShowErrorMessage(false);

        //Package Data
        let postData = {
            operation: 'UPDATE_BOOK',
            bookid: props['all_data']['bookid'],
            authorlastname: authorLastName,
            authorfirstname: authorFirstName,
            title: title,
            series: series,
            seriesnumber: seriesNumber,
            status: status,
            type: type,
            source: source,
            dateread: dateread,
            rating: rating,
            review: review
        }

        console.log(postData);

        //Send Data
        let url = 'Library_Handler.php';
        load_fetch(url, postData, (data) => {
            console.log(data);
            if(!data['ERROR']) {
                //HANDLE GUI UPDATE
                props.reloadFunction();
                props.cancel_edit();
            } else {
                console.log("ERROR UPDATEING");
            }
        });
    }
    const handle_type_change = (props) => {
        setType(props);
    }
    const handle_source_change = (props) => {
        setSource(props);
    }
    const handle_status_change = (props) => {
        console.log("New Status: " + props)
        setStatus(props);
    }

    return (
        <tr>
            <td colSpan='4' className={Styles.ofb_library_details_table_row}>
                <form>
                <div id='add_new_book_form' className={Styles.ofb_library_add_new_container}>
                    <div className={Styles.ofb_input_holder}>
                        <div className={Styles.ofb_label}>First Name</div>
                        <input type='text'
                               id="authorfirstname"
                               className={Styles.ofb_text_input}
                               value={authorFirstName}
                               onChange={e => setAuthorFirstName(e.target.value)}
                        />
                    </div>
                    <div className={Styles.ofb_input_holder}>
                        <div className={Styles.ofb_label}>Last Name</div>
                        <input type='text'
                               id="authorlastname"
                               value={authorLastName}
                               onChange={e => setAuthorLastName(e.target.value)}
                               className={Styles.ofb_text_input} />
                    </div>
                    <div className={Styles.ofb_input_holder}>
                        <div className={Styles.ofb_label}>Title</div>
                        <input type='text'
                               id="title" value={title}
                               onChange={e => setTitle(e.target.value)}
                               className={Styles.ofb_text_input} />
                    </div>
                    <div className={Styles.ofb_input_holder}>
                        <div className={Styles.ofb_label}>Series</div>
                        <input
                            type='text'
                            id="series"
                            className={Styles.ofb_text_input}
                            value={series}
                            onChange={event => setSeries(event.target.value)}
                        />
                    </div>
                    <div className={Styles.ofb_input_holder}>
                        <div className={Styles.ofb_label}>Series Number</div>
                        <input
                            type='number'
                            id="seriesnumber"
                            className={Styles.ofb_text_input}
                            value={seriesNumber}
                            onChange={event => setSeriesNumber(event.target.value)}
                        />
                    </div>
                    <div className={Styles.ofb_input_holder}>
                        <div className={Styles.ofb_label}>Status</div>
                        <Status value={status} onStatusChange={handle_status_change}/>
                    </div>
                    <div className={Styles.ofb_input_holder}>
                        <div className={Styles.ofb_label}>Type</div>
                        <Type value={type} onTypeChange={handle_type_change}/>
                    </div>
                    <div className={Styles.ofb_input_holder}>
                        <div className={Styles.ofb_label}>Source</div>
                        <Source value={source} onSourceChange={handle_source_change}/>
                    </div>
                    <div className={Styles.ofb_input_holder}>
                        <div className={Styles.ofb_label}>Rating</div>
                        <input
                            type='number'
                            id="rating"
                            className={Styles.ofb_text_input}
                            value={rating}
                            onChange={event => setRating(event.target.value)}
                        />
                    </div>
                    <div className={Styles.ofb_input_holder}>
                        <div className={Styles.ofb_label}>Review</div>
                        <textarea
                            id='review'
                            className={Styles.ofb_text_input}
                            value={review}
                            onChange={event => setReview(event.target.value)}
                        />
                    </div>
                    <div className={Styles.ofb_input_holder}>
                        <div className={Styles.ofb_label}>Date Read</div>
                        <input
                            type='date'
                            className={Styles.ofb_datetime_input}
                            id="dateread"
                            value={dateread}
                            onChange={event => setDateRead(event.target.value)}
                        />
                    </div>
                    <div className={
                        (showErrorMessage) ?
                            Styles.ofb_error_message_div :
                            Styles.ofb_hide
                    } id="error_message_div">{errorMessage}</div>
                    <div className={Styles.ofb_input_holder}>
                        <div className={Styles.ofb_label}>&nbsp;</div>
                        <button
                            className={`${Styles.ofb_button} ${Styles.ofb_black}`}
                            type='button'
                            onClick={props.cancel_edit}>Cancel
                        </button>
                        <button
                            className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                            type='button'
                            onClick={handle_update}>Submit
                        </button>
                    </div>
                </div>
                </form>
            </td>
        </tr>
    )
}
export default EditRow;