import React from 'react';

const Footer = (props) => {
    return (
        <>
            <img src="menus/babel.jpg" className="w3-image w3-greyscale-min" style={{width: "100%"}} />
            <footer className="w3-container w3-padding-16 w3-center w3-opacity w3-light-grey w3-xlarge">
                <p className="w3-medium">&copy; 2022 by Michael D. Turner</p>
            </footer>
        </>
    );
};

export default Footer;