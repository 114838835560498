import React from 'react'
import Styles from "../styles/ofbstyles.module.css";


const FormReconciliationStatus = (props) => {
    return (
        <select
            className={Styles.ofb_select_input}
            value={props.value}
            onChange={event => props.handleReconciliationStatusChange(event.target.value)}
        >
            <option>Not Reconciled</option>
            <option>Reconciled</option>
            <option>Cleared</option>
        </select>
    )
}
export default FormReconciliationStatus;