import React, {useEffect, useState} from "react";
import {talk} from "../ofb_util";
import TStyles from "../styles/todo_simple.module.css";

const ParentCategorySelector = (props) => {

    //DATA
    const [parentItems, setParentItems] = useState(false);
    const [selectedParentId, setSelectedParentId] = useState(props.parent_id);

    //USE EFFECTS
    useEffect(() => {
        get_parent_items();
    }, []);

    //AJAX FUNCTIONS
    const get_parent_items = () => {
        talk("todo_handler.php", "GET_PARENT_ITEMS", null, get_parent_items_handler);
    }
    const get_parent_items_handler = (operation, sent_data, return_data) => {
        setParentItems(return_data);
    }
    const handle_selection_change = (event) => {
        event.stopPropagation();
        setSelectedParentId(event.target.value);
    }

    //RENDERING
    return <div className={TStyles.parent_category_selector}>
        {parentItems && parentItems.length > 0 ? <>
            <select
                className={TStyles.parent_selector}
                value={selectedParentId}
                onChange={(event) => handle_selection_change(event)}
            >
                <option value={-1}>UNCATEGORIZED</option>
                <option value={0}>TOP LEVEL CATEGORY</option>
                {parentItems && parentItems.map((item, index) => (
                    <option key={index} style={{marginLeft: '50px'}} value={item['todoid']}>
                        {item['spacer']} {item['title'].substring(0, 50)}{item['title'].length > 50 ? '...' : ''}
                    </option>
                ))}
            </select>
            <button onClick={props.cancel_editing_parent_id}>x</button>
            <button onClick={() => props.save_parent_id(selectedParentId)}>💾</button>
        </> : '-loading-'}
    </div>
}

export default ParentCategorySelector;
