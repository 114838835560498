import Styles from "../styles/ofbstyles.module.css";
import React, {useContext, useEffect, useState} from "react";
import LibraryContext from "./LibraryContext";

const Status = (props) => {
    const [status, setStatus] = useState(props.value ?? "");
    const libraryOptions = useContext(LibraryContext);

    useEffect(() => {
        props.onStatusChange(status);
    }, [props, status]);

    if(libraryOptions['statuses'] && !libraryOptions['statuses']['ERROR']) {
        return (
            <select
                className={Styles.ofb_select_input}
                name="status"
                id="status_selector"
                value={status}
                onChange={event => setStatus(event.target.value)}
            >
                {libraryOptions['statuses'].map(item => (
                    <option key={item['status']} value={item['status']}>{item['status']}</option>
                ))}
            </select>
        )
    } else {
        return <></>
    }
}
export default Status;