import Styles from "../styles/ofbstyles.module.css";
import React, {useContext, useEffect, useState} from "react";
import LibraryContext from "./LibraryContext";

const Source = (props) => {
    const [source, setSource] = useState(props.value ?? "");
    const libraryOptions = useContext(LibraryContext);

    useEffect(() => {
        props.onSourceChange(source);
    }, [props, source]);

    if(libraryOptions['sources'] && !libraryOptions['sources']['ERROR']) {
        return (
            <select
                className={Styles.ofb_select_input}
                name="source"
                id="source_selector"
                value={source}
                onChange={event => setSource(event.target.value)}
            >
                {libraryOptions['sources'].map(item => (
                    <option key={item['source']} value={item['source']}>{item['source']}</option>
                ))}
            </select>
        )
    } else {
        return <></>
    }
}
export default Source;