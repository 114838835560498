import React from "react";
import PulseStyles from "../styles/pulse.module.css";

const ConnectionInfo = (props) => {
	if(props.active) {
		return (
			<div className={PulseStyles.connection_info}>
			<div className={PulseStyles.circles}>
				<div className={`${PulseStyles.one} ${PulseStyles.green_circle}`} />
				<div className={`${PulseStyles.two} ${PulseStyles.green_circle}`} />
				<div className={`${PulseStyles.three} ${PulseStyles.green_circle}`} />
				<div className={PulseStyles.mid_circle} />
			</div>
			<div className={PulseStyles.connection_info_message}>Connection Active</div>
			</div>
		)
	} else {
		return (
			<div className={PulseStyles.connection_info}>
			<div className={PulseStyles.circles}>
				<div className={`${PulseStyles.one} ${PulseStyles.red_circle}`} />
				<div className={`${PulseStyles.two} ${PulseStyles.red_circle}`} />
				<div className={`${PulseStyles.three} ${PulseStyles.red_circle}`} />
				<div className={PulseStyles.mid_circle_red}/>
			</div>
			<div className={PulseStyles.connection_info_message}>Not Connected</div>
			</div>
		)
	}
}

export default ConnectionInfo;
