import React, {useState} from 'react';
import DetailRow from "./DetailRow";
import EditRow from "./EditRow";
import Styles from "../styles/ofbstyles.module.css";

const BookShelf = (props) => {
    const [editingItem, setEditingItem] = useState(false);

    const reload_parent_library_data = () => {
        props.reloadFunction();
    }
    const display_details = (props) => {
        if(editingItem) {
            return;
        }
        let el = document.getElementById('details_row_' + props);
        el.classList.toggle('w3-hide');
    }
    const get_rating_stars = (star_count) => {
        switch (star_count) {
            case 1:
                return <span className={Styles.ofb_library_rating_1}>* </span>
            case 2:
                return <span className={Styles.ofb_library_rating_2}>* * </span>
            case 3:
                return <span className={Styles.ofb_library_rating_3}>* * * </span>
            case 4:
                return <span className={Styles.ofb_library_rating_4}>* * * * </span>
            case 5:
                return <span className={Styles.ofb_library_rating_5}>* * * * * </span>
            default:
                return <></>
        }
    }
    const LibraryRow = (props) => {
        return (
            <>
                <tr
                    onClick={() => display_details(props['all_data']['bookid'])}
                    className={`${Styles.ofb_table_main_row} ${props['all_data']['status'] == 'WANT_TO_READ' ? Styles.ofb_library_want_to_read : ''}`}
                >
                    <td>{props['all_data']['authorlastname']}</td>
                    <td>{props['all_data']['authorfirstname']}</td>
                    <td>{props['all_data']['title']}</td>
                    <td className={Styles.ofb_library_rating_cell}>{get_rating_stars(props['all_data']['rating'])}</td>
                </tr>{editingItem === props['all_data']['bookid'] ? (
                    <EditRow
                        all_data={props['all_data']}
                        cancel_edit={handle_cancel_edit}
                        reloadFunction={reload_parent_library_data}
                    />
                ) : (
                <DetailRow
                    all_data={props['all_data']}
                    show_edit_form={show_edit_form}
                />
                )}
            </>
        )
    }
    const show_edit_form = (props) => {
        setEditingItem(props.bookid);
    }
    const handle_cancel_edit = () => {
        setEditingItem(false);
    }


    function filter_books(libraryData) {
        if (props.filterParams['lower'] === -1 && props.filterParams['upper'] === -1 && !props.searchTerms) {
            return libraryData;
        } else if (props.searchTerms && props.showSearch) {
            return libraryData.filter(book => {
                if (book['authorlastname'].toLowerCase().indexOf(props.searchTerms.toLowerCase()) !== -1) {
                    return true;
                } else if (book['authorfirstname'].toLowerCase().indexOf(props.searchTerms.toLowerCase()) !== -1) {
                    return true;
                } else if (book['title'].toLowerCase().indexOf(props.searchTerms.toLowerCase()) !== -1) {
                    return true;
                } else if (book['series'] && book['series'].toLowerCase().indexOf(props.searchTerms.toLowerCase()) !== -1) {
                    return true;
                }
                return false;
            });
        } else {
            //TODO: integrate personal books/library books filter with the alphabet and search filter.
            // In other words, layer the two filters together
            return libraryData.filter(book => {
                if(props.filterParams['special'] === 'VIEW_OWNED_BOOKS') {
                    if(book['source'] === 'Personal Library') {
                        return true;
                    } else {
                        return false;
                    }
                } else if (props.filterParams['special'] === 'VIEW_READ_BOOKS') {
                    if(book['source'] === 'Personal Library') {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    const author = book['authorlastname'].toLowerCase();
                    return (author.charCodeAt(0) >= props.filterParams['lower'] && author.charCodeAt(0) <= props.filterParams['upper']);
                }
            })
        }
    }

    if (props.libraryData && !props.libraryData['ERROR']) {
        return (
            <div key="all_books_holder">
                <table className={`${Styles.ofb_pointer} ${Styles.ofb_table}`}>
                    <tbody>
                    {
                        filter_books(props.libraryData).map(item => (
                            <LibraryRow
                                key={item['bookid']}
                                all_data={item}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        )
    } else {
        return <></>
    }
}

export default BookShelf;