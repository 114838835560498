import React, {useEffect, useState} from "react";
import Styles from "../styles/ofbstyles.module.css"

const KeyboardKey = (props) => {
    const [keyStyle, setKeyStyle] = useState(Styles.ofb_bible_memory_key)

    useEffect(() => {
        //Calculate proper style
        if(props.pressedKeys.includes(props.latin)) {
            if(props.char == 'space') {
                setKeyStyle(`${Styles.ofb_bible_memory_pressed_key} ${Styles.ofb_bible_memory_space_bar}`);
            } else {
                setKeyStyle(`${Styles.ofb_bible_memory_pressed_key} ${Styles.ofb_bible_memory_key}`);
            }
        } else {
            if(props.char == 'space') {
                setKeyStyle(`${Styles.ofb_bible_memory_space_bar}`);
            } else {
                setKeyStyle(Styles.ofb_bible_memory_key);
            }
        }
    }, [props.pressedKeys, props.windowWidth])
    /*
    className={props.pressedKeys.includes(props.latin) ?
                `${Styles.ofb_bible_memory_pressed_key} ${Styles.ofb_bible_memory_key}` :
                (props.char=='space' ? `${Styles.ofb_bible_memory_space_bar} ${Styles.ofb_bible_memory_key}` : Styles.ofb_bible_memory_key)}
     */
    return (
        <div
            onClick={() => props.onClick(props.latin)}
            className={keyStyle}
        >
            <div>
                {props.char} {props.windowWidth > 700 ?
                (<span className={Styles.ofb_bible_memory_latin}>{props.latin ?? ""}</span>) : ''}
            </div>
        </div>
    )
}
export default KeyboardKey;