import React, {useEffect, useState} from "react"
import Styles from "../styles/ofbstyles.module.css"

const DateInput = (props) => {
    const today = new Date();

   const handleKeyDown = (event) => {
        switch(event.key.toLowerCase()) {
            case "+":
            case "=":
                increment_date(1);
                break;
            case "-":
                increment_date(-1);
                break;
            case "t":
                props.onDateChange(date_to_string(today));
                break;
            case "c":
                props.onDateChange("");
                break;
            case "l":
                if(props.mostRecentDate && props.mostRecentDate.length) {
                    props.onDateChange(props.mostRecentDate);
                } else {
                    //console.log("Unable to find a most recent date");
                }
                break;
        }
    }
    const increment_date = (amount) => {
        let local_date = new Date();

        //If no date set, use today as our starting date
        if(props.transaction_date.length != 0) {
            let input_parts = props.transaction_date.split('-');
            local_date.setFullYear(
                Number(input_parts[0]),
                Number(input_parts[1]) - 1,
                Number(input_parts[2]));
        }
        local_date.setDate(local_date.getDate() + amount)
        props.onDateChange(date_to_string(local_date));
    }
    const date_to_string = (date_object) => {
        return date_object.getFullYear() + "-" +
            (date_object.getMonth() + 1).toString().padStart(2, '0') + "-" +
            date_object.getDate().toString().padStart(2, '0');
    }
    return (
        <div>
            <input
                autoFocus
                type='date'
                name={props.name}
                id={props.name}
                className={props.className}
                onKeyDown={event => handleKeyDown(event)}
                value={props.transaction_date}
                onChange={event => props.onDateChange(event.target.value)}
            />
        </div>
    )
}
export default DateInput;