import React, {useEffect, useState, useContext } from "react";
import { load_fetch, talk } from "../ofb_util";
import Styles from "../styles/pseudos.module.css";
import GenStyles from "../styles/ofb_gen_styles.module.css"
import LearningStyles from "../styles/learning.module.css";
import Widget from "./widget";

const LearningWidget = (props) => {
    //DATA
    const [questionId, setQuestionId] = useState(-1);
    const [questionText, setQuestionText] = useState("QUESTION");
    const [answerText, setAnswerText] = useState("ANSWER");
    const [questionCategory, setQuestionCategory] = useState('category');
    const [cardFaceToShow, setCardFaceToShow] = useState('QUESTION');

    //const [prayerItem, setPrayerItem] = useState(false);
    const [clickUrl, setClickUrl] = useState("../bible-memory");

    //USE EFFECTS
    useEffect(() => {
        get_question();
    }, []);
    useEffect(() => {
        if(props.index === props.selected_widget) {
            if(props.keyboard_toggler === 'LEFT') {
                thumbs_down();
                props.reset_widget_button_keyboard_toggler();
            }
            if(props.keyboard_toggler === 'RIGHT') {
                thumbs_up();
                props.reset_widget_button_keyboard_toggler();
            }
            if(props.keyboard_toggler === 'RESET') {
                flip_card();
                props.reset_widget_button_keyboard_toggler();
            }
        }
    }, [props.keyboard_toggler])
    useEffect(() => {
        if(props.selected_widget === props.index) {
            document.location.href=clickUrl;
        }
    }, [props.go_to_url_triggered])

    //AJAX FUNCTIONS
    const get_question = () => {
        talk(
            "Learning_Handler.php",
            "PSEUDOS_GET_ONE_QUESTION",
            null,
            get_question_handler
            );
    }
    const get_question_handler = (operation, sent_data, return_data) => {
        console.log(return_data);

        return_data=return_data[Math.floor(Math.random() * 20)];
        if(return_data) {
            setQuestionText(return_data['questiontext'].replace('&#13;', '\n').replace('&#10;', '\n'));
            setAnswerText(return_data['answertext']);
            setQuestionCategory(return_data['learningtopic']);
            setQuestionId(return_data['learningquestionid']);
            setCardFaceToShow('QUESTION');
        }
    }
    const record_learning_attempt = (correctness_value) => {
        let postData = {
            question_id: questionId,
            correctness_value: correctness_value
        }
        let operation = 'RECORD_LEARNING_ATTEMPT';
        let url = 'Learning_Handler.php';
        talk(url, operation, postData, record_learning_attempt_handler);
    }
    const record_learning_attempt_handler = (operation, sent_data, return_data) => {
        console.log(return_data);
        get_question();
    }


    //OTHER FUNCTIONS
     const thumbs_down = (event) => {
         if(event) event.stopPropagation();
         record_learning_attempt(0);
     }
     const thumbs_up = (event) => {
         if(event) event.stopPropagation();
         record_learning_attempt(1);
     }
     const flip_card = (event) => {
         if(event) event.stopPropagation();
         setCardFaceToShow(cardFaceToShow === 'QUESTION' ? 'ANSWER' : 'QUESTION');
     }

    // const mark_prayer_completed = (event) => {
    //     if(event) {
    //         event.stopPropagation();
    //     }
    //     let postData = {
    //         prayer_id: prayerItem['prayer_id']
    //     }
    //     talk('Prayer_Handler.php', 'MARK_COMPLETED', postData, mark_prayer_completed_handler);
    // }
    // const mark_prayer_completed_handler = (operation, sent_data, return_data) => {
    //     if(!return_data['ERROR']) {
    //         get_widget_display_info();
    //     }
    // }

    //OTHER FUNCTIONS


    return (
        <div className={Styles.widget}>
            <Widget
                selected_widget={props.selected_widget}
                index={props.index}
                title="Learn"
                click_url="../learning"
                other_code={(
                    <>
                        <div className={LearningStyles.button_row}>
                            <button
                                className={LearningStyles.thumbs_button}
                                onClick={thumbs_down}
                            >👎</button>
                            <div
                                className={LearningStyles.question_answer_text_box}
                                onClick={flip_card}
                            >{cardFaceToShow === 'QUESTION' ? questionText : answerText}
                            </div>
                            <button
                                className={LearningStyles.thumbs_button}
                                onClick={thumbs_up}
                            >👍</button>
                        </div>
                        <div className={LearningStyles.widget_category}>
                            {questionCategory}
                        </div>
                    </>
                )}
            />
        </div>
    );
}

export default LearningWidget;
