import React, {useEffect, useRef, useState} from "react";
import Styles from "./styles/ofbstyles.module.css";
import MemStyles from "./styles/bible_memory.module.css";
import GreekKeyboard from "./BibleMemory/GreekKeyboard";
import {load_fetch, talk} from "./ofb_util";
import VerseSelector from "./BibleMemory/VerseSelector";
import VersesCompleted from "./BibleMemory/VersesCompleted";

const BibleMemory = () => {
    const [bibleBooks, setBibleBooks] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [pressedKeys, setPressedKeys] = useState([]);
    const [displayContents, setDisplayContents] = useState("");
    const [errorCounter, setErrorCounter] = useState(0);
    const errorCounterRef = useRef(errorCounter);
    const [victoryMessage, setVictoryMessage] = useState('');
    const isError = useRef(false);
    const tokenIndex = useRef(0);
    const bibleMemoryDisplay = useRef();
    const verseText = useRef(null);
    const verse_num = useRef(1);
    const diacritics = [':', '"', ';', "'", '[', ']'];
    const punctuation = [',', '.', 'q', '`'];
    const game_mode = useRef("letters");
    const currentBook = useRef("");
    const currentChapter = useRef(0);
    const currentVerse = useRef(0);
    const currentVersion = useRef('gnt');
    const [showVersesCompleted, setShowVersesCompleted] = useState(false);
    const [readChapterMode, setReadChapterMode] = useState(false);
    const [greekChapterText, setGreekChapterText] = useState({});
    //Alternate game modes: WORDS (typing only the first letter), VERSEREF (matching a verse to its reference), POLYTONIC (typing accents), CLOZE (random missing letters)

    //;

    // let verse = null;
    // let verse1 = "Παῦλος καὶ Τιμόθεος δοῦλοι Χριστοῦ Ἰησοῦ πᾶσιν τοῖς ἁγίοις ἐν Χριστῷ Ἰησοῦ τοῖς οὖσιν ἐν Φιλίπποις σὺν ἐπισκόποις καὶ διακόνοις";
    // let verse2 = "χάρις ὑμῖν καὶ εἰρήνη ἀπὸ Θεοῦ Πατρὸς ἡμῶν καὶ Κυρίου Ἰησοῦ Χριστοῦ";
    // let verse3 = "Εὐχαριστῶ τῷ Θεῷ μου ἐπὶ πάσῃ τῇ μνείᾳ ὑμῶν";
    // let verse4 = "πάντοτε ἐν πάσῃ δεήσει μου ὑπὲρ πάντων ὑμῶν μετὰ χαρᾶς τὴν δέησιν ποιούμενος";
    // let verse_num = 1;
    //let pk = [];    //This was a workaround because state was constantly resetting


    const increment_errors = () => {
        setErrorCounter(errorCounter => errorCounter + 1);
        errorCounterRef.current = errorCounterRef.current + 1;
    }
    useEffect(() => {
        window.addEventListener('keypress', event => keyClicked(event));
        window.addEventListener('resize', () => {
            setWindowWidth(window.innerWidth);
        })
        //get_verse(verse_num.current);
        load_selected_verse({book: "Philippians", chapter: 1, verse: 1, game_type:"WORDS", version:"gnt"});
        load_books_of_the_bible();
        scroll_to_display();
        return () => {
            window.removeEventListener('keypress', event => keyClicked(event['key']));
        }
    }, [])
    useEffect(() => {
        //console.log(verseText.current);
    }, [verseText.current])
    function keyClicked(event) {
        event.preventDefault();
        handle_on_click(event['key']);
    }
    const scroll_to_display = () => {
        let newOffset = bibleMemoryDisplay.current.offsetTop - 80;
        if(windowWidth < 700) {
            newOffset -= 50;
        }

        window.scrollTo({
            top: newOffset,
            left:0,
            behavior:"smooth"
        });
    }
    const load_selected_verse = (verseInfo) => {
        currentBook.current = verseInfo['book'];
        currentChapter.current = verseInfo['chapter'];
        currentVerse.current = verseInfo['verse'];
        currentVersion.current = verseInfo['version'];

        if(verseInfo['game_type']) {
            if (verseInfo['game_type'] === 'letters' || verseInfo['game_type'] === 'POLYTONIC') {
                game_mode.current = 'POLYTONIC';
            } else if (verseInfo['game_type'] === 'LEARN_MODE') {
                game_mode.current = 'LEARN_MODE';
            } else {
                game_mode.current = 'WORDS';
            }
        }

        let url = 'Bible_Handler.php';
        let postData = {
            operation: 'GET_VERSE',
            book: verseInfo['book'],
            chapter: verseInfo['chapter'],
            verse: verseInfo['verse'],
            version:"gnt"
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                //console.log(data);
                verseText.current = data['verse'];
                reset();
            } else {
                verseText.current = null;
            }
        });
    }
    function reset() {
        tokenIndex.current = 0;
        isError.current = false;
        setDisplayContents('');
        setVictoryMessage('');
        setErrorCounter(0);
        errorCounterRef.current = 0;
        setPressedKeys([]);
    }
    const repeat_verse = () => {
        reset();
    }
    const load_books_of_the_bible = () => {
        let url = 'Bible_Handler.php';
        let postData = {
            operation: 'GET_BIBLE_BOOKS'
        }
        load_fetch(url, postData, (data) => {
            //console.log(data);
            if(!data['ERROR'] && !data['error']) {
                setBibleBooks(data);
            } else {
                console.log(data);
            }
        });
    }
    const load_next_verse = () => {
        load_selected_verse({
            book: currentBook.current,
            chapter: currentChapter.current,
            verse: Number(currentVerse.current) + 1,
            version: currentVersion.current,
            game_type:game_mode.current
        });
        reset();
    }
    const handle_on_click = (keychar) => {
        if(game_mode.current == "LEARN_MODE") {
            console.log("IN KEY CLICK");
            let word = get_word_from_current_index();
            setDisplayContents(displayContents => (<>{displayContents}{word} </>));

            //If this is the last letter, display success for the verse
            tokenIndex.current = tokenIndex.current + word.length + 1;
            if(tokenIndex.current >= verseText.current.length) {
                handle_victory();
            }
            return;
        }



        //console.log(errorCounter);
        //HANDLE DIACRITICS
        if(diacritics.includes(keychar)) {
            if(game_mode.current === 'POLYTONIC') {
                let token = verseText.current.charAt(tokenIndex.current);
                let real_key = latin_to_greek(keychar);

                //Handle free standing accent marks like ’ whether POLYTONIC OR LETTERS
                if(token === real_key) {
                    //allow it to pass?
                } else {
                    console.log("PRESSED A DIACRITIC KEY");
                    console.log("TOKEN: " + token);
                    console.log("KEYCHAR: " + keychar);
                    console.log("REALKEYCHAR: " + real_key);
                    setPressedKeys(pressedKeys => ([...pressedKeys, keychar]));
                    return;
                }
            }
        }
        // if(punctuation.includes(keychar)) {
        //     if(game_mode == 'POLYTONIC') {
        //         console.log("PRESSED A PUNCTUATION KEY");
        //         let token = verse.charAt(tokenIndex.current);
        //         console.log("KEYCHAR: " + keychar);
        //         console.log("TOKEN: " + token);
        //         setPressedKeys(pressedKeys => ([...pressedKeys, keychar]));
        //     }
        // }

        //RETURN IF NON-ALPHA, NON-DIACRITIC, NON-SPACE KEY IS PRESSED

        if(!pressedKeys.includes(keychar)) {
            setPressedKeys(pressedKeys => ([...pressedKeys, keychar]));

            //Handle Correctness Check
            let token = verseText.current.charAt(tokenIndex.current);

            //Handle Spaces
            if(token === ' ') {
                if(keychar === ' ') {
                    tokenIndex.current = tokenIndex.current + 1;
                    token = verseText.current.charAt(tokenIndex.current);
                    setDisplayContents(displayContents => (<>{displayContents} </>));
                    setPressedKeys([]);
                    return;
                } else {
                    return;
                }
            }
            if(keychar === ' ') {
                return;
            }

            //Begin comparison
            let comp_a = token;
            let comp_b = keychar;

            if(!diacritics.includes(comp_a) && !punctuation.includes(comp_a)) {
                comp_a = comp_a.toUpperCase();
            }
            comp_a = simplify_greek(comp_a);

            comp_b = latin_to_greek(comp_b);
            if(!diacritics.includes(comp_b) && !punctuation.includes(comp_b)) {
                comp_b = comp_b.toUpperCase();
            }


            //Process Success or Failure
            if(comp_a === comp_b) {
                //SUCCESS

                //Clear selected keys
                setPressedKeys([]);

                //Display the correct text (depending on game mode: letter or word)
                switch(game_mode.current) {
                    case 'POLYTONIC':
                        if (isError.current) {
                            //In red if there was an error
                            token = <span className={Styles.ofb_red_text}>{token}</span>;
                            isError.current = false;
                            setDisplayContents(displayContents => (<>{displayContents}{token}</>));
                        } else {
                            //In black if no error
                            setDisplayContents(displayContents => (<>{displayContents}{token}</>));
                        }

                        //If this is the last letter, display success for the verse
                        tokenIndex.current = tokenIndex.current + 1;
                        if(tokenIndex.current === verseText.current.length) {
                            handle_victory();
                        }
                        break;
                    case 'WORDS':
                        let word = get_word_from_current_index();
                        if (isError.current) {
                            //In red if there was an error
                            token = <span className={Styles.ofb_red_text}>{word}</span>;
                            isError.current = false;
                            setDisplayContents(displayContents => (<>{displayContents}{token} </>));
                        } else {
                            //In black if no error
                            setDisplayContents(displayContents => (<>{displayContents}{word} </>));
                        }

                        //If this is the last letter, display success for the verse
                        tokenIndex.current = tokenIndex.current + word.length + 1;
                        if(tokenIndex.current >= verseText.current.length) {
                            handle_victory();
                        }
                }


            } else {
                //FAILURE

                //Increment Error Counter
                //console.log("TOKEN: " + comp_a);
                //console.log("KEY PRESSED: "+ comp_b);
                increment_errors();

                //Flag the current error state (so when it is eventually gotten right, the letter can be displayed in red)
                isError.current = true;
            }
        }
    }
    const handle_victory = () => {
        if(game_mode.current === 'LEARN_MODE') {
            setVictoryMessage('End of Verse');
            return;
        } else {
            setVictoryMessage("You did it!");
            document.getElementById('next_verse_button').focus();
        }


        //Save Score
        let url = 'Bible_Handler.php';
        let postData = {
            operation: 'SAVE_SCORE',
            book: currentBook.current,
            chapter: currentChapter.current,
            verse: currentVerse.current,
            version: currentVersion.current,
            game_type: game_mode.current,
            errors: errorCounterRef.current
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                console.log(data);
            } else {
                console.log("ERROR: " . data);
            }
        }, 'text');
    }
    function get_word_from_current_index() {
        let start = tokenIndex.current;
        let end = -1;
        for(let i = start; i < verseText.current.length && end === -1; i++) {
            if(verseText.current.charAt(i) === ' ') {
                end = i;
            }
        }

        //If no space was found, then we use the end of the verse to delineate the end of the word
        if(end === -1) {
            end = verseText.current.length;
        }
        return verseText.current.substring(start, end);
    }
    const read_chapter = () => {
        if(readChapterMode) {
            setReadChapterMode(false);
            setGreekChapterText({});
            return;
        }
        let postData = {
            book: currentBook.current,
            chapter: currentChapter.current
        }
        console.log(postData);
        talk("Bible_Handler.php", "GET_GREEK_CHAPTER", postData, read_chapter_handler);
    }
    const read_chapter_handler = (operation, sent_data, return_data) => {
        if(!return_data['ERROR']) {
            setReadChapterMode(true);
            setGreekChapterText(return_data);
        } else {
            console.log(return_data);
        }
    }

    function latin_to_greek(latin_char) {
        switch(latin_char) {
            case 'w':
                return 'ς';
            case 'e':
                return 'ε';
            case 'r':
                return 'ρ';
            case 't':
                return 'τ';
            case 'y':
                return 'υ';
            case 'u':
                return 'θ';
            case 'i':
                return 'ι';
            case 'o':
                return 'ο';
            case 'p':
                return 'π';
            case 'a':
                return 'α';
            case 's':
                return 'σ';
            case 'd':
                return 'δ';
            case 'f':
                return 'φ';
            case 'g':
                return 'γ';
            case 'h':
                return 'η';
            case 'j':
                return 'ξ';
            case 'k':
                return 'κ';
            case 'l':
                return 'λ';
            case 'm':
                return 'μ';
            case 'n':
                return 'ν';
            case 'b':
                return 'β';
            case 'v':
                return 'ω';
            case 'c':
                return 'ψ';
            case 'x':
                return 'χ';
            case 'z':
                return 'ζ';
            case ':':
                return '’';
            case '"':
                return 'ʽ';
            case ';':
                return '´';
            case "'":
                return '`';
            case '[':
                return '~';
            case ']':
                return 'ͺ';
            case '`':
                return '·';
            case 'q':
                return ';';
            case '.':
                return '.';
            case ',':
                return ',';
        }
        return null;
    }
    function simplify_greek(c) {
        switch (c) {
            case 'Α':
                return 'Α';
            case 'Ἀ':
                return 'Α';
            case 'Ἄ':
                return 'Α';
            case 'Ἂ':
                return 'Α';
            case 'Ἆ':
                return 'Α';
            case 'Ἁ':
                return 'Α';
            case 'Ἅ':
                return 'Α';
            case 'Ἃ':
                return 'Α';
            case 'Α͂':
                return 'Α';
            case 'Ά':
                return 'Α';
            case 'Ὰ':
                return 'Α';
            case 'ΑΙ':
                return 'Α';
            case 'Ε':
                return 'Ε';
            case 'Ἐ':
                return 'Ε';
            case 'Ἔ':
                return 'Ε';
            case 'Ἑ':
                return 'Ε';
            case 'Ἕ':
                return 'Ε';
            case 'Ἓ':
                return 'Ε';
            case 'Ὲ':
                return 'Ε';
            case 'Έ':
                return 'Ε';
            case 'Η':
                return 'Η';
            case 'Ἠ':
                return 'Η';
            case 'Ἤ':
                return 'Η';
            case 'Ἢ':
                return 'Η';
            case 'Ἦ':
                return 'Η';
            case 'Ἡ':
                return 'Η';
            case 'Ἥ':
                return 'Η';
            case 'Ἣ':
                return 'Η';
            case 'Ἧ':
                return 'Η';
            case 'Ή':
                return 'Η';
            case 'ΗΙ':
                return 'Η';
            case 'Η͂Ι':
                return 'Η';
            case 'Ὴ':
                return 'Η';
            case 'Η͂':
                return 'Η';
            case 'Ι':
                return 'Ι';
            case 'Ἰ':
                return 'Ι';
            case 'Ἴ':
                return 'Ι';
            case 'Ἶ':
                return 'Ι';
            case 'Ἱ':
                return 'Ι';
            case 'Ἵ':
                return 'Ι';
            case 'Ὶ':
                return 'Ι';
            case 'Ι͂':
                return 'Ι';
            case 'Ί':
                return 'Ι';
            case 'Ο':
                return 'Ο';
            case 'Ὀ':
                return 'Ο';
            case 'Ὄ':
                return 'Ο';
            case 'Ὂ':
                return 'Ο';
            case 'Ὁ':
                return 'Ο';
            case 'Ὅ':
                return 'Ο';
            case 'Ὃ':
                return 'Ο';
            case 'Ό':
                return 'Ο';
            case 'Ὸ':
                return 'Ο';
            case 'Υ':
                return 'Υ';
            case 'Ὑ':
                return 'Υ';
            case 'Ὕ':
                return 'Υ';
            case 'Ὗ':
                return 'Υ';
            case 'Υ͂':
                return 'Υ';
            case 'Υ̓͂':
                return 'Υ';
            case 'Ὺ':
                return 'Υ';
            case 'Ύ':
                return 'Υ';
            case 'Υ̓':
                return 'Υ';
            case 'ῳ':
                return 'Ω';
            case 'Ὠ':
                return 'Ω';
            case 'Ὤ':
                return 'Ω';
            case 'Ὢ':
                return 'Ω';
            case 'Ὦ':
                return 'Ω';
            case 'Ὡ':
                return 'Ω';
            case 'Ὥ':
                return 'Ω';
            case 'Ὧ':
                return 'Ω';
            case 'Ω͂Ι':
                return 'Ω';
            case 'ΩΙ':
                return 'Ω';
            case 'Ω͂':
                return 'Ω';
            case 'Ώ':
                return 'Ω';
            case 'Ὼ':
                return 'Ω';
        }
        return c;
    }

    /*
    TODO:<br/>
            Additional Quizzing:<br/>
            Matching verses with their reference<br/>
                Maybe the verse and four to eight verse references near the actual one to click and choose from<br/>
            Selecting random words in a verse and requiring the user to type it out<br/>
                -Could have two levels: with accent marks or without<br/>
            Data collection can expand to include individual words in a verse (book, chapter, verse, word-index)<br/>
            Have a testing type where the user types the entire verse, but it could be word by word, giving correct/error messages before going on to the next word.<br/>
            When switching to Greek, the onscreen keyboard should change to show the Greek letter in the middle large, and the English key small in bottom right<br/>
            When typing a key (English or Greek) and making a mistake, it should color the key so you don't re-guess the same letter.<br/>
            Add Diacritics on the visual Keyboard for Greek.<br/>
            Allow in the "click-single-letter-to-guess-word" for Greek to enter the actual Greek, with or without accent marks, depending on the level of difficulty<br/>
            Have a vocabulary quiz, with typing the word (with diacritics) and "thinking" the meaning, then self-grading correct/incorrect<br/>
     */
    const hide_verse_selector_modal = () => {
        document.getElementById('verse_selector_modal').style.display = 'none';
    }
    const show_verse_selector = () => {
        document.getElementById('verse_selector_modal').style.display = 'block';
    }
    const show_verses_completed = () => {
        setShowVersesCompleted(true);
        //document.getElementById('verses_completed_modal').style.display = 'block';
    }
    const hide_verses_completed = () => {
        setShowVersesCompleted(false);
        //document.getElementById('verses_completed_modal').style.display = 'none';
    }

    return (
        <div ref={bibleMemoryDisplay}>
            <div className={readChapterMode ? MemStyles.greek_reading_pane : Styles.ofb_hide}>
                {greekChapterText.length > 0 && greekChapterText.map((item, index) => (
                    <div
                        key={index}
                        className={MemStyles.greek_verse}
                    >
                        <div className={MemStyles.greek_verse_number}>{item['verse']}</div>
                        <div className={MemStyles.greek_verse_text}>{item['verse_text']}</div>
                    </div>
                ))}
            </div>
            <div
                className={errorCounter > 0 ? Styles.ofb_bible_memory_error_display : Styles.ofb_hide}
            >
                {errorCounter} errors
            </div>
            <div className={victoryMessage ? Styles.ofb_bible_memory_display : Styles.ofb_hide}>{victoryMessage}
                <button
                    className={Styles.ofb_button}
                    onClick={repeat_verse}
                >Repeat This Verse</button>
                <button
                    id="next_verse_button"
                    className={Styles.ofb_button}
                    onClick={load_next_verse}
                >Next Verse?</button></div>
            <div>{currentBook.current} {currentChapter.current}:{currentVerse.current}</div>
            <div className={Styles.ofb_bible_memory_display}>{displayContents}</div>
            <GreekKeyboard windowWidth={windowWidth} pressedKeys={pressedKeys} onClick={handle_on_click}/>

            <div>
                <button className={Styles.ofb_button} onClick={show_verse_selector}>Select Verse</button>
                <button className={Styles.ofb_button} onClick={show_verses_completed}>Verses Completed</button>
                <button className={Styles.ofb_button} onClick={read_chapter}>{readChapterMode ? "Hide Chapter Text" : "Read Chapter Text"}</button>
            </div>
            <VerseSelector
                hide_verse_selector_modal={hide_verse_selector_modal}
                books={bibleBooks}
                selection_handler={load_selected_verse}
            />
            {showVersesCompleted ? (
                <VersesCompleted
                    visible={showVersesCompleted}
                    hide_verses_completed_modal={hide_verses_completed}
                    selection_handler={load_selected_verse}
                />
            ) : null}
        </div>
    )
}
export default BibleMemory;