import React, {useEffect, useState, useContext } from "react";
import { load_fetch, talk } from "../ofb_util";
import Styles from "../styles/pseudos.module.css";
import GenStyles from "../styles/ofb_gen_styles.module.css"
import MemoryStyles from "../styles/bible_memory.module.css";
import Widget from "./widget";

const BibleMemoryWidget = (props) => {
    //DATA
    const [words, setWords] = useState(['In', 'the', 'beginning']);
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [verseReference, setVerseReference] = useState('Genesis 1:1');

    //const [prayerItem, setPrayerItem] = useState(false);
    const [clickUrl, setClickUrl] = useState("../bible-memory");

    //USE EFFECTS
    useEffect(() => {
        get_current_verse();
    }, []);
    useEffect(() => {
        if(props.index === props.selected_widget) {
            if(props.keyboard_toggler === 'LEFT') {
                back_a_word();
                props.reset_widget_button_keyboard_toggler();
            }
            if(props.keyboard_toggler === 'RIGHT') {
                advance_word();
                props.reset_widget_button_keyboard_toggler();
            }
            if(props.keyboard_toggler === 'RESET') {
                reset_index();
                props.reset_widget_button_keyboard_toggler();
            }
        }
    }, [props.keyboard_toggler])
    useEffect(() => {
        if(props.selected_widget === props.index) {
            document.location.href=clickUrl;
        }
    }, [props.go_to_url_triggered])

    //AJAX FUNCTIONS
    const get_current_verse = () => {
        talk("Bible_Handler.php", "GET_WIDGET_VERSE", null,
            get_current_verse_handler);
    }
    const get_current_verse_handler = (operation, data, return_data) => {
        console.log(return_data);
        if(!return_data['error']) {
            setCurrentWordIndex(0);
            setVerseReference(return_data['reference']);
            setWords(return_data['words']);
        }
    }

    //OTHER FUNCTIONS
    const advance_word = (event) => {
        if(event) event.stopPropagation();
        if(currentWordIndex >= words.length - 1) {
            setCurrentWordIndex(0);
        } else {
            setCurrentWordIndex(currentWordIndex + 1);
        }
    }
    const back_a_word = (event) => {
        if(event) event.stopPropagation();
        if(currentWordIndex === 0) {
            setCurrentWordIndex(words.length - 1);
        } else {
            setCurrentWordIndex(currentWordIndex - 1);
        }
    }
    const reset_index = (event) => {
        if(event) event.stopPropagation();
        setCurrentWordIndex(0);
    }

    // const mark_prayer_completed = (event) => {
    //     if(event) {
    //         event.stopPropagation();
    //     }
    //     let postData = {
    //         prayer_id: prayerItem['prayer_id']
    //     }
    //     talk('Prayer_Handler.php', 'MARK_COMPLETED', postData, mark_prayer_completed_handler);
    // }
    // const mark_prayer_completed_handler = (operation, sent_data, return_data) => {
    //     if(!return_data['ERROR']) {
    //         get_widget_display_info();
    //     }
    // }

    //OTHER FUNCTIONS


    return (
        <div className={Styles.widget}>
            <Widget
                selected_widget={props.selected_widget}
                index={props.index}
                title="Bible Mem"
                click_url="../bible-memory"
                other_code={(
                    <>
                        <div className={MemoryStyles.button_row}>
                            <button
                                className={MemoryStyles.nav_button}
                                onClick={back_a_word}
                            >&lt;-</button>
                            <div
                                className={MemoryStyles.widget_word}
                                onClick={reset_index}
                            >{words[currentWordIndex]}
                            </div>
                            <button
                                className={MemoryStyles.nav_button}
                                onClick={advance_word}
                            >-&gt;</button>
                        </div>
                        <div className={MemoryStyles.widget_ref}>
                            {verseReference}
                        </div>
                    </>
                )}
            />
        </div>
    );
}

export default BibleMemoryWidget;
