import React, {useContext, useEffect, useState} from "react";
import Styles from "../styles/ofbstyles.module.css";
import {format_currency, load_fetch, talk} from "../ofb_util";
import {format_currency_new} from "../ofb_util";
import BudgetEditRow from "./BudgetEditRow";
import {PayeeContext} from "./PayeeContext";
import FormPayee from "./FormPayee";

const CategoryReportItem = (props) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showBudget, setShowBudget] = useState(window.innerWidth > 700 ? true : false);
    const [showBudgetEditRow, setShowBudgetEditRow] = useState(false);
    const [budgetEditRowData, setBudgetEditRowData] = useState([]);
    const [showNewBudgetItemModal, setShowNewBudgetItemModal] = useState(false);
    const [selectedPayee, setSelectedPayee] = useState('');
    const payees = useContext(PayeeContext);
    const [newBudgetItemAmount, setNewBudgetItemAmount] = useState('');
    const [newBudgetItemDate, setNewBudgetItemDate] = useState('');


    useEffect(() => {
        window.addEventListener('resize', handle_resize);
        get_budget_data_row();
    }, []);

    const handle_resize = () => {
        setWindowWidth(window.innerWidth);
        if(window.innerWidth > 700) {
            setShowBudget(true);
        } else {
            setShowBudget(false);
        }
    }


    const show_budget_row_details = () => {
        setShowBudgetEditRow(!showBudgetEditRow);
    }
    const get_budget_data_row = () => {
        let postData = {
            operation: 'GET_BUDGET_REPORT_ROW',
            category_id: props.data.category_id,
            account_id: props.data.account_id
        }
        talk("Finance_Handler.php", "GET_BUDGET_REPORT_ROW",
            postData, get_budget_data_row_handler);
    }
    const get_budget_data_row_handler = (operation, data, return_data) => {
        //console.log(return_data);
        if(return_data) {
            setBudgetEditRowData(return_data);
        } else {
            setBudgetEditRowData([]);
        }
    }
    const showAddNewBudgetItem = () => {
        setShowNewBudgetItemModal(true);
    }
    const payee_add_new_handler = () => {
        alert("payee adding new handler???");
    }
    const cancel_new_budget_item = () => {
        setSelectedPayee('');
        setNewBudgetItemAmount('');
        setNewBudgetItemDate('');
        setShowNewBudgetItemModal(false);
    }
    const insert_new_budget_item = () => {
        if(props.data.category_id != -1) {
            //Budgeting for categoried expenditures/income
            let url = 'Finance_Handler.php';
            let postData = {
                operation: 'INSERT_BUDGET_REPORT_ITEM',
                category_id: props.data.category_id,
                payee_name: selectedPayee,
                day_of_month: newBudgetItemDate,
                amount: newBudgetItemAmount
            }
            load_fetch(url, postData, (data) => {
                if (data) {
                    console.log(data);
                    props.update_budget_gui();
                    get_budget_data_row();
                } else {
                    //console.log("No budget data found.");
                }
            }, 'text');
        } else {
            //Budgeting for transfers from one account to another (auto-savings, loan payments, etc)
            let url = 'Finance_Handler.php';
            let postData = {
                operation: 'INSERT_BUDGETED_TRANSFER_ITEM',
                receiving_account_id: props.data.account_id,
                payee_name: selectedPayee,
                day_of_month: newBudgetItemDate,
                amount: newBudgetItemAmount
            }
            load_fetch(url, postData, (data) => {
                console.log(data);
                if (data) {
                    console.log(data);
                    props.update_budget_gui();
                    get_budget_data_row();
                } else {
                    //console.log("No budget data found.");
                }
            }, 'text');
        }

        //After submitting, clear old form
        cancel_new_budget_item();
    }
    // const delete_budget_item = (budget_id) => {
    //     console.log(budget_id);
    //     return;
    //
    //     let url = 'Finance_Handler.php';
    //     let postData = {
    //         operation: 'DELETE_BUDGET_REPORT_ITEM',
    //         budget_id: budget_id
    //     }
    //     load_fetch(url, postData, (data) => {
    //         if(data) {
    //             console.log(data);
    //             props.update_budget_gui();
    //             get_budget_data_row();
    //         } else {
    //             //console.log("No budget data found.");
    //         }
    //     }, 'text');
    // }
    const delete_budget_item = (item_type, item_id) => {
        let postData = {
            operation: 'DELETE_BUDGET_REPORT_ITEM',
            budget_id: (item_type === 'BUDGET_ITEM') ? item_id : null,
            budget_transfer_id: (item_type === 'BUDGET_TRANSFER_ITEM') ? item_id : null
        }
        talk("Finance_Handler.php", "DELETE_BUDGET_REPORT_ITEM", postData, delete_budget_item_handler);
    }
    const delete_budget_item_handler = (operation, sent_data, return_data) => {
        console.log(return_data);
        if(return_data) {
            props.update_budget_gui();
            get_budget_data_row();
        } else {
            //console.log("No budget data found.");
        }
    }
    const launch_category_report = () => {
        props.handle_category_report_selection(props.data.category_id);
    }
    const prorate_budgeted_total = (amount) => {
        if(!props.days_in_report_range) {
            return amount;
        }

        //Amount is for a month, so calculate the fraction of a month
        let days_in_month = (365.25 / 12);
        let fraction_of_month = props.days_in_report_range / days_in_month;
        return Math.round(amount * fraction_of_month);
    }

    let budget_difference = 0 - (prorate_budgeted_total(props.data.budgeted_total) - props.data.total);
    let diff_class = budget_difference < 0 ? Styles.ofb_red_text : '';

    return (
        <>
        <div className={`${Styles.ofb_finance_category_report} ${props.is_summary_row ? Styles.ofb_border_top : ''}`}>
            <div
                className={`${props.data.category_id != -1 ? Styles.ofb_finance_category_report_selector_div: ''} 
                    ${props.shaded ? Styles.ofb_shaded : ''}`}
                onClick={launch_category_report}
            >
                {props.data.category_name}
            </div>
            <div className={props.shaded ? Styles.ofb_shaded : ''}>{format_currency_new(props.data['total'] ?? 0)}</div>
            <div
                className={showBudget ?
                    (props.shaded ?
                        `${Styles.ofb_shaded} ${Styles.ofb_finance_budget_cell}` :
                        `${Styles.ofb_finance_budget_cell}`) :
                    Styles.ofb_hide}
                onClick={show_budget_row_details}
            >
                {props.data.budgeted_total != false ? format_currency_new(prorate_budgeted_total(props.data.budgeted_total)) : ''}
            </div>
            <div className={showBudget ? (props.shaded ? Styles.ofb_shaded : '')  : Styles.ofb_hide}>
                <span className={diff_class}>{props.data.budgeted_total != false ? format_currency_new(budget_difference) : ''}</span>
            </div>
        </div>
        <div className={showBudgetEditRow ? Styles.ofb_finance_budget_edit_holder : Styles.ofb_hide}>
            {budgetEditRowData.length > 0 ? (
                <>
                {budgetEditRowData.map((item, index) => (
                    <BudgetEditRow
                        delete_handler={delete_budget_item}
                        key={index}
                        data={item}
                    />
                    ))}
                </>
            ) : ''}
            <button className={Styles.ofb_button} onClick={showAddNewBudgetItem}>-Add New Budget Row Here-</button>
            <div className={showNewBudgetItemModal ? Styles.ofb_modal_shown : Styles.ofb_modal_hidden}>
                <div className={Styles.ofb_modal_content}>
                    <div className={Styles.ofb_red}><h3>New Budget Item: {props.data.category_name}</h3></div>
                    <div className={Styles.ofb_budget_form_row}>
                        <div className={Styles.ofb_label}>Payee (optional)</div>
                        <div className={Styles.ofb_budget_input}>
                            <FormPayee
                                options={payees}
                                name='payee'
                                payee={selectedPayee}
                                onChange={setSelectedPayee}
                                payee_add_new_handler={payee_add_new_handler}
                            />
                        </div>
                    </div>
                    <div className={Styles.ofb_budget_form_row}>
                        <div className={Styles.ofb_label}>Enter Amount</div>
                        <div className={Styles.ofb_budget_input}>
                            <input
                                type="text"
                                className={Styles.ofb_text_input}
                                placeholder="$0.00"
                                value={newBudgetItemAmount}
                                onChange={event => setNewBudgetItemAmount(event.target.value)}
                            />
                        </div>
                    </div>
                    <div className={Styles.ofb_budget_form_row}>
                        <div className={Styles.ofb_label}>Enter Date</div>
                        <div className={Styles.ofb_budget_input}>
                            <input
                                type="text"
                                className={Styles.ofb_text_input}
                                placeholder="[1-31] (optional)"
                                value={newBudgetItemDate}
                                onChange={event => setNewBudgetItemDate(event.target.value)}
                            />
                        </div>
                    </div>
                    <div>
                        <button className={Styles.ofb_button} onClick={insert_new_budget_item}>Submit</button>
                        <button className={Styles.ofb_button} onClick={cancel_new_budget_item}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default CategoryReportItem;