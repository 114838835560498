import styles from "../styles/nav_section.module.css";

export const menuData = [
    {
        nav_block_name: "Planning",
        nav_block_image: "planning.jpg",
        nav_block_items: [
            {
                link_key: 1,
                link_name: "TODO",
                url: "./todo-active-simple",
                styles: null,
                classNames: null,
            },
            {
                link_key: 2,
                link_name: "CALENDAR",
                url: "./calendar",
                styles: null,
                classNames: null,
            },
            {
                link_key: 3,
                link_name: "TODAY",
                url: "./today",
                styles: null,
                classNames: styles.null,
            },
            {
                link_key: 4,
                link_name: "OVERVIEW",
                url: "./overview",
                styles:null,
                classNames: styles.last_item,
            }
        ],
    },
    {
        nav_block_name: "Growing",
        nav_block_image: "growing.jpg",
        nav_block_items: [
            {
                link_key: 1,
                link_name: "DISCIPLESHIP",
                url: "./discipleship",
                styles: null,
                classNames: null,
            },
            {
                link_key: 2,
                link_name: "PRAYER",
                url: "./prayer",
                styles: null,
                classNames: null,
            },
            {
                link_key: 3,
                link_name: "BIBLE",
                url: "./bible",
                styles: null,
                classNames: null,
            },
            {
                link_key: 4,
                link_name: "BIBLE MEM",
                url: "./bible-memory",
                styles: null,
                classNames: styles.last_item,
            },
        ],
    },
    {
        nav_block_name: "Home",
        nav_block_image: "home.jpg",
        nav_block_items: [
            {
                link_key: 1,
                link_name: "SHOPPING",
                url: "./shopping",
                styles: null,
                classNames: null,
            },
            {
                link_key: 2,
                link_name: "MEALS",
                url: "./meals",
                styles: null,
                classNames: null,
            },
            {
                link_key: 3,
                link_name: "FINANCE",
                url: "./finance",
                styles: null,
                classNames: null,
            },
            {
                link_key: 4,
                link_name: "CYCLICAL",
                url: "./cycles",
                styles: null,
                classNames: styles.last_item,
            },
        ],
    },
    {
        nav_block_name: "Teaching",
        nav_block_image: "teaching.jpg",
        nav_block_items: [
            {
                link_key: 1,
                link_name: "LESSON PLANS",
                url: "./lesson-plans",
                styles: null,
                classNames: null,
            },
        ],
    },
    {
        nav_block_name: "Health",
        nav_block_image: "health.jpg",
        nav_block_items: [
            {
                link_key: 1,
                link_name: "EXERCISE",
                url: "./exercise",
                styles: null,
                classNames: null,
            },
            {
                link_key: 2,
                link_name: "HEALTH",
                url: "./health",
                styles: null,
                classNames: null,
            },
        ],
    },
    {
        nav_block_name: "The Mind",
        nav_block_image: "mind.jpg",
        nav_block_items: [
            {
                link_key: 1,
                link_name: "LIBRARY",
                url: "./library",
                styles: null,
                classNames: null,
            },
            {
                link_key: 2,
                link_name: "LEARNING",
                url: "./learning",
                styles: null,
                classNames: null,
            },
        ],
    },
];