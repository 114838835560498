import React, {useEffect, useState} from "react"
import Styles from "../styles/ofbstyles.module.css";
import {load_fetch} from "../ofb_util";
import {get_bible_book_number} from "./bible_utils";

const VersesCompleted = (props) => {

    const [versesCompleted, setVersesCompleted] = useState([]);
    const [sortKeys, setSortKeys] = useState({key: 'lastdate', direction: 'DESC'});

    useEffect(() => {
        if(!versesCompleted.length) {
            get_verses_completed();
        }
    }, []);
    useEffect(() => {
        if(props.visible) {
            //Reload Verses Completed
            get_verses_completed();
        }
    }, [props.visible]);
    useEffect(() => {
        sort_verses([...versesCompleted], sortKeys['key'], sortKeys['direction']);
    }, [sortKeys]);

    function sort_verses(local_verses, key, order) {
        //let local_verses = [...versesCompleted];
        local_verses.sort(function(a, b) {
            if(key === 'reference') {
                if(a['book'] !== b['book']) {
                    if(order != 'ASC') {
                        return get_bible_book_number(b['book']) -
                            get_bible_book_number(a['book']);
                    } else {
                        return get_bible_book_number(a['book']) -
                            get_bible_book_number(b['book']);
                    }
                } else {
                    if(a['chapter'] !== b['chapter']) {
                        if(order != 'ASC') {
                            return b['chapter'] - a['chapter'];
                        } else {
                            return a['chapter'] - b['chapter'];
                        }
                    } else {
                        if(order != 'ASC') {
                            return b['verse'] - a['verse'];
                        } else {
                            return a['verse'] - b['verse'];
                        }
                    }
                }
            }
            if(key === 'lastdate') {
                if(order != 'ASC') {
                    return new Date(b[key]) - new Date(a[key]);
                } else {
                    return new Date(a[key]) - new Date(b[key]);
                }
            }
            if(key === 'version' || key === 'game_type' || key === 'lastthree') {
                if(order != 'ASC') {
                    return b[key].localeCompare(a[key]);
                } else {
                    return a[key].localeCompare(b[key]);
                }
            }

            if(order != 'ASC') {
                return b[key] - a[key];
            } else {
                return a[key] - b[key];
            }
        })
        setVersesCompleted([...local_verses]);
    }
    function change_sort(key) {
        if(key === sortKeys['key']) {
            //Flip Direction
            let new_direction = (sortKeys['direction'] == 'ASC') ? 'DESC' : 'ASC';
            setSortKeys({key: key, direction: new_direction});
        } else {
            setSortKeys({key: key, direction: 'ASC'});
        }
    }
    function get_verses_completed() {
        let url = 'Bible_Handler.php';
        let postData = {
            operation: 'GET_VERSES_COMPLETED'
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                //DO SOMETHING HERE
                sort_verses(data, 'lastdate', 'DESC');
            } else {
                console.log(data);
            }
        });
    }

    const handle_selected_verse = () => {
        //props.selection_handler({book: selectedBook, chapter: selectedChapter, verse:selectedVerse, game_type: gameType});
        props.selection_handler({book: "No book yet"});
        props.hide_verses_completed_modal();
    }
    const load_verse = (book, chapter, verse, version, game_type) => {
        props.selection_handler({book: book, chapter: chapter, verse: verse, game_type:game_type, version:version});
        props.hide_verses_completed_modal();
    }

    return (
        <div id="verses_completed_modal" className={`w3-modal ${Styles.ofb_bible_verses_completed_modal}`}>
            <div className="w3-modal-content">
                <header className="w3-container w3-red">
                    <span onClick={props.hide_verses_completed_modal} className="w3-button w3-display-topright">&times;</span>
                    <h3>Verses Completed</h3>
                </header>

                <div className={`w3-container ${Styles.ofb_bible_verses_completed_header}`}>
                    <div>
                        <div className={Styles.ofb_bible_verses_completed_verse}>
                            <button
                                className={Styles.ofb_bible_sort_key_button}
                                onClick={() => change_sort('reference')}
                            ><span className={Styles.ofb_bold}>Verse</span></button>
                        </div>
                        <div className={Styles.ofb_bible_verses_completed_lastdate}>
                            <button
                                className={Styles.ofb_bible_sort_key_button}
                                onClick={() => change_sort('lastdate')}
                            ><span className={Styles.ofb_bold}>Last Date</span></button>
                        </div>
                        <div className={Styles.ofb_bible_verses_completed_version}>
                            <button
                                className={Styles.ofb_bible_sort_key_button}
                                onClick={() => change_sort('version')}
                            ><span className={Styles.ofb_bold}>Ver.</span></button>
                        </div>
                        <div className={Styles.ofb_bible_verses_completed_gametype}>
                            <button
                                className={Styles.ofb_bible_sort_key_button}
                                onClick={() => change_sort('game_type')}
                            ><span className={Styles.ofb_bold}>Type</span></button>
                        </div>
                        <div className={Styles.ofb_bible_verses_completed_lastthree}>
                            <button
                                className={Styles.ofb_bible_sort_key_button}
                                onClick={() => change_sort('lastthree')}
                            ><span className={Styles.ofb_bold}>Last 3</span></button>
                        </div>
                        <div className={Styles.ofb_bible_verses_completed_lastscore}>
                            <button
                                className={Styles.ofb_bible_sort_key_button}
                                onClick={() => change_sort('lastscore')}
                            ><span className={Styles.ofb_bold}>Last</span></button>
                        </div>
                        <div className={Styles.ofb_bible_verses_completed_best}>
                            <button
                                className={Styles.ofb_bible_sort_key_button}
                                onClick={() => change_sort('best')}
                            ><span className={Styles.ofb_bold}>Best</span></button>
                        </div>
                        <div className={Styles.ofb_bible_verses_completed_attempts}>
                            <button
                                className={Styles.ofb_bible_sort_key_button}
                                onClick={() => change_sort('attempts')}
                            ><span className={Styles.ofb_bold}>Tries</span></button>
                        </div>
                        <div className={Styles.ofb_bible_verses_completed_errors}>
                            <button
                                className={Styles.ofb_bible_sort_key_button}
                                onClick={() => change_sort('errors')}
                            ><span className={Styles.ofb_bold}>Err.</span></button>
                        </div>
                    </div>
                </div>
                <div className={`w3-container ${Styles.ofb_bible_verses_completed_holder}`}>
                    {versesCompleted.length && versesCompleted.map((item, index) => (
                        <div
                            key={index}
                            className={Styles.ofb_bible_verse_completed_row}
                            onClick={() => load_verse(item['book'], item['chapter'], item['verse'], item['version'], item['game_type'])}
                        >
                            <div className={Styles.ofb_bible_verses_completed_verse}>{item['book']} {item['chapter']}:{item['verse']}</div>
                            <div className={Styles.ofb_bible_verses_completed_lastdate}>{item['lastdate'].substring(0,10)}</div>
                            <div className={Styles.ofb_bible_verses_completed_version}>{item['version']}</div>
                            <div className={Styles.ofb_bible_verses_completed_gametype}>{item['game_type']}</div>
                            <div className={Styles.ofb_bible_verses_completed_lastthree}>{item['lastthree']}</div>
                            <div className={Styles.ofb_bible_verses_completed_lastscore}>{item['lastscore']}</div>
                            <div className={Styles.ofb_bible_verses_completed_best}>{item['best']}</div>
                            <div className={Styles.ofb_bible_verses_completed_attempts}>{item['attempts']}</div>
                            <div className={Styles.ofb_bible_verses_completed_errors}>{item['errors']}</div>
                        </div>
                    ))}
                </div>

                <footer className="w3-container w3-red" id="modal_footer">
                    <button
                        className={`${Styles.ofb_button} ${Styles.ofb_black}`}
                        onClick={handle_selected_verse}
                    >
                        Select
                    </button>
                </footer>
            </div>
        </div>
    )
}
export default VersesCompleted;
