import Styles from "../styles/ofbstyles.module.css";
import React, {useEffect, useState} from "react";
// import Form_Transaction_Part from "./Form_Transaction_Part";
// import Form_Transaction from "./Form_Transaction";
import {format_currency, load_fetch} from "../ofb_util";
import ConfirmModal from "../ConfirmModal";
import DateInput from "./DateInput";
import FormReconciliationStatus from "./Form_Reconciliation_Status";
import CurrencyInput from "./Currency_Input";
import FormTransactionPart from "./Form_Transaction_Part";
import FormPayee from "./FormPayee";

const EditTransactionModal = (props) => {
    //DATA
    const [transaction, setTransaction] = useState(false);

    //USEEFFECTS
    useEffect(() => {
        get_transaction();
    }, [props.transaction_id]);

    //AJAX FUNCTIONS
    const get_transaction = () => {
        if(!props.transaction_id) {
            return;
        }
        let url = 'Finance_Handler.php';
        let postData = {
            operation: 'GET_TRANSACTION',
            transaction_id: props.transaction_id
        }
        load_fetch(url, postData, (data) => {
            console.log("DATA");
            console.log(data);
            if(!data['ERROR'] && !data['error']) {
                switch(data['reconciliation_status']) {
                    case 'C':
                        data['reconciliation_status'] = 'Cleared';
                        break;
                    case 'R':
                        data['reconciliation_status'] = 'Reconciled';
                        break;
                    default:
                        data['reconciliation_status'] = 'Not Reconciled';
                        break;
                }
                console.log(typeof data['transaction_parts']);
                setTransaction(data);
            } else {
                setTransaction(false);
            }
        });
    }

    //OTHER FUNCTIONS
    const handle_date_change = (new_date) => {
        console.log(new_date);
    }
    const update_payee = (event) => {
        console.log(event.target);
    }
    const update_local_transaction_data = (event) => {
        const {name, value} = event.target;
        setTransaction(prevData => ({
            ...prevData,
            [name]: value
        }));
    }
    const update_reconciliation_status = (new_status) => {
        let e = {};
        e['target'] = {
            name: 'reconciliation_status',
            value: new_status
        };
        update_local_transaction_data(e);
    }

    //RENDERING
    if(transaction) {
        return (<>
            <div
                id="edit_transaction_modal"
                className={Styles.ofb_modal_shown}
            >
                <div className={Styles.ofb_modal_content}>
                    <div className={Styles.ofb_modal_header}>

                    </div>
                    <div className={Styles.ofb_modal_body}>
                        <div className={Styles.ofb_font_size_12}>
                            <DateInput
                            name='transaction_date'
                            placeholder='transaction_date'
                            mostRecentDate={props.mostRecentDate}
                            startValue={transaction.transaction_date}
                            transaction_date={transaction.transaction_date}
                            onDateChange={handle_date_change}
                            className={Styles.ofb_datetime_input}/>
                        </div>
                    </div>
                    <div className={Styles.ofb_font_size_12}>
                        <FormPayee
                            options={props.payees}
                            name='payee'
                            payee={props.payee}
                            onChange={update_payee}
                            payee_add_new_handler={props.payee_add_new_handler}
                        />
                    </div>
                    <div className={Styles.ofb_font_size_12}>
                        <FormReconciliationStatus
                            value={transaction.reconciliation_status}
                            handleReconciliationStatusChange={update_reconciliation_status}
                        />
                    </div>
                    <div className={Styles.ofb_font_size_12}>
                        <input
                            type="text"
                            name="number"
                            className={Styles.ofb_text_input}
                            placeholder="number"
                            value={transaction.number}
                            onChange={update_local_transaction_data}
                        />
                    </div>
                    <div className={Styles.ofb_font_size_12}>
                        <input
                            type="text"
                            name="memo"
                            className={Styles.ofb_text_input}
                            placeholder="memo"
                            value={transaction.memo}
                            onChange={update_local_transaction_data}
                        />
                    </div>
                    <div className={Styles.ofb_font_size_12}>
                        {transaction.transaction_parts && transaction.transaction_parts.map((item, index) => (
                            <div key={index}>
                                <FormTransactionPart
                                    presetData={item}
                                    categories={props.categories}
                                />
                            </div>
                            ))}
                    </div>
                    <div className={Styles.ofb_modal_footer}>
                        <button
                            className={Styles.ofb_modal_footer_button}
                            onClick={props.cancel_edit_transaction}
                        >Cancel</button>
                    </div>
                </div>
            </div>




        </>)
    } else {
        return <div>Loading</div>
    }
}

export default EditTransactionModal;