import NavSection from "./nav_section";
import PageHeader from "./page_header";
import {AuthContext} from "./auth_context";
import {useContext} from "react";

const Home = (props) => {
    const [authStatus, setAuthStatus] = useContext(AuthContext);

    if(authStatus === true) {
        return (
            <>
                <PageHeader page_title="LIFE" bible_randomizer="true"/>
                <NavSection/>
            </>
        );
    } else {
        return (
            <></>
        );
    }
};
export default Home;