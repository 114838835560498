import Styles from "../styles/ofbstyles.module.css";
import React from "react";
import {format_currency_new} from "../ofb_util";

const Accounts = (props) => {
    return (
        <>
            {props.accounts.map(item => (
                <div className={Styles.ofb_finance_account_holder} key={item['account_id']}>
                    <div className={Styles.ofb_finance_account_holder_left}>
                        <div
                            className={Styles.ofb_finance_account_name_div}
                            onClick={() => props.handle_select_account(item)}
                        >{item['account_name']}</div>
                        <div className={Styles.ofb_finance_account_type_cell}>{item['account_type']}</div>
                    </div>
                    <div className={Styles.ofb_finance_account_holder_right}>
                        {item['sub_accounts'] ? (
                            <>
                                <div>Parent Balance: {format_currency_new(item['parent_balance'])}</div>
                                <div>Balance: {format_currency_new(item['current_balance'])}</div>
                                <div className={Styles.ofb_finance_subaccount_label}>Subaccounts:</div>
                                {item['sub_accounts'].map((subacc, subindex) => (
                                    <div
                                        key={subindex}
                                        onClick={() => props.handle_select_account(subacc)}
                                        className={Styles.ofb_finance_subaccount_name}
                                    >
                                        {subacc['account_name']}: {format_currency_new(subacc['current_balance'])}
                                    </div>
                                ))}
                            </>
                        ) : (
                            <div>Balance: {format_currency_new(item['current_balance'])}</div>
                        )}

                    </div>
                </div>
            ))}
        </>
    )
}

export default Accounts;
