import React, {useEffect, useState} from "react";
import { Line } from "react-chartjs-2";
import { format } from 'date-fns';

const BPChart = (props) => {
    const [systolics, setSystolics] = useState(null);
    const [diastolics, setDiastolics] = useState(null);
    const [pulses, setPulses] = useState(null);
    const [dates, setDates] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [truncatedSystolics, setTruncatedSystolics] = useState(null);
    const [truncatedDiastolics, setTruncatedDiastolics] = useState(null);
    const [truncatedPulses, setTruncatedPulses] = useState(null);
    const [truncatedDates, setTruncatedDates] = useState(null);
    const [truncatedChartData, setTruncatedChartData] = useState(null);
    const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;

    useEffect(() => {
        if(!props.data) {
            return;
        }
        let local_systolics = [];
        let local_diastolics = [];
        let local_pulses = [];

        let local_dates = [];

        let local_systolics_recent = [];
        let local_diastolics_recent = [];
        let local_pulses_recent = [];

        let local_dates_recent = [];

        const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
        const today = new Date();

        for(let i = props.data.length - 1; i >= 0; i--) {
            let bp_date = new Date(props.data[i]['bpdateandtime']);
            const timeDifference = today.getTime() - bp_date.getTime();

            //Decide whether to include this data point in the "most recent 30 days" array
            if(timeDifference <= thirtyDaysInMilliseconds) {
                local_dates_recent.push(format(bp_date, 'M/d/yy'));
                local_systolics_recent.push(props.data[i]['systolic']);
                local_diastolics_recent.push(props.data[i]['diastolic']);
                local_pulses_recent.push(props.data[i]['pulse']);
            }

            local_systolics.push(props.data[i]['systolic']);
            local_diastolics.push(props.data[i]['diastolic']);
            local_pulses.push(props.data[i]['pulse']);
            local_dates.push(format(bp_date, 'M/d/yy'));
        }
        setDates(local_dates);
        setSystolics(local_systolics);
        setDiastolics(local_diastolics);
        setPulses(local_pulses);

        setTruncatedDates(local_dates_recent);
        setTruncatedSystolics(local_systolics_recent);
        setTruncatedDiastolics(local_diastolics_recent);
        setTruncatedPulses(local_pulses_recent);

        const data = {
            labels: dates,
            datasets: [
                {
                    label: "Systolic - All Time",
                    backgroundColor: "rgb(255, 99, 132)",
                    borderColor: "rgb(255, 99, 132)",
                    data: systolics,
                },
                {
                    label: "Diastolic - All Time",
                    backgroundColor: "rgb(126,152,234)",
                    borderColor: "rgb(126,152,234)",
                    data: diastolics,
                },
                {
                    label: "Pulses - All Time",
                    backgroundColor: "rgb(156,255,146)",
                    borderColor: "rgb(156,255,146)",
                    data: pulses,
                },
            ],
        };
        setChartData(data);

        const truncated_data = {
            labels: truncatedDates,
            datasets: [
                {
                    label: "Systolic - Last 30 Days",
                    backgroundColor: "rgb(255, 99, 132)",
                    borderColor: "rgb(255, 99, 132)",
                    data: truncatedSystolics,
                },
                {
                    label: "Diastolic - Last 30 Days",
                    backgroundColor: "rgb(126,152,234)",
                    borderColor: "rgb(126,152,234)",
                    data: truncatedDiastolics,
                },
                {
                    label: "Pulses - Last 30 Days",
                    backgroundColor: "rgb(156,255,146)",
                    borderColor: "rgb(156,255,146)",
                    data: truncatedPulses,
                },
            ],
        };
        setTruncatedChartData(truncated_data);
    }, [props.data]);


    if(chartData) {
        if(props.range=='all') {
            return (
                <div>
                    <Line data={chartData}/>
                </div>
            )
        } else if(props.range == '30 days') {
            return (
                <div>
                    <Line data={truncatedChartData} />
                </div>
            )
        }
    } else {
        return <></>
    }
};

export default BPChart;