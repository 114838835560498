import React from "react";
import Styles from "../styles/ofbstyles.module.css"
import KeyboardKey from "./KeyboardKey";

const GreekKeyboard = (props) => {
    return (
        <div className={Styles.ofb_bible_memory_greek_keyboard}>
            <div className={Styles.ofb_bible_memory_greek_row}>
                <KeyboardKey char="ʼ" type="diacritic" latin=":" windowWidth={props.windowWidth} onClick={props.onClick} pressedKeys={props.pressedKeys} />
                <KeyboardKey char="ʽ" type="diacritic" latin='"' windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="´" type="diacritic" latin=";" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="`" type="diacritic" latin="'" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="~" type="diacritic" latin="[" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="ͺ" type="diacritic" latin="]" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
            </div>
            <div className={Styles.ofb_bible_memory_greek_row}>
                <KeyboardKey char="Σς" latin="w" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="Εε" latin="e" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="Ρρ" latin="r" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="Ττ" latin="t" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="Υυ" latin="y" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="Θθ" latin="u" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="Ιι" latin="i" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="Οο" latin="o" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="Ππ" latin="p" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
            </div>
            <div className={Styles.ofb_bible_memory_greek_row}>
                <KeyboardKey char="Αα" latin="a" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="Σσ" latin="s" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="Δδ" latin="d" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="Φφ" latin="f" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="Γγ" latin="g" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="Ηη" latin="h" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="Ξξ" latin="j" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="Κκ" latin="k" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="Λλ" latin="l" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
            </div>
            <div className={Styles.ofb_bible_memory_greek_row}>
                <KeyboardKey char="Ζζ" latin="z" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="Χχ" latin="x" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="Ψψ" latin="c" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="Ωω" latin="v" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="Ββ" latin="b" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="Νν" latin="n" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="Μμ" latin="m" windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
            </div>
            <div className={Styles.ofb_bible_memory_greek_row}>
                <KeyboardKey char="space" latin=" " windowWidth={props.windowWidth} onClick={props.onClick}  pressedKeys={props.pressedKeys} />
                <KeyboardKey char="," type="punctuation" latin="," windowWidth={props.windowWidth} onClick={props.onClick} pressedKeys={props.pressedKeys} />
                <KeyboardKey char="." type="punctuation" latin="." windowWidth={props.windowWidth} onClick={props.onClick} pressedKeys={props.pressedKeys} />
                <KeyboardKey char=";" type="punctuation" latin="q" windowWidth={props.windowWidth} onClick={props.onClick} pressedKeys={props.pressedKeys} />
                <KeyboardKey char="·" type="punctuation" latin="`" windowWidth={props.windowWidth} onClick={props.onClick} pressedKeys={props.pressedKeys} />
            </div>
        </div>
    )
}
export default GreekKeyboard;