import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import { useOutletContext } from "react-router-dom";
import {load_fetch, get_current_time} from "../ofb_util";
import Styles from "../styles/pseudos.module.css"
import SideNavButton from "./side_nav_button";
import Widget from "./widget";
import WeightWidget from "./WeightWidget";
import BPWidget from "./BPWidget";
import PrayerWidget from "./PrayerWidget";
import {WidgetContext} from "./WidgetContext";
import Marquee from "react-fast-marquee";
import GenStyles from "../styles/ofb_gen_styles.module.css";
import PStyles from "../styles/pseudos.module.css";
import {AuthContext} from "../auth_context";
import Signin from "../signin";
import BibleMemoryWidget from "./BibleMemoryWidget";
import LearningWidget from "./LearningWidget";


const PseudosHome = (props) => {
    const [currentMenuPage, setCurrentMenuPage] = useState(0);
    const [leftNavEnabled, setLeftNavEnabled] = useState(false);
    const [rightNavEnabled, setRightNavEnabled] = useState(true);
    // const [outletContext, setOutletContext] = useOutletContext();
    const [selectedWidget, setSelectedWidget] = useState(null);
    const [menuCount, setMenuCount] = useState(3);
    const keyCaptureInput = useRef(null);
    const [keyCaptureInputValue, setKeyCaptureInputValue] = useState('');
    const [goToClickUrl, setGoToClickUrl] = useState(false);
    const [triggerWidgetButton, setTriggerWidgetButton] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [modal, setModal] = useState('');
    const [widgetButtonKeyboardToggler, setWidgetButtonKeyboardToggler] = useState(false);
    const [authStatus, setAuthStatus] = useContext(AuthContext);

    const widgetClickHandler = (data) => {
        console.log("HERE" + data);
    }





    //FROM OLD LAYOUT FILE
    useEffect(() => {   //On Modal Shown/Hidden
        // console.log("HERE");
        // console.log(modal);
        // if(typeof modal === 'object') {
        //     let bpform = document.getElementById("bp_form");
        //     console.log(bpform);
        //     console.log(bpform.children[0]);
        //     //bpform.children[0].focus();
        // }
    }, [modal])

    //GENERAL FUNCTIONS

    const show_modal_function = (content) => {
        setModal(content);
    }
    const [showModalFunction, setShowModalFunction] = useState(
        {
            show_modal_function: show_modal_function,
            return_data: {},
            modal_data: modal
        }
    )
    const close_modal = () => {
        setModal('');
        modal.cancel_callback();
    }
    const modal_submit_handler = () => {
        modal.callback();
    }
    //FROM OLD LAYOUT FILE



















    //USE EFFECTS
    useEffect(() => {
        if(authStatus) {
            get_current_time(set_current_time);
        }
    }, [authStatus])
    useEffect(() => {
        if(authStatus) {
            keyCaptureInput.current.focus();
        }
    }, [authStatus]);
    useEffect(() => {
        console.log("SELECTED WIDGET IS NOW: " + selectedWidget);
    }, [selectedWidget])
    // useEffect(() => {
    // //     console.log("TRIGGER WIDGET BUTTON IS: " + triggerWidgetButton)
    // }, [triggerWidgetButton])





    //GENERAL FUNCTIONS
    const set_current_time = (time) => {
        if(authStatus) {
            setCurrentTime(time);
        }
    }
    const update_widget_rows = () => {

    }
    const nav_to = (widget_index) => {
        if(selectedWidget === widget_index) {
            setSelectedWidget(null);
        } else {
            setSelectedWidget(widget_index);
        }
    }
    const handle_key_press = (event) => {
        event.stopPropagation();
        console.log(event.key);
        switch(event.key) {
            case '9':
                shift_menu(1);
                break;
            case '7':
                shift_menu(-1);
                break;
            case '8':
                //Nav to Widget click_url
                // if(selectedWidget) {
                //     setGoToClickUrl(true);
                // } else {
                //     setGoToClickUrl(false);
                // }
                setGoToClickUrl(!goToClickUrl);
                break;
            case '0':
                //Trigger Widget Button
                if(selectedWidget) {
                    setTriggerWidgetButton(true);
                } else {
                    setTriggerWidgetButton(false);
                }
                setWidgetButtonKeyboardToggler(!widgetButtonKeyboardToggler);
                break;
            case '1':
                if(selectedWidget === 13 || selectedWidget === 11) {
                    //Bible Memory Widget or Learning Widget
                    setWidgetButtonKeyboardToggler('LEFT');
                    break;
                }
                nav_to((currentMenuPage * 6) + 3);
                break;
            case '2':
                if(selectedWidget === 13 || selectedWidget === 11) {
                    //Bible Memory Widget or Learning Widget
                    setWidgetButtonKeyboardToggler('RESET');
                    break;
                }
                nav_to((currentMenuPage * 6) + 4);
                break;
            case '3':
                if(selectedWidget === 13 || selectedWidget === 11) {
                    //Bible Memory Widget or Learning Widget
                    setWidgetButtonKeyboardToggler('RIGHT');
                    break;
                }
                nav_to((currentMenuPage * 6) + 5);
                break;
            case '4':
                nav_to((currentMenuPage * 6) + 0);
                break;
            case '5':
                nav_to((currentMenuPage * 6) + 1);
                break;
            case '6':
                nav_to((currentMenuPage * 6) + 2);
                break;
        }
    }
    const handle_nav_click = (direction) => {
        if(direction === 'left') {
            shift_menu(-1);
        } else {
            shift_menu(1);
        }
    }
    const shift_menu = (direction) => {
        setSelectedWidget(null);
        if(direction > 0) {
            //Block Moving Past the Outer Limit
            if(currentMenuPage >= (menuCount - 1)) {
                return
            }

            //Disable Right Nav if We are Moving to the Outer Limit
            if(currentMenuPage >= (menuCount - 2)) {
                setRightNavEnabled(false);
            }

            //Perform the Movement and Reenable the left nav button if necessary
            setLeftNavEnabled(true);
            setCurrentMenuPage(currentMenuPage + 1);

        } else {
            //Block Moving Past the Start Point
            if(currentMenuPage <= 0) {
                return;
            }

            //Disable Left Nav if We are Moving to the Start point
            if(currentMenuPage <= 1) {
                setLeftNavEnabled(false);
            }

            //Perform the movement and reenable the right nav button if necessary
            setRightNavEnabled(true);
            setCurrentMenuPage(currentMenuPage - 1);
        }
    }
    const recapture_input = () => {
        if(!triggerWidgetButton) {
            console.log("Reclaiming focus on main GUI");
            keyCaptureInput.current.focus();
        } else {
            //Maybe put something here about focusing on the modal??
            console.log("Allowing focus on main GUI to pass unreclaimed.");
        }
    }
    const modal_close_handler = () => {
        console.log("Registering the close of the modal in pseudos_home");
        setTriggerWidgetButton(false);
        keyCaptureInput.current.focus();
    }
    const set_trigger_widget_button = (value) => {
        setTriggerWidgetButton(value);
    }
    const reset_widget_button_keyboard_toggler = () => {
        setWidgetButtonKeyboardToggler('');
    }

    //TODO: Clean up All Messes
    //TODO: Add code for handling the end of a modal... resetting triggerWidgetButton, recapture input
    //TODO: Have to figure out how to listen for a click of the close_modal button in the parent layout

    if(!authStatus) {
        return <Signin/>
    } else {

        return (
            <>
                <div
                    className={Styles.viewport}
                >
                    <div className={Styles.header_row}>
                        <div
                            id="clock"
                        ></div>
                    </div>
                    <div className={Styles.main}>


                        <div className={Styles.weird_hidey_div}>
                            <input
                                type="text"
                                className={Styles.weird_hidey_input}
                                onKeyPress={handle_key_press}
                                onBlur={recapture_input}
                                ref={keyCaptureInput}
                                value={keyCaptureInputValue}
                                onChange={() => setKeyCaptureInputValue('')}
                            />
                        </div>
                        <SideNavButton
                            enabled={leftNavEnabled}
                            direction="left"
                            handle_click={() => handle_nav_click('left')}
                        />
                        <div
                            className={currentMenuPage === 0 ? Styles.tile_row_holder : Styles.hidden}
                        >
                            <WidgetContext.Provider value={selectedWidget}>
                                <div className={Styles.tile_row}>
                                    <div className={Styles.tile}>
                                        <Widget
                                            title="Today"
                                            click_url="./today"
                                            go_to_url_triggered={goToClickUrl}
                                            selected_widget={selectedWidget}
                                            index={0}
                                        />
                                    </div>
                                    <div className={Styles.tile}>
                                        <Widget
                                            title="TODO"
                                            click_url="./todo-active-simple"
                                            go_to_url_triggered={goToClickUrl}
                                            selected_widget={selectedWidget}
                                            index={1}
                                        />
                                    </div>
                                    <div className={Styles.tile}>
                                        <PrayerWidget
                                            go_to_url_triggered={goToClickUrl}
                                            keyboard_toggler={widgetButtonKeyboardToggler}
                                            selected_widget={selectedWidget}
                                            index={2}
                                        />
                                    </div>
                                </div>
                                <div className={Styles.tile_row}>
                                    <div className={Styles.tile}>
                                        <Widget
                                            title="Bible"
                                            click_url="https://www.biblegateway.com"
                                            go_to_url_triggered={goToClickUrl}
                                            selected_widget={selectedWidget}
                                            index={3}
                                        />
                                    </div>
                                    <div className={Styles.tile}>
                                        <BPWidget
                                            show_modal={show_modal_function}
                                            //return_data={outletContext.return_data}
                                            // modal_data={outletContext.modal_data}
                                            selected_widget={selectedWidget}
                                            index={4}
                                            go_to_click_url={goToClickUrl}
                                            set_trigger_widget_button={set_trigger_widget_button}
                                            is_trigger_widget_button_set={triggerWidgetButton}
                                            modal_close_handler={modal_close_handler}
                                        />
                                    </div>
                                    <div className={Styles.tile}>
                                        <WeightWidget
                                            show_modal={show_modal_function}
                                            // return_data={outletContext.return_data}
                                            selected_widget={selectedWidget}
                                            index={5}
                                            go_to_click_url={goToClickUrl}
                                            set_trigger_widget_button={set_trigger_widget_button}
                                            is_trigger_widget_button_set={triggerWidgetButton}
                                            modal_close_handler={modal_close_handler}
                                        />
                                    </div>
                                </div>
                            </WidgetContext.Provider>
                        </div>
                        <div
                            className={currentMenuPage === 1 ? Styles.tile_row_holder : Styles.hidden}
                        >
                            <div className={Styles.tile_row}>
                                <div className={Styles.tile}>
                                    <Widget
                                        title="Calendar"
                                        click_url="./calendar"
                                        go_to_url_triggered={goToClickUrl}
                                        selected_widget={selectedWidget}
                                        index={6}
                                    />
                                </div>
                                <div className={Styles.tile}>
                                    <Widget
                                        title="Cleaning"
                                        click_url="./cleaning"
                                        go_to_url_triggered={goToClickUrl}
                                        selected_widget={selectedWidget}
                                        index={7}
                                    />
                                </div>
                                <div className={Styles.tile}>
                                    <Widget
                                        title="Finance"
                                        click_url="./finance"
                                        go_to_url_triggered={goToClickUrl}
                                        selected_widget={selectedWidget}
                                        index={8}
                                    />
                                </div>
                            </div>
                            <div className={Styles.tile_row}>
                                <div className={Styles.tile}>
                                    <Widget
                                        title="Bible Memory"
                                        click_url="./bible-memory"
                                        go_to_url_triggered={goToClickUrl}
                                        selected_widget={selectedWidget}
                                        index={9}
                                    />
                                </div>
                                <div className={Styles.tile}>
                                    <Widget
                                        title="Library"
                                        click_url="./library"
                                        go_to_url_triggered={goToClickUrl}
                                        selected_widget={selectedWidget}
                                        index={10}
                                    />
                                </div>
                                <div className={Styles.tile}>
                                    <LearningWidget
                                        selected_widget={selectedWidget}
                                        index={11}
                                        go_to_url_triggered={goToClickUrl}
                                        keyboard_toggler={widgetButtonKeyboardToggler}
                                        reset_widget_button_keyboard_toggler={reset_widget_button_keyboard_toggler}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className={currentMenuPage === 2 ? Styles.tile_row_holder : Styles.hidden}
                        >
                            <div className={Styles.tile_row}>
                                <div className={Styles.tile}>
                                    <Widget
                                        title="Utmost"
                                        click_url="./discipleship"
                                        go_to_url_triggered={goToClickUrl}
                                        selected_widget={selectedWidget}
                                        index={12}
                                    />
                                </div>
                                <div className={Styles.tile}>
                                    <BibleMemoryWidget
                                        selected_widget={selectedWidget}
                                        index={13}
                                        go_to_url_triggered={goToClickUrl}
                                        keyboard_toggler={widgetButtonKeyboardToggler}
                                        reset_widget_button_keyboard_toggler={reset_widget_button_keyboard_toggler}
                                    />
                                </div>
                                <div className={Styles.tile}>

                                </div>
                            </div>
                            <div className={Styles.tile_row}>
                                <div className={Styles.tile}>

                                </div>
                                <div className={Styles.tile}>

                                </div>
                                <div className={Styles.tile}>

                                </div>
                            </div>
                        </div>
                        <SideNavButton
                            enabled={rightNavEnabled}
                            direction="right"
                            handle_click={() => handle_nav_click('right')}
                        />


                    </div>
                    <div className={Styles.footer_row}>
                        <Marquee speed={25} pauseOnHover={true}>
                            <div>
                                καὶ τοὺς πλείονας τῶν ἀδελφῶν ἐν Κυρίῳ πεποιθότας τοῖς δεσμοῖς μου περισσοτέρως τολμᾶν
                                ἀφόβως τὸν λόγον τοῦ Θεοῦ λαλεῖν. Philippians 1:14
                            </div>
                        </Marquee>
                    </div>


                    {/* MODAL */}
                    <div id="pseudos_top_modal" className={modal ? GenStyles.modal_shown : GenStyles.modal_hidden}>
                        <div className={GenStyles.modal_content}>
                            <header className={`${PStyles.modal_header} ${GenStyles.red} ${GenStyles.font_white}`}>
                                <div>{modal.header_text ?? ' '}</div>
                                <div
                                    onClick={close_modal}
                                    className={PStyles.modal_close_button}
                                >X
                                </div>
                            </header>

                            <div
                                className={GenStyles.container}
                            >
                                {modal.body_content ?? ' '}
                            </div>

                            <footer
                                className={PStyles.modal_footer}
                                id="modal_footer"
                            >
                                {modal.footer_text ?? ' '}
                                <button
                                    className={PStyles.modal_button}
                                    onClick={modal_submit_handler}
                                >{modal.submit_button_text ?? 'Submit'}</button>
                            </footer>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PseudosHome;