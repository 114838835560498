import NavBlockItemsHolder from "./nav_block_items_holder";
import styles from './styles/nav_section.module.css';

const NavBlock = (props) => {
    const image_name = props.nav_block_data.nav_block_name.toLowerCase();

    return (
        <>
            <div className="w3-third">
                <p>{props.nav_block_data.nav_block_name}</p>
                <div>
                    <div
                        className={`w3-card-4 w3-margin-bottom ${styles.nav_block}`}
                        alt={`Navigation Block - ${props.nav_block_data.nav_block_name}`}
                    >
                        <img
                            src={`./menus/${props.nav_block_data.nav_block_image}`}
                            className={styles.nav_image}
                        />
                        <NavBlockItemsHolder items={props.nav_block_data.nav_block_items}/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NavBlock;