import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {load_fetch} from "../ofb_util";
import ConnectionInfo from "./connection_info";
import PulseStyles from "../styles/pulse.module.css"
import QRCodeCanvas from "qrcode.react";
import PulseSessionViewer from "./pulse_session_viewer";
import Styles from "../styles/ofbstyles.module.css";


//TODO: Display the custom answer choices in the teacher view as a key to interpret: A = ???, B = ???

const PulseServer = (props) => {
    //DATA
    const query = new URLSearchParams(useLocation().search);
    const [classId, setClassId] = useState(query.get('cid'));
    const [view, setView] = useState('CLIENT');
    const [thisClassName, setThisClassName] = useState('');
    const [thisClassId, setThisClassId] = useState(-1);
    const [thisSessionId, setThisSessionId] = useState(-1);
    const [passphrase, setPassphrase] = useState('');
    const [connectionActive, setConnectionActive] = useState(false);
    const navigate = useNavigate();
    const pulseCount = useRef(0);
    const [whichTab, setWhichTab] = useState("CLASS");
    const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
    const [tagOutcomes, setTagOutcomes] = useState(false);
    
    //Connection Info Data
    const [lastHandShake, setLastHandShake] = useState(false);
    const [totalHandShakes, setTotalHandShakes] = useState(0);
    const [longestHandShakeInterval, setLongestHandShakeInterval] = useState(0);
    const firstHandShake = useRef(false);
    
    //Question Data
    const [questionType, setQuestionType] = useState('multiple_choice');
    const [answerCount, setAnswerCount] = useState(4);
    const [correctAnswer, setCorrectAnswer] = useState('');
    const [questionTitle, setQuestionTitle] = useState('');
    const [deployedQuestion, setDeployedQuestion] = useState(false);
    const [answerCustomization, setAnswerCustomization] = useState('letters');
    const [customizedAnswers, setCustomizedAnswers] = useState({});
    const [taggedOutcomes, setTaggedOutcomes] = useState(false);
    const [selectedOutcomes, setSelectedOutcomes] = useState([]);

    //Classes Data
    const [classesList, setClassesList] = useState({});
    const [outcomesList, setOutcomesList] = useState(false);

    //Students Data
    const [studentsInfo, setStudentsInfo] = useState({});


    

    //USE EFFECTS
    useEffect(() => {
        //console.log(classId);
    }, [classId]);
    useEffect(() => {
        get_classes_list();
        const interval = setInterval(() => {
            keep_alive();
        }, 2000);
        return () => clearInterval(interval);
    }, []);
    useEffect(() => {
        // console.log("LOAD STUDENTS:");
        // console.log("CONNECTIONACTIVE:" + connectionActive);
        // console.log("THISCLASSID: " + thisClassId);
        // console.log("THISSESSIONID: " + thisSessionId);
        if(connectionActive && thisClassId != -1 && thisSessionId != -1) {
            get_students_info(thisClassId, thisSessionId);
        }
    }, [thisClassId, thisSessionId, connectionActive]);
    useEffect(() => {
        if(answerCustomization === 'letters' || answerCustomization === 'numbers') {
            setCustomizedAnswers({});
        }
    }, [answerCustomization])
    useEffect(() => {
        console.log(outcomesList);
        console.log(selectedOutcomes);
    }, [selectedOutcomes, outcomesList]);


    //AJAX FUNCTIONS
    const talk = (operation, post_data, handler) => {
        let url = 'Pulse_Handler.php';
        let postData = {
            operation: operation,
            ...post_data
        }
        load_fetch(url, postData, (data) => {
            try {
                let json_data = JSON.parse(data);
                handler(operation, post_data, json_data);
            } catch (e) {
                console.log(e);
                console.log(post_data);
                console.log(handler);
                console.log(data);
                console.log(operation);
            }
        }, 'text');
    }

    //OTHER FUNCTIONS
    const switch_view = (view) => {
        navigate("/pulse");
        //setView(view);
    }
    const submit_class_info = () => {
        talk('REGISTER_CLASS',
            {class_id: thisClassId, passphrase: passphrase, class_name: thisClassName},
            submit_class_info_handler);
    }
    const submit_class_info_handler = (operation, data, return_data) => {
        console.log(operation);
        console.log(data);
        console.log(return_data);

        //Check for Errors
        if(return_data['ERROR']) {
            console.log(return_data);
            setConnectionActive(false);
        } else {
            setConnectionActive(true);
            get_outcomes(data['class_id']);
        }

        window.onbeforeunload = function() {
            return true;
        };
    }
    const keep_alive = () => {
        talk(
            'KEEP_ALIVE',
            null,
            keep_alive_handler);
    }
    const keep_alive_handler = (operation, data, return_data) => {
        console.log(return_data);
        // console.log(return_data['class_session_id']);
        if(return_data['ERROR'] || return_data['SESSION'] === 'INACTIVE') {
            setConnectionActive(false);
        } else {
			setConnectionActive(true);
            if(!thisClassName || thisClassName.length == 0) {
                setThisClassName(return_data['class_name']);
            }
            if(!passphrase || passphrase.length == 0) {
                setPassphrase(return_data['passphrase']);
            }
            if(!thisClassId || thisClassId == -1) {
                setThisClassId(return_data['class_id']);
            }
            if(!thisSessionId || thisSessionId == -1) {
                setThisSessionId(return_data['class_session_id']);
            }
            if(!outcomesList || outcomesList['ERROR'] === false) {
                console.log("HERE");
                get_outcomes(return_data['class_id']);
            }
            if(return_data['deployed_question']) {
                setDeployedQuestion(return_data['deployed_question']);
            }
           pulseCount.current = pulseCount.current + 1;
            if(pulseCount.current > 3) {
                get_students_info(return_data['class_id'], return_data['class_session_id']);
                pulseCount.current = 0;
            }
		}

        if(!firstHandShake.current) {
            firstHandShake.current = return_data['handshake_time'];
        }
    }
    const get_classes_list = () => {
        talk("GET_CLASSES_LIST", null, get_classes_list_handler);
    }
    const get_classes_list_handler = (operation, data, return_data) => {
        if(return_data['ERROR'] || typeof(return_data) !== 'object') {
            console.log("Failed to load class list.");
            setClassesList({});
        } else {
            setClassesList(return_data);
        }
    }
    const set_selected_class = (event) => {
        console.log(event.target.value);
        setThisClassId(event.target.value);
        setThisClassName(event.target.options[event.target.selectedIndex].innerText);
    }
    const end_class_session = () => {
        talk("END_CLASS_SESSION", {class_id:thisClassId, class_session_id:thisSessionId},
            end_class_session_handler);
    }
    const end_class_session_handler = (operation, data, return_data) => {
        console.log(return_data);
        setShowAreYouSureModal(false);
        setThisClassId(-1);
        setThisClassName('');
        setPassphrase('');
        setStudentsInfo({});
        setThisSessionId(-1);
        setDeployedQuestion(false);

        // Remove navigation prompt
        window.onbeforeunload = null;
    }
    const get_students_info = (class_id, class_session_id) => {
        talk("GET_STUDENTS_IN_CLASS_INFO",
            {class_id: class_id, class_session_id: class_session_id}, get_students_info_handler);
    }
    const get_students_info_handler = (operation, data, return_data) => {
        if(return_data['ERROR'] || typeof(return_data) !== 'object') {
            console.log("Failed to load students info.");
            console.log(return_data);
            console.log(data);
            setStudentsInfo({});
        } else {
            // console.log(return_data);
            setStudentsInfo(return_data);
        }
    }
    const deploy_question = () => {
        talk("DEPLOY_QUESTION",
            {
                class_id: thisClassId,
                my_class_session_id: thisSessionId,
                question_type: questionType,
                question_title: questionTitle,
                choice_count: answerCount,
                correct_answer: correctAnswer,
                answer_type: answerCustomization,
                customized_answers: customizedAnswers
            },
            deploy_question_handler);
    }
    const deploy_question_handler = (operation, data, return_data) => {
        console.log("SENT QUESTION DATA:");
        console.log(data);
        setDeployedQuestion(return_data);
        console.log(return_data);
    }
    const toggle_show_hide_answers = () => {
        console.log("HERE");
        talk("TOGGLE_SHOW_HIDE_ANSWERS", {question_id: deployedQuestion['pulse_question_id']},
            toggle_show_hide_answers_handler);
    }
    const toggle_show_hide_answers_handler = (operation, data, return_data) => {
        console.log(return_data);
        console.log(data);
        if(return_data) {
            setDeployedQuestion(prevData => ({
                ...prevData,
                show_answers:return_data['SHOW_ANSWERS']
            }));
        } else {
            console.log("ERROR: Failed to toggle show/hide answers status.");
            console.log(return_data);
        }
    }
    const number_to_letter = (num) => {
        switch(parseInt(num)) {
            case 1:
                return "A";
                break;
            case 2:
                return "B";
                break;
            case 3:
                return "C";
                break;
            case 4:
                return "D";
                break;
            case 5:
                return "E";
                break;
            case 6:
                return "F";
                break;
            case 7:
                return "G";
                break;
            case 8:
                return "H";
                break;
            case 9:
                return "I";
                break;
            case 10:
                return "J";
                break;
            case 11:
                return "K";
                break;
            case 12:
                return "L";
                break;
            case 13:
                return "M";
                break;
            case 14:
                return "N";
                break;
            case 15:
                return "O";
                break;
            case 16:
                return "P";
                break;
            case 17:
                return "Q";
                break;
            case 18:
                return "R";
                break;
            case 19:
                return "S";
                break;
            case 20:
                return "T";
                break;
            case 21:
                return "U";
                break;
            case 22:
                return "V";
                break;
            case 23:
                return "W";
                break;
            case 24:
                return "X";
                break;
            case 25:
                return "Y";
                break;
            case 26:
                return "Z";
                break;
        }
    }
    const end_question = () => {
        if(!deployedQuestion) {
            return;
        }

        talk("STOP_QUESTION", {
            question_id: deployedQuestion['pulse_question_id'],
            class_session_id: deployedQuestion['class_session_id']},
            end_question_handler);
    }
    const end_question_handler = (operation, data, return_data) => {
        if(return_data['ERROR'] || typeof(return_data) !== 'object') {
            console.log(return_data);
        } else {
            setDeployedQuestion(false);
        }
    }
    const generate_passphrase = () => {
        talk("GENERATE_PASSPHRASE", null, generate_passphrase_handler);
    }
    const generate_passphrase_handler = (operation, data, return_data) => {
        if(return_data['ERROR']) {
            console.log(return_data);
        } else {
            setPassphrase(return_data);
        }
    }
	const show_student_pin = (pin) => {
		alert(pin);
	}
    const set_tab = (tab) => {
        setWhichTab(tab);
    }
    const download_qr_code = () => {
        let link = document.createElement('a');
        link.download = 'filename.jpg';
        link.href = document.getElementById('qrcode_canvas').toDataURL('image/jpeg');
        link.click();
    }
    const update_customized_answers = (event) => {
        const {name, value} = event.target;
        console.log(name + " " + value);
        setCustomizedAnswers(prevData => ({
            ...prevData,
            [name]: value
        }))
    }
    const get_display_answer = (student_answer) => {
        switch(deployedQuestion['answer_type']) {
            case "letters":
                return number_to_letter(student_answer);
                break;
            case "numbers":
                return student_answer;
                break;
            case "customize":
                return JSON.parse(deployedQuestion['customized_answers'])['custom_answer_' + student_answer];
                break;
        }
    }
    const display_answer_choices = () => {
        let return_value = [];
        switch(deployedQuestion['answer_type']) {
            case "letters":
                for(let i = 0; i < deployedQuestion['answer_count']; i++) {
                    return_value.push(<span key={i} className={PulseStyles.static_answer_div}>{number_to_letter(i + 1)}</span>);
                }
                break;
            case "numbers":
                for(let i = 0; i < deployedQuestion['answer_count']; i++) {
                    return_value.push(<span key={i} className={PulseStyles.static_answer_div}>{(i + 1)}</span>);
                }
                break;
            case "customize":
                let custom_answers;
                try {
                    custom_answers = JSON.parse(deployedQuestion['customized_answers']);
                } catch (e) {
                    //console.log(e);
                    return null;
                }
                console.log(custom_answers);
                for(let i = 0; i < deployedQuestion['answer_count']; i++) {
                    return_value.push(<span key={i} className={PulseStyles.static_answer_div}>{custom_answers['custom_answer_' + (i
                        + 1)]}</span>);
                }
                break;
        }
        return <div>{return_value}</div>;
    }
    const toggle_tag_outcomes = (event) => {
        event.preventDefault();
        setTagOutcomes(!tagOutcomes);
    }
    const get_outcomes = () => {
        //talk("GET_OUTCOMES", {class_id: thisClassId}, get_outcomes_handler);
    }
    const get_outcomes_handler = (operation, data, return_data) => {

        if(!return_data['ERROR']) {
            setOutcomesList(return_data);
        } else {
            console.log(return_data);
            console.log("Class ID: " + data['class_id']);
            setOutcomesList(false);
        }
    }
    const select_outcomes = (selected_options) => {
        let op = Array();
        for(let i = 0; i < selected_options.length; i++) {
            op.push(selected_options[i].value);
        }
        setSelectedOutcomes(op);
    }

    //RENDERING
    if(props.authStatus) {
        return (
            <div>
                {/* PAGE TITLE */}
                <h3>Pulse Server</h3>

                <div>
                    <select
                        onChange={set_selected_class}
                        name="class_id"
                        value={thisClassId}
                        className={PulseStyles.class_selector_input}
                    >
                        <option value="-1">Select Your Class</option>
                        {classesList.length > 0 && classesList.map((class_item, index) => (
                            <option
                                key={class_item['pulse_class_id']}
                                value={class_item['pulse_class_id']}
                            >{class_item['class_name']}</option>
                        ))}
                    </select>
                </div>

                {/* TAB BUTTONS */}
                <div className={PulseStyles.tab_buttons}>
                    <button
                        className={whichTab === 'CLASS' ?
                            PulseStyles.tab_button_selected :
                            PulseStyles.tab_button}
                        onClick={() => set_tab('CLASS')}
                    >
                        Start a Class
                    </button>
                    <button
                        className={whichTab === 'SESSIONS' ?
                            PulseStyles.tab_button_selected :
                            PulseStyles.tab_button}
                        onClick={() => set_tab('SESSIONS')}
                    >Past Sessions</button>
                    <button
                        className={whichTab === 'OTHER' ?
                            PulseStyles.tab_button_selected :
                            PulseStyles.tab_button}
                        onClick={() => set_tab('OTHER')}
                    >Other Thing</button>
                </div>

                {/* CLASS TAB */}
                <div className={whichTab === 'CLASS' ?
                    `${PulseStyles.tab_container} ${PulseStyles.animate_opacity}` :
                    PulseStyles.hide}>


                    {/* START CLASS SESSION FORM */}
                    <div className={(connectionActive || !firstHandShake) ?
                        PulseStyles.hide : PulseStyles.class_selector}>
                        <div>
                            <input
                                type='text'
                                name='passphrase'
                                autoComplete="off"
                                value={passphrase}
                                placeholder="create session passphrase"
                                onChange={(event) => setPassphrase(event.target.value)}
                            />
                            <button
                                className={PulseStyles.simple_button}
                                onClick={generate_passphrase}
                            >Autogen</button>
                        </div>
                        <button
                            type='button'
                            className={PulseStyles.simple_button}
                            onClick={submit_class_info}
                            disabled={thisClassId < 0 || passphrase.length === 0}
                        >Create Class Session</button>
                    </div>


                    {/* DISPLAY CLASS DATA, QUESTION CREATOR, ETC */}
                    <form>
                    <div
                        className={(thisClassName && connectionActive && !deployedQuestion) ? PulseStyles.question_builder : PulseStyles.hide}
                    >
                        <div className={PulseStyles.question_builder_title}>Question Builder</div>

                        <select
                            name="question_type"
                            className={PulseStyles.question_type_select}
                            value={questionType}
                            onChange={(event) =>
                                setQuestionType(event.target.value)}
                        >
                            <option value="multiple_choice">Multiple Choice</option>
                            <option value="short_answer">Short Answer</option>
                        </select>
                        <div>
                            <input
                                type='text'
                                name='question_title'
                                placeholder="question title"
                                className={PulseStyles.question_title}
                                value={questionTitle}
                                onChange={(event) =>
                                    setQuestionTitle(event.target.value)}
                            />
                        </div>
                        <div className={PulseStyles.choices_builder}>
                            <div className={PulseStyles.possible_count_div}>
                                <div>Number of Answer Choices:</div>
                                <div className={PulseStyles.number_input}>
                                    <input
                                        type='number'
                                        name='answer_count'
                                        placeholder='# of answers'
                                        value={answerCount}
                                        min="1"
                                        onChange={(event) =>
                                            setAnswerCount(parseInt(event.target.value, 10))}
                                    />
                                </div>
                            </div>
                            <div className={PulseStyles.correct_answer_div}>
                                <div>Correct Answer:</div>
                                <div className={PulseStyles.number_input}>
                                    <input
                                        type='number'
                                        name='correct_answer'
                                        placeholder='corr. ans.'
                                        value={correctAnswer}
                                        min="1"
                                        max={answerCount}
                                        onChange={(event) =>
                                            setCorrectAnswer(parseInt(event.target.value, 10))}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={PulseStyles.customize_answers_row}>
                            <div>Answers: </div>
                            <div>
                                <input
                                    type="radio"
                                    className={PulseStyles.radio}
                                    name="answer_customizer_radio"
                                    value="letters"
                                    onChange={() => setAnswerCustomization('letters')}
                                    checked={answerCustomization === 'letters'}
                                /> LETTERS
                            </div>
                            <div><input
                                type="radio"
                                className={PulseStyles.radio}
                                name="answer_customizer_radio"
                                value="numbers"
                                onChange={() => setAnswerCustomization('numbers')}
                                checked={answerCustomization === 'numbers'}
                            /> NUMBERS</div>
                            <div><input
                                type="radio"
                                className={PulseStyles.radio}
                                name="answer_customizer_radio"
                                value="customize"
                                onChange={() => setAnswerCustomization('customize')}
                                checked={answerCustomization === 'customize'}
                            />CUSTOMIZE</div>
                        </div>
                        <div className={answerCustomization === 'customize' ? PulseStyles.custom_answers_holder : PulseStyles.hide}>
                            {answerCustomization === 'customize' && Array.from(Array(answerCount).keys()).map(x => x + 1).map((answer, answer_index) => (
                                <input
                                    key={answer_index}
                                    name={'custom_answer_' + (answer_index + 1)}
                                    placeholder={'answer ' + (answer_index + 1)}
                                    onChange={(event) => update_customized_answers(event)}
                                    value={customizedAnswers['answer_' + (answer_index + 1)]}
                                />
                            ))}
                        </div>
                        <button
                            type="button"
                            onClick={(event) => toggle_tag_outcomes(event)}
                        >Tag Outcomes?</button>
                        <div className={tagOutcomes ? '' : PulseStyles.hide}>
                            <select
                                name="outcomes_selector"
                                multiple={true}
                                onChange={(event) => select_outcomes(event.target.selectedOptions)}
                                >
                                {outcomesList.length > 0 && outcomesList.map((item, index) => (
                                    <option
                                        className={PulseStyles.outcomes_option}
                                        key={index}
                                        value={item['pulse_outcome_id']}
                                    >{item['outcome']}</option>
                                ))}
                            </select>
                        </div>
                        <button
                            type="button"
                            onClick={deploy_question}
                        >
                            Deploy Question
                        </button>
                    </div>
                    </form>


                    {/* DEPLOYED QUESTION DISPLAY */}
                    <div className={deployedQuestion ? PulseStyles.deployed_question : PulseStyles.hide}>
                        <div className={PulseStyles.deployed_question_header}>CURRENT QUESTION</div>
                        <div className={PulseStyles.deployed_question_body}>
                            <div><b>Question Text</b>: {deployedQuestion['question_text']}</div>
                            <div><b>Question Type</b>: {deployedQuestion['question_type']}</div>
                            <div><b>Answer Count</b>: {deployedQuestion['answer_count']}</div>
                            <div className={PulseStyles.static_answers_holder}><b>Answer Choices</b>: &nbsp;
                                {display_answer_choices()}</div>
                            <div><b>Correct Answer</b>: {deployedQuestion['correct_answer']}</div>
                            <div><button
                                className={`${PulseStyles.simple_button} ${deployedQuestion['show_answers'] ?
                                    PulseStyles.green : ''}`}
                                onClick={toggle_show_hide_answers}
                            >{deployedQuestion['show_answers'] ? "Hide Answers from Student" : "Reveal Answer to Students"}</button></div>
                            <div><button
                                className={PulseStyles.simple_button}
                                onClick={end_question}
                            >End Question</button></div>
                        </div>
                    </div>


                    <div className={thisClassName && connectionActive ? '' : PulseStyles.hide}>
                        {/* ACTIVE STUDENTS DISPLAY */}
                        <div className={PulseStyles.students_table_div}>
                            <table className={PulseStyles.students_table}>
                                <thead>
                                <tr className={PulseStyles.student_header_row}>
                                    <td></td>
                                    <td>STATUS</td>
                                    <td>ANSWER</td>
                                    <td>TIME TO ANSWER</td>
                                </tr>
                                </thead>
                                <tbody>
                                {studentsInfo.length > 0 && studentsInfo.map((student, index) => (
                                    <tr
                                        className={index % 2 === 0 ? '' : PulseStyles.odd_row}
                                        key={student['pulse_student_id']}
                                    >
                                        <td
                                            className={PulseStyles.first_name_column}
                                            onClick={() => show_student_pin(student['pin'])}
                                        >
                                            {student['first_name']}
                                        </td>
                                        <td className={PulseStyles.students_table_cell}>
                                            <div className={student['active_status'] === 'LOGGED_IN_ACTIVE' ?
                                                PulseStyles.green_status_circle :
                                                (student['active_status'] === 'LOGGED_IN_INACTIVE' ? PulseStyles.red_status_circle : PulseStyles.black_status_circle)}
                                            />
                                        </td>
                                        <td>
                                            <div className={PulseStyles.students_table_cell}>
                                                <div className={student['student_answer'] ? PulseStyles.teacher_answer_cell : PulseStyles.hide}>
                                                    <div>
                                                        {student['student_answer'] ?
                                                            get_display_answer(student['student_answer']) : '-'}
                                                    </div>
                                                    <div className={student['student_answer'] == deployedQuestion['correct_answer'] ?
                                                        PulseStyles.green_check : PulseStyles.red_check}>
                                                        ✔
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className={PulseStyles.students_table_cell}>{student['time_to_answer'] ? student['time_to_answer'] + 's' : ''}</div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>


                        {/* BUTTON TO END SESSION */}
                        <button
                            onClick={() => setShowAreYouSureModal(true)}
                            className={PulseStyles.simple_button}
                        >End Class Session</button>


                    </div>


                </div>

                {/* SESSIONS TAB */}
                <div className={whichTab === 'SESSIONS' ?
                    `${PulseStyles.tab_container} ${PulseStyles.animate_opacity}` :
                    PulseStyles.hide}>
                    <div>Past Sessions</div>
                    <PulseSessionViewer
                        class_id={thisClassId}
                        visible={whichTab === 'SESSIONS'}
                    />
                </div>

                {/* OTHER TAB */}
                <div className={whichTab === 'OTHER' ?
                    `${PulseStyles.tab_container} ${PulseStyles.animate_opacity}` :
                    PulseStyles.hide}>
                    <div className={thisClassName && connectionActive ? PulseStyles.passphrase_holder : PulseStyles.hide}>
                        <span className={PulseStyles.passphrase}>{passphrase}</span>
                    </div>
                    <div
                        className={thisClassName && connectionActive ? PulseStyles.passphrase_holder : PulseStyles.hide}
                        onClick={download_qr_code}
                    >
                        <QRCodeCanvas
                            className={PulseStyles.qr_code}
                            size={256}
                            renderAs="canvas"
                            value={`https://www.outfrombabel.com/pulse?cid=` + passphrase}
                            id="qrcode_canvas"
                        />
                    </div>
                </div>







                <div className={showAreYouSureModal ? PulseStyles.modal_shown : PulseStyles.hide}>
                    <div className={PulseStyles.modal_content}>
                        <div className={PulseStyles.modal_header}>
                            <div></div>
                            <div></div>
                        </div>
                        <div className={PulseStyles.modal_body}>Are you sure you want to end the session?</div>
                        <div className={PulseStyles.modal_footer}>
                            <button
                                className={PulseStyles.modal_footer_button}
                                onClick={end_class_session}
                            >End Session
                            </button>
                            <button
                                className={PulseStyles.modal_footer_button}
                                onClick={() => setShowAreYouSureModal(false)}
                            >Cancel
                            </button>
                        </div>
                    </div>
                </div>



                {/* CONNECTION STATUS INDICATOR */}
                <ConnectionInfo active={connectionActive} />
            </div>
        )
    } else {
        return null;
    }

}

export default PulseServer;
