import Styles from "../styles/ofbstyles.module.css";
import React, {useEffect, useState} from "react";
import {load_fetch} from "../ofb_util";
import Bulk_Import from "./bulk_import";




const AddBookForm = (props) => {
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [librarySources, setLibrarySources] = useState(false);
    const [libraryStatuses, setLibraryStatuses] = useState(false);
    const [libraryTypes, setLibraryTypes] = useState(false);
    const [seriesList, setSeriesList] = useState(false);
    const [authorFirstName, setAuthorFirstName] = useState("");
    const [authorLastName, setAuthorLastName] = useState("");
    const [title, setTitle] = useState("");
    const [series, setSeries] = useState("");
    const [seriesNumber, setSeriesNumber] = useState("");
    const [status, setStatus] = useState("READ");
    const [source, setSource] = useState("");
    const [dateread, setDateRead] = useState("");
    const [rating, setRating] = useState("");
    const [review, setReview] = useState("");
    const [type, setType] = useState("");
    const [bulkImporting, setBulkImporting] = useState(false);
    const [primaryAuthorRole, setPrimaryAuthorRole] = useState("");
    const [lcClassification, setLcClassification] = useState('');

    function handleFormSubmit(e) {
        e.preventDefault();
    }
    const get_sources = () => {
        let url = 'Library_Handler.php';
        let postData = {
            operation: 'GET_LIBRARY_SOURCES'
        }
        load_fetch(url, postData, (data) => {
            setLibrarySources(data['SOURCES']);
            setLibraryStatuses(data['STATUSES']);
            setLibraryTypes(data['TYPES']);
            setSeriesList(data['SERIESLIST']);
            setDateRead(get_todays_date());
        });
    }
    useEffect(() => {
        if(!librarySources) {
            get_sources();
        }
    }, [props.authStatus]);
    useEffect(() => {
        if(!librarySources) {
            get_sources();
        }
    }, [props.authStatus]);
    useEffect(() => {
        setBulkImporting(false);
    }, [props.showAddNew])

    const handle_add_new = () => {
        //Validate Data
        if(!authorLastName) {
            setErrorMessage("You must have an author's last name. If it is a one name author, " +
                "put the one name here.");
            setShowErrorMessage(true);
            return;
        }
        if(!title) {
            setErrorMessage("You must have a title.");
            setShowErrorMessage(true);
            return;
        }
        if(!status) {
            setErrorMessage("You must have a reading status entered.");
            setShowErrorMessage(true);
            return;
        }
        if(rating && rating > 5) {
            setErrorMessage("The maximum rating is 5.");
            setShowErrorMessage(true);
            return;
        }

        setErrorMessage('');
        setShowErrorMessage(false);

        let postData = {
            operation: 'INSERT_NEW_BOOK',
            authorlastname: authorLastName,
            authorfirstname: authorFirstName,
            title: title,
            series: series,
            seriesnumber: seriesNumber,
            status: status,
            type: type,
            source: source,
            dateread: dateread,
            rating: rating,
            review: review,
            lc_classification: lcClassification,
            primary_author_role: primaryAuthorRole
        }

        let url = 'Library_Handler.php';
        load_fetch(url, postData, (data) => {
            if(!data['ERROR']) {
                props.reloadFunction();
                props.setShowAddNew(false);
                clear_form();
            }
        });
    }
    function clear_form() {
        setAuthorLastName('');
        setAuthorFirstName('');
        setTitle('');
        setPrimaryAuthorRole("");
        setSeries('');
        setSeriesNumber('');
        setStatus('READ');
        setSource('');
        setType('');
        setDateRead(get_todays_date());
        setRating('');
        setReview('');
        setLcClassification('');
    }

    const Source = () => {
        if(librarySources && !librarySources['ERROR']) {
            return (
                <select
                    className={Styles.ofb_select_input}
                    name="source"
                    id="source_selector"
                    value={source}
                    onChange={event => setSource(event.target.value)}
                >
                    {librarySources.map(item => (
                        <option key={item['source']} value={item['source']}>{item['source']}</option>
                    ))}
                </select>
            )
        } else {
            return <></>
        }
    }
    const Status = () => {
        if(libraryStatuses && !libraryStatuses['ERROR']) {
            return (
                <select
                    className={Styles.ofb_select_input}
                    name="status"
                    id="status_selector"
                    value={status}
                    onChange={event => setStatus(event.target.value)}
                >
                    {libraryStatuses.map(item => (
                        <option key={item['status']} value={item['status']}>{item['status']}</option>
                    ))}
                </select>
            )
        } else {
            return <></>
        }
    }
    const Type = () => {
        if(libraryTypes && !libraryTypes['ERROR']) {
            return (
                <select
                    className={Styles.ofb_select_input}
                    name="type"
                    id="type_selector"
                    value={type}
                    onChange={event => setType(event.target.value)}
                >
                    {libraryTypes.map(item => (
                        <option key={item['type']} value={item['type']}>{item['type']}</option>
                    ))}
                </select>
            )
        } else {
            return <></>
        }
    }
    const get_todays_date = () => {
        let d = new Date();
        return d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2, '0')
            + "-" + d.getDate().toString().padStart(2, '0');
    }

    return (
        <>
        <div className={props.showAddNew ? '' : Styles.ofb_hide}>
            <button
                className={`${Styles.ofb_button} ${Styles.ofb_black}`}
                onClick={() => setBulkImporting(!bulkImporting)}
            >{bulkImporting ? "Cancel Bulk Import" : "Do Bulk Import"}</button>
            <div className={bulkImporting ? '' : Styles.ofb_hide}>
                <Bulk_Import />
            </div>
        </div>
        <form onSubmit={handleFormSubmit} className={props.showAddNew && !bulkImporting ? '' : Styles.ofb_hide}>
            <div id='add_new_book_form' className={
                props.showAddNew ?
                    Styles.ofb_library_add_new_container
                    :
                    Styles.ofb_hide
            }>
                <div className={Styles.ofb_input_holder}>
                    <div className={Styles.ofb_label}>First Name</div>
                    <input type='text'
                           id="authorfirstname"
                           className={Styles.ofb_text_input}
                           value={authorFirstName}
                           onChange={e => setAuthorFirstName(e.target.value)}
                    />
                </div>
                <div className={Styles.ofb_input_holder}>
                    <div className={Styles.ofb_label}>Last Name</div>
                    <input type='text'
                           id="authorlastname"
                           value={authorLastName}
                           onChange={e => setAuthorLastName(e.target.value)}
                           className={Styles.ofb_text_input}/>
                </div>
                <div className={Styles.ofb_input_holder}>
                    <div className={Styles.ofb_label}>Primary Author Role</div>
                    <select
                        value={primaryAuthorRole}
                        onChange={event => setPrimaryAuthorRole(event.target.value)}
                        className={Styles.ofb_select_input}
                    >
                        <option value=""></option>
                        <option value="Author">Author</option>
                        <option value="Editor">Editor</option>
                    </select>
                </div>
                <div className={Styles.ofb_input_holder}>
                    <div className={Styles.ofb_label}>Title</div>
                    <input type='text'
                           id="title" value={title}
                           onChange={e => setTitle(e.target.value)}
                           className={Styles.ofb_text_input}/>
                </div>
                <div className={Styles.ofb_input_holder}>
                    <div className={Styles.ofb_label}>Series</div>
                    <input
                        type='text'
                        id="series"
                        name="series"
                        className={Styles.ofb_text_input}
                        value={series}
                        onChange={event => setSeries(event.target.value)}
                        list='datalist_series'
                        autoComplete='off'
                    />
                    <datalist id='datalist_series'>
                        {seriesList && seriesList.map((value, index) => (
                            <option key={index} value={value['series']}/>
                        ))}
                    </datalist>
                </div>
                <div className={Styles.ofb_input_holder}>
                    <div className={Styles.ofb_label}>Series Number</div>
                    <input
                        type='number'
                        id="seriesnumber"
                        className={Styles.ofb_text_input}
                        value={seriesNumber}
                        onChange={event => setSeriesNumber(event.target.value)}
                    />
                </div>
                <div className={Styles.ofb_input_holder}>
                    <div className={Styles.ofb_label}>LC Classification</div>
                    <input
                        type="text"
                        className={Styles.ofb_text_input}
                        value={lcClassification}
                        onChange={event => setLcClassification(event.target.value)}
                        />
                </div>
                <div className={Styles.ofb_input_holder}>
                    <div className={Styles.ofb_label}>Status</div>
                    <Status/>
                </div>
                <div className={Styles.ofb_input_holder}>
                    <div className={Styles.ofb_label}>Type</div>
                    <Type/>
                </div>
                <div className={Styles.ofb_input_holder}>
                    <div className={Styles.ofb_label}>Source</div>
                    <Source/>
                </div>
                <div className={Styles.ofb_input_holder}>
                    <div className={Styles.ofb_label}>Date Read</div>
                    <input
                        type='date'
                        className={Styles.ofb_datetime_input}
                        id="dateread"
                        value={dateread}
                        onChange={event => setDateRead(event.target.value)}
                    />
                </div>
                <div className={Styles.ofb_input_holder}>
                    <div className={Styles.ofb_label}>Rating (1-5)</div>
                    <input
                        type='number'
                        id="rating"
                        className={Styles.ofb_text_input}
                        value={rating}
                        onChange={event => setRating(event.target.value)}
                    />
                </div>
                <div className={Styles.ofb_input_holder}>
                    <div className={Styles.ofb_label}>Review</div>
                    <textarea
                        id='review'
                        className={Styles.ofb_text_input}
                        value={review}
                        onChange={event => setReview(event.target.value)}
                    />
                </div>
                <div className={
                    (showErrorMessage) ?
                        Styles.ofb_error_message_div :
                        Styles.ofb_hide
                } id="error_message_div">{errorMessage}</div>
                <div className={Styles.ofb_input_holder}>
                    <div className={Styles.ofb_label}>&nbsp;</div>
                    <button
                        className={`${Styles.ofb_button} ${Styles.ofb_black}`}
                        type='button'
                        onClick={props.cancelAddNew}>Cancel
                    </button>
                    <button
                        className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                        type='button'
                        onClick={handle_add_new}>Submit
                    </button>
                </div>
            </div>
        </form>
        </>
    )
}

export default AddBookForm;