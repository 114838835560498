import React from 'react';
import NavBlockItem from "./nav_block_item";
import styles from './styles/nav_section.module.css';

const NavBlockItemsHolder = (props) => {
    return (
        <>
            <div
                className={`w3-bar-block w3-black ${styles.nav_block_items_holder}`}
            >
                {
                    props.items.map(
                        (item, itemIndex) =>
                            <NavBlockItem
                                key={item.link_key}
                                link_name={item.link_name}
                                url={item.url}
                                styles={item.styles}
                                classNames={item.classNames}
                            />
                    )
                }
            </div>
        </>
    );
}

export default NavBlockItemsHolder;