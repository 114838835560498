import React, {useEffect, useState, useContext } from "react";
import MealCSS from "../styles/Meals.module.css";
import GenStyles from "../styles/ofb_gen_styles.module.css";
import {talk, load_fetch} from "../ofb_util";

//TODO: Rewrite all of this to conform more closely with the spreadsheet.
// Ease of data entry is the top priority

const Meals = (props) => {
    //DATA
    const [meals, setMeals] = useState(false);
    const [mealsByDate, setMealsByDate] = useState(false);
    const [recipes, setRecipes] = useState(false);
    const [view, setView] = useState("SPREADSHEET");
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().substring(0, 10));
    const [age, setAge] = useState(false);
    const [weight, setWeight] = useState(false);
    const [height, setHeight] = useState(false);




    //USEEFFECTS
    useEffect(() => {
        get_body_data();
        //load_meals();
    }, []);

    //AJAX FUNCTIONS
    const get_body_data = () => {
        talk("Meals_Handler.php", "GET_BODY_DATA", null, get_body_data_handler);
    }
    const get_body_data_handler = (operation, sent_data, return_data) => {
        console.log(return_data);
        if(return_data['age']) {
            setAge(return_data['age']);
        }
        if(return_data['weight']) {
            setWeight(return_data['weight']);
        }
        if(return_data['height']) {
            setHeight(return_data['height']);
        }
    }


    const load_meals = () => {
        let url = 'Meals_Handler.php';
        let postData = {
            operation: 'LOAD_CURRENT_MEALS'
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                console.log(data);
                setMeals(data);
            } else {
                console.log("FAILED TO LOAD MEALS");
                console.log(data);
            }
        });
    }
    const load_meals_by_date = () => {
        let url = 'Meals_Handler.php';
        let postData = {
            operation: 'LOAD_MEALS_BY_DATE'
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                console.log(data);
                setMealsByDate(data);
            } else {
                console.log("FAILED TO LOAD MEALS");
                console.log(data);
            }
        });
    }
    const load_recipes = () => {
        let url = 'Meals_Handler.php';
        let postData = {
            operation: 'LOAD_ALL_RECIPES'
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                console.log(data);
                setRecipes(data);
            } else {
                console.log("FAILED TO LOAD RECIPES");
                setRecipes(false);
                console.log(data);
            }
        });
    }

    
    /**
     * Planning: Views - 
     *      View all possible meals in a selectable list, 
     *      view meals by calendar (really just a list of upcoming days, don't need an actual calendar)
     *      View the component recipe cards for a single Meal
     *      View a recipe card
     *      View all Ingredients (maybe)... add to shopping list, future feature
    
    **/

    //OTHER FUNCTIONS
    const view_meal = (meal) => {
        setSelectedItem(meal);
        setView("ONE_MEAL");
    }
    const view_recipe = (recipe) => {
        setSelectedItem(recipe);
        setView("ONE_RECIPE");
    }
    const nav_to_meals_list = () => {
        setSelectedItem(false);
        setView("ALL_MEALS");
    }
    const nav_to_calendar_view = () => {
        setSelectedItem(false);
        load_meals_by_date();
        setView("MEALS_BY_DATE");
    }
    const nav_to_all_recipes_view = () => {
        setSelectedItem(false);
        load_recipes();
        setView("ALL_RECIPES");
    }
    const pounds_to_kg = (pounds) => {
        return pounds * 0.45359237;
    }
    const feet_and_inches_to_inches = (feet, inches) => {
        return ((feet * 12) + inches);
    }
    const inches_to_cm = (inches) => {
        return inches * 2.54;
    }
    const mifflin_st_jeor = () => {
        if(!weight) {
            return false;
        }
        if(!height) {
            return false;
        }
        let kg = pounds_to_kg(weight);
        let cm = inches_to_cm(height);
        return (10 * kg) + (6.25 * cm) - (5 * age) + 5;
    }
    const get_calorie_allotment = () => {
        let exercise_multiplier = 1.4651735355848; //15-30 minutes of elevated heart rate activity 4-5 times/week
        let mifflin = mifflin_st_jeor();
        if(!mifflin) {
            return "NA";
        } else {
            return Math.round(mifflin * exercise_multiplier);
        }

    }

    if(!props.authStatus) {
        return null;
    } else {

        //RENDERING
        switch (view) {
            case "ALL_MEALS":
                return (<>
                    <h2>Meals</h2>
                    <button
                        onClick={nav_to_calendar_view}
                    >Meals by Date
                    </button>
                    <button
                        onClick={nav_to_all_recipes_view}
                    >All Recipes
                    </button>
                    <div>
                        <form>
                            <div className={MealCSS.bold}>New Meal</div>
                            <div><input type='text' name='new_meal_name' placeholder='new meal name'/></div>
                            <div><select>
                                <option>Meal 1</option>
                                <option>Meal 2</option>
                            </select>
                                <button>Add</button>
                            </div>
                        </form>
                    </div>

                    {meals.length > 0 && meals.map((meal, index) => (
                        <div
                            key={meal['meal_id']}
                            onClick={() => view_meal(meal)}
                            className={MealCSS.meal_list_item}
                        >{meal['meal_name']}</div>
                    ))}
                </>)
                break;
            case "MEALS_BY_DATE":
                return (<>
                    <h2>Upcoming Meals</h2>
                    <button onClick={nav_to_meals_list}
                    >-Back to Meals List-
                    </button>
                    {mealsByDate.length > 0 && mealsByDate.map((item, index) => (
                        <div
                            key={item['meal_date']}
                            onClick={() => view_meal(item)}
                            className={MealCSS.meal_list_item}
                        >
                            <div className={MealCSS.meal_date}>
                                {item['day_of_week']}, {item['meal_date']}
                            </div>
                            <div>{item['meal_name']}</div>
                        </div>
                    ))}
                </>)
                break;
            case "ONE_MEAL":
                return (<>
                    <h2>Meal: </h2>
                    <button onClick={nav_to_meals_list}
                    >-Back to Meals List-
                    </button>
                    <div>{selectedItem['meal_name']}</div>
                </>)
                break;
            case "ONE_RECIPE":
                return (<>
                    <h2>Recipe: </h2>
                    <button onClick={nav_to_all_recipes_view}
                    >-Back to All Recipes-
                    </button>
                    <div>{selectedItem['recipe_name']}</div>
                    <div>{selectedItem['recipe_url'] ? <a href={selectedItem['recipe_url']}>-Go to Page-</a> : ''}</div>
                    <div>{selectedItem['recipe_text']}</div>
                    <div>{selectedItem['recipe_type']}</div>
                    <div>Lo Carb? {selectedItem['lo_carb']}</div>
                </>)
                break;
            case "ALL_RECIPES":
                return (<>
                    <h2>All Recipes</h2>
                    <button onClick={nav_to_meals_list}
                    >-Back to Meals List-
                    </button>
                    {recipes.length > 0 && recipes.map((recipe, index) => (
                        <div
                            key={index}
                            onClick={() => view_recipe(recipe)}
                            className={MealCSS.meal_list_item}
                        >{recipe['recipe_name']}</div>
                    ))}
                </>)
            case "SPREADSHEET":
                return (<>
                    <h2>Today</h2>
                    <div>
                        <input
                            type="date"
                            value={selectedDate}
                            onChange={(event) => setSelectedDate(event.target.value)}
                        />
                    </div>
                    <div>Total Calories Consumed Today: </div>
                    <div>Total Calories Burned by Exercise: </div>
                    <div>Daily Calorie Allotment: {get_calorie_allotment()}</div>
                    <div>Calories Remaining Today: </div>
                    <div>Estimated Pounds Lost Today: </div>


                    <div className={MealCSS.food_row}>
                        <div className={MealCSS.food_row_name_div}>
                            <input className={MealCSS.food_row_name} type="text" />
                        </div>
                        <div className={MealCSS.food_row_units_div}>
                            <input className={MealCSS.cal_unit_input} type="text" placeholder="Cal/Unit"/>
                            <input className={MealCSS.units_input} type="text" placeholder="Units"/>
                            <input className={MealCSS.unit_type_input} type="text" placeholder="Unit Type"/>
                            <div><button className={GenStyles.button}>💾</button></div>
                            <div>XXX</div>
                            <div>TOT</div>
                        </div>
                    </div>
                </>)
        }
    }
    
}

export default Meals;
