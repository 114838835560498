import Styles from "../styles/ofbstyles.module.css";
import React, {useContext, useEffect, useState} from "react";
import LibraryContext from "./LibraryContext";

const Type = (props) => {
    const [type, setType] = useState(props.value ?? "");
    const libraryOptions = useContext(LibraryContext);

    useEffect(() => {
        props.onTypeChange(type);
    }, [props, type]);

    if(libraryOptions['types'] && !libraryOptions['types']['ERROR']) {
        return (
            <select
                className={Styles.ofb_select_input}
                name="type"
                id="type_selector"
                value={type}
                onChange={event => setType(event.target.value)}
            >
                {libraryOptions['types'].map(item => (
                    <option key={item['type']} value={item['type']}>{item['type']}</option>
                ))}
            </select>
        )
    } else {
        return <></>
    }
}
export default Type;