import {useEffect, useState} from "react";

const AreYouSureModal = (props) => {
    if(props.visible) {
        return (
            <>
                <div id="areYouSureModal" className="w3-modal" style={{"display":"block"}}>
                    <div className="w3-modal-content w3-card-4">
                        <header className="w3-container w3-red">
                            <h2>Are You Sure?</h2>
                        </header>
                        <div className="w3-container">
                            <p>{props.mainText}</p>
                            <p>"{props.subText}"</p>
                        </div>
                        <footer className="w3-container w3-black">
                            <button className="w3-button" onClick={props.yesButtonPressed}>Yes, Continue</button>
                            <button className="w3-button" onClick={props.noButtonPressed}>No, Cancel</button>
                        </footer>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <></>
        )
    }
}

export default AreYouSureModal;