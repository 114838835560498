import React, {useEffect, useState} from "react";
import Styles from "../styles/ofbstyles.module.css";

const CleaningSubItem = (props) => {
    const [countDown, setCountDown] = useState(null);

    useEffect(() => {
        if(props) {
            if(!props.data['completion_data']) {
                setCountDown('∞');
            } else {
                setCountDown(props.data['frequency'] - props.data['completion_data']['days_elapsed'])
            }
        }
    }, [props])

    return (
        <>
        <div
            className={Styles.ofb_todo_simple_item}
            style={!props.active_only ? {marginLeft: props.data['depth'] * 20} : {}}
            onClick={() => props.set_selected_item(props.data)}
        >
            {props.data['completed'] ? '✔' : '○'}&nbsp;
            <span className={props.data['completed'] ? Styles.ofb_strike : ''}>
                <span className={props.data['completion_data']['days_elapsed'] === props.data['frequency']
                    ? Styles.ofb_highlight_yellow :
                    (props.data['completion_data']['days_elapsed'] > props.data['frequency'] ? Styles.ofb_red : (
                        props.data['completion_data'] === false ? Styles.ofb_red : ''
                    ))}>
                    {props.data['name']}
                </span> [{countDown}]
            </span>
        </div>
            {!props.active_only && props.data['sub_items'] && props.data['sub_items'].length > 0 && props.data['sub_items'].map((subitem, subitem_index) => (
                <CleaningSubItem
                    key={subitem['todoid']}
                    data={subitem}
                    set_selected_item={props.set_selected_item}
                    today={props.today}
                />
            ))}
        </>
    )
}
export default CleaningSubItem;