import React, {useEffect, useState} from "react";
import {getDateInYYYYMMDD, load_fetch, talk} from "./ofb_util";
import Styles from "./styles/ofbstyles.module.css";
import TodoActiveSimpleSubitem from "./ToDo/todo_active_simple_subitem";
import NewTodoSubitemForm from "./ToDo/new_todo_subitem_form";
import TrashCan from "./images/trash_can.png";
import TODORapidEntryModule from "./ToDo/todo_rapid_entry_module";
import ParentCategorySelector from "./ToDo/parent_category_selector";

const TodoActiveSimple = (props) => {
    //DATA
    const [todoItems, setTodoItems] = useState({});
    const [todoUncategorized, setTodoUncategorized] = useState({});
    const [selectedItem, setSelectedItem] = useState(false);
    const today = getDateInYYYYMMDD(new Date());
    const [activeOnly, setActiveOnly] = useState(false);
    const [editingTitle, setEditingTitle] = useState(false);
    const [editingDetails, setEditingDetails] = useState(false);
    const [editingDueDate, setEditingDueDate] = useState(false);
    const [deletingItem, setDeletingItem] = useState(false);
    const [deemphasizeInactive, setDeemphasizeInactive] = useState(true);
    const [editingParentCategory, setEditingParentCategory] = useState(false);

    //TODO: Implement the creation of a new top level item

    //USEEFFECTS
    useEffect(() => {
        get_todo_items();
        get_todo_uncategorized();
    }, []);
    useEffect(() => {
        if(props.authStatus) {
            get_todo_items();
        }
    }, [activeOnly])
    useEffect(() => {
        if(editingTitle) {
            return;
        }
        if(selectedItem) {
            let nst = document.getElementById('new_subitem_title');
            if(nst) {
                nst.focus();
            }
        }
    }, [selectedItem])
    useEffect(() => {
        if(props.authStatus) {
            get_todo_items();
        } else {
            setTodoItems({});
        }
    }, [props.authStatus]);
    useEffect(() => {
    }, [editingParentCategory])


    //AJAX FUNCTIONS
    const get_todo_items = () => {
        let url = 'todo_handler.php';
        let postData = {
            operation: 'GET_TODO_ACTIVE_SIMPLE',
            active_only: activeOnly
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                setTodoItems(data);
            } else {
                console.log("TODO ITEMS NOT FOUND: ERROR");
                console.log(data);
                setTodoItems({});
            }
        });
    }
    const get_todo_uncategorized = () => {
        talk("todo_handler.php", "GET_TODO_UNCATEGORIZED", null, get_todo_uncategorized_handler);
    }
    const get_todo_uncategorized_handler = (operation, sent_data, return_data) => {
        if(return_data) {
            setTodoUncategorized(return_data);
        } else {
            console.log("No uncategorized items found.");
            setTodoUncategorized({});
        }
    }
    const toggle_completed_status = () => {
        let url = 'todo_handler.php';
        let postData = {
            operation: 'TOGGLE_COMPLETED',
            todoid: selectedItem['todoid'],
            completed: selectedItem['completed'] ? null : "NOW"
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                get_todo_items();
                get_todo_uncategorized();
            } else {
                //setTodoItems({});
            }
        }, 'text');
    }
    const save_toggle_checkbox_field = (todoid, field_name, new_value) => {
        let url = 'todo_handler.php';
        let postData = {
            operation: 'TOGGLE_CHECKBOX',
            todoid: todoid,
            field_name: field_name,
            new_value: new_value
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                //IF ACTIVEONLY AND THE ITEM IS NOT ACTIVE, IT WAS REMOVED... CLOSE THE MODAL
                if(activeOnly && field_name == 'is_active' && new_value==false) {
                    close_modal();
                }
            } else {
                //WARN USER ABOUT FAILURE AND SUGGEST REFRESH?
                alert("Failed to save changes. You should refresh the page before continuing.");
            }
        });
    }
    const save_edit_title = (event) => {
        event.stopPropagation();
        let url = 'todo_handler.php';
        let postData = {
            operation: 'UPDATE_TITLE',
            todoid: selectedItem['todoid'],
            title: editingTitle
        }
        load_fetch(url, postData, (data) => {
            if (!data['ERROR'] && !data['error']) {
                //UPDATE GUI
                let local_selected_item = JSON.parse(JSON.stringify(selectedItem));
                local_selected_item = {...local_selected_item, title:editingTitle};
                setSelectedItem(local_selected_item);

                //UPDATE TODOITEMS
                let local_todo_items = recursive_update_selected_item(JSON.parse(JSON.stringify(todoItems)),
                    local_selected_item);
                setTodoItems(local_todo_items);

                setEditingTitle(false);
                event.stopPropagation();
            } else {
                //WARN USER ABOUT FAILURE AND SUGGEST REFRESH?
                alert("Failed to save Title. You should refresh the page before continuing.");
            }
        });
    }
    const save_edit_details = (event) => {
        event.stopPropagation();
        let url = 'todo_handler.php';
        let postData = {
            operation: 'UPDATE_DETAILS',
            todoid: selectedItem['todoid'],
            details: editingDetails
        }
        load_fetch(url, postData, (data) => {
            if (!data['ERROR'] && !data['error']) {
                //UPDATE GUI
                let local_selected_item = JSON.parse(JSON.stringify(selectedItem));
                local_selected_item = {...local_selected_item, details:editingDetails};
                setSelectedItem(local_selected_item);

                //UPDATE TODOITEMS
                let local_todo_items = recursive_update_selected_item(JSON.parse(JSON.stringify(todoItems)),
                    local_selected_item);
                setTodoItems(local_todo_items);

                setEditingDetails(false);
                event.stopPropagation();
            } else {
                //WARN USER ABOUT FAILURE AND SUGGEST REFRESH?
                alert("Failed to save Details. You should refresh the page before continuing.");
            }
        });
    }
    const save_edit_duedate = (event) => {
        event.stopPropagation();
        let url = 'todo_handler.php';
        let postData = {
            operation: 'UPDATE_DUEDATE',
            todoid: selectedItem['todoid'],
            duedate: editingDueDate
        }
        load_fetch(url, postData, (data) => {
            if (!data['ERROR'] && !data['error']) {
                //UPDATE GUI
                let local_selected_item = JSON.parse(JSON.stringify(selectedItem));
                local_selected_item = {...local_selected_item, duedate:editingDueDate};
                setSelectedItem(local_selected_item);

                //UPDATE TODOITEMS
                let local_todo_items = recursive_update_selected_item(JSON.parse(JSON.stringify(todoItems)),
                    local_selected_item);
                setTodoItems(local_todo_items);

                setEditingDueDate(false);
                event.stopPropagation();
            } else {
                //WARN USER ABOUT FAILURE AND SUGGEST REFRESH?
                alert("Failed to save Due Date. You should refresh the page before continuing.");
            }
        });
    }
    const handle_deleting = (event) => {
        event.stopPropagation();
        let url = 'todo_handler.php';
        let postData = {
            operation: 'DELETE_TODO_ITEM',
            todoid: selectedItem['todoid']
        }
        load_fetch(url, postData, (data) => {
            if (!data['ERROR'] && !data['error']) {
                setDeletingItem(false);

                //UPDATE GUI
                let local_todo_items = JSON.parse(JSON.stringify(todoItems));
                let modified = recursive_remove_selected_item(local_todo_items)
                setTodoItems(modified);
                close_modal();
            } else {
                //WARN USER ABOUT FAILURE AND SUGGEST REFRESH?
                alert("Failed to delete item.");
            }
        }, 'text');
    }
    const save_edit_parent_category = (parentid) => {
        let postData = {
            todoid: selectedItem['todoid'],
            parentid: parentid
        }
        talk("todo_handler.php", "UPDATE_PARENT_CATEGORY", postData, save_edit_parent_category_handler);
    }
    const save_edit_parent_category_handler = (operation, sent_data, return_data) => {
        if(!return_data['error'] && !return_data['ERROR']) {
            cancel_edit_parent_category();
            setSelectedItem(false);
            get_todo_uncategorized();
            setTodoItems(return_data);
            //setTodoItems(JSON.parse(JSON.stringify(return_data)));
            //setTodoItems(false);
        } else {
            alert("ERROR UPDATING PARENT CATEGORY:\n" + return_data);
        }
    }

    //OTHER FUNCTIONS
    function recursive_update_selected_item(local_todo_data, selected_item) {
        for(let key in local_todo_data) {
            if(typeof local_todo_data[key] == 'object' && local_todo_data[key] !== null) {
                if(local_todo_data[key]['todoid']) {
                    if(local_todo_data[key]['todoid'] === selected_item['todoid']) {
                        local_todo_data[key] = selected_item;
                    }
                }
                recursive_update_selected_item(local_todo_data[key], selected_item);
            }
        }
        return local_todo_data;
    }
    const navigate_to_todo = () => {
        document.location.href='todo';
    }
    const navigate_to_manage_overdue = () => {
        document.location.href='todo-manage-overdue';
    }
    const set_selected_item = (item) => {
        setSelectedItem(item);
    }
    const close_modal = () => {
        //Clear the Form
        setEditingTitle(false);
        setEditingDetails(false);
        setEditingDueDate(false);
        setDeletingItem(false);

        //Close the Modal
        setSelectedItem(false);
    }
    const toggle_active_only = () => {
        setTodoItems({});
        setActiveOnly(!activeOnly);
    }
    function recursive(obj, field_name) {
        for(let key in obj) {
            if(typeof obj[key] == 'object' && obj[key] !== null) {
                if(obj[key]['todoid']) {
                    if(obj[key]['todoid'] === selectedItem['todoid']) {
                        obj[key][field_name] = (obj[key][field_name] === 1) ? 0 : 1;
                        if(field_name === 'is_active' && activeOnly) {
                            //remove it if the value is not active
                            if(obj[key][field_name] === 0) {
                                obj.splice(key, 1);
                                return obj;
                            }
                        }
                    }
                }
                recursive(obj[key], field_name);
             }
        }
        return obj;
    }
    const toggle_selected_item_checkbox = (field_name) => {
        let local_data = JSON.parse(JSON.stringify(todoItems));

        let newObj = recursive(local_data, field_name);
        let new_value = selectedItem[field_name] === 1 ? 0 : 1;
        setTodoItems(newObj);
        setSelectedItem(prevData => ({
            ...prevData,
            [field_name]: new_value
        }))
        save_toggle_checkbox_field(selectedItem['todoid'], field_name, new_value);
    }
    function recursive_add_subitem(local_todo_data, new_subitem_data, parent_id) {
        for(let key in local_todo_data) {
            if(typeof local_todo_data[key] == 'object' && local_todo_data[key] !== null) {
                if(local_todo_data[key]['todoid']) {
                    if(local_todo_data[key]['todoid'] === parent_id) {
                        new_subitem_data['depth'] = local_todo_data[key]['depth'] + 1;
                        if(!local_todo_data[key]['sub_items']) {
                            local_todo_data[key]['sub_items'] = [];
                        }

                        local_todo_data[key]['sub_items'].push(new_subitem_data);
                    }
                }
                recursive_add_subitem(local_todo_data[key], new_subitem_data, parent_id);
            }
        }
        return local_todo_data;
    }
    const on_save_new_subitem = (new_subitem_data) => {
        //Update the data
        let local_data = JSON.parse(JSON.stringify(todoItems));
        local_data = recursive_add_subitem(local_data, new_subitem_data, new_subitem_data['parentid']);

        //Set the State
        setTodoItems(local_data);

        //Close the Modal and Clear Selected Item
        setSelectedItem(false);
    }
    const edit_title = () => {
        setEditingTitle(selectedItem['title']);
    }
    const edit_details = () => {
        setEditingDetails(selectedItem['details']);
    }
    const cancel_edit_title = (event) => {
        setEditingTitle(false);
        if(event) {
            event.stopPropagation();
        }
    }
    const cancel_edit_details = (event) => {
        setEditingDetails(false);
        if(event) {
            event.stopPropagation();
        }
    }
    const update_selected_item = (event) => {
        const {name, value} = event.target;
        if(name === 'title') {
            setEditingTitle(value);
        } else if(name === 'details') {
            setEditingDetails(value);
        } else if(name === 'duedate') {
            setEditingDueDate(value);
        }
    }
    const edit_due_date = () => {
        setEditingDueDate(selectedItem['duedate'] === null ? '' : selectedItem['duedate']);
    }
    const cancel_edit_duedate = (event) => {
        setEditingDueDate(false);
        if(event) {
            event.stopPropagation();
        }
    }
    const delete_selected_item = () => {
        setDeletingItem(true);
    }
    const cancel_deleting = () => {
        setDeletingItem(false);
    }
    function recursive_remove_selected_item(local_todo_data) {
        for(let key in local_todo_data) {
            if(typeof local_todo_data[key] == 'object' && local_todo_data[key] !== null) {
                if(local_todo_data[key]['todoid']) {
                    if(local_todo_data[key]['todoid'] === selectedItem['todoid']) {
                        //delete local_todo_data[key];
                        local_todo_data.splice(key, 1);

                        return local_todo_data;
                    }
                }

                if(local_todo_data[key]) {
                    recursive_remove_selected_item(local_todo_data[key]);
                }
            }
        }
        return local_todo_data;
    }
    const toggle_deemphasize_inactive = () => {
        setDeemphasizeInactive(!deemphasizeInactive);
    }
    const toggle_item_visibility = (todoid) => {
        let el = document.getElementById('todo_div_' + todoid);
        if(el.style.display === 'flex') {
            el.style.display = 'none';
        } else {
            el.style.display = 'flex';
        }
    }
    const navigate_to_today = () => {
        document.location.href="./today";
    }
    const edit_parent_category = () => {
        setEditingParentCategory(selectedItem['parentid']);
    }
    const cancel_edit_parent_category = (event) => {
        setEditingParentCategory(false);
        if(event) {
            event.stopPropagation();
        }
    }





    if(props.authStatus) {
        return (
            <>
                <button
                    className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                    onClick={navigate_to_todo}
                >TODO Complete List
                </button>
                <button
                    className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                    onClick={toggle_active_only}
                >Toggle Active Only
                </button>
                <button
                    className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                    onClick={navigate_to_manage_overdue}
                >Manage Overdue
                </button>
                <button
                    className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                    onClick={toggle_deemphasize_inactive}
                >
                    Toggle De-Emphasize Inactive
                </button>
                <button
                    className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                    onClick={navigate_to_today}
                >
                    Back to Today
                </button>

                <TODORapidEntryModule />

                <div className={Styles.ofb_todo_simple_uncategorized}>
                    {todoUncategorized.length > 0 && todoUncategorized.map((item, index) => (
                        <TodoActiveSimpleSubitem
                            active_only={activeOnly}
                            data={item}
                            key={item['todoid']}
                            set_selected_item={set_selected_item}
                            today={today}
                            deemphasize_inactive={deemphasizeInactive}
                        />
                    ))}
                </div>
                <div className={Styles.ofb_todo_simple}>

                    {
                        todoItems.length > 0 && todoItems.map((item, index) => (
                            <div key={item['todoid']} className={Styles.ofb_todo_simple}>
                                <div
                                    className={Styles.ofb_todo_header_button}
                                    onClick={() => toggle_item_visibility(item['todoid'])}
                                >
                                    {item['title']}
                                </div>
                                <div
                                    id={'todo_div_' + item['todoid']}
                                    className={activeOnly ? Styles.ofb_todo_simple_box :
                                        Styles.ofb_todo_simple_box_wide}
                                    key={item['todoid']}
                                >
                                    {/* Outputting the top level item */}
                                    <div
                                        className={Styles.ofb_todo_simple_item}
                                        onClick={() => set_selected_item(item)}
                                    ><b>{item['title']}</b>
                                    </div>

                                    {/* Mapping the subitems*/}
                                    {item['sub_items'].length > 0 && item['sub_items'].map((sub_item, sub_index) => (
                                        <TodoActiveSimpleSubitem
                                            active_only={activeOnly}
                                            key={sub_item['todoid']}
                                            data={sub_item}
                                            set_selected_item={set_selected_item}
                                            today={today}
                                            deemphasize_inactive={deemphasizeInactive}
                                        />
                                    ))}

                                    {/* Empty Row for hand-writing something in on a printed version */}
                                    <div className={Styles.ofb_todo_simple_item}>○</div>
                                </div>
                            </div>
                        ))}
                </div>

                {/* MODAL */}
                <div className={selectedItem ? Styles.ofb_modal_shown : Styles.ofb_modal_hidden}>
                    <div className={Styles.ofb_modal_content}>
                        <div className={Styles.ofb_modal_header}>
                            <div>Todo Item</div>
                            <div><button
                                className={Styles.ofb_learning_close_results_button}
                                onClick={close_modal}
                            >X</button></div>
                        </div>
                        <div className={Styles.ofb_modal_body}>
                            {/* TITLE AND EDIT TITLE */}
                            <div
                                className={Styles.ofb_cursor_pointer}
                                onClick={edit_title}>{editingTitle ?
                                    <>
                                    <input
                                        type="text"
                                        name="title"
                                        value={editingTitle}
                                        onChange={update_selected_item}
                                        className={Styles.ofb_todo_edit_title_input}
                                    />
                                    <button onClick={cancel_edit_title}>❌</button>
                                    <button onClick={save_edit_title}>💾</button>
                                    </>
                                : selectedItem['title']}</div>

                            {/* DETAILS AND EDIT DETAILS */}
                            <div
                                className={`${Styles.ofb_font_size_12} ${Styles.ofb_cursor_pointer}`}
                                onClick={edit_details}
                            >{editingDetails !== false ? <>
                                <input
                                    type="text"
                                    value={editingDetails}
                                    name="details"
                                    onChange={update_selected_item}
                                    />
                                <button onClick={cancel_edit_details}>❌</button>
                                <button onClick={save_edit_details}>💾</button>
                            </> : (selectedItem['details'] ?
                                selectedItem['details'] : "-NO DETAILS-")}</div>

                            {/* DUE DATE FOR EXISTING ITEM */}
                            <div className={`${Styles.ofb_font_size_12} ${Styles.ofb_cursor_pointer}`}
                                 onClick={edit_due_date}
                            >
                                <b>Due Date</b>: {editingDueDate !== false ? <>
                                    <input
                                        type="date"
                                        className={Styles.ofb_new_todo_form_text_input}
                                        name="duedate"
                                        value={editingDueDate}
                                        onChange={update_selected_item}
                                    />
                                    <button onClick={cancel_edit_duedate}>❌</button>
                                    <button onClick={save_edit_duedate}>💾</button>
                                </> :
                                selectedItem['duedate']}
                            </div>

                            {/* EDIT PARENT CATEGORY */}
                            <div className={`${Styles.ofb_font_size_12} ${Styles.ofb_cursor_pointer}`}
                                  onClick={edit_parent_category}
                            >
                                <b>Parent Category</b>: {editingParentCategory !== false ? <>
                                    <ParentCategorySelector
                                        parent_id={editingParentCategory}
                                        cancel_editing_parent_id={cancel_edit_parent_category}
                                        save_parent_id={save_edit_parent_category}
                                    />
                                    {/*<button onClick={cancel_edit_parent_category}>x</button>*/}
                                    {/*<button onClick={save_edit_parent_category}>💾</button>*/}
                                </> :
                                selectedItem['parent_title']}
                            </div>


                            {/* MARK COMPLETED BUTTONS */}
                            <div className={Styles.ofb_todo_completed_row}>
                            {selectedItem['depth'] !== 0 ? (selectedItem['completed'] ? (
                                <div className={Styles.ofb_todo_mark_completed_button_div}>
                                    <button className={Styles.ofb_modal_footer_button}
                                            onClick={toggle_completed_status}>
                                        Mark Uncompleted
                                    </button>
                                </div>
                            ) : (
                                <div className={Styles.ofb_todo_mark_completed_button_div}>
                                    <button className={Styles.ofb_modal_footer_button}
                                            onClick={toggle_completed_status}>
                                        Mark Completed
                                    </button>
                                </div>
                            )) : ''}

                                {/* DELETE BUTTON */}
                                <div className={Styles.ofb_todo_mark_completed_button_div}>
                                    <button
                                        className={Styles.ofb_modal_footer_button}
                                        onClick={delete_selected_item}
                                    ><img src={TrashCan} /></button>
                                </div>
                            </div>
                            <div className={deletingItem ? Styles.ofb_todo_ays : Styles.ofb_hide}>
                                <div>Are you sure you want to delete this item?</div>
                                {(selectedItem['sub_items'] && selectedItem['sub_items'].length > 0) ? <div>It has subitems.</div> : ''}
                                <div>
                                    <button className={Styles.ofb_button} onClick={cancel_deleting}>Cancel</button>
                                    <button className={Styles.ofb_button} onClick={handle_deleting}>Delete</button>
                                </div>
                            </div>

                            {/* CHECKBOXES FOR PROJECT, URGENCT, AND ACTIVE */}
                            <div className={selectedItem['depth'] > 0 ?
                                Styles.ofb_todo_simple_checkbox_row : Styles.ofb_hide}>
                                <div>
                                    Project? <input
                                    type="checkbox"
                                    className={Styles.ofb_checkbox_large}
                                    name="new_todo_is_project"
                                    checked={selectedItem['isproject'] || false}
                                    onChange={() => toggle_selected_item_checkbox('isproject')}
                                />

                                </div>
                                <div>
                                    Urgent? <input
                                    type="checkbox"
                                    className={Styles.ofb_checkbox_large}
                                    name="new_todo_is_urgent"
                                    checked={selectedItem['urgency'] || false}
                                    onChange={(event) => toggle_selected_item_checkbox('urgency')}
                                />
                                </div>
                                <div>
                                    Active? <input
                                    type="checkbox"
                                    className={Styles.ofb_checkbox_large}
                                    name="new_todo_is_active"
                                    checked={selectedItem['is_active'] || false}
                                    onChange={(event) => toggle_selected_item_checkbox('is_active')}
                                />
                                </div>
                            </div>

                            {/* NEW SUBITEM FORM */}
                            {activeOnly ? '' :
                                <NewTodoSubitemForm
                                    is_shown={selectedItem ? true : false}
                                    parentid={selectedItem['todoid']}
                                    on_save={on_save_new_subitem}
                                />
                            }
                        </div>

                        {/* MODAL FOOTER WITH CLOSE BUTTON */}
                        <div className={Styles.ofb_modal_footer}>
                            <button
                                className={Styles.ofb_modal_footer_button}
                                onClick={close_modal}
                            >Close
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return <></>
    }
}
export default TodoActiveSimple;