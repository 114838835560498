export function get_bible_book_number(book_name) {
    switch(book_name) {
        case 'Genesis':
            return 1;
        case 'Exodus':
            return 2;
        case 'Leviticus':
            return 3;
        case 'Numbers':
            return 4;
        case 'Deuteronomy':
            return 5;
        case 'Joshua':
            return 6;
        case 'Judges':
            return 7;
        case 'Ruth':
            return 8;
        case '1 Samuel':
            return 9;
        case '2 Samuel':
            return 10;
        case '1 Kings':
            return 11;
        case '2 Kings':
            return 12;
        case '1 Chronicles':
            return 13;
        case '2 Chronicles':
            return 14;
        case 'Ezra':
            return 15;
        case 'Nehemiah':
            return 16;
        case 'Esther':
            return 17;
        case 'Job':
            return 18;
        case 'Psalms':
            return 19;
        case 'Proverbs':
            return 20;
        case 'Ecclesiastes':
            return 21;
        case 'Song of Songs':
            return 22;
        case 'Isaiah':
            return 23;
        case 'Jeremiah':
            return 24;
        case 'Lamentations':
            return 25;
        case 'Ezekiel':
            return 26;
        case 'Daniel':
            return 27;
        case 'Hosea':
            return 28;
        case 'Joel':
            return 29;
        case 'Amos':
            return 30;
        case 'Obadiah':
            return 31;
        case 'Jonah':
            return 32;
        case 'Micah':
            return 33;
        case 'Nahum':
            return 34;
        case 'Habakkuk':
            return 35;
        case 'Zephaniah':
            return 36;
        case 'Haggai':
            return 37;
        case 'Zechariah':
            return 38;
        case 'Malachi':
            return 39;
        case 'Matthew':
            return 40;
        case 'Mark':
            return 41;
        case 'Luke':
            return 42;
        case 'John':
            return 43;
        case 'Acts':
            return 44;
        case 'Romans':
            return 45;
        case '1 Corinthians':
            return 46;
        case '2 Corinthians':
            return 47;
        case 'Galatians':
            return 48;
        case 'Ephesians':
            return 49;
        case 'Philippians':
            return 50;
        case 'Colossians':
            return 51;
        case '1 Thessalonians':
            return 52;
        case '2 Thessalonians':
            return 53;
        case '1 Timothy':
            return 54;
        case '2 Timothy':
            return 55;
        case 'Titus':
            return 56;
        case 'Philemon':
            return 57;
        case 'Hebrews':
            return 58;
        case 'James':
            return 59;
        case '1 Peter':
            return 60;
        case '2 Peter':
            return 61;
        case '1 John':
            return 62;
        case '2 John':
            return 63;
        case '3 John':
            return 64;
        case 'Jude':
            return 65;
        case 'Revelation':
            return 66;
    }
    return -1;
}