import React from 'react';
import Styles from "../styles/ofbstyles.module.css";

const LibraryRatingSystem = () => (
    <div className={Styles.ofb_library_rating_system_holder}>
        <div className={Styles.ofb_bold}>Rating System:</div>
        <div className={Styles.ofb_library_rating_system}>

            <div><div className={`${Styles.ofb_library_rating} ${Styles.ofb_library_rating_5}`}>* * * * * </div> = Would read again</div>
            <div><div className={`${Styles.ofb_library_rating} ${Styles.ofb_library_rating_4}`}>* * * * </div> = Very, very good</div>
            <div><div className={`${Styles.ofb_library_rating} ${Styles.ofb_library_rating_3}`}>* * * </div> = Enjoyable</div>
            <div><div className={`${Styles.ofb_library_rating} ${Styles.ofb_library_rating_2}`}>* * </div> = More bad than good</div>
            <div><div className={`${Styles.ofb_library_rating} ${Styles.ofb_library_rating_1}`}>* </div> = Atrocious</div>
        </div>
    </div>
);

export default LibraryRatingSystem;