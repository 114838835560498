import Config from "./ofb_config.json";

export function getDateInYYYYMMDD(dateObject) {
    return dateObject.getFullYear().toString() + "-" + (dateObject.getMonth() + 1).toString().padStart(2, "0") + "-" +
        dateObject.getDate().toString().padStart(2, "0");

}

export function talk(url, operation, post_data, handler) {
    let postData = {
        operation: operation,
        ...post_data
    }
    load_fetch(url, postData, (data) => {
        try {
            let json_data = JSON.parse(data);
            handler(operation, post_data, json_data);
        } catch (e) {
            console.log(e);
            console.log(data);
        }
    },'text');
}
export function load_fetch(relative_url, postData, callback, textOrJson='json') {
    let url = Config.api_url + relative_url;
    //console.log("In load_fetch(): " + url);

    const options = {
        method: 'POST',
        body: JSON.stringify(postData),
        cache: 'no-cache',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };

    if(textOrJson === 'json') {
        fetch(url, options)
            .then(response => {
                if(!response.ok) {
                    throw new Error("Failed with HTTP code " + response.status);
                }
                return response;
            })
            .then(result => result.json())
            .then(function (data) {
                callback(data);
            })
            .catch(error => {
                callback({error: error});
            });
    } else {
        fetch(url, options)
            .then(result => result.text())
            .then(function (data) {
                callback(data);
            })
            .catch(error => {
                callback({error: error});
            });
    }
}
export function deformat_currency(formatted_value) {
    //Strip out commas
    let value = formatted_value.toString().trim();
    if(value.length <= 0) {
        return;
    }
    value = value.replaceAll(/,/ig, '');
    return value;
}
export function format_currency(currency_value) {
    if(!currency_value) {
        return currency_value;
    }
    currency_value = currency_value.toString();
    //Is there a decimal?
    if(currency_value.indexOf('.') !== -1) {
        let parts = currency_value.split('.');

        //If only a single post-decimal digit, add a zero
        if(parts[1].length === 1) {
            parts[1] += "0";
        }
        return add_commas(parts[0]) + "." + parts[1];
    } else {
        if(currency_value.trim().length !== 0) {
            currency_value = add_commas(currency_value) + ".00";
            return currency_value;
        } else {
            return '';
        }
    }
}
export function format_currency_new(currency_value_in_cents) {
    if(!currency_value_in_cents) {
        return currency_value_in_cents;
    }

    //Convert to a String
    currency_value_in_cents = currency_value_in_cents.toString();

    //Remove any negative sign
    let negative = false;
    if(currency_value_in_cents.charAt(0) === '-') {
        negative = true;
        currency_value_in_cents = currency_value_in_cents.substring(1);
    }

    //Make sure it is at least two digits long (e.g 5 cents will become 05)
    currency_value_in_cents = currency_value_in_cents.padStart(2, '0');

    //Add commas
    let new_value = '';
    let dollar_value = currency_value_in_cents.substring(0, currency_value_in_cents.length - 2);
    let cents_value = currency_value_in_cents.substring(currency_value_in_cents.length - 2);

    let number_of_commas = Math.floor((dollar_value.length - 1) / 3);
    for(let i = 0; i <= number_of_commas; i++) {
        let start = dollar_value.length - ((i + 1) * 3);
        let end = dollar_value.length - (i * 3);
        if(start < 0) start = 0;
        let segment = dollar_value.slice(start, end)
        new_value = (i < number_of_commas ? "," : '') + segment + new_value;
    }

    //Add in decimal
    new_value = new_value + "." + cents_value;

    //Add the dollar sign
    new_value = "$" + new_value;

    //Add the negative sign if necessary
    if(negative) {
        new_value = "-" + new_value;
    }
    return new_value;
}
export function date_to_ordinal(date_number) {
    if(!date_number) {
        return '';
    }
    date_number = date_number.toString().trim();

    if(date_number.charAt(date_number.length - 1) == '1') {
        return "the " + date_number + "st"
    } else if(date_number.charAt(date_number.length - 1) == '2') {
        return "the " + date_number + "nd"
    } else if(date_number.charAt(date_number.length - 1) == '3') {
        return "the " + date_number + "rd"
    } else {
        return "the " + date_number + "th"
    }
}
function add_commas(value) {
    let new_value = '';
    let number_of_commas = Math.floor(value.length / 3);
    for(let i = 0; i <= number_of_commas; i++) {
        let start = value.length - ((i + 1) * 3);
        let end = value.length - (i * 3);
        if(start < 0) start = 0;
        let segment = value.slice(start, end)
        new_value = segment + (new_value.length > 0 && segment.length > 0 ? "," : "") + new_value;
    }
    return new_value;
}
export function get_current_time(callback) {
    let date = new Date();
    let hh = date.getHours();
    let mm = date.getMinutes();
    let ss = date.getSeconds();
    let session = "AM";

    if (hh == 0) {
        hh = 12;
    }
    if (hh > 12) {
        hh = hh - 12;
        session = "PM";
    }

    hh = (hh < 10) ? "0" + hh : hh;
    mm = (mm < 10) ? "0" + mm : mm;
    ss = (ss < 10) ? "0" + ss : ss;

    let time = hh + ":" + mm + ":" + ss + " " + session;

    document.getElementById("clock").innerText = time;
    let t = setTimeout(function () {
        get_current_time(callback)
    }, 1000);
    callback(time);
}