import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Styles from "../styles/ofbstyles.module.css";
import {load_fetch} from "../ofb_util";

const Projects = (props) => {
    const navigate = useNavigate();
    const [projectsData, setProjectsData] = useState([]);

    useEffect(() => {
        load_projects_data();
    }, [])

    const load_projects_data = (item) => {
        const postData = {
            operation: "GET_PROJECTS_DATA"
        }
        load_fetch('todo_handler.php', postData, function(data) {
            if(data && "ERROR" in data) {
                console.log("ERROR: FAILED TO GET PROGRESS NOTES");
            } else if (data) {
                //SUCCESS
                setProjectsData(data);
                console.log(data);
                //Add Progress to the display (can reload progress items later)
            } else {
                //No Notes Found
                setProjectsData([]);
            }
        });
    }
    const display_projects = () => {
        if(projectsData.length > 0) {
            return (
                <div className={Styles.ofb_projects_holder}>
                    {projectsData.map((item, index) => (
                        <div
                            key={item['todoid']}
                            className={`${Styles.ofb_projects_row} ${item['urgency'] ? Styles.ofb_projects_urgent : ''}`}
                        >
                            <div className={Styles.ofb_projects_title}><button onClick={() => view_todo_item(item)}>{item['title']}</button></div>
                            <div className={Styles.ofb_projects_date}>{item['progress_notes'] ? item['progress_notes'][0]['work_date_time'] : ''}</div>
                            <div className={Styles.ofb_projects_duration}>{item['total_duration_of_work'] ? item['total_duration_of_work'] : ''}</div>
                        </div>
                    ))}
                </div>
            )
        } else {
            return <></>
        }
    }
    const view_todo_item = (item) => {
        navigate("/todo", {state: {focus_todo_item: item}});
    }
    const navigate_to_todo_simple = () => {
        navigate("/todo");
    }
    return (
        <>
            <button
                className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                onClick={navigate_to_todo_simple}
            >Back to TODO</button>
            <h2>Projects</h2>
            {display_projects()}
        </>
    )
}
export default Projects;