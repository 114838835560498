import React from 'react';
import ReactDOM from 'react-dom';
import styles from './styles/language.module.css';


const IntroductionToLinguistics = (props) => {
    return (
        <div style={{textAlign: "left"}}>
            <h2>Introduction to Linguistics</h2>
            <h3>Open Access Textbook (planning)</h3>
            <h4>Part One: What is Language?</h4>
            <ol>
                <li className={styles.numbered_list_item}>What is Language?</li>
                <ul className={styles.bullet_list}>
                    <li>Prescriptive vs. Descriptive Linguistics</li>
                    <li>Where do languages come from?</li>
                    <li>Pidgins and Creoles</li>
                </ul>
                <li className={styles.numbered_list_item}>Language Change</li>
                <li className={styles.numbered_list_item}>The English Language</li>
            </ol>
            <h4>Part Two: The Components of Language</h4>
            <ol>
                <li className={styles.numbered_list_item}>Phonetics and Phonology - the Sounds of Languages</li>
                <li className={styles.numbered_list_item}>Morphology - the Words of Languages</li>
                <li className={styles.numbered_list_item}>Syntax - the Grammar of Languages</li>
                <li className={styles.numbered_list_item}>Semantics - the Meaning of Languages</li>
                <li className={styles.numbered_list_item}>Pragmantics - the Social Context of Languages</li>
            </ol>
            <h4>Part Three: Language and Society</h4>
            <ol>
                <li className={styles.numbered_list_item}>Dialect and Language</li>
                <ul className={styles.bullet_list}>
                    <li>Political/Religious/Cultural influences on the delineation of langauges/dialects</li>
                </ul>
                <li className={styles.numbered_list_item}>Language Policy</li>
                <li className={styles.numbered_list_item}>Stigma associated with languages/dialects</li>
                <li className={styles.numbered_list_item}>Language eradication efforts</li>
                <li className={styles.numbered_list_item}>Language revival efforts</li>
                <li className={styles.numbered_list_item}>Language resurrection efforts</li>
                <li className={styles.numbered_list_item}>Ebonics</li>
                <li className={styles.numbered_list_item}>“Standard” English</li>
                <li className={styles.numbered_list_item}>English Only/Official English</li>
                <li className={styles.numbered_list_item}>Official Multilingualism</li>
            </ol>
            <h4>Part Four: Language Acquisition</h4>
            <h4>Part ??: Language Disorders</h4>
            <h4>Part Five: The Subfields of Linguistics</h4>
            <ol>
                <li className={styles.numbered_list_item}>Historical Linguistics</li>
                <li className={styles.numbered_list_item}>Computational Linguistics</li>
                <li className={styles.numbered_list_item}>Psycholinguistics</li>
                <li className={styles.numbered_list_item}>Sociolinguistics</li>
                <li className={styles.numbered_list_item}>Neurolinguistics</li>
            </ol>
            <h4>Other:</h4>
            <ol>
                <li className={styles.numbered_list_item}>Typology</li>
                <li className={styles.numbered_list_item}>Sign Languages</li>
            </ol>
        </div>
    );
};

export default IntroductionToLinguistics;