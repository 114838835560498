import React, {useEffect, useState, useContext, forwardRef} from "react";
import { load_fetch, talk } from "../ofb_util";
import Styles from "../styles/pseudos.module.css";

const Widget = forwardRef((props, ref) => {
    const [title, setTitle] = useState(props.title);

    useEffect(() => {
        if(props.selected_widget === props.index) {
            document.location.href=props.click_url;
        }
    }, [props.go_to_url_triggered])

    const click_handler = () => {
        if(props.click_handler) {
            props.click_handler();
        } else if (props.click_url) {
            document.location.href=props.click_url;
        }
    }

    return (
        <div
            ref={ref}
            onClick={click_handler}
            className={`${Styles.widget} ${props.selected_widget === props.index ? Styles.widget_selected : ''}`}
        >
            {props.title ? <div>{props.title}</div> : ''}
            {props.other_code ? props.other_code : ''}
        </div>
    );
});

export default Widget;
