import React from 'react';
import BibleVerseRandomizer from "./bible_verse_randomizer";

const PageHeader = (props) => {
    let bibleRandomizer = null;
    if(props.bible_randomizer === "true") {
        bibleRandomizer = <BibleVerseRandomizer />;
    }

    return (
        <>
            <h2 className="w3-wide" id="page_title">{props.page_title}</h2>
            <p className="w3-opacity w3-wide"><i>Oratio et Obedientia</i></p>
            {bibleRandomizer}
        </>
    );
}

export default PageHeader;