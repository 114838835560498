import Styles from "./styles/toggle_button.module.css";
import { useState } from "react";

const ToggleButton = (props) => {
    const [isPushed, setIsPushed] = useState(props.checked);

    const handleToggleButtonPush = () => {
        setIsPushed((!isPushed));

        //Pass the event back to the parent if there is a callback
        if(props.handleToggleButtonPush) {
            props.handleToggleButtonPush(props.parentId + "_" + props.label_text);
        }
    }

    return (
        <div className={isPushed ? Styles.toggleButtonChecked : Styles.toggleButtonUnchecked }>
        <label>
            <input
                className={Styles.toggleButtonInput}
                type="checkbox"
                name={props.parentId + "_" + props.label_text}
                onChange={() => {
                    handleToggleButtonPush();
                }}
                checked={isPushed}
            />
            <div className={Styles.toggleButtonLabel}>{props.label_text}</div>
        </label>
        </div>
    );
};

export default ToggleButton;