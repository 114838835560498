import React, {useEffect, useState} from "react";
import { load_fetch} from "./ofb_util";
import Styles from "./styles/ofbstyles.module.css";
import LearningStyles from "./styles/learning.module.css";
import NewFolderIcon from "../src/images/new_folder.png";
import TrashCan from "../src/images/trash_can_white.png";
import TrashCanBlack from "../src/images/trash_can.png";

//TODO Work on Quiz Ending, showing results, updating screen

const Learning = (props) => {
    //DATA
    const [quiz, setQuiz] = useState(false);
    //QUIZTYPES - LEARN, REVIEW or REVIEW_10
    const [quizType, setQuizType] = useState('');
    //QUIZQUESTIONS - An array of questions for this loaded quiz
    const [quizQuestions, setQuizQuestions] = useState(false);
    //SUBFOLDERS - An array of subfolder data
    const [subFolders, setSubFolders] = useState(false);
    //CURRENTFOLDERNAME - The name of the currently select folder
    const [currentFolderName, setCurrentFolderName] = useState('');
    //CURRENTFOLDERID - The ID of the currently selected folder
    const [currentFolderId, setCurrentFolderId] = useState(0);
    //PARENTFOLDERID - This folder's parent's ID (for navigating up)
    const [parentFolderId, setParentFolderId] = useState(null);
    //QUESTIONS - An array of questions contained in this folder
    const [questions, setQuestions] = useState(false);
    //CURRENTQUESTIONINDEX - the array index of the current question when quiz is running
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(false);
    //CARDSIDEUP - 0 for front, 1 for back
    const [cardSideUp, setCardSideUp] = useState(0);
    //QUIZQUESTIONATTEMPTS - Total questions answered
    const [quizQuestionAttempts, setQuizQuestionAttempts] = useState(0);
    //QUIZQUESTIONSCORRECT - Total correct answers on this quiz
    const [quizQuestionsCorrect, setQuizQuestionsCorrect] = useState(0);
    //THUMBSBUTTONSTATE - 0=none pushed, 1=thumbs up, 2=thumbs down
    const [thumbsButtonState, setThumbsButtonState] = useState(0);
    //QUESTIONSTATS - Statistics for this particular question
    const [questionStats, setQuestionStats] = useState(false);
    //FOLDERSTATS - Statistics for this particular question
    const [folderStats, setFolderStats] = useState(false);
    //EDITING - Are we editing a folder
    const [editing, setEditing] = useState(false);
    //NEWQUESTIONTEXT - For adding a new question
    const [newQuestionText, setNewQuestionText] = useState('');
    //NEWQUESTIONANSWER - For adding a new question
    const [newQuestionAnswer, setNewQuestionAnswer] = useState('');
    //EDITINGQUESTIONID - The id of the question being edited
    const [editingQuestionId, setEditingQuestionId] = useState(false);
    //EDITQUESTIONTEXT - A holder for the text of a question that we are editing
    const [editQuestionText, setEditQuestionText] = useState('');
    //EDITANSWERTEXT - A holder for the answer of a question that we are editing
    const [editAnswerText, setEditAnswerText] = useState('');
    //EDITFOLDERNAME - A holder for the value being input to change the folder name
    const [editFolderName, setEditFolderName] = useState('');
    //NEWSUBFOLDERNAME - A holder for the name of a subfolder being created
    const [newSubfolderName, setNewSubfolderName] = useState('');
    //THINGTODELETESTRING - The name of the question or folder to delete
    const [thingToDeleteString, setThingToDeleteString] = useState('');
    //DELETEQUESTIONID - The ID of the question to delete
    const [deleteQuestionId, setDeleteQuestionId] = useState(false);
    //FOLDERTODELETENAME - When deleting a folder, saves the name for the modal
    const [deleteFolderName, setDeleteFolderName] = useState('');
    //DELETEFOLDERID - For deleting a folder
    const [deleteFolderId, setDeleteFolderId] = useState(false);
    //ISFOLDEREMPTYSTRING
    const [isFolderEmpty, setIsFolderEmpty] = useState(false);
    //QUIZSCOREDATA
    const [quizScoreData, setQuizScoreData] = useState([]);
    //QUIZCOMPLETED
    const [quizCompleted, setQuizCompleted] = useState(false);
    //QUIZCOMPLETEDSTATS - Results at the end
    const [quizCompletedStats, setQuizCompletedStats] = useState(false);
    //HIGHLIGHTEDFOLDER - Which folder is highlighted in keyboard navigation
    const [highlightedFolder, setHighlightedFolder] = useState(false);
    //WHICHKEYCODE (temp for testing)
    const [whichKeyCode, setWhichKeyCode] = useState('');


    /* AUTO FUNCTIONS */

    //Load Top Level Folder on Load
    useEffect(() => {
        if(!props.authStatus) {
            setSubFolders(false);
            return;
        }
        if(!subFolders) {
            get_sub_folders(0);
        }
    }, [props.authStatus]);

    //Load subfolders/questions if the parent folder id has changed
    useEffect(() => {
        get_sub_folders(currentFolderId);
        get_questions(currentFolderId);
        get_folder_stats(currentFolderId);
    }, [currentFolderId]);

    //Load question stats on question change
    useEffect(() => {
        if(quizQuestions && currentQuestionIndex !== false) {
            get_question_stats(quizQuestions[currentQuestionIndex]['learningquestionid']);
            if(quizScoreData[currentQuestionIndex]) {
                switch (quizScoreData[currentQuestionIndex]) {
                    case 'INCORRECT':
                        setThumbsButtonState(2);
                        break;
                    case 'CORRECT':
                        setThumbsButtonState(1);
                        break;
                }
            }
        }
    }, [currentQuestionIndex]);

    //Change some things when editing
    useEffect(() => {
        if(editing) {
            setEditFolderName(currentFolderName);
        }
    }, [editing]);

    //Start the quiz when the questions have been loaded
    useEffect(() => {
        if(quizQuestions) {
            setCurrentQuestionIndex(0);
            setQuiz(true);
        }
    }, [quizQuestions]);

    //Add keyboard listener
    useEffect(() => {
        function handle_key_down(e) {
            console.log(e.keyCode);
            setWhichKeyCode(e.keyCode);

            //If Quiz Selector is visible
            if(document.getElementById('quiz_selector_modal') &&
                document.getElementById('quiz_selector_modal').style.display === 'block') {
                switch(e.keyCode) {
                    case 97: //Keypad 1
                    case 49: //BT1
                        load_quiz(currentFolderId, 'LEARN');
                        break;
                    case 98: //Keypad 2
                    case 50: //BT2
                        load_quiz(currentFolderId, 'REVIEW');
                        break;
                    case 99: //Keypad 3
                    case 51: //BT3
                        load_quiz(currentFolderId, 'REVIEW_10');
                        break;
                    case 96: //Keypad 0
                    case 48: //BT0
                        //Cancel the starting of the quiz
                        document.getElementById('quiz_selector_modal').style.display = 'none';
                        break;
                }
            }

            //If we are showing quiz results
            if(quizCompleted) {
                //Any key can close results
                cancel_quiz();
                return;
            }

            //if it's a quiz, handle the controls separately
            if(quiz) {
                switch (e.keyCode) {
                    case 97: //Keypad 1
                    case 49: //BT1
                        //Go back card
                        prev_card();
                        break;
                    case 99: //Keypad 3
                    case 51: //BT3
                        //Next Card
                        next_card();
                        break;
                    case 100: //Keypad 4
                    case 52: //BT4
                        //Mark incorrect
                        tag_incorrect();
                        break;
                    case 101: //Keypad 5
                    case 53: //BT5
                        //Flip card
                        flip_card();
                        break;
                    case 102: //Keypad 6
                    case 54: //BT6
                        //Mark correct
                        tag_correct();
                        break;
                    case 96: //Keypad 0
                    case 48: //BT0
                        //Cancel Quiz
                        cancel_quiz();
                        break;
                }
                return;
            }

            //In Folder Navigation
            switch(e.keyCode) {

                case 40:
                case 98:
                case 50: //BT2
                    //keypad 2 or down arrow
                    console.log("DOWN");
                    if(!highlightedFolder) {
                        setHighlightedFolder(subFolders[0]['learningtopicid']);
                    } else {
                        //Cycle through all folders till we found current, then select next one
                        for(let i = 0; i < subFolders.length; i++) {
                            if(highlightedFolder === subFolders[i]['learningtopicid']) {
                                //If we are on the last one, just break
                                if(i === subFolders.length - 1) {
                                    break;
                                }

                                //Select the next one and break
                                setHighlightedFolder(subFolders[i + 1]['learningtopicid']);
                                scroll_to('sub_folder_' + subFolders[i + 1]['learningtopicid'],
                                    'learning_folder_selector');
                                break;
                            }
                        }
                    }
                    break;
                case 38:
                case 104:
                case 56: //BT8
                    //keypad 8 or up arrow
                    console.log("UP");
                    if(!highlightedFolder) {
                        setHighlightedFolder(subFolders[0]['learningtopicid']);
                    } else {
                        //Cycle through all folders till we found current, then select next one
                        for(let i = subFolders.length - 1; i >= 0; i--) {
                            if(highlightedFolder === subFolders[i]['learningtopicid']) {
                                //If we are on the first one, just break
                                if(i === 0) {
                                    break;
                                }

                                //Select the previous one and break
                                setHighlightedFolder(subFolders[i - 1]['learningtopicid']);
                                scroll_to('sub_folder_' + subFolders[i - 1]['learningtopicid'],
                                    'learning_folder_selector');
                                break;
                            }
                        }
                    }
                    break;
                case 39:
                case 102:
                case 54: //BT6
                    //keypad 6 or right arrow
                    console.log("RIGHT");
                    //If quiz questions, start quiz
                    if(questions.length > 0) {
                        show_quiz_selector();
                    } else {
                        //Create a Random Quiz of any 10 questions, but get the least well-known first

                    }
                    break;
                case 37:
                case 100:
                case 52: //BT4
                    //keypad 4 or left arrow
                    console.log("LEFT");
                    if(currentFolderId !== 0) {
                        select_folder(parentFolderId);
                    }
                    break;
                case 101:
                case 53: //BT5
                    //keypad 5 (select item)
                    setCurrentFolderId(highlightedFolder);
                    setHighlightedFolder(false);
                    break;
                case 103:
                case 55: //BT7
                    //Keypad 7 (start random quiz)
                    if(currentFolderId === 0) {
                        get_random_master_quiz();
                    }
                    break;
                // case 96:
                //     //keypad 0 (start quiz)
                //     load_quiz(currentFolderId, 'LEARN')
                //     break;


            }
        }
        document.addEventListener('keydown', handle_key_down);

        return function cleanup() {
            document.removeEventListener('keydown', handle_key_down);
        }
    })


    /* CONTROL FUNCTIONS */

    //Start the Quiz
    const start_quiz = () => {
        setCurrentQuestionIndex(0);
        setQuiz(true);
    }
    //Cancel the Quiz
    const cancel_quiz = () => {
        setQuiz(false);
        setQuizQuestionAttempts(0);
        setQuizQuestionsCorrect(0);
        setQuizQuestions(false);
        setQuizCompletedStats(false);
        setCurrentQuestionIndex(false);
        setQuizCompleted(false);
        setQuizScoreData([]);
        setCardSideUp(0);
        get_questions(currentFolderId);
        get_folder_stats(currentFolderId);
        setThumbsButtonState(0);
    }
    //Select a new folder (navigating through folder system)
    const select_folder = (new_folder_id) => {
        setCurrentFolderId(new_folder_id);
    }
    //Show the other side of a flashcard
    const flip_card = () => {
        setCardSideUp(1 - cardSideUp);
    }
    //Show the next card
    const next_card = () => {
        setCardSideUp(0);
        setThumbsButtonState(0);
        if(currentQuestionIndex > quizQuestions.length - 2) {
            setCurrentQuestionIndex(0);
        } else {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    }
    //Show the previous card
    const prev_card = () => {
        setCardSideUp(0);
        setThumbsButtonState(0);
        if(currentQuestionIndex === 0) {
            setCurrentQuestionIndex(quizQuestions.length - 1);
        } else {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    }
    //Tag Question Correct
    const tag_correct = () => {
        set_thumbs_state(1);
    }
    //Tag Question Incorrect
    const tag_incorrect = () => {
        set_thumbs_state(2);
    }
    //Thumbs Up/Thumbs Down Pressed
    const set_thumbs_state = (new_thumbs_state) => {
        //Create a clone of the quizscoredata object
        let localQuizScoreData = JSON.parse(JSON.stringify(quizScoreData));

        switch (new_thumbs_state) {
            case 0:
                if(thumbsButtonState === 1) {
                    //Canceling a Correct Mark
                    setQuizQuestionsCorrect(quizQuestionsCorrect - 1);
                    setQuizQuestionAttempts(quizQuestionAttempts - 1);
                    erase_attempt(quizQuestions[currentQuestionIndex]['learningquestionid']);
                } else if (thumbsButtonState === 2) {
                    //Cancelling an Incorrect Mark
                    setQuizQuestionAttempts(quizQuestionAttempts - 1);
                    erase_attempt(quizQuestions[currentQuestionIndex]['learningquestionid']);
                }
                setThumbsButtonState(0);
                localQuizScoreData[currentQuestionIndex] = null;
                break;
            case 1:
                if(thumbsButtonState === 0) {
                    //Recording a Correct Mark
                    setQuizQuestionsCorrect(quizQuestionsCorrect + 1);
                    setQuizQuestionAttempts(quizQuestionAttempts + 1);
                    setThumbsButtonState(1);
                    record_attempt(quizQuestions[currentQuestionIndex]['learningquestionid'], 1);
                    localQuizScoreData[currentQuestionIndex] = 'CORRECT';
                } else if (thumbsButtonState === 1) {
                    //Cancel a Correct Mark
                    setQuizQuestionsCorrect(quizQuestionsCorrect - 1);
                    setQuizQuestionAttempts(quizQuestionAttempts - 1);
                    setThumbsButtonState(0);
                    erase_attempt(quizQuestions[currentQuestionIndex]['learningquestionid']);
                    localQuizScoreData[currentQuestionIndex] = null;
                } else if (thumbsButtonState === 2) {
                    //Switching from Incorrect Mark to Correct
                    setQuizQuestionsCorrect(quizQuestionsCorrect + 1);
                    setThumbsButtonState(1);
                    switch_attempt(quizQuestions[currentQuestionIndex]['learningquestionid'], 1);
                    localQuizScoreData[currentQuestionIndex] = 'CORRECT';
                }
                break;
            case 2:
                if(thumbsButtonState === 0) {
                    //Recording an Incorrect Mark
                    setQuizQuestionAttempts(quizQuestionAttempts + 1);
                    setThumbsButtonState(2);
                    record_attempt(quizQuestions[currentQuestionIndex]['learningquestionid'], 0);
                    localQuizScoreData[currentQuestionIndex] = 'INCORRECT';
                } else if (thumbsButtonState === 1) {
                    //Switching from a Correct Mark to an Incorrect Mark
                    setQuizQuestionsCorrect(quizQuestionsCorrect - 1);
                    setThumbsButtonState(2);
                    switch_attempt(quizQuestions[currentQuestionIndex]['learningquestionid'], -1);
                    localQuizScoreData[currentQuestionIndex] = 'INCORRECT';
                } else if (thumbsButtonState === 2) {
                    //Cancel an Incorrect Mark
                    setQuizQuestionAttempts(quizQuestionAttempts - 1);
                    setThumbsButtonState(0);
                    erase_attempt(quizQuestions[currentQuestionIndex]['learningquestionid']);
                    localQuizScoreData[currentQuestionIndex] = null;
                }
                break;
        }

        //Update State
        setQuizScoreData(localQuizScoreData);

        //Check for Quiz Completeness
        if(localQuizScoreData.filter(Boolean).length >= quizQuestions.length) {
            end_quiz();
        }
    }
    //End Quiz Successfully
    const end_quiz = () => {
        setQuizCompleted(true);
        get_quiz_completed_stats();
    }
    //Switch between editing states
    const toggle_editing = () => {
        //If we are ending an edit, check to see if the folder name has changed and update
        if(editing) {
            if (editFolderName !== currentFolderName) {
                update_folder_name(currentFolderId, editFolderName);
                setEditFolderName('');
            }
        }
        setEditing(!editing);
    }
    //Show add new question modal
    const show_new_question_modal = () => {
        let nqModal = document.getElementById('new_question_modal');
        if(nqModal.style.display === 'block') {
            nqModal.style.display = 'none';
        } else {
            nqModal.style.display = 'block';
        }
    }
    //Cancel adding the new question and clear the form
    const cancel_add_new_question = () => {
        show_new_question_modal();
        setNewQuestionText('');
        setNewQuestionAnswer('');
    }
    //Process adding the new question and handle updates to GUI
    const process_add_new_question = () => {
        save_new_question(currentFolderId, newQuestionText, newQuestionAnswer);
    }
    //Show edit question modal
    const show_edit_question_modal = (questionId, questionText, answerText) => {
        setEditingQuestionId(questionId);
        setEditQuestionText(questionText);
        setEditAnswerText(answerText);
        let nqModal = document.getElementById('edit_question_modal');
        if(nqModal.style.display === 'block') {
            nqModal.style.display = 'none';
        } else {
            nqModal.style.display = 'block';
        }
    }
    //Cancel editing the question and clear the form
    const cancel_edit_question = () => {
        let nqModal = document.getElementById('edit_question_modal');
        if(nqModal.style.display === 'block') {
            nqModal.style.display = 'none';
        } else {
            nqModal.style.display = 'block';
        }
        setEditingQuestionId(false);
        setEditQuestionText('');
        setEditAnswerText('');
    }
    //Process adding the new question and handle updates to GUI
    const process_edit_question = () => {
        save_question_edits();
    }
    //Show the new subfolder modal
    const show_add_subfolder_modal = () => {
        document.getElementById('new_subfolder_modal').style.display = 'block';
    }
    //Call to add the new subfolder to the DB
    const process_add_subfolder = () => {
        insert_new_subfolder(currentFolderId, newSubfolderName);
    }
    //Cancel showing the new subfolder modal
    const cancel_add_subfolder = () => {
        document.getElementById('new_subfolder_modal').style.display = 'none';
        setNewSubfolderName('');
    }
    //Initiate "Are You Sure" for deleting a question
    const delete_question_are_you_sure = (question_id, question_text) => {
        setThingToDeleteString(question_text);
        setDeleteQuestionId(question_id);
        document.getElementById('delete_modal').style.display = 'block';
    }
    //Perform safety checks (is folder empty) and ask are you sure before deleting a folder
    const delete_folder_initiation = (folder_id, folder_name) => {
        setDeleteFolderId(folder_id);
        setDeleteFolderName(folder_name);
        check_for_unempty_folder(folder_id);
    }
    //Initiate the actual deletion of the question
    const process_deletion = () => {
        delete_question(deleteQuestionId);
    }
    //Cancel the deletion of a question
    const cancel_deletion = () => {
        setDeleteQuestionId(false);
        document.getElementById('delete_modal').style.display = 'none';
    }
    //Cancel the deletion of a folder
    const cancel_folder_deletion = () => {
        setDeleteFolderId(false);
        setDeleteFolderName('');
        document.getElementById('delete_folder_modal').style.display = 'none';
    }
    //Initiate the actual deletion of the folder
    const process_folder_deletion = () => {
        delete_folder(deleteFolderId);
    }
    //Show the quiz options selector (before starting quiz)
    const show_quiz_selector = () => {
        document.getElementById('quiz_selector_modal').style.display = 'block';
    }
    //Cancel the quiz start and hide the selector (before actually starting)
    const cancel_quiz_selection = () => {
        document.getElementById('quiz_selector_modal').style.display = 'none';
    }
    //Load a quiz with specifications
    const load_quiz = (folder_id, quiz_type) => {
        get_quiz(folder_id, quiz_type);
    }


    /* DATA FUNCTIONS */

    //Load the sub folders when a new folder is selected
    const get_sub_folders = (folder_id) => {
        let url = 'Learning_Handler.php';
        let postData = {
            operation: 'GET_FOLDERS',
            folder_id: folder_id
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                setSubFolders(data['folders']);
                setParentFolderId(data['parent_id']);
                setCurrentFolderName(data['folder_name']);
            } else {
                setSubFolders(false);
                setParentFolderId(data['parent_id']);
                setCurrentFolderName(data['']);
            }
            if(editing) {
                setEditFolderName(data['folder_name'] ?? '');
            }
        });
    }

    //Load the questions for this particular folder
    const get_questions = (folder_id) => {
        let url = 'Learning_Handler.php';
        let postData = {
            operation: 'GET_QUESTIONS',
            folder_id: folder_id
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                setQuestions(data);
            } else {
                console.log(data);
                setQuestions(false);
            }
        });
    }

    //Load stats for one question
    const get_question_stats = (question_id) => {
        let url = 'Learning_Handler.php';
        let postData = {
            operation: 'GET_QUESTION_STATS',
            question_id: question_id
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                setQuestionStats(data);
            } else {
                setQuestionStats(false);
            }
        });
    }

    //Load stats for one folder
    const get_folder_stats = (folder_id) => {
        let url = 'Learning_Handler.php';
        let postData = {
            operation: 'GET_FOLDER_STATS',
            folder_id: folder_id
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                setFolderStats(data);
            } else {
                setFolderStats(false);
            }
        });
    }

    //Record a question attempt
    const record_attempt = (question_id, correctness_value) => {
        let url = 'Learning_Handler.php';
        let postData = {
            operation: 'RECORD_LEARNING_ATTEMPT',
            question_id: question_id,
            correctness_value: correctness_value
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                get_question_stats(question_id);
            } else {
                console.log('Failed to update learning record.');
            }
        });
    }

    //Erase a question attempt
    const erase_attempt = (question_id) => {
        let url = 'Learning_Handler.php';
        let postData = {
            operation: 'ERASE_LEARNING_ATTEMPT',
            question_id: question_id
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                get_question_stats(question_id);
            } else {
                console.log('Failed to update learning record.');
            }
        });
    }

    //Switch a score from correct to incorrect or vice-versa
    //Direction: 1 (from wrong to correct) -1 (from correct to incorrect)
    const switch_attempt = (question_id, direction) => {
        let url = 'Learning_Handler.php';
        let postData = {
            operation: 'SWITCH_LEARNING_ATTEMPT',
            question_id: question_id,
            direction: direction
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                get_question_stats(question_id);
            } else {
                console.log('Failed to update learning record.');
            }
        });
    }

    //Save a new question
    const save_new_question = (folder_id, question_text, answer_text) => {
        let url = 'Learning_Handler.php';
        let postData = {
            operation: 'ADD_NEW_QUESTION',
            folder_id: folder_id,
            question_text: question_text,
            answer_text: answer_text
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                setQuestions(data);
                cancel_add_new_question();
            } else {
                console.log('Failed to insert new question.');
            }
        });
    }

    //Update the question in the DB
    const save_question_edits = () => {
        let url = 'Learning_Handler.php';
        let postData = {
            operation: 'EDIT_QUESTION',
            question_id: editingQuestionId,
            question_text: editQuestionText,
            answer_text: editAnswerText,
            folder_id: currentFolderId
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                setQuestions(data);
                cancel_edit_question();
            } else {
                console.log('Failed to update question.');
            }
        });
    }

    //Update the folder name
    const update_folder_name = (folder_id, folder_name) => {
        console.log("Calling Update Folder Name");
        let url = 'Learning_Handler.php';
        let postData = {
            operation: 'UPDATE_FOLDER_NAME',
            folder_id: folder_id,
            folder_name: folder_name
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                setCurrentFolderName(folder_name);
            } else {
                alert('Failed to edit folder name.');
            }
        });
    }

    //Insert new Subfolder
    const insert_new_subfolder = (folder_id, new_folder_name) => {
        let url = 'Learning_Handler.php';
        let postData = {
            operation: 'INSERT_NEW_SUBFOLDER',
            folder_id: folder_id,
            new_folder_name: new_folder_name
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                cancel_add_subfolder();
                setSubFolders(data['folders']);
            } else {
                alert('Failed to insert new subfolder');
            }
        });
    }

    //Check for un-empty folders before deleting
    const check_for_unempty_folder = (folder_id) => {
        let url = 'Learning_Handler.php';
        let postData = {
            operation: 'CHECK_FOR_UNEMPTY_FOLDER',
            folder_id: folder_id
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                if(data['is_empty'] === true) {
                    //Show the are you Sure Modal
                    setIsFolderEmpty(true);
                    document.getElementById('delete_folder_modal').style.display = 'block';
                } else {
                    //Not empty, show the Are You Sure Modal with just the message, no delete option
                    setIsFolderEmpty(false);
                    document.getElementById('delete_folder_modal').style.display = 'block';
                }
            } else {
                alert('Error in determining whether the folder is empty. Cannot delete.');

            }
        });
    }

    //Delete Question from Database
    const delete_question = (question_id) => {
        let url = 'Learning_Handler.php';
        let postData = {
            operation: 'DELETE_QUESTION',
            question_id: question_id,
            folder_id: currentFolderId
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                cancel_deletion();
                setQuestions(data);
            } else {
                alert('Failed to delete question');
                alert(data);
            }
        });
    }

    //Delete a Folder from the Database
    const delete_folder = (folder_id) => {
        let url = 'Learning_Handler.php';
        let postData = {
            operation: 'DELETE_FOLDER',
            folder_id: folder_id,
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                cancel_folder_deletion();
                get_sub_folders(parentFolderId);
            } else {
                alert('Failed to delete folder');
                alert(data);
            }
        });
    }

    //Get a Quiz from the Database
    const get_quiz = (folder_id, quiz_type) => {
        let url = 'Learning_Handler.php';
        let postData = {
            operation: 'GET_QUIZ',
            folder_id: folder_id,
            quiz_type: quiz_type
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                setCardSideUp(0);
                setQuizQuestions(data);
                setQuizType(quiz_type);
            } else {
                alert('Failed to load quiz questions');
                alert(data);
            }
        });
    }

    //Get Random Quiz from All Questions
    const get_random_master_quiz = () => {
        let url = 'Learning_Handler.php';
        let postData = {
            operation: 'GET_RECURSIVE_QUIZ',
            folder_id: currentFolderId
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                console.log(data);
                setCardSideUp(0);
                setQuizQuestions(data);
                setQuizType('REVIEW');
            } else {
                alert('Failed to load quiz questions');
                alert(data);
            }
        });
    }

    //Get the data to display to the user on the results screen
    const get_quiz_completed_stats = () => {
        let url = 'Learning_Handler.php';
        let postData = {
            operation: 'GET_QUIZ_COMPLETED_STATS',
            quiz_questions: quizQuestions,
            quiz_type: quizType
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                setQuizCompletedStats(data);
            } else {
                console.log("Failed to load quiz results");
                setQuizCompletedStats(false);
            }
        });
    }

    //Scroll to an item in a list (subfolder, quiz question, etc)
    const scroll_to = (element_to_scroll_to_id, container) => {
        let top_position = document.getElementById(element_to_scroll_to_id).offsetTop - 330;
        console.log(top_position);
        document.getElementById(container).scrollTo(0, top_position);
    }

    /* RENDERING */
    if(props.authStatus) {

        //QUIZ VIEW
        if(quiz) {
            if(quizCompleted) {

                //Show the Results
                return (
                    <div className={Styles.ofb_learning_main_div}>
                        <div className={Styles.ofb_learning_top}>
                            <div className={Styles.ofb_learning_folder_name}>
                                Folder: {currentFolderName}
                            </div>
                        </div>
                        <div className={Styles.ofb_learning_quiz_report}>
                            <div>You Finished!</div>
                            <div className={Styles.ofb_learning_results_row}>
                                Total Score: {quizQuestionsCorrect}/{quizQuestionAttempts}&nbsp;
                                {quizQuestionAttempts != 0 ?
                                    Math.round(quizQuestionsCorrect / quizQuestionAttempts * 100) : ''}
                                {quizQuestionAttempts != 0 ? '%' : ''}
                            </div>
                            <div className={quizType === 'LEARN' ? Styles.ofb_learning_results_row: Styles.ofb_hide}>
                                {quizCompletedStats['newly_learned']} Newly Learned!
                            </div>
                            <div className={(quizType === 'REVIEW' || quizType === 'REVIEW_10') ?
                                Styles.ofb_learning_results_row : Styles.ofb_hide}>
                                {quizCompletedStats['freshly_reviewed']} Cleared from Review
                            </div>
                        </div>
                        <div className={Styles.ofb_learning_stats_row}></div>
                        <div className={Styles.ofb_learning_bottom}>
                            <button
                                className={Styles.ofb_learning_close_results_button}
                                onClick={cancel_quiz}
                            >Close Results</button>
                        </div>
                    </div>
                )
            } else {

                //Show the Quiz
                return (
                    <div className={Styles.ofb_learning_main_div}>
                        <div className={Styles.ofb_learning_top}>
                            <div className={Styles.ofb_learning_q_num}>{currentQuestionIndex + 1}/
                                {quizQuestions.length}</div>
                            <div className={Styles.ofb_learning_q_category}>{currentFolderName}</div>
                            <div className={Styles.ofb_learning_cancel_x}>
                                <button onClick={cancel_quiz} className={`w3-button`}>X</button>
                            </div>
                        </div>
                        <div className={Styles.ofb_learning_middle}>
                            <div
                                className={Styles.ofb_learning_question_textbox}
                                onClick={flip_card}
                            >
                                {cardSideUp === 0 ? quizQuestions[currentQuestionIndex]['questiontext'] :
                                    quizQuestions[currentQuestionIndex]['answertext']
                                }
                            </div>
                        </div>
                        <div className={Styles.ofb_learning_stats_row}>
                            <div>{questionStats && questionStats['totalattempts'] ?
                                Math.round(questionStats['last_three_average']) + '%' : ''}</div>
                            <div>{quizQuestionsCorrect}/{quizQuestionAttempts}&nbsp;
                                {quizQuestionAttempts != 0 ?
                                    Math.round(quizQuestionsCorrect / quizQuestionAttempts * 100) : ''}
                                {quizQuestionAttempts != 0 ? '%' : ''}
                            </div>
                        </div>
                        <div className={Styles.ofb_learning_bottom}>
                            <div className={Styles.ofb_learning_bottom_button_group}>️
                                <button
                                    className={Styles.ofb_learning_next_card}
                                    onClick={prev_card}
                                >⬅</button>
                                <button
                                    className={Styles.ofb_learning_flip_card}
                                    onClick={flip_card}
                                >Flip<span
                                    className={Styles.ofb_learning_card_word}> Card</span></button>
                                <button
                                    className={Styles.ofb_learning_next_card}
                                    onClick={next_card}
                                >➡
                                </button>
                            </div>
                            <div className={Styles.ofb_learning_bottom_button_group}>
                                <button
                                    className={thumbsButtonState === 1 ? Styles.ofb_learning_thumbs_pressed :
                                        Styles.ofb_learning_thumbs}
                                    onClick={tag_correct}
                                >👍
                                </button>
                                <button
                                    className={thumbsButtonState === 2 ? Styles.ofb_learning_thumbs_pressed :
                                        Styles.ofb_learning_thumbs}
                                    onClick={tag_incorrect}
                                >👎
                                </button>
                            </div>
                            <div className={Styles.ofb_learning_bottom_button_group}>
                                <button className={Styles.ofb_learning_font_size}>+</button>
                                <button className={Styles.ofb_learning_font_size}>-</button>
                            </div>
                        </div>
                    </div>

                )
            }
        } else {
            //QUIZ SELECTOR VIEW (MAIN VIEW)
            return (
                <>
                    {whichKeyCode}
                    <div className={Styles.ofb_learning_main_view}>

                        <button
                            onClick={get_random_master_quiz}
                        >Random Quiz from All Questions</button>

                        <div className={Styles.ofb_learning_top}>
                            <div className={Styles.ofb_learning_folder_heading}>{currentFolderName ?
                                (editing ?
                                    <input
                                        type="text"
                                        size="20"
                                        value={editFolderName}
                                        onChange={(event) =>
                                            setEditFolderName(event.target.value)} /> :
                                    currentFolderName) : 'Folders'}
                            </div>
                            <div>
                                <button
                                    className={`${Styles.ofb_learning_edit_folder_button} 
                                        ${editing ? Styles.ofb_learning_edit_folder_button_editing : 
                                        Styles.ofb_learning_edit_folder_button_not_editing}`}
                                    onClick={toggle_editing}
                                >✏</button>
                            </div>
                            <div>
                                <button
                                    className={editing ? Styles.ofb_learning_add_question_button : Styles.ofb_hide}
                                    onClick={show_new_question_modal}
                                >
                                    +
                                </button>
                            </div>
                            <div>
                                <button
                                    className={editing ? Styles.ofb_learning_new_folder_button : Styles.ofb_hide}
                                    onClick={show_add_subfolder_modal}
                                >
                                    <img src={NewFolderIcon}  />
                                </button>
                            </div>
                            <div>
                                <button
                                    className={editing ? Styles.ofb_learning_new_folder_button : Styles.ofb_hide}
                                    onClick={() => delete_folder_initiation(currentFolderId, currentFolderName)}
                                ><img src={TrashCan} />️
                                </button>
                            </div>
                        </div>
                        <div
                            className={Styles.ofb_learning_folder_selector}
                            id="learning_folder_selector"
                        >
                            {
                                parentFolderId !== null && parentFolderId !== false &&
                                    <div
                                        className={Styles.ofb_learning_folder_name}
                                    >
                                        <button
                                            className={Styles.ofb_learning_folder_button}
                                            onClick={() =>
                                                select_folder(parentFolderId)}
                                        >..</button>
                                    </div>
                            }
                            {
                                subFolders.length > 0 && subFolders.map(item => (
                                    <div
                                        className={LearningStyles.folder_name}
                                        key={item['learningtopicid']}
                                        id={"sub_folder_" + item['learningtopicid']}
                                    >
                                        <button
                                            className={`${LearningStyles.folder_button} 
                                            ${item['learningtopicid'] === highlightedFolder ? 
                                                LearningStyles.folder_button_highlighted : ''}`}
                                            onClick={() =>
                                                select_folder(item['learningtopicid'])}
                                        >{item['learningtopic']}
                                            <span className={Styles.ofb_learning_folder_stats}>
                                                 &nbsp;[{item['sub_folder_count']}/{item['questions_count']}]
                                            </span>
                                        </button>
                                    </div>
                                ))
                            }
                            {
                                questions.length > 0 && questions.map((item, index) => (
                                    <div
                                        className={Styles.ofb_learning_question_preview}
                                        key={index}
                                    >
                                        <div className={Styles.ofb_learning_status_div}>
                                            {item['learned'] == 1 ?
                                                <span>✔</span>: (
                                                    item['learned'] == 2 ?
                                                <span>🔍</span> :
                                                        ''
                                                )
                                            }
                                        </div>
                                        <div className={Styles.ofb_learning_question_text_preview}>
                                            {item['questiontext'].replace(/(<([^>]+)>)/gi, " ")}
                                        </div>
                                        <div className={Styles.ofb_learning_question_stats_preview}>
                                            {item['stats'] ?
                                                '[' + item['stats']['totalattempts'] + ']' +
                                                Math.round(item['stats']['last_three_average']) + "%" : '-'}
                                        </div>
                                        <div className={editing ? Styles.ofb_learning_question_edit_button :
                                            Styles.ofb_hide}>
                                            <button
                                                className={Styles.ofb_learning_edit_question_button}
                                                onClick={() => show_edit_question_modal(item['learningquestionid'],
                                                    item['questiontext'], item['answertext'])}
                                            >✏</button>
                                        </div>
                                        <div className={editing ? Styles.ofb_learning_question_edit_button :
                                            Styles.ofb_hide}>
                                            <button
                                                className={editing ? Styles.ofb_learning_new_folder_button :
                                                    Styles.ofb_hide}
                                                onClick={() => delete_question_are_you_sure(item['learningquestionid'],
                                                    item['questiontext'])}
                                            ><img src={TrashCanBlack} />️
                                            </button>
                                        </div>
                                    </div>

                                ))
                            }
                        </div>
                        <div className={questions ? Styles.ofb_learning_folder_stats_row : Styles.ofb_hide}>
                            {folderStats ? ' Learned: ' + folderStats['total_learned'] : ''}
                            {folderStats ? ' Review: ' + folderStats['total_needing_review'] : ''}
                            {folderStats ? ' Total: ' + folderStats['total'] : ''}
                            {folderStats ? ' - ' + folderStats['recent_percent'] : ''}
                        </div>
                        <div className={questions ? Styles.ofb_learning_practice_button_div : Styles.ofb_hide}>
                            <button onClick={show_quiz_selector} className={'w3-button w3-black'}>Practice!</button>
                        </div>
                    </div>
                    <div id="new_question_modal" className="w3-modal">
                        <div className='w3-modal-content w3-animate-zoom'>
                            <header className="w3-container w3-red">
                                &nbsp;
                            </header>
                            <div className={Styles.ofb_modal_body}>
                                <div>Add new question to folder:</div>
                                <div>{currentFolderName}</div>
                                <div>
                                    <textarea
                                        id="new_question_text"
                                        placeholder="New Question Text"
                                        className={Styles.ofb_learning_new_question_textarea}
                                        value={newQuestionText}
                                        onChange={event => setNewQuestionText(event.target.value)}
                                        />
                                </div>
                                <div>
                                    <textarea
                                        id="new_question_answer"
                                        placeholder="New Question Answer"
                                        className={Styles.ofb_learning_new_question_textarea}
                                        value={newQuestionAnswer}
                                        onChange={event => setNewQuestionAnswer(event.target.value)}
                                        />
                                </div>
                            </div>
                            <footer className="w3-container w3-red">
                                <div>
                                    <button
                                        type='button'
                                        className={`${Styles.ofb_button} ${Styles.ofb_black}`}
                                        onClick={process_add_new_question}
                                    >Add Question</button>
                                    <button
                                        type='button'
                                        className={`${Styles.ofb_button} ${Styles.ofb_black}`}
                                        onClick={cancel_add_new_question}
                                    >Cancel</button>
                                </div>
                            </footer>

                        </div>
                    </div>
                    <div id="edit_question_modal" className="w3-modal">
                        <div className='w3-modal-content w3-animate-zoom'>
                            <header className="w3-container w3-red">
                                &nbsp;
                            </header>
                            <div className={Styles.ofb_modal_body}>
                                <div>Edit question from:</div>
                                <div>{currentFolderName}</div>
                                <div>
                                    <textarea
                                        id="edit_question_text"
                                        className={Styles.ofb_learning_new_question_textarea}
                                        value={editQuestionText}
                                        onChange={event => setEditQuestionText(event.target.value)}
                                    />
                                </div>
                                <div>
                                    <textarea
                                        id="edit_question_answer"
                                        className={Styles.ofb_learning_new_question_textarea}
                                        value={editAnswerText}
                                        onChange={event => setEditAnswerText(event.target.value)}
                                    />
                                </div>
                            </div>
                            <footer className="w3-container w3-red">
                                <div>
                                    <button
                                        type='button'
                                        className={`${Styles.ofb_button} ${Styles.ofb_black}`}
                                        onClick={process_edit_question}
                                    >Update Question</button>
                                    <button
                                        type='button'
                                        className={`${Styles.ofb_button} ${Styles.ofb_black}`}
                                        onClick={cancel_edit_question}
                                    >Cancel</button>
                                </div>
                            </footer>

                        </div>
                    </div>
                    <div id="new_subfolder_modal" className="w3-modal">
                        <div className='w3-modal-content w3-animate-zoom'>
                            <header className="w3-container w3-red">
                                &nbsp;
                            </header>
                            <div className={Styles.ofb_modal_body}>
                                <div>Create subfolder for:</div>
                                <div>{currentFolderName}</div>
                                <div>
                                    <input
                                        type="text"
                                        className={Styles.ofb_learning_new_subfolder_input}
                                        value={newSubfolderName}
                                        onChange={event => setNewSubfolderName(event.target.value)}
                                    />
                                </div>
                            </div>
                            <footer className="w3-container w3-red">
                                <div>
                                    <button
                                        type='button'
                                        className={`${Styles.ofb_button} ${Styles.ofb_black}`}
                                        onClick={process_add_subfolder}
                                    >Add Subfolder</button>
                                    <button
                                        type='button'
                                        className={`${Styles.ofb_button} ${Styles.ofb_black}`}
                                        onClick={cancel_add_subfolder}
                                    >Cancel</button>
                                </div>
                            </footer>

                        </div>
                    </div>
                    <div id="delete_modal" className="w3-modal">
                        <div className='w3-modal-content w3-animate-zoom'>
                            <header className="w3-container w3-red">
                                &nbsp;
                            </header>
                            <div className={Styles.ofb_modal_body}>
                                <div>Are you sure you want to delete:</div>
                                <div className={Styles.ofb_learning_thing_to_delete}>{thingToDeleteString}</div>
                                <div>
                                    All your progress records related to this question will also be deleted.
                                </div>
                            </div>
                            <footer className="w3-container w3-red">
                                <div>
                                    <button
                                        type='button'
                                        className={`${Styles.ofb_button} ${Styles.ofb_black}`}
                                        onClick={process_deletion}
                                    >Delete</button>
                                    <button
                                        type='button'
                                        className={`${Styles.ofb_button} ${Styles.ofb_black}`}
                                        onClick={cancel_deletion}
                                    >Cancel</button>
                                </div>
                            </footer>

                        </div>
                    </div>
                    <div id="delete_folder_modal" className="w3-modal">
                        <div className='w3-modal-content w3-animate-zoom'>
                            <header className="w3-container w3-red">
                                &nbsp;
                            </header>
                            <div className={Styles.ofb_modal_body}>
                                <div>{isFolderEmpty ? "Are you sure you want to delete the folder" : "The folder"}</div>
                                <div className={Styles.ofb_learning_thing_to_delete}>
                                    {deleteFolderName}
                                </div>
                                <div>
                                    {isFolderEmpty ? "" :
                                        " is not empty and cannot be deleted. You must delete all questions and " +
                                        "subfolders before " +
                                        "deleting."}
                                </div>
                            </div>
                            <footer className="w3-container w3-red">
                                <div>
                                    {isFolderEmpty ?
                                        <button
                                            type='button'
                                            className={`${Styles.ofb_button} ${Styles.ofb_black}`}
                                            onClick={process_folder_deletion}
                                        >Delete</button> : ''
                                    }
                                    <button
                                        type='button'
                                        className={`${Styles.ofb_button} ${Styles.ofb_black}`}
                                        onClick={cancel_folder_deletion}
                                    >Cancel</button>
                                </div>
                            </footer>

                        </div>
                    </div>
                    <div id="quiz_selector_modal" className="w3-modal">
                        <div className='w3-modal-content w3-animate-zoom'>
                            <header className="w3-container w3-red">
                                &nbsp;
                            </header>
                            <div className={Styles.ofb_modal_body}>
                                <div>Quiz Options for</div>
                                <div className={Styles.ofb_learning_quiz_selector}>{currentFolderName}</div>
                                <div>
                                    <button
                                        className={Styles.ofb_button}
                                        disabled={!folderStats['total_not_learned']}
                                        onClick={() => load_quiz(currentFolderId, 'LEARN')}
                                    >
                                        Learn [{folderStats['total_not_learned']}]
                                    </button>
                                    <button
                                        className={Styles.ofb_button}
                                        disabled={!folderStats['total_needing_review']}
                                        onClick={() => load_quiz(currentFolderId, 'REVIEW')}
                                    >
                                        Review Needed [{folderStats['total_needing_review']}]
                                    </button>
                                    <button
                                        className={Styles.ofb_button}
                                        disabled={!folderStats['total_learned']}
                                        onClick={() => load_quiz(currentFolderId, 'REVIEW_10')}
                                    >
                                        Review Any 10
                                    </button>
                                </div>
                            </div>
                            <footer className="w3-container w3-red">
                                <div>
                                    <button
                                        type='button'
                                        className={`${Styles.ofb_button} ${Styles.ofb_black}`}
                                        onClick={cancel_quiz_selection}
                                    >Cancel</button>
                                </div>
                            </footer>

                        </div>
                    </div>
                </>
            );
        }
    } else {
        return null;
    }
}

export default Learning;