import FStyles from "../styles/finance.module.css";
import GenStyles from "../styles/ofb_gen_styles.module.css";
import React, {useEffect, useState} from "react";
import {talk} from "../ofb_util";
import {useSearchParams} from "react-router-dom";

const ReconciliationReport = (props) => {
    //STATE DATA
    //const [accountId, setAccountId] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [reconciliationReportId, setReconciliationReportId] = useState(searchParams.get("rid"));
    const [accountName, setAccountName] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [reportData, setReportData] = useState(false);
    const [reportCompletedDate, setReportCompletedDate] = useState(false);
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [totalIncome, setTotalIncome] = useState(0);
    const [lastStatementBalance, setLastStatementBalance] = useState('');
    const [statementEndingBalance, setStatementEndingBalance] = useState('');
    const [statementDate, setStatementDate] = useState('');

    //USE EFFECTS
    useEffect(() => {
        if(reconciliationReportId) get_reconciliation_report();
    }, [reconciliationReportId]);


    //AJAX FUNCTIONS
    const get_reconciliation_report = () => {
        if(reportData) {
            return;
        }
        let postData = {
            reconciliation_report_id: reconciliationReportId
        }
        console.log(postData);
        talk(
            "Finance_Handler.php",
            "GET_RECONCILIATION_REPORT",
            postData,
            get_reconciliation_report_handler
        )
    }
    const get_reconciliation_report_handler = (operation, sent_data, return_data) => {
        if(return_data['ERROR']) {
            console.log(return_data);
        } else {
            console.log(return_data);
            setLastStatementBalance(cents_to_dollars(return_data['REPORT_INFO']['last_statement_balance']));
            setStatementEndingBalance(cents_to_dollars(return_data['REPORT_INFO']['closing_balance']));
            setStatementDate(return_data['REPORT_INFO']['reconciliation_date']);
            setReportCompletedDate(return_data['REPORT_INFO']['report_completed_date']);
            //setAccountId(return_data['ACCOUNT']['account_id']);
            setAccountName(return_data['ACCOUNT']['account_name']);

            let local_transactions = return_data['TRANSACTIONS'];
            let local_income = 0;
            let local_expenses = 0;

            for(let i = 0; i < local_transactions.length; i++) {
                local_transactions[i]['reconciliation_status'] = 'R';
                let amount = sum_transasction_parts(local_transactions[i]);
                local_transactions[i]['total'] = amount;
                if(amount > 0) {
                    local_income += amount;
                } else {
                    local_expenses += amount;
                }
            }
            setTotalIncome(local_income);
            setTotalExpenses(local_expenses);
            setTransactions(local_transactions);

            setReportData(return_data['REPORT_INFO']);
            setTransactions(return_data['TRANSACTIONS']);
        }
    }



    //OTHER FUNCTIONS

    const sum_transasction_parts = (transaction) => {
        let amount = 0;
        for(let i = 0; i < transaction.transaction_parts.length; i++) {
            amount += transaction.transaction_parts[i].amount;
        }
        return amount;
    }

    const cents_to_dollars = (cents) => {
        return cents / 100
    }


    //RENDER
    if(props.authStatus) {
        return (
            <div>
                <h1>Reconciliation Report</h1>
                <div>Account: <span className={GenStyles.font_18}>{accountName}</span></div>
                <div className={FStyles.reconciliation_main_div}>
                    <div>
                        Last Statement Balance: <span className={GenStyles.bold}>${lastStatementBalance}</span>
                    </div>
                    <div>
                        Statement Ending Balance: <span className={GenStyles.bold}>${statementEndingBalance}</span>
                    </div>
                    <div>
                        Statement Date: <span className={GenStyles.bold}>{statementDate}</span>
                    </div>
                    <div>
                        Reconciliation Completed on: <span className={GenStyles.bold}>{reportCompletedDate}</span>
                    </div>
                    <div className={FStyles.reconciliation_balances_div}>
                        <div className={FStyles.sub_total_div}>
                            Total Expenses: <span className={GenStyles.bold}>${cents_to_dollars(totalExpenses)}</span>
                        </div>
                        <div className={FStyles.sub_total_div}>
                            Total Income: <span className={GenStyles.bold}>${cents_to_dollars(totalIncome)}</span>
                        </div>

                    </div>
                    <div className={FStyles.reconciliation_table_div}>
                        <table>
                            <tbody>
                            {transactions.length > 0 && transactions.map((item, index) => (
                                <tr key={'trans_' + index} className={FStyles.reconciliation_row}>
                                    <td>{item['reconciliation_status']}</td>
                                    <td>{item['transaction_date']}</td>
                                    <td>{item['payee_name']}</td>
                                    <td className={item['total'] < 0 ? FStyles.red_cell : ''}>
                                        {item['total'] < 0 ? '-' : ''}${Math.abs(item['total'] / 100)}
                                    </td>
                                </tr>
                            ))}
                            {transactions.length === 0 ? <tr>
                                <td>Loading</td>
                            </tr> : <tr>
                                <td></td>
                            </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    } else {
        return <div></div>
    }
}

export default ReconciliationReport;