import React from "react";
import Styles from "../styles/ofbstyles.module.css";

const TodoActiveSimpleSubitem = (props) => {

    return (
        <>
        <div
            className={Styles.ofb_todo_simple_item}
            style={!props.active_only ? {marginLeft: (props.data['depth'] ?? 0) * 20} : {}}
            onClick={() => props.set_selected_item(props.data)}
        >
            {props.data['completed'] ? '✔' : '○'}&nbsp;
            <span className={props.data['completed'] ? Styles.ofb_strike : ''}>
				{(props.data['duedate'] < props.today) ? '🚩 ' : ''}{props.data['isproject'] ? '🚧 ' : ''}
                <span className={(props.data['duedate'] === props.today && !props.data['urgency']) ?
                    Styles.ofb_light_yellow : (props.data['urgency'] ? Styles.ofb_red : '')}>
                    <span
                        className={!props.data['is_active'] && props.deemphasize_inactive ? Styles.ofb_deephasized_text : ''}
                    >{props.data['title']}</span>
                </span>
            </span>
        </div>
            {!props.active_only && props.data['sub_items'] && props.data['sub_items'].length > 0 && props.data['sub_items'].map((subitem, subitem_index) => (
                <TodoActiveSimpleSubitem
                    key={subitem['todoid']}
                    data={subitem}
                    set_selected_item={props.set_selected_item}
                    today={props.today}
                    deemphasize_inactive={props.deemphasize_inactive}
                />
            ))}
        </>
    )
}
export default TodoActiveSimpleSubitem;
