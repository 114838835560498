import React, {useEffect, useState} from "react";
import {load_fetch} from "./ofb_util";
import './styles/calendar.css';
import {format} from 'date-fns'
import * as ReactDOM from 'react-dom';
import trash_icon from './images/trash_can.png';


const Calendar = (props) => {
    const [_month_year, setMonthYear] = useState({
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
    });
    const [calendarData, setCalendarData] = useState(false);

    const loadCalendar = () => {
        //Ignore load attempts if the month/year isn't set yet
        if (_month_year['month'] < 1 || _month_year['month'] > 12 || _month_year['month'] === undefined) {
            return;
        }

        let url = 'calendar_handler.php';
        if (_month_year['month'] != null && _month_year['year'] != null) {
            url += '?month=' + _month_year['month'] + '&year=' + _month_year['year'];
        }
        let postData = {
            operation: 'GET_ITEMS',
        }
        load_fetch(url, postData, myCallback);
    }
    const fillInCalendarData = (calData) => {
        if(!document.getElementById('day_of_week_labels')) {
            console.log()
            return;
        }
        if (calendarData['weekdays_header']) {
            document.getElementById('day_of_week_labels').innerHTML = calData['weekdays_header'];
        }
        //if (calendarData['date_cells_html']) {
        //document.getElementById('date_cells_html').innerHTML = calData['date_cells_html'];
        //}
        if (calendarData['year_month_header']) {
            document.getElementById('year_month_header').innerHTML = calData['year_month_header'];
        }
    };
    const loadPreviousMonth = () => {
        let local_month = _month_year['month'] - 1;
        let local_year = _month_year['year'];

        if (local_month === 0) {
            local_month = 12;
            local_year--;
        }
        setMonthYear({"month": local_month, "year": local_year});
    }
    const loadNextMonth = () => {
        let local_month = _month_year['month'] + 1;
        let local_year = _month_year['year'];

        if (local_month === 13) {
            local_month = 1;
            local_year++;
        }
        setMonthYear({"month": local_month, "year": local_year});
    }
    useEffect(() => {
        loadCalendar();
    }, [_month_year]);

    useEffect(() => {
        if(!props.authStatus) {
            setCalendarData(false);
        } else {
            loadCalendar();
        }
    }, [props.authStatus]);
    useEffect(() => {
        if(props.authStatus) {
            fillInCalendarData(calendarData);
        }
        console.log(calendarData);
    }, [calendarData]);
    const extract_and_format_time = (date_and_time_string) => {
        let time_string = date_and_time_string.substring(11, 18);
        let time_parts = time_string.split(':');
        let hour = Number(time_parts[0]);
        let am_pm;
        if(hour > 12) {
            am_pm = "pm";
        } else {
            am_pm = "am";
        }
        if(hour > 12) {
            hour -= 12;
        }
        if(hour === 0) {
            hour = 12;
        }
        return hour + ":" + time_parts[1] + am_pm;

    }
    const populate_show_more_window = (props) => {
        //Get the date string YYYY-MM-DD
        const event_date = _month_year['year'] + "-"
            + _month_year['month'].toString().padStart(2, "0") + "-"
            + (props['text']).toString().padStart(2, "0");

        //Add the date string to the modal to be reused with the add_new_event
        document.getElementById('new_event_date').value = event_date;

        //Date Header
        const event_date_obj = new Date(_month_year['year'], (_month_year['month'] - 1), props['text']);
        document.getElementById('show_more_header_date').innerText =
            format(event_date_obj, 'EEE, LLL. do, y');

        //Populate it with events to view/edit/delete if there are any
        if(props['events'].length > 0) {
            //Cycle through events and get a subset of the ones (if any) that are for this date
            let events_this_date = [];
            for(let i = 0; i < props['events'].length; i++) {
                //Compare the event date to the currently selected date
                if(event_date === props['events'][i]['dateandtime'].substring(0, 10)) {
                    events_this_date.push(props['events'][i]);
                }
            }
            const child_events = events_this_date.map((item) => (
                <div key={item['eventid'] + '_modal_event_key'} id={item['eventid'] + '_modal_event_holder'}>
                    <div id={item['eventid'] + '_modal_event'}
                         className="calendar_modal_event_item"
                         onClick={() => toggle_modal_details(item['eventid'])}>
                        {(item['dateandtime'].substring(11, 19) === '00:00:00' || item['dateandtime'].length === 10 ? <></> :<span className="calendar_time_span">[<span>{extract_and_format_time(item['dateandtime'])}</span>]&nbsp;&nbsp;</span>)}
                        {item['title']} {item['details'] ? <span className="calendar_click_for_details">(click for details)</span> : <></>}
                    </div>
                    <div id={item['eventid'] + '_modal_event_details'}
                         className="calendar_modal_event_details w3-hide">
                        <div>
                            <button className="w3-button" onClick={() => delete_event(item['eventid'])}>
                                <img src={trash_icon} alt="trash can delete button" />
                            </button>
                        </div>
                        <div>
                            {item['details']}
                        </div>
                    </div>
                </div>
            ));
            const modal_events = React.createElement("div", {className: "calendar_events", id:"modal_calendar_events"},
                child_events);

            //Add the current events
            ReactDOM.render(modal_events, document.getElementById('modal_events_holder'));
        } else {
            //No events... add an element to say so
            const modal_no_events = React.createElement("div", {className: "calendar_events"},
                "no events");
            ReactDOM.render(modal_no_events, document.getElementById('modal_events_holder'));
        }
    }

    const showMoreWindow = (props) => {
        //Display the Modal
        document.getElementById('more_modal').style.display = 'block';
        populate_show_more_window(props);
    }
    const delete_event = (props) => {
        let url = 'calendar_handler.php';
        let postData = {
            operation: 'DELETE_EVENT',
            eventid: props
        }
        load_fetch(url, postData, delete_event_callback);
    }
    const delete_event_callback = (data) => {
        if (data && "ERROR" in data) {
            console.log("ERROR: FAILED TO DELETE ITEM");
            console.log("ERROR MESSAGE: " + data['ERROR']);
        } else if (data) {
            let event_id = data['eventid'];
            let modal_events = document.getElementById('modal_calendar_events');
            let me_children = modal_events.children;
            for(let i = 0; i < me_children.length; i++) {
                if(me_children[i]['id'] === event_id + "_modal_event_holder") {
                    modal_events.removeChild(me_children[i]);
                    break;
                }
            }

            //Remove item from the events box by reloading the calendar
            loadCalendar();

        } else {
            console.log("Should return data, but didn't. In insert_event_callback.");
        }
    }
    const toggle_modal_details = (props) => {
        let el = document.getElementById(props + '_modal_event_details');
        el.classList.toggle('w3-hide');
    }
    const hideMoreWindow = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById('modal_events_holder'));
        document.getElementById('more_modal').style.display = 'none';
    }

    //REUSABLE OBJECTS TO RENDER (Date cells, events list, etc)
    const date_cells = (cells_info) => {
        if(!cells_info) {
            return;
        }
        return <>
            {cells_info.map(cell => (
                date_cell(cell)
            ))}
            </>
    }
    const date_cell = (cell_info) => {
        //console.log("CELL INFO:");
        //console.log(cell_info);
        let classNames = "date_cell";
        if(cell_info['events'].length > 0) {
            classNames += " date_cell_events";
        }
        return <div
                    key={cell_info['index'] + "_cell"}
                    className={classNames}
                    id={'cell_' + cell_info['index']}
                    onClick={() => showMoreWindow(cell_info)}
                >
            {cell_info['text']}
        </div>
    }
    const events_list_item = (event_info) => {
        if(!event_info) {
            return;
        }
        return <li key={event_info['eventid'] + '_main_event_list_item_key'}>
            <span className="calendar_events_list_date">
                {event_info['dateandtime'].substring(5, 10).replace("-", "/")}
            </span> - {event_info['title']}&nbsp;&nbsp;
            {(event_info['dateandtime'].substring(11, 19) === '00:00:00' ? <></> : <span className="calendar_time_span">[<span>{extract_and_format_time(event_info['dateandtime'])}</span>]&nbsp;&nbsp;</span>)}
        </li>
    }
    const events_list = (events) => {
        if(!events || events.length === 0) {
            return <ul><li>
                No events this month
            </li></ul>
        } else {
            return <ul>
                {events.map(item => (
                    events_list_item(item)
                ))
                }
            </ul>
        }
    }

    const myCallback = (data) => {
        if (data && "ERROR" in data) {
           console.log("ERROR: FAILED TO DELETE ITEM");
           console.log(data);
        } else if (data) {
            if("error" in data) {
                console.log("No calendar data");
            } else {
                setCalendarData(data);
            }
        } else {
            console.log("Should return data, but isn't. In handleDeleteItem() in today_daily.js");
        }
    }
    const clear_add_new_form = () => {
        document.getElementById('new_event_title_input').value = '';
        document.getElementById('new_event_details_input').value = '';
        document.getElementById('new_event_time_picker').value = '';
    }
    const add_new_event = () => {
        let add_new_event_button = document.getElementById('add_new_event_button')
        if(add_new_event_button.innerHTML === 'Cancel') {
            add_new_event_button.innerHTML = 'Add New Event';

            //Clear Form
            clear_add_new_form();
        } else {
            add_new_event_button.innerHTML = 'Cancel';
        }
        document.getElementById('add_new_event_holder').classList.toggle('w3-hide');
    }
    const submit_new_event = () => {
        //Load the data to submit
        let title = document.getElementById('new_event_title_input').value;
        let details = document.getElementById('new_event_details_input').value;
        let new_date = document.getElementById('new_event_date').value;
        let new_time = document.getElementById('new_event_time_picker').value;

        let url = 'calendar_handler.php';
        let postData = {
            operation: 'INSERT_EVENT',
            title: title,
            details: details,
            new_date: new_date,
            new_time: new_time
        }
        load_fetch(url, postData, insert_event_callback);

    }
    const insert_event_callback = (data) => {
        if (data && "ERROR" in data) {
            console.log("ERROR: FAILED TO INSERT ITEM");
            console.log("ERROR MESSAGE: " + data['ERROR']);
        } else if (data) {
            let new_event = {
                dateandtime: data['dateandtime'],
                details: data['details'],
                eventid: data['eventid'],
                title: data['title'],
                userid: data['userid']
            }
            let old_cell_info = get_cell_info_from_date(data['dateandtime'].substring(8, 10))
            old_cell_info['events'].push(new_event);
            populate_show_more_window(old_cell_info);

            //Reload the calendar to update the Events box (and the red dates)
            loadCalendar();

            //Clear form entry
            clear_add_new_form();

        } else {
            console.log("Should return data, but didn't. In insert_event_callback.");

        }
    }
    const get_cell_info_from_date = (date_int) => {
        for(let i = 0; i < calendarData['date_cells_data'].length; i++) {
            if(Number(calendarData['date_cells_data'][i].index) === Number(date_int)) {
                return calendarData['date_cells_data'][i];
            }
        }
        return false;
    }


    //TODO: Change the "title" "year_month_header" to a month picker... style it nicely
    if(props.authStatus) {
        return <>
            <div id="calendar_and_events_holder">
                <div id="calendar_div">
                    <div id="calendar">
                        <div id="calendar_box" className="box">
                            <div className='header' id="calendar_navigation_header">
                                <button id="prev_button" className='prev' onClick={loadPreviousMonth}>Prev</button>
                                <div className='title' id="year_month_header">...loading...</div>
                                <button id="next_button" className='next' onClick={loadNextMonth}>Next</button>
                            </div>
                        </div>
                        <div className="box-content">
                            <ul className="label" id="day_of_week_labels"></ul>
                            <div className="clear"></div>
                            <div className="dates_holder" id="date_cells_html">
                                {date_cells(calendarData['date_cells_data'])}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="events_holder">
                    <div className="header">
                        Events
                    </div>
                    <div id="calendar_events">
                        {events_list(calendarData['events'])}
                    </div>
                </div>
            </div>
            <div id="more_modal" className="w3-modal">
                <div className="w3-modal-content">
                    <header className="w3-container w3-red">
                        <span onClick={hideMoreWindow} className="w3-button w3-display-topright">&times;</span>
                        <h3 id="show_more_header_date">DATE</h3>
                    </header>

                    <div className="w3-container" id="modal_events_holder">
                    </div>

                    <div className="w3-container w3-hide" id="add_new_event_holder">
                        <input type="text" className="w3-input" id="new_event_title_input" placeholder="Event Title" />
                        <textarea className="w3-input" placeholder="Event Details" id="new_event_details_input"></textarea>
                        <input type="hidden" id="new_event_date" />
                        <input type="time" id="new_event_time_picker" className="w3-input" />
                        <button className="w3-button w3-input" id="new_event_submit_button" onClick={submit_new_event}>Submit</button>
                    </div>

                    <footer className="w3-container w3-red">
                        <button className="w3-btn" id="add_new_event_button" onClick={add_new_event}>Add New Event</button>
                    </footer>
                </div>
            </div>
        </>
    } else {
        return <div></div>
    }
}
export default Calendar;
