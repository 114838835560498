import React, {useEffect, useRef, useState} from "react";
import {talk} from "../ofb_util.js";

const PulseSessionViewer = (props) => {

    const [sessionList, setSessionList] = useState(false);

    useEffect(() => {
        if(props.class_id && props.class_id != -1 && props.visible) {
            talk('Pulse_Handler.php', "GET_SESSION_LIST", {class_id: props.class_id}, handle_load_sessions);
        } else {
            setSessionList(false);
        }
    }, [props.class_id, props.visible]);

    const handle_load_sessions = (operation, sent_data, return_data) => {
        if(return_data['ERROR']) {
            setSessionList(false);
        } else {
            setSessionList(return_data);
        }
    }

    if(props.visible) {
        return (
            <div>
                <div>SESSION LIST</div>
                <div>
                    {sessionList && sessionList.map((item, index) => (
                        <div key={index}>{item['class_date']}</div>
                    ))}
                </div>
            </div>
        );
    } else {
        return <></>
    }

}

export default PulseSessionViewer;