import React, {useEffect, useState} from "react";
import {talk} from "../ofb_util.js";
import GameStyles from "../styles/games.module.css";

const Wobble = (props) => {
    const bones = [
        ['H', 'S', 'P', 'A', 'C', 'O'],
        ['T', 'S', 'T', 'I', 'Y', 'D'],
        ['L', 'Y', 'E', 'D', 'R', 'V'],
        ['X', 'R', 'D', 'I', 'E', 'L'],
        ['R', 'H', 'T', 'V', 'E', 'W'],
        ['J', 'B', 'O', 'O', 'B', 'A'],
        ['Z', 'H', 'R', 'L', 'N', 'N'],
        ['P', 'S', 'A', 'F', 'K', 'F'],
        ['E', 'A', 'G', 'A', 'N', 'E'],
        ['M', 'U', 'O', 'C', 'T', 'I'],
        ['Qu', 'U', 'M', 'H', 'I', 'N'],
        ['E', 'O', 'T', 'S', 'I', 'S'],
        ['R', 'T', 'T', 'E', 'Y', 'L'],
        ['E', 'E', 'S', 'U', 'I', 'N'],
        ['T', 'T', 'A', 'O', 'W', 'O'],
        ['E', 'G', 'N', 'W', 'E', 'H']
    ];
    const [timeRemaining, setTimeRemaining] = useState('');
    const [textAreaDisabled, setTextAreaDisabled] = useState(false);
    const [answerBoxData, setAnswerBoxData] = useState('');
    const [score, setScore] = useState(0);
    const maxTimer = 180;
    const [grid, setGrid] = useState(false);
    var startTime = new Date().getTime();

    let t = null;
    function update_timer() {
        let now = new Date().getTime();
        let distance = now - startTime;
        let seconds_elapsed = Math.floor(distance / 1000);
        let time_remaining = maxTimer - seconds_elapsed;

        if(time_remaining <= 0) {
            clearInterval(t);
            setTextAreaDisabled(true);
            setTimeRemaining("STOP!");
            return;
        }

        let minutes = Math.floor(time_remaining / 60);
        let seconds = (time_remaining % 60);
        let timer_value = 180 - seconds_elapsed;
        setTimeRemaining(minutes + ":" + seconds.toString().padStart(2, '0'));

        t = setTimeout(function(){ update_timer() }, 100);
    }

    useEffect(() => {
        //Build Grid
        let tGrid = [];
        for(let i = 0; i < 16; i++) {
            tGrid[i] = bones[i][roll_die()];
        }
        shuffle(tGrid);
        setGrid(tGrid);
        update_timer();
    }, []);
    useEffect(() => {
        console.log(answerBoxData);
    }, [answerBoxData])
    useEffect(() => {
        if(textAreaDisabled) {
            check_answers();
        }
    }, [textAreaDisabled])

    function roll_die() {
        return Math.floor(Math.random() * 6);
    }
    function shuffle(array) {
        let currentIndex = array.length;

        // While there remain elements to shuffle...
        while (currentIndex != 0) {

            // Pick a remaining element...
            let randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
    }
    const calculate_score = (data) => {
        let words = data.split('\n');
        let score = 0;
        for(let i = 0; i < words.length; i++) {
            //console.log(words[i].trim());
            let length = words[i].trim().length;
            switch(length) {
                case 0:
                case 1:
                case 2:
                    break;
                case 3:
                case 4:
                    score += 1;
                    break;
                case 5:
                    score += 2;
                    break;
                case 6:
                    score += 3;
                    break;
                default:
                    score += 5;
                    break;
            }
        }
        setScore(score);
    }
    const handle_answer_text_change = (event) => {
        setAnswerBoxData(event.target.value);
        calculate_score(event.target.value);
    }
    const check_answers = () => {
        console.log(answerBoxData);
        let postData = {
            answers_blob: answerBoxData
        }
        talk("games_handler.php", "CHECK_WOBBLE_ANSWERS", postData, check_answers_handler);
    }
    const check_answers_handler = (operation, sent_data, return_data) => {
        console.log(return_data);
    }
    // const import_dictionary = () => {
    //     let counter = 0;
    //     Object.keys(dictionary).every(function(key) {
    //         console.log(key + ' - ' + dictionary[key]);
    //         upload_dictionary_item({entry:key, definition: dictionary[key]});
    //         counter++;
    //         console.log(counter);
    //         return true;
    //     })
    // }
    // const upload_dictionary_item = (item) => {
    //     let postData = {
    //         entry: item['entry'],
    //         definition: item['definition']
    //     }
    //     talk("games_handler.php", "IMPORT_DICTIONARY_ENTRY", postData, upload_dictionary_entry_handler);
    // }
    // const upload_dictionary_entry_handler = (operation, sent_data, return_data) => {
    //     console.log(return_data);
    // }

    return <>
        <div className={GameStyles.clock}>
            <span className={timeRemaining === 'STOP!' ? GameStyles.clock_stopped : ''}>
                {timeRemaining}
            </span>
            <span className={GameStyles.score_span}>{score}</span>
        </div>
        <div className={GameStyles.wobble_game_main_row}>
            <div className={GameStyles.wobble_grid}>
                <div className={GameStyles.wobble_row}>
                    <div>{grid[0]}</div>
                    <div>{grid[1]}</div>
                    <div>{grid[2]}</div>
                    <div>{grid[3]}</div>
                </div>
                <div className={GameStyles.wobble_row}>
                    <div>{grid[4]}</div>
                    <div>{grid[5]}</div>
                    <div>{grid[6]}</div>
                    <div>{grid[7]}</div>
                </div>
                <div className={GameStyles.wobble_row}>
                    <div>{grid[8]}</div>
                    <div>{grid[9]}</div>
                    <div>{grid[10]}</div>
                    <div>{grid[11]}</div>
                </div>
                <div className={GameStyles.wobble_row}>
                    <div>{grid[12]}</div>
                    <div>{grid[13]}</div>
                    <div>{grid[14]}</div>
                    <div>{grid[15]}</div>
                </div>
            </div>
            <div className={GameStyles.wobble_answer_column}>
                <textarea
                    disabled={textAreaDisabled}
                    value={answerBoxData}
                    onChange={(event) => handle_answer_text_change(event)}
                ></textarea>
            </div>
        </div>
    </>
}

export default Wobble;