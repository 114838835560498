import TodoDailyItem from './todo_daily_item';
import GenStyles from "../styles/ofb_gen_styles.module.css";

const getTimeOfDayString = (timeOfDayInteger) => {
    switch(timeOfDayInteger) {
        case 0:
            return "Any Time of Day";
            break;
        case 1:
            return "Early Morning";
            break;
        case 2:
            return "Late Morning";
            break;
        case 3:
            return "Afternoon";
            break;
        case 4:
            return "Evening";
            break;
        case 5:
            return "Before Bed";
            break;
    }
}

const TodoDailyGroup = (props) => {
    return (
        <table className={`${GenStyles.table} ${props.editing ? "" : GenStyles.hoverable}`}>
            <thead>
            <tr className={`${GenStyles.black} ${GenStyles.font_white}`}>
                <th>{getTimeOfDayString(Number(props.group.timeOfDay))}</th>
            </tr>
            </thead>
            <tbody>
            {props.group.items.map((item) =>
                <TodoDailyItem
                    handleDeleteItem={props.handleDeleteItem}
                    key={item.dailyid}
                    editing={props.editing}
                    clickEventHandler={props.clickEventHandler}
                    item={item} />
            )}
            </tbody>

        </table>
    );
};

export default TodoDailyGroup;