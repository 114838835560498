import React, { useEffect } from 'react';
import Styles from "./styles/slide_show_header.module.css";

const SlideShowHeader = (props) => {

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "./js/slide_show_carousel.js";
        script.async = true;
        document.body.appendChild(script);
    });

    if(window.location.pathname !== "/pulse") {
        return (
            <>
                <div className="mySlides w3-display-container w3-center">
                    <img src="./images/planet.jpg" style={{width: "100%"}}/>
                    <div className="w3-display-bottommiddle w3-container w3-text-white w3-padding-32 w3-hide-small">
                        <h3>I have been</h3>
                        <p><b>crucified with Christ</b></p>
                    </div>
                </div>
                <div className="mySlides hiddenSlide w3-display-container w3-center">
                    <img src="./images/nebula.jpg" style={{width: "100%"}}/>
                    <div className="w3-display-bottommiddle w3-container w3-text-white w3-padding-32 w3-hide-small">
                        <h3>and I</h3>
                        <p><b>no longer live</b></p>
                    </div>
                </div>
                <div className="mySlides hiddenSlide w3-display-container w3-center">
                    <img src="./images/voyager.jpg" style={{width: "100%"}}/>
                    <div className="w3-display-bottommiddle w3-container w3-text-white w3-padding-32 w3-hide-small">
                        <h3>but Christ</h3>
                        <p><b>lives in me.</b></p>
                    </div>
                    <div className="w3-display-topleft w3-container w3-text-white w3-padding64">
                        <h2 className="w3-wide" id="page_title">OUT FROM BABEL</h2>
                        <p className="w3-opacity w3-wide"><i>Oratio et Obedientia</i></p>
                    </div>
                </div>
            </>
        );
    } else {
        return <div className={Styles.pulse_header}><h1>Pulse</h1></div>;
    }
};

export default SlideShowHeader;