import Styles from "../styles/ofbstyles.module.css";
import React, {useEffect, useState} from "react";
import CurrencyInput from "./Currency_Input";
import {format_currency} from "../ofb_util";
import {deformat_currency} from "../ofb_util";
import TrashCanIcon from "../images/trash_can.png";
import FormCategory from "./FormCategory";

const FormTransactionPart = (props) => {
    const [memo, setMemo] = useState(props.presetData['memo']);
    const [currencyValue, setCurrencyValue] = useState(props.presetData['amount']);
    const [category, setCategory] = useState(props.presetData['category']);

    /* useEffect(() => {
        setCurrencyValue(props.presetData['amount']);
    }, [props.presetData['amount']]); */
    useEffect(() => {
        setCurrencyValue(props.presetData['amount']);
        setMemo(props.presetData['memo']);
        setCategory(props.presetData['category']);
    }, [props.presetData]);

    const update_memo = (value) => {
        setMemo(value);
        let data = props.presetData;
        data['memo'] = value;
        props.update_transaction_part(props.name, data);
    }
    const update_amount = (value) => {
        setCurrencyValue(value);
        let data = props.presetData;
        data['amount'] = value;
        props.update_transaction_part(props.name, data);
    }
    const update_category = (value) => {
        setCategory(value);
        let data = props.presetData;
        data['category'] = value;
        props.update_transaction_part(props.name, data);
    }
    const delete_transaction_part = () => {
        props.delete_transaction_part(props.name);
    }
    if(props['only_one']) {
        return (
            <div className={Styles.ofb_transaction_part}>
                <div>
                    <FormCategory
                        name={props.name}
                        category={props.presetData['category']}
                        onChange={update_category}
                        options={props.categories}
                        category_add_new_handler={props.category_add_new_handler}
                    />
                </div>
            </div>
        )
    } else {
        return (
            <div className={Styles.ofb_transaction_part}>
                <div>
                    <FormCategory
                        name={props.name}
                        category={props.presetData['category']}
                        onChange={update_category}
                        options={props.categories}
                        category_add_new_handler={props.category_add_new_handler}
                    />
                </div>
                <div>
                    <input
                        type='text'
                        name='transaction_part_memo'
                        placeholder='mem'
                        value={memo}
                        onChange={event => update_memo(event.target.value)}
                        className={Styles.ofb_text_input}
                    />
                </div>
                <div>
                    <CurrencyInput
                        name={`transaction_part_amount_${props.name}`}
                        currency_value={props.presetData['amount']}
                        disabled={false}
                        changeHandler={amount => {
                            update_amount(amount);
                            props.update_total_handler(amount);
                        }}
                        placeholder='amt'
                        block_select={props.block_select}
                    />
                </div>
                <div>
                    <button
                        className={Styles.ofb_tpart_trash_button}
                        onClick={delete_transaction_part}
                    ><img src={TrashCanIcon} /></button>
                </div>
            </div>
        )
    }
}

export default FormTransactionPart;