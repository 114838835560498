import React from 'react';

const NavBlockItem = (props) => {
    return (
        <>
            <a
                href={props.url}
                className={`${props.classNames}  w3-bar-item w3-button w3-padding-large`}
                key={props.link_key}
                style={props.styles}
            >
                {props.link_name}
            </a>
        </>
    );
}

export default NavBlockItem;