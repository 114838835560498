import { useContext} from "react";
import { AuthContext } from "./auth_context";

const NavBarTopItem = (props) => {
    const [authStatus, setAuthStatus] = useContext(AuthContext);

    if((props.showIfUnauthenticated === true) || (authStatus === true)) {
        return (
            <a
                href={`/${props.linkPage}#${props.pageAnchor}`}
                className={props.classNames}>
                {props.linkName}
            </a>
        );
    } else {
        return <></>
    }
};

export default NavBarTopItem;