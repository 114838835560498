import FStyles from "../styles/finance.module.css";
import GenStyles from "../styles/ofb_gen_styles.module.css";
import React, {useEffect, useState} from "react";
import {format_currency, talk} from "../ofb_util";
import {useSearchParams} from "react-router-dom";
import AreYouSureModal from "../are_you_sure_modal";

//TODO: On parent accound ledger, display both the ongoing parent balance and subaccount balance (will need to modify
// the Transaction object to give it this extra field
//TODO: Display proper balances at the top of the ledger
//TODO: Display proper balances on the home page

//TODO: Add nav to reconciliation report
//TODO: Add nav to ledger page to view past reconciliation reports??
//TODO: Start using the reconciliation reports in ongoing balance calculations
//TODO: When I reload the data, it messily fails.
// Then, what does a finalization accomplish (updates the "base balance" of the account, along with a date?
// Do we read base account balance from the accounts table or from the most recent reconciliation?
// To save DB time on the constant getting of the base balance, we could write it into the accounts table at the same time
// DONE: Reconciliations in progress could be saved in a cell in the reconciliation report table... all IDS comma separated?
// DONE: last statement balance should be autofilled, I think.
// DONE: Think of a way to save a reconciliation in-progress before it is finalized
// DONE: Basic reconciliation math seems to work
// DONE: Change Difference so it is ZERO when finished instead of matching the statement ending balance
// DONE: How do we figure the most recent reconciliation? (probably from the reconciliation date).
// DONE: Code the reloading of a reconciliation that is in progress
// DONE: Code the deletion of a reconciliation in progress.
// DONE: Fix the conversion of reconcile_id to reconcile_status
// DONE: Code the finalization of a reconciliation


const ReconcileAccount = (props) => {
    //STATE DATA
    const [searchParams, setSearchParams] = useSearchParams();
    const [reconciliationReportId, setReconciliationReportId] = useState(false);
    const [accountId, setAccountId] = useState(searchParams.get("aid"));
    const [accountName, setAccountName] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [totalIncome, setTotalIncome] = useState(0);
    const [difference, setDifference] = useState(0);
    const [lastStatementBalance, setLastStatementBalance] = useState('');
    const [statementEndingBalance, setStatementEndingBalance] = useState('');
    const [statementDate, setStatementDate] = useState('');
    const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);

    //USE EFFECTS
    useEffect(() => {
        if(transactions.length <= 0) {
            get_unreconciled_transactions();
        }
    }, []);


    //AJAX FUNCTIONS
    const get_unreconciled_transactions = () => {
        let postData = {
            account_id: accountId
        };
        talk(
            "Finance_Handler.php",
            "GET_UNRECONCILED_TRANSACTIONS",
            postData,
            handle_get_unreconciled_transactions);
    }
    const handle_get_unreconciled_transactions = (operation, sent_data, return_data) => {
        console.log(return_data);
        if(!return_data['ERROR']) {
            //SAVE THE ACCOUNT INFO TO STATE
            if(return_data['ACCOUNT_INFO']) {
                setAccountName(return_data['ACCOUNT_INFO']['account_name']);
            }
            //SAVE LAST BALANCE TO STATE
            if(return_data['LAST_STATEMENT_BALANCE']) {
                setLastStatementBalance(cents_to_dollars(return_data['LAST_STATEMENT_BALANCE']));
            }
            //IF THERE ARE UNFINISHED REPORTS, PREP THAT DATA FIRST
            if(return_data['UNFINISHED_REPORTS']) {
                restore_unfinished_reconciliation(return_data['TRANSACTIONS'], return_data['UNFINISHED_REPORTS']);
            } else {
                //OTHERWISE, NEW RECONCILE, DISPLAY TRANSACTIONS
                if(return_data['TRANSACTIONS']) {
                    setTransactions(return_data['TRANSACTIONS']);
                }
            }

        }
    }
    const toggle_transaction_reconciliation = (transaction_id) => {
        let local_data = JSON.parse(JSON.stringify(transactions));
        let local_expenses_total = totalExpenses;
        let local_income_total = totalIncome;

        for(let i = 0; i < local_data.length; i++) {
            if(local_data[i]['transaction_id'] === transaction_id) {
                let amount = sum_transasction_parts(local_data[i]);
                if(local_data[i]['reconciliation_status'] !== 'R') {
                    local_data[i]['old_status'] = local_data[i]['reconciliation_status']
                    local_data[i]['reconciliation_status'] = 'R';
                    if(amount < 0) {
                        local_expenses_total += amount;
                    } else {
                        local_income_total += amount;
                    }
                } else {
                    local_data[i]['reconciliation_status'] = local_data[i]['old_status'];
                    if(amount < 0) {
                        local_expenses_total -= amount;
                    } else {
                        local_income_total -= amount;
                    }
                }
                break;
            }
        }
        setDifference(dollars_to_cents(lastStatementBalance) +
            local_expenses_total + local_income_total);
        setTotalIncome(local_income_total);
        setTotalExpenses(local_expenses_total);
        setTransactions(local_data);
        console.log(transaction_id);
    }
    const select_all_transactions = () => {
        let total = 0;
        let local_income = 0;
        let local_expenses = 0;
        let local_data = JSON.parse(JSON.stringify(transactions));
        for(let i = 0; i < transactions.length; i++) {
            local_data[i]['reconciliation_status'] = 'R';
            let amount = sum_transasction_parts(local_data[i]);
            if(amount > 0) {
                local_income += amount;
            } else {
                local_expenses += amount;
            }
        }
        setTotalIncome(local_income);
        setTotalExpenses(local_expenses);
        setDifference(dollars_to_cents(lastStatementBalance) + local_income + local_expenses)
        setTransactions(local_data);
    }
    const save_in_progress_reconciliation = () => {
        //Check for a statement date being entered
        if(!statementDate) {
            alert('Must enter a statement date before saving.');
            return;
        }

        let ids = [];
        for(let i = 0; i < transactions.length; i++) {
            if(transactions[i]['reconciliation_status'] === 'R') {
                ids.push(transactions[i]['transaction_id']);
            }
        }
        // console.log(ids);
        // console.log('kb: ' + ((new TextEncoder().encode(JSON.stringify(ids)).length) / 1024));

        let postData = {
            reconciliation_id: reconciliationReportId,
            account_id: accountId,
            ids: ids,
            reconciliation_date: statementDate,
            closing_balance: dollars_to_cents(statementEndingBalance)
        };

        console.log("POST DATA:");
        console.log(postData);

        talk(
            "Finance_Handler.php",
            "SAVE_RECONCILIATION_TEMPORARY",
            postData,
            save_in_progress_reconciliation_handler);
    }
    const save_in_progress_reconciliation_handler = (operation, sent_data, return_data) => {
        if(return_data['NEW_RECONCILIATION_ID']) {
            setReconciliationReportId(return_data['NEW_RECONCILIATION_ID']);
        }
        console.log(return_data);
    }
    const process_delete_unfinished_reconciliation_report = () => {
        let postData= {
            reconciliation_id: reconciliationReportId,
            account_id: accountId
        }
        talk(
            "Finance_Handler.php",
            "DELETE_UNFINISHED_RECONCILIATION_REPORT",
            postData,
            handle_process_delete_unfinished_reconciliation_report
        )
    }
    const handle_process_delete_unfinished_reconciliation_report = (operation, sent_data, return_data) => {
        if(return_data['ERROR']) {
            console.log(return_data);
            return;
        }

        handle_get_unreconciled_transactions(null, null, return_data);

        setShowAreYouSureModal(false);

    }
    const finalize_report = () => {
        if(!statementDate) {
            alert("Must enter a statement date.");
            return;
        }
        //Load up only the transactions that have become reconciled
        let t = [];
        for(let i = 0; i < transactions.length; i++) {
            if(transactions[i].reconciliation_status === 'R') {
                t.push(transactions[i]);
            }
        }

        //Add in other important data (date, closing balance, etc)
        let postData = {
            transactions:t,
            last_statement_balance: dollars_to_cents(lastStatementBalance),
            reconciliation_date: statementDate,
            account_id: accountId,
            closing_balance: dollars_to_cents(statementEndingBalance),
            reconciliation_id: reconciliationReportId
        }

        console.log(postData);

        //Send to server
        talk(
            "Finance_Handler.php",
            "FINALIZE_RECONCILIATION",
            postData,
            finalize_report_handler
        );
    }
    const finalize_report_handler = (operation, sent_data, return_data) => {
        console.log(return_data);
    }




    //OTHER FUNCTIONS
    const sum_transasction_parts = (transaction) => {
        let amount = 0;
        for(let i = 0; i < transaction.transaction_parts.length; i++) {
            amount += transaction.transaction_parts[i].amount;
        }
        return amount;
    }
    const dollars_to_cents = (dollars) => {
        return Math.round(dollars * 100);
    }
    const cents_to_dollars = (cents) => {
        return cents / 100
    }
    const restore_unfinished_reconciliation = (local_transactions, reconcile_data) => {
        setStatementEndingBalance(cents_to_dollars(reconcile_data['closing_balance']));
        setStatementDate(reconcile_data['reconciliation_date']);
        setReconciliationReportId(reconcile_data['reconciliation_id']);

        //Toggle individual transactions
        let total = 0;
        let local_income = 0;
        let local_expenses = 0;
        let ids = reconcile_data['in_progress_ids'].split(',');
        for(let i = 0; i < ids.length; i++) {
            ids[i] = parseInt(ids[i]);
        }

        for(let i = 0; i < local_transactions.length; i++) {
            if(ids.indexOf(local_transactions[i]['transaction_id']) !== -1) {
                local_transactions[i]['old_status'] = local_transactions[i]['reconciliation_status'];
                local_transactions[i]['reconciliation_status'] = 'R';

                let amount = sum_transasction_parts(local_transactions[i]);
                if(amount > 0) {
                    local_income += amount;
                } else {
                    local_expenses += amount;
                }
            }
        }
        setTotalIncome(local_income);
        setTotalExpenses(local_expenses);
        setDifference(dollars_to_cents(lastStatementBalance) + local_income + local_expenses)
        setTransactions(local_transactions);

    }
    const delete_unfinished_reconciliation_report = () => {
        setShowAreYouSureModal(true);
    }
    const cancel_delete_unfinished_reconciliation_report = () => {
        setShowAreYouSureModal(false);
    }


    //RENDER
    if(props.authStatus) {
        return (
            <div>
                <h1>Reconcile Account</h1>
                <div>{accountName} {reconciliationReportId}</div>
                <div className={FStyles.reconciliation_main_div}>
                    <div>Last Statement Balance: <input
                        type="text"
                        size="20"
                        value={lastStatementBalance}
                        onChange={(event) =>
                            setLastStatementBalance(event.target.value)}/></div>
                    <div>Statement Ending Balance: <input
                        type="text"
                        size="20"
                        value={statementEndingBalance}
                        onChange={(event) =>
                            setStatementEndingBalance(event.target.value)} /></div>
                    <div>Statement Date: <input
                        value={statementDate}
                        onChange={(event) => setStatementDate(event.target.value)}
                        type="date" />
                    </div>
                    <div className={FStyles.reconciliation_balances_div}>
                        <div className={FStyles.sub_total_div}>Total Expenses: ${cents_to_dollars(totalExpenses)}</div>
                        <div className={FStyles.sub_total_div}>Total Income: ${cents_to_dollars(totalIncome)}</div>
                        <div className={FStyles.sub_total_div}>Difference: ${cents_to_dollars(difference -
                            dollars_to_cents(statementEndingBalance))}</div>
                    </div>
                    <div className={FStyles.reconciliation_table_div}>
                        <button
                            className={reconciliationReportId ? FStyles.delete_reconciliation_button : GenStyles.hidden}
                            onClick={delete_unfinished_reconciliation_report}
                        >
                            Delete Reconciliation In Progress
                        </button>
                        <button onClick={select_all_transactions}>Select All Transactions</button>
                        <table>
                            <tbody>
                            {transactions.length > 0 && transactions.map((item, index) => (
                                <tr key={'trans_' + index} className={FStyles.reconciliation_row}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={item['reconciliation_status'] === 'R' ? 'checked' : ''}
                                            onChange={() =>
                                                toggle_transaction_reconciliation(item['transaction_id'])}
                                        />{item['reconciliation_status']}
                                    </td>
                                    <td>{item['transaction_date']}</td>
                                    <td>{item['payee_name']}</td>
                                    <td className={item['total'] < 0 ? FStyles.red_cell : ''}>
                                        {item['total'] < 0 ? '-' : ''}${Math.abs(item['total'] / 100)}
                                    </td>
                                </tr>
                            ))}
                            {transactions.length === 0 ? <tr>
                                <td>Loading</td>
                            </tr> : <tr>
                                <td></td>
                            </tr>}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <button onClick={save_in_progress_reconciliation}>Save Temp Data</button>
                        <button
                            disabled={difference -
                                dollars_to_cents(statementEndingBalance) === 0 ? false : 'disabled'}
                            onClick={finalize_report}
                        >Finalize Reconciliation Report</button>
                    </div>
                </div>
                <AreYouSureModal
                    visible={showAreYouSureModal}
                    mainText="Deleting Unfinished Reconciliation Report"
                    subText="This will reset the entire form"
                    yesButtonPressed={process_delete_unfinished_reconciliation_report}
                    noButtonPressed={cancel_delete_unfinished_reconciliation_report}
                />
            </div>
        )
    } else {
        return <div></div>
    }
}

export default ReconcileAccount;