import Styles from "../styles/ofbstyles.module.css";
import GenStyles from "../styles/ofb_gen_styles.module.css";
import FStyles from "../styles/finance.module.css";
import React, {useEffect, useState} from "react";
import Form_Transaction_Part from "./Form_Transaction_Part";
import Form_Transaction from "./Form_Transaction";
import {format_currency, load_fetch, talk} from "../ofb_util";
import ConfirmModal from "../ConfirmModal";
import confirmModal from "../ConfirmModal";
import Transaction from "./Transaction";
import EditTransactionModal from "./Edit_Transaction_Modal";
import SimpleEntry from "./SimpleEntry";
import {Link} from 'react-router-dom';
import ReconciliationReport from "./ReconciliationReport";

const Ledger = (props) => {
    //DATA
    const [showNewTransactionModal, setShowNewTranasctionModal] = useState(false);
    const [newTransactionErrorMessage, setNewTransactionErrorMessage] = useState('');
    const [confirmModalMessage, setConfirmModalMessage] = useState('');
    const [confirmModalData, setConfirmModalData] = useState({});
    const [newTransactionTotal, setNewTransactionTotal] = useState(0);
    const [newTransactionDate, setNewTransactionDate] = useState('');
    const [mostRecentDate, setMostRecentDate] = useState('');
    const [newTransactionType, setNewTransactionType] = useState('withdrawal');
    const [newTransactionPayee, setNewTransasctionPayee] = useState('');
    const [confirmedNewPayee, setConfirmedNewPayee] = useState('');
    const [confirmedNewCategory, setConfirmedNewCategory] = useState('');
    //TODO: Will need to rework this someday... you may have more than one new payee
    const [newTransactionMemo, setNewTransactionMemo] = useState('');
    const [newTransactionNumber, setNewTransactionNumber] = useState('');
    const [newTransactionReconciliationStatus, setNewTransactionReconciliationStatus] = useState('Not Reconciled');
    const [transactionPartsData, setTransactionPartsData] = useState([{
        category: '',
        amount: 0,
        memo: ''
    }]);
    let tpHighestIndex = -1;
    const [editingTransaction, setEditingTransaction] = useState(false);
    const [showReconciliationReports, setShowReconciliationReports] = useState(false);
    const [reconciliationReports, setReconciliationReports] = useState([]);

    //USE EFFECTS
    useEffect(() => {
    }, [transactionPartsData]);
    useEffect(() => {
        get_most_recent_transaction_date();
        get_reconciliation_reports_list();
    }, []);
    useEffect(() => {
        //Focus on Date Field
        if(showNewTransactionModal) {
            //document.getElementById('transaction_date').focus();
        }
    }, [showNewTransactionModal])
    useEffect(() => {
        if(confirmModalMessage.length > 0) {
            focus_on_modal_true_button();
        } else {
            if(confirmModalData['type'] === 'CONFIRM_PAYEE') {
                document.getElementById('transaction_amount').focus();
            } else if (confirmModalData['type'] === 'CONFIRM_CATEGORY') {
                document.getElementById('save_new_transaction_button').focus();
            }
        }
    }, [confirmModalMessage]);

    //AJAX FUNCTIONS
    const get_most_recent_transaction_date = () => {
        let url = 'Finance_Handler.php';
        let postData = {
            operation: 'GET_MOST_RECENT_TRANSACTION_DATE'
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                setMostRecentDate(data['most_recent']);
            } else {
                setMostRecentDate('');
            }
        });
    }
    // const save_new_transaction = () => {
    //     //Perform basic validation
    //     //Must Have: Date, Payee, Amount
    //     //Each TPart Must have amount, category
    //     //Check for Transaction Date
    //     if(!newTransactionDate) {
    //         setNewTransactionErrorMessage("Please enter a date.");
    //         return;
    //     }
    //     //Check for Payee
    //     if(!newTransactionPayee.trim()) {
    //         setNewTransactionErrorMessage("Please enter a payee.");
    //         return;
    //     }
    //     //Check for Total Amount
    //     if(!newTransactionTotal || newTransactionTotal == 0) {
    //         setNewTransactionErrorMessage("The total amount of the transaction cannot be zero or blank.");
    //         return;
    //     }
    //     //Validate Transaction Parts
    //     for(let i = 0; i < transactionPartsData.length; i++) {
    //         //Check for Amount
    //         if(transactionPartsData[0]['amount'] == 0) {
    //             setNewTransactionErrorMessage("One of your transaction parts is missing an amount (cannot be zero).");
    //             return;
    //         }
    //         //Check for Category
    //         if(!transactionPartsData[0]['category'].trim()) {
    //             setNewTransactionErrorMessage("One of your transaction parts is missing a category.");
    //             return;
    //         }
    //     }
    //
    //     //If it makes it through validation, clear the error messages
    //     setNewTransactionErrorMessage('');
    //     let url = 'Finance_Handler.php';
    //     let postData = {
    //         operation: 'INSERT_NEW_TRANSACTION',
    //         transaction_parts: transactionPartsData,
    //         transaction_date: newTransactionDate,
    //         payee:newTransactionPayee,
    //         total:newTransactionTotal,
    //         reconciliation_status:newTransactionReconciliationStatus,
    //         number:newTransactionNumber,
    //         memo:newTransactionMemo,
    //         type:newTransactionType,
    //         account:props.account_name
    //     }
    //
    //     load_fetch(url, postData, (data) => {
    //         console.log(data);
    //         if(!data['ERROR'] && !data['error']) {
    //             //Success... for now, just perform a reload of the page? the ledger? For now, hide the form
    //             cancel_new_transaction();
    //             props.reload();
    //         } else {
    //             console.log("ERROR IN INSERTING NEW TRANSACTION. ERROR MESSAGE:");
    //             console.log(data);
    //         }
    //     }, 'text');
    //
    //     console.log(transactionPartsData);
    //     setMostRecentDate(newTransactionDate);
    //     cancel_new_transaction();
    //     document.getElementById('show_new_transaction_form_button').focus();
    // }
    const set_transaction_reconciliation_status = (transaction_id, new_status) => {
        let url = 'Finance_Handler.php';
        let postData = {
            operation: '',
            transaction_id: transaction_id
        }
        switch(new_status) {
            case 'CLEARED':
                postData['operation'] = 'MARK_TRANSACTION_CLEARED';
                break;
            case 'NOT_RECONCILED':
                postData['operation'] = 'MARK_TRANSACTION_NOT_RECONCILED';
                break;
            case 'RECONCILED':
                postData['operation'] = 'MARK_TRANSACTION_RECONCILED';
                break;
        }
        load_fetch(url, postData, (data) => {
            //console.log(data);
            if(!data['ERROR'] && !data['error']) {
                let ts = [...props.transactions];
                for(let i = 0; i < ts.length; i++) {
                    if(ts[i].transaction_id == transaction_id) {
                        switch(new_status) {
                            case 'CLEARED':
                                ts[i].reconciliation_status = 'C';
                                break;
                            case 'NOT_RECONCILED':
                                ts[i].reconciliation_status = '';
                                break;
                            case 'RECONCILED':
                                ts[i].reconciliation_status = 'R';
                                break;
                        }
                    }
                }
                props.update_transactions(ts);
                props.reload_balances();
            } else {
                console.log("Failed to mark cleared.");
            }
        });
    }
    const get_reconciliation_reports_list = () => {
        let postData = {
            account_id: props.account_id
        }
        talk(
            "Finance_Handler.php",
            "GET_RECONCILIATION_REPORTS_LIST",
            postData,
            get_reconciliation_reports_list_handler
        );
    }
    const get_reconciliation_reports_list_handler = (operation, sent_data, return_data) => {
        if(!return_data['ERROR']) {
            setReconciliationReports(return_data);
        }
    }
    const recalculate_account_balance = () => {
        let postData = {
            account_id: props.account_id
        }
        talk(
            "Finance_Handler.php",
            "RECALCULATE_ACCOUNT_BALANCE",
            postData,
            recalculate_account_balance_handler
        );
    }
    const recalculate_account_balance_handler = (operation, sent_data, return_data) => {
        console.log(return_data);
    }


    //OTHER FUNCTIONS
    const cents_to_dollars = (cents) => {
        return cents / 100
    }
    function deformat_currency(currencyValue) {
        //Strip out commas
        let value = currencyValue.toString().trim();
        if(value.length <= 0) {
            return;
        }
        value = value.replaceAll(/,/ig, '');
        return value;
    }
    const add_split = () => {
        let tParts = [...transactionPartsData];
        if(tParts.length === 1) {
            tParts[0]['amount'] = Number(deformat_currency(newTransactionTotal));
        }

        setTransactionPartsData([...tParts, {
            category: '',
            amount:0,
            memo: ''
        }]);
    }
    const update_transaction_total = (new_amount) => {
        //Only allow direct updating of the transaction total if there is only one tpart
        if(transactionPartsData.length > 1) {
            return;
        }
        setNewTransactionTotal(new_amount)

        //Keeps the Hidden Transaction Part 0 (the only one) in sync with the total
        setTransactionPartsData([{
                category: transactionPartsData[0]['category'],
                amount: Number(deformat_currency(new_amount)),
                memo: transactionPartsData[0]['memo']
            }]);
    }
    const handle_transaction_part_amount_change = () => {
        //Cycle through tpart data and update amount
        let total = 0.0;
        for(let i = 0; i < transactionPartsData.length; i++) {
            total += Math.round(Number(deformat_currency(transactionPartsData[i]['amount'])) * 100);
        }
        setNewTransactionTotal(format_currency(total/100));
    }
    const update_transaction_part = (index, data) => {
        let local_tps = [...transactionPartsData];
        local_tps[index] = data;
        setTransactionPartsData(local_tps);
    }
    const handle_delete_transaction_part = (index) => {
        let tps = [...transactionPartsData];
        tps = [...tps.slice(0, index), ...tps.slice(index + 1)];
        setTransactionPartsData(tps);

        //Update total (do it here so we don't have to wait for the tparts data to eventually be updated
        let total = 0;
        for(let i = 0; i < tps.length; i++) {
            total += Math.round(Number(deformat_currency(tps[i]['amount'])) * 100);
        }
        setNewTransactionTotal(format_currency(total/100));
    }
    const filter_out_current_account = () => {
        //Get index of current account
        let caIndex = -1;
        for(let i = 0; i < props.categories.length; i++) {
            if(props.categories[i] === '[' + props.account_name + ']') {
                caIndex = i;
                break;
            }
        }

        //If found, remove
        if(caIndex != -1) {
            let tempCategories = [...props.categories.slice(0, caIndex), ...props.categories.slice(caIndex + 1)];
            return tempCategories;
        } else {
            return props.categories;
        }
    }
    const clear_new_transaction_form = () => {
        setNewTransactionDate('');
        setNewTransasctionPayee('');
        setNewTransactionTotal(0);
        setNewTransactionReconciliationStatus('Not Reconciled');
        setNewTransactionNumber('');
        setNewTransactionMemo('');
        setTransactionPartsData([{
            category: '',
            amount: 0,
            memo: ''
        }]);
        setNewTransactionType('withdrawal');
        setNewTransactionErrorMessage('');
    }
    const cancel_new_transaction = () => {
        //Hide the Modal
        setShowNewTranasctionModal(false);

        //Clear the Form
        clear_new_transaction_form();
    }
    const show_new_transaction_modal = () => {
        setShowNewTranasctionModal(true);
    }
    const payee_add_new_handler = (value) => {
        //TODO: Down the road, I will have to push adding new Payees to the database and updating the datalists
        // in real time, because if I have a transaction split where there are two new categories, for instance,
        // then each time I visit one of those category boxes, it will keep asking me if I really want to add that
        // category, because it doesn't find it in the datalist (one of the two WILL be confirmed, but the other not)
        // OR: Could use an array for categories, cause could be multiples,
        if(value !== confirmedNewPayee) {
            setConfirmModalData({
                type:'CONFIRM_PAYEE',
                value:value
            });
            setConfirmModalMessage("Would you like to add " + value + " as a new Payee?");
        }
    }
    const category_add_new_handler = (value, id) => {
        //TODO: Need to add code to allow setting these as income or expense categories
        // Probably need to do it right away to get this integrated with the main site so I don't have to
        // go back and manually enter a bunch of category data.
        if(value !== confirmedNewCategory) {
            setConfirmModalData({
                type:'CONFIRM_CATEGORY',
                value:value,
                id:id,
                buttons: [{text:"Yes, add as an Income Category.", id:'confirm_modal_true_button', c_handler:handle_modal_clicks},
                    {text:"Yes, add as an Expense Category.", id:'YES_EXPENSE', c_handler:handle_modal_clicks},
                    {text:"No, cancel.", id:'cancel', c_handler:handle_modal_clicks}]
            })
            setConfirmModalMessage("Would you like to add " + value + " as a new Category?");
        }
    }
    function focus_on_modal_true_button() {
        document.getElementById('confirm_modal_true_button').focus();
    }
    const handle_add_new_category = (value) => {
        //console.log(value);
        if(!value) {
            //Hide the Modal
            setConfirmModalMessage('');

            //Clear the category
            //TODO: Here I need to know which transaction part is possibly being updated!!!!!!!!!
            setNewTransasctionPayee('');
        } else {
            //We're good, just close the Modal
            setConfirmModalMessage('');

            //Set the new payee as "confirmed" so we don't keep asking the question everytime we visit the input
            setConfirmedNewCategory(value);
        }
    }
    const handle_modal_clicks = (value) => {
        //console.log(confirmModalData);
        switch(confirmModalData['type']) {
            case "CONFIRM_PAYEE":
                if(value) {
                    //Hide the Modal
                    setConfirmModalMessage('');
                    //Set the new Payee as "confirmed"
                    setConfirmedNewPayee(confirmModalData['value'])
                } else {
                    //Hide the Modal
                    setConfirmModalMessage('');
                    //Erase the Payee
                    setNewTransasctionPayee('');
                }
                break;
            case "CONFIRM_CATEGORY":
                if(value) {
                    //Hide the Modal
                    setConfirmModalMessage('');

                    //console.log(confirmModalData['value']);
                    //console.log(value);
                    let category_type = (value === "YES_EXPENSE" ? "EXPENSE" : "INCOME");

                    let tParts = [...transactionPartsData];
                    tParts[confirmModalData['id']]['new_category_type'] = category_type;
                    setTransactionPartsData([...tParts]);

                    //Set the new Category as "confirmed"
                    //setConfirmedNewCategory({category_name: confirmModalData['value'], category_type:category_type});
                } else {
                    //Hide the Modal
                    setConfirmModalMessage('');

                    //Erase the Category from the Transaction Part
                    let tParts = [...transactionPartsData];
                    tParts[confirmModalData['id']]['category'] = '';
                    setTransactionPartsData([...tParts]);
                }
                break;
        }
    }
    const handle_edit_transaction = (transaction) => {
        setEditingTransaction(transaction);
    }
    const cancel_edit_transaction = () => {
        setEditingTransaction(false);
    }
    const cancel_add_transaction = () => {
        //For SimpleEntry
        setShowNewTranasctionModal(false);
    }
    const handle_simple_entry_transaction_save = () => {
        //Update Ledger
        props.reload();

        //Hide the Transaction Entry Form
        setShowNewTranasctionModal(false);
    }
    const reconcile_account = () => {
        document.location.href = 'reconcile_account?aid=' + props.account_id;
    }
    const toggle_view_reconciliation_reports = () => {
        setShowReconciliationReports(!showReconciliationReports);
    }

    //RENDERING
    return (<div>
        <h3>Ledger - {props.account_name}</h3>
        <div>Actual Balance: ${format_currency(props.balance/100)} - Cleared Balance: ${format_currency(props.cleared_balance/100)}</div>
        <div>
            <button
                className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                onClick={show_new_transaction_modal}
                id='show_new_transaction_form_button'
            >
                Input New Transaction
            </button>
            <button
                className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                onClick={reconcile_account}
                id='reconcile_account_button'
            >
                Reconcile Account
            </button>
            <button
                className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                onClick={toggle_view_reconciliation_reports}
            >
                {showReconciliationReports ? 'Hide' : 'View'} Reconciliation Reports
            </button>
            <button
                className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                onClick={recalculate_account_balance}
            >
                Recalc Balance
            </button>
        </div>
        <div className={showReconciliationReports ? '' : GenStyles.hidden}>
            <div>Reconciliation Reports</div>
            {reconciliationReports.length > 0 && reconciliationReports.map((item, index) => (
                <div key={'rec_report_' + item['reconciliation_id']}>
                    <Link to={'../reconciliation_report' + '?rid=' + item['reconciliation_id']}>
                        {item['reconciliation_date']}
                    </Link>
                    <span className={FStyles.closing_balance_rec_ledger}>${format_currency(cents_to_dollars(item['closing_balance']))}</span>
                </div>
            ))}
        </div>
        {Object.keys(props.transactions).length > 0 &&
            props.transactions.slice(0, props.ten_or_all).map(item => (
                <Transaction
                    key={item['transaction_id']}
                    t={item} //Transaction Data
                    change_reconciliation={set_transaction_reconciliation_status}
                    edit_handler={handle_edit_transaction}
                    disabled={props.account_id !== item['account_id']}
                    is_parent_account={props.is_parent_account}
                />

            ))}


        {/* EDIT TRANSACTION MODAL */}
        <EditTransactionModal
            transaction_id={editingTransaction.transaction_id}
            mostRecentDate={mostRecentDate}
            cancel_edit_transaction={cancel_edit_transaction}
            categories={props.categories}
            payees={props.payees}
        />

        {/* NEW TRANSACTION MODAL */}
        <div id='new_transaction_modal' className={showNewTransactionModal ? Styles.ofb_modal_shown : Styles.ofb_modal_hidden}>
            <div className={`${Styles.ofb_modal_content} w3-animate-zoom`}>
                <div className={Styles.ofb_new_transaction_form}>
                    <div
                        id='error_message_div'
                        className={`${Styles.ofb_error_message_div} 
                            ${newTransactionErrorMessage ? '' : Styles.ofb_hide}`}
                    >{newTransactionErrorMessage}
                    </div>
                    <div>
                        <SimpleEntry
                            account_name={props.account_name}
                            mostRecentDate={mostRecentDate}
                            cancel_transaction={cancel_add_transaction}
                            save_transaction_handler={handle_simple_entry_transaction_save}
                            reload_page={props.reload}
                        />
                    </div>
        {/*<Form_Transaction*/}
        {/*    payees={props.payees}*/}
        {/*    payee={newTransactionPayee}*/}
        {/*    payee_changed_handler={setNewTransasctionPayee}*/}
        {/*    payee_add_new_handler={payee_add_new_handler}*/}
        {/*    reconciliationStatus={newTransactionReconciliationStatus}*/}
        {/*    handleReconciliationStatusChange={setNewTransactionReconciliationStatus}*/}
        {/*    transactionNumber={newTransactionNumber}*/}
        {/*    handleTransactionNumberChange={setNewTransactionNumber}*/}
        {/*    transactionMemo={newTransactionMemo}*/}
        {/*    handleTransactionMemoChange={setNewTransactionMemo}*/}
        {/*    transactionType={newTransactionType}*/}
        {/*    transactionTypeChanged={setNewTransactionType}*/}
        {/*    mostRecentDate={mostRecentDate}*/}
        {/*    transaction_date={newTransactionDate}*/}
        {/*    onDateChange={setNewTransactionDate}*/}
        {/*    amount={newTransactionTotal}*/}
        {/*    amount_changed_handler={update_transaction_total}*/}
        {/*    amount_disabled={transactionPartsData.length > 1 ? true : false}*/}
        {/*    block_select={(confirmModalMessage.length > 0)}*/}
        {/*/>*/}
        {/*<div>{transactionPartsData.map((tp, index) => (*/}
        {/*    <Form_Transaction_Part*/}
        {/*        key={index}*/}
        {/*        name={index}*/}
        {/*        only_one={transactionPartsData.length === 1 ? true : false}*/}
        {/*        categories={filter_out_current_account()}*/}
        {/*        presetData={tp}*/}
        {/*        update_transaction_part={update_transaction_part}*/}
        {/*        update_total_handler={handle_transaction_part_amount_change}*/}
        {/*        delete_transaction_part={handle_delete_transaction_part}*/}
        {/*        category_add_new_handler={category_add_new_handler}*/}
        {/*        block_select={false}*/}
        {/*    />*/}
        {/*))}</div>*/}
        {/*<div>*/}
        {/*    <button*/}
        {/*        className={`${Styles.ofb_button} ${Styles.ofb_red}`}*/}
        {/*        onClick={add_split}*/}
        {/*    >Add Split</button>*/}
        {/*    <button*/}
        {/*        className={`${Styles.ofb_button} ${Styles.ofb_red}`}*/}
        {/*        id='save_new_transaction_button'*/}
        {/*        onClick={save_new_transaction}*/}
        {/*    >Save New Transaction</button>*/}
        {/*    <button*/}
        {/*        className={`${Styles.ofb_button} ${Styles.ofb_red}`}*/}
        {/*        onClick={cancel_new_transaction}*/}
        {/*    >Cancel</button>*/}
        {/*</div>*/}
                </div>
            </div>
        </div>

        {/* CONFIRM MODAL */}
        <ConfirmModal
            data={confirmModalData}
            show={confirmModalMessage ? true : false}
            message={confirmModalMessage}
            clickHandler={handle_modal_clicks}
        />


    </div>)
}
export default Ledger;