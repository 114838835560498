import React, {useState} from 'react'
import Styles from "../styles/ofbstyles.module.css"

const CurrencyInput = (props) => {

    const modify_amount = (amount, focus) => {
        props.changeHandler(amount);
    }
    
    function add_commas(value) {
        let new_value = '';
        let number_of_commas = Math.floor(value.length / 3);
        for(let i = 0; i <= number_of_commas; i++) {
            let start = value.length - ((i + 1) * 3);
            let end = value.length - (i * 3);
            if(start < 0) start = 0;
            let segment = value.slice(start, end)
            new_value = segment + (new_value.length > 0 && segment.length > 0 ? "," : "") + new_value;
        }
        return new_value;
    }
    
    const deformat_currency = (event) => {
        //Strip out commas
        //let value = currencyValue.toString().trim();
        let value = props.currency_value.toString().trim(); //localAmount.toString().trim();
        if(value.length <= 0) {
            return;
        }
        value = value.replaceAll(/,/ig, '');
        //let form_input = document.getElementById(props.name);
        modify_amount(value, true);
        if(!props.block_select) {
            event.target.select();
        }
    }
    
    function format_currency() {
        let input_value = props.currency_value;//localAmount;//currency_input.value;
        if(!input_value) {
            return;
        }
        
        //Is there a decimal?
        if(input_value.indexOf('.') !== -1) {
            let parts = input_value.split('.');
    
            //If only a single post-decimal digit, add a zero
            if(parts[1].length === 1) {
                parts[1] += "0";
            }
            //setCurrencyValue(add_commas(parts[0]) + "." + parts[1]);
            modify_amount(add_commas(parts[0]) + "." + parts[1], false);
        } else {
            if(input_value.trim().length !== 0) {
                input_value = add_commas(input_value) + ".00";
                //localAmount = input_value;//currency_input.value = input_value;
                //setCurrencyValue(input_value);
                modify_amount(input_value, false);
            } else {
                modify_amount('', false);
            }
        }
    }

    function validate_keydown(event) {
        let char = event.keyCode;

        //Only allow numbers and decimal point
        if((char < 48 || (char > 57 && char < 96) || (char > 105 && char != 110 && char != 190)) && event.key.length===1) {
            event.preventDefault();
            return;
        }

        let currency_input = document.getElementById(props.name);
        let value = props.currency_value;//currency_input.value;
        if(value.indexOf('.') !== -1) {
    
            //If the character entered was a second decimal return
            if(char === 190 || char === 110) {
                event.preventDefault();
                return;
            }
            let caretPosition = currency_input.selectionStart;
            let decimalIndex = value.indexOf('.');
            if(caretPosition > decimalIndex) {
                let decimalDigitCount = value.substring(decimalIndex).length;
                
                if(event.key.length === 1 && decimalDigitCount >= 3) {
                    event.preventDefault();
                    return;
                }
            }
        }
    }
    return (
        <div className={Styles.ofb_currency_input_holder}>
            <div className={Styles.ofb_dollar_sign}>$</div>
            <input 
                type='text'
                disabled={props.disabled}
                name={props.name}
                id={props.name}
                className={Styles.ofb_currency_input}
                onFocus={deformat_currency}
                onBlur={format_currency}
                value={props.currency_value}
                onKeyDown={event => validate_keydown(event)}
                placeholder={props.placeholder ?? ''}
                onChange={e => {
                    modify_amount(e.target.value, false)
                }}
            />
        </div>
    )
}
export default CurrencyInput;