import React, {useEffect, useState, useContext } from "react";
import { load_fetch} from "./ofb_util";
import TabSelector from "./tab_selector";
import Styles from "./styles/ofbstyles.module.css";
import { format } from 'date-fns';
import WeightChart from "./weight_chart";
import BPChart from "./bp_chart";
import WeightLossChart from "./Health/WeightLossChart";

const Health = (props) => {
    const [tabShown, setTabShown] = useState(3);
    const [tabs, setTabs] = useState([
        {id: 1, name: "Weight", value: "WEIGHT", selected: true},
        {id: 2, name: "BP", value: "BP"},
        {id: 3, name: "+", value: "ADD_NEW"}
    ]);
    const [weightData, setWeightData] = useState(null);
    const [bpData, setBPData] = useState(null);
    const [messageDivVisible, setMessageDivVisible] = useState(false);
    const [modalInfo, setModalInfo] = useState({});
    const [weightLossChartData, setWeightLossChartData] = useState(false);

    useEffect(() => {
        if(props.authStatus) {
            load_weight_data();
            load_bp_data();
            load_weightloss();
        }
        setTabShown(1);
    }, [props.authStatus]);

    useEffect(() => {

    }, [weightData]);

    const editTabHandler = (props) => {
        switch(props) {
            case 'WEIGHT':
                document.getElementById('new_weight_form').classList.add('w3-hide');
                document.getElementById('new_bp_form').classList.add('w3-hide');
                setTabShown(1);
                load_weight_data();
                break;
            case 'BP':
                document.getElementById('new_weight_form').classList.add('w3-hide');
                document.getElementById('new_bp_form').classList.add('w3-hide');
                setTabShown(2);
                load_bp_data();
                break;
            case "ADD_NEW":
                if(tabShown === 1) {
                    document.getElementById('new_weight_form').classList.remove('w3-hide');
                    document.getElementById('new_bp_form').classList.add('w3-hide');
                } else if (tabShown === 2) {
                    document.getElementById('new_weight_form').classList.add('w3-hide');
                    document.getElementById('new_bp_form').classList.remove('w3-hide');
                }
                break;
        }
    }


    const weight_table = () => {
        if(props.authStatus && weightData) {
            return <div id='weight_table>'>
                <table className={`w3-table-all ${Styles.ofb_pointer}`} style={{cursor:'pointer'}}>
                    <thead>
                    <tr>
                        <td>Date</td>
                        <td>Weight</td>
                        <td>Body Fat %</td>
                    </tr>
                    </thead>
                    <tfoot>
                    {weightData.map(data_row => (
                        <tr
                            key={data_row['weightid']}
                            onClick={() => show_delete_are_you_sure_modal(data_row['weightid'])}>
                            <td>{
                                format(new Date(data_row['weightdateandtime']), 'MMM d, yyyy')
                            }</td>
                            <td>{data_row['weight']}</td>
                            <td>{data_row['bodyfat']}</td>
                        </tr>
                    ))}
                    </tfoot>
                </table>
            </div>
        } else {
            return <></>
        }
    }
    const bp_table = () => {
        if(props.authStatus && bpData) {
            return <div id='weight_table>'>
                <table className={`w3-table-all ${Styles.ofb_pointer}`} style={{cursor:'pointer'}}>
                    <thead>
                    <tr>
                        <td>Date</td>
                        <td>Systolic</td>
                        <td>Diastolic</td>
                        <td>Pulse</td>
                    </tr>
                    </thead>
                    <tfoot>
                    {bpData.map(data_row => (
                        <tr
                            key={data_row['bpid']}
                            onClick={() => show_delete_bp_are_you_sure_modal(data_row['bpid'])}>
                            <td>{
                                format(new Date(data_row['bpdateandtime']), 'MMM d, yyyy')
                            }</td>
                            <td>{data_row['systolic']}</td>
                            <td>{data_row['diastolic']}</td>
                            <td>{data_row['pulse']}</td>
                        </tr>
                    ))}
                    </tfoot>
                </table>
            </div>
        } else {
            return <></>
        }
    }

    const show_delete_are_you_sure_modal = (props) => {
        setModalInfo({
            operation: 'delete_weight',
            weightid: props
        });
        let ays_modal = document.getElementById('are_you_sure_delete');
        ays_modal.style.display = (ays_modal.style.display === 'block' ? 'none' : 'block');
    }
    const show_delete_bp_are_you_sure_modal = (props) => {
        setModalInfo({
            operation: 'delete_bp',
            bpid: props
        });
        let ays_modal = document.getElementById('are_you_sure_delete');
        ays_modal.style.display = (ays_modal.style.display == 'block' ? 'none' : 'block');
    }
    const modal_press_yes = (props) => {
        //Hide the Modal
        let ays_modal = document.getElementById('are_you_sure_delete');
        ays_modal.style.display = (ays_modal.style.display == 'block' ? 'none' : 'block');

        switch(modalInfo['operation']) {
            case 'delete_weight':
                delete_weight_row(modalInfo['weightid']);
                break;
            case 'delete_bp':
                delete_bp_row(modalInfo['bpid']);
        }
    }
    const modal_press_no = (props) => {
        //Hide the Modal
        let ays_modal = document.getElementById('are_you_sure_delete');
        ays_modal.style.display = (ays_modal.style.display == 'block' ? 'none' : 'block');

        //Clear modal info
        setModalInfo(null);
    }
    const delete_bp_row = (bpid) => {
        const postData = {
            operation: 'DELETE_BP',
            bpid: bpid
        }
        const myCallback = (response) => {
            if(response && "error" in response) {
                console.log("ERROR: " + response['error']);
            } else if (response) {
                setModalInfo(null);
                load_bp_data();
            } else {
                console.log("NO RESPONSE");
            }
        }
        load_fetch('health_handler.php', postData, myCallback);
    }
    const delete_weight_row = (weightid) => {
        const postData = {
            operation: 'DELETE_WEIGHT',
            weightid: weightid
        }
        const myCallback = (response) => {
            if(response && "error" in response) {
                console.log("ERROR: " + response['error']);
            } else if (response) {
                setModalInfo(null);
                load_weight_data();
            } else {
                console.log("NO RESPONSE");
            }
        }
        load_fetch('health_handler.php', postData, myCallback);
    }
    const load_weightloss = () => {
        const postData = {
            operation: 'GET_WEIGHT_LOSS_TABLE'
        }

        const myCallback = (response) => {
            let local_average_weights = [];

            for(let key in response['AVERAGE_WEIGHTS']) {
                console.log(response['AVERAGE_WEIGHTS'][key]);
                local_average_weights.push(response['AVERAGE_WEIGHTS'][key]);
            }
            let local_target_weights = [];
            for(let key in response['TARGET_WEIGHTS']) {
                local_target_weights.push(response['TARGET_WEIGHTS'][key]);
            }

            const data = {
                labels: response['WEEKS'],
                datasets: [
                    {
                        label: "Average Weight Per Week",
                        backgroundColor: "rgb(255, 99, 132)",
                        borderColor: "rgb(255, 99, 132)",
                        data: local_average_weights,
                    },
                    {
                        label: "Goal Weight Per Week",
                        backgroundColor: "rgb(126,152,234)",
                        borderColor: "rgb(126,152,234)",
                        data: local_target_weights,
                    },
                ],
            };
            setWeightLossChartData(data);
        }
        load_fetch('health_handler.php', postData, myCallback);

    }
    const load_weight_data = () => {
        const postData = {
            operation: 'LOAD_WEIGHT_TABLE'
        }

        const myCallback = (response) => {
            if(response && "error" in response) {
                console.log("ERROR: " + response['error']);
            } else if (response) {
                setWeightData(response);
            } else {
                console.log("NO RESPONSE");
            }
        }
        load_fetch('health_handler.php', postData, myCallback);
    }
    const load_bp_data = () => {
        const postData = {
            operation: 'LOAD_BP_TABLE'
        }

        const myCallback = (response) => {
            if(response && "error" in response) {
                //console.log("ERROR: " + response['error']);
            } else if (response) {
                //console.log(response);
                setBPData(response);
            } else {
                console.log("NO RESPONSE");
            }
        }
        load_fetch('health_handler.php', postData, myCallback);
    }

    const insert_new_weight = () => {
        //Validate data first
        let wdt_value = document.getElementById('new_weight_datetime').value;
        if(!wdt_value) {
            let error_div = document.getElementById('error_message_div');
            error_div.innerHTML = 'You must set a date.';
            setMessageDivVisible(true);
            return;
        }
        let weight_value = document.getElementById('new_weight').value;
        if(!weight_value) {
            let error_div = document.getElementById('error_message_div');
            error_div.innerHTML = 'You must set a weight.';
            setMessageDivVisible(true);
            return;
        }
        let weight_date_time = new Date(wdt_value);

        weight_date_time = format(weight_date_time, "yyyy-MM-dd HH:mm:ss");

        const postData = {
            operation: 'INSERT_NEW_WEIGHT',
            weightdateandtime: weight_date_time,
            weight: document.getElementById('new_weight').value,
            bodyfat: document.getElementById('new_weight_bodyfat').value,
        }

        const myCallback = (response) => {
            if(response && "error" in response) {
                console.log("ERROR: " + response['error']);
            } else if (response) {
                load_weight_data();
                setTabShown(1);
                document.getElementById('new_weight_form').classList.add('w3-hide');
                document.getElementById('new_bp_form').classList.add('w3-hide');
                reset_insert_forms();
            } else {
                console.log("NO RESPONSE");
            }
        }
        load_fetch('health_handler.php', postData, myCallback);
    }
    const insert_new_bp = () => {
        //Validate data first
        let bpdt_value = document.getElementById('new_bp_datetime').value;
        if(!bpdt_value) {
            let error_div = document.getElementById('error_message_div');
            error_div.innerHTML = 'You must set a date.';
            setMessageDivVisible(true);
            return;
        }
        let diastolic_value = document.getElementById('new_diastolic').value;
        if(!diastolic_value) {
            let error_div = document.getElementById('error_message_div');
            error_div.innerHTML = 'You must enter a Diastolic BP (upper number).';
            setMessageDivVisible(true);
            return;
        }
        let systolic_value = document.getElementById('new_systolic').value;
        if(!systolic_value) {
            let error_div = document.getElementById('error_message_div');
            error_div.innerHTML = 'You must enter a Systolic BP (lower number).';
            setMessageDivVisible(true);
            return;
        }

        let bp_date_time = new Date(bpdt_value);

        bp_date_time = format(bp_date_time, "yyyy-MM-dd HH:mm:ss");

        const postData = {
            operation: 'INSERT_NEW_BP',
            bpdateandtime: bp_date_time,
            diastolic: document.getElementById('new_diastolic').value,
            systolic: document.getElementById('new_systolic').value,
            pulse: document.getElementById('new_pulse').value,
        }

        const myCallback = (response) => {
            if(response && "error" in response) {
                console.log("ERROR: " + response['error']);
            } else if (response) {
                load_bp_data();
                document.getElementById('new_weight_form').classList.add('w3-hide');
                document.getElementById('new_bp_form').classList.add('w3-hide');
                setTabShown(2);
                reset_insert_forms();
            } else {
                console.log("NO RESPONSE");
            }
        }
        load_fetch('health_handler.php', postData, myCallback);
    }
    const reset_insert_forms = () => {
        document.getElementById('new_weight').value = '';
        document.getElementById('new_weight_bodyfat').value = '';
        document.getElementById('new_weight_datetime').value = '';
        document.getElementById('new_diastolic').value = '';
        document.getElementById('new_systolic').value = '';
        document.getElementById('new_pulse').value = '';
        document.getElementById('new_bp_datetime').value = '';
    }
    const cancel_insert = () => {
        //Hide the form(s)
        document.getElementById('new_weight_form').classList.add('w3-hide');
        document.getElementById('new_bp_form').classList.add('w3-hide');

        //Reset the form(s)
        reset_insert_forms();

        //Set the proper tab handler button???? (for gui clarity)
        //TODO: When canceling a new weight/bp, change the color for the input button back to black for weight/bp
    }
    if(props.authStatus) {
        return (
            <>
                <TabSelector
                    clickHandler={editTabHandler}
                    visible={true}
                    items={tabs}
                />
                <div id="error_message_div" className={messageDivVisible ? Styles.ofb_error_message_div : `${Styles.ofb_hide} ${Styles.ofb_error_message_div}`}></div>
                <div id="new_weight_form" className="w3-hide">
                    <div className={Styles.ofb_input_holder}>
                        <div className={Styles.ofb_label}>Weight</div>
                        <input type='number' id="new_weight" className={Styles.ofb_text_input} />
                    </div>
                    <div className={Styles.ofb_input_holder}>
                        <div className={Styles.ofb_label}>Date</div>
                        <input type='datetime-local' className={Styles.ofb_datetime_input} id="new_weight_datetime" />
                    </div>
                    <div className={Styles.ofb_input_holder}>
                        <div className={Styles.ofb_label}>Body Fat</div>
                        <input type='number' className={Styles.ofb_text_input} id="new_weight_bodyfat" />
                    </div>
                    <div className={Styles.ofb_input_holder}>
                        <div className={Styles.ofb_label}></div>
                        <button
                            className={`${Styles.ofb_button} ${Styles.ofb_black}`}
                            type='button'
                            onClick={cancel_insert}>Cancel
                        </button>
                        <button
                            className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                            type='button'
                            onClick={insert_new_weight}>Submit
                        </button>
                    </div>
                </div>
                <div id="new_bp_form" className="w3-hide">
                    <div className={Styles.ofb_input_holder}>
                        <div className={Styles.ofb_label}>Systolic</div>
                        <input type='number' id="new_systolic" className={Styles.ofb_text_input} />
                    </div>
                    <div className={Styles.ofb_input_holder}>
                        <div className={Styles.ofb_label}>Diastolic</div>
                        <input type='number' id="new_diastolic" className={Styles.ofb_text_input} />
                    </div>
                    <div className={Styles.ofb_input_holder}>
                        <div className={Styles.ofb_label}>Pulse</div>
                        <input type='number' id="new_pulse" className={Styles.ofb_text_input} />
                    </div>
                    <div className={Styles.ofb_input_holder}>
                        <div className={Styles.ofb_label}>Date</div>
                        <input type='datetime-local' className={Styles.ofb_datetime_input} id="new_bp_datetime" />
                    </div>
                    <div className={Styles.ofb_input_holder}>
                        <div className={Styles.ofb_label}></div>
                        <button
                            className={`${Styles.ofb_button} ${Styles.ofb_black}`}
                            type='button'
                            onClick={cancel_insert}>Cancel
                        </button>
                        <button
                            className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                            type='button'
                            onClick={insert_new_bp}>Submit
                        </button>
                    </div>
                </div>
                <div id='tab_1'
                     className={(tabShown == 1 ? Styles.ofb_health_tab : Styles.ofb_health_tab_hidden)}>
                    <h3>Weight - Last 30 Days</h3>
                    <WeightChart data={weightData} range="30 days" />
                    <h3>Weight - All Time</h3>
                    <WeightChart data={weightData} range="all" />
                    <div>
                        <WeightLossChart chart_data={weightLossChartData} />
                    </div>
                    <h3>Weight Data</h3>
                    {weight_table()}
                </div>
                <div id='tab_2' className={(tabShown == 2 ? Styles.ofb_health_tab : Styles.ofb_health_tab_hidden)}>
                    <h3>BP - Last 30 Days</h3>
                    <BPChart data={bpData} range="30 days" />
                    <h3>BP - All Time</h3>
                    <BPChart data={bpData} range="all" />
                    <h3>Blood Pressure Data</h3>
                    {bp_table()}
                </div>
                <div id="are_you_sure_delete" className="w3-modal">
                    <div className='w3-modal-content w3-animate-zoom'>
                        <header className="w3-container w3-red">
                            &nbsp;
                        </header>
                        <div className={Styles.ofb_modal_body}>
                            <div>Are you sure?</div>
                            <div>
                                <button
                                    type='button'
                                    className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                                    onClick={modal_press_yes}
                                >Yes</button>
                                <button
                                    type='button'
                                    className={`${Styles.ofb_button} ${Styles.ofb_black}`}
                                    onClick={modal_press_no}
                                >No</button>
                            </div>
                        </div>
                        <footer className="w3-container w3-red">
                            &nbsp;
                        </footer>

                    </div>
                </div>
            </>
        )
    } else {
        return <></>
    }
}

export default Health;