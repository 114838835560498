import Styles from "../styles/ofbstyles.module.css";
import LibStyles from "../styles/library.module.css";
import React, {useEffect, useState} from "react";
import {load_fetch, talk} from "../ofb_util";
import {usePDF, Margin} from 'react-to-pdf';

const Print_Labels = (props) => {

    const [books, setBooks] = useState({});
    const [labels, setLabels] = useState({});
    const [labelRows, setLabelRows] = useState([0,1,2,3,4,5,6,7,8,9]);
    const [availableLabels, setAvailableLabels] = useState(20);
    const [labelQueue, setLabelQueue] = useState([]);
    const {toPDF, targetRef} = usePDF({filename: 'page.pdf', page: {margin: Margin.NONE, format: 'A6'}});
    const [hideBorders, setHideBorders] = useState(false);

    useEffect(() => {
        get_possible_books();
    }, []);
    useEffect(() => {
        console.log(labelQueue);
        seed_labels(null);
    }, [labelQueue]);
    //DELETE:
    useEffect(() => {
        console.log(labels);
    }, [labels]);


    const get_possible_books = () => {
        talk("Library_Handler.php",
            "GET_BOOKS_FOR_LABEL_PRINTING",
            null,
            get_possible_books_handler);
    }
    const get_possible_books_handler = (operation, data, return_data) => {
        if(return_data['ERROR']) {
            console.log(return_data);
            setBooks({});
            return;
        } else {
            setBooks(return_data);
        }
    }
    function get_book_by_id(id) {
        let found_book = false;
        books.forEach(function(book) {
            console.log(id);
            if(book['bookid'] == id) {
                found_book = book;
            }
        });
        return found_book;
    }
    const toggle_label = (event) => {
        //Get book data
        let book = get_book_by_id(event.target.name);

        //Add or Remove the label from the Queue
        if(event.target.checked) {
            //Adding this label to the queue
            let local_label_queue = labelQueue;
            local_label_queue.push(book['authorlastname'] + "#" + book['lc_classification']);
            setLabelQueue([...local_label_queue]);
            setAvailableLabels(availableLabels - 1);
        } else {
            //Removing this label from the queue
            const filtered_label_queue = labelQueue.filter(val => val !== book['authorlastname'] + "#" + book['lc_classification']);
            setLabelQueue([...filtered_label_queue]);
            setAvailableLabels(availableLabels + 1);
        }
    }

    const toggle_banned_label = (index) => {
        let local_labels = JSON.parse(JSON.stringify(labels));
        if(local_labels[index] === 'BANNED') {
            local_labels[index] = '';
        } else {
            //Can't ban it if we have too many
            if(availableLabels <= 0) {
                alert("You have too many labels selected to block one. Remove a label first.");
                return;
            }
            local_labels[index] = 'BANNED';
        }

        setLabels(local_labels);
        seed_labels(local_labels);

        //Calculate available labels
        let available = 0;
        for(let i = 0; i < 20; i++) {
            if(local_labels[i].length <= 0) {
                available++;
            }
        }
        setAvailableLabels(available);
    }

    const seed_labels = (local_labels) => {
        console.log("SEED_LABELS CALLED");
        //Create a local copy of the label object
        if(!local_labels) { local_labels = JSON.parse(JSON.stringify(labels)); }

        //Clear the labels before seeding
        for(let i = 0; i < 20; i++) {
            if(local_labels[i] !== 'BANNED') {
                local_labels[i] = "";
            }
        }

        //Loop through each label and find a place for it
        for(let i = 0; i < labelQueue.length; i++) {
            let label = labelQueue[i];
            for(let j = 0; j < 20; j++) {
                if(!local_labels[j] || local_labels[j].length === 0) {
                    //Split off the author's name
                    let authorlastname = label.split("#")[0];
                    let solo_label = label.split("#")[1];

                    //Split the label into 3 parts
                    let loc1 = solo_label.search(/\d/);
                    let label_part_1 = solo_label.substring(0, loc1); //1st 2 letters
                    let label_remainder = solo_label.substring(loc1);
                    let label_part_2 = label_remainder;
                    let label_part_3 = ' ';
                    if(label_remainder.indexOf(' ') != -1) {
                        let loc = label_remainder.indexOf(' ');
                        label_part_2 = label_remainder.substring(0, loc);
                        label_part_3 = label_remainder.substring(loc + 1);
                    }

                    local_labels[j] = authorlastname + "#" + label_part_1 + "#" + label_part_2 + "#" + label_part_3;
                    break;
                }
            }
        }
        setLabels(local_labels);
    }
    function is_checked(id) {
        let el = document.getElementById(id);
        if(el && el.checked) {
            return true;
        } else {
            return false;
        }
    }
    const generate_actual = () => {
        toPDF();
    }
    const mark_as_printed = (printed_or_not) => {
        //Gather the ids
        let ids = Array();
        labelQueue.forEach(function (item) {
            let parts = item.split("#");
            ids.push(parts[1]);
        });

        talk("Library_Handler.php",
            'MARK_BOOK_LABEL_PRINTED',
            {ids: ids, printed_or_not: printed_or_not},
            mark_as_printed_handler);
    }
    const mark_as_printed_handler = (operation, data, return_data) => {
        if(!return_data['ERROR']) {
            setBooks(return_data);

            //Clear label sheet?
        } else {
            console.log(return_data);
        }
    }

    return (<>
        <div>
            <h3>Print Labels</h3>
        </div>
        <div>
            <button
                className={Styles.ofb_button}
                onClick={() => document.location.href='library'}
            >
                -Back to Library-
            </button>
        </div>
        <div className={LibStyles.label_row}>
            <table className={LibStyles.label_table}>
                <thead>
                    <tr>
                        <td></td>
                        <td>LCN</td>
                        <td>Author</td>
                        <td>Title</td>
                    </tr>
                </thead>
                <tbody>
                {books.length > 0 && books.map((book, index) => (
                    <tr
                        key={book['bookid']}
                        className={`${book['label_printed'] ? LibStyles.label_printed_row : (LibStyles.label_data_row)}
                         ${index % 2 == 1 && !book['label_printed'] ? LibStyles.gray_row : ''}`}>
                        <td className={LibStyles.data_cell}>
                            <input
                                type="checkbox"
                                className={LibStyles.label_checkbox}
                                name={book['bookid']}
                                id={book['bookid']}
                                onClick={(event) => toggle_label(event)}
                                disabled={availableLabels <= 0 && !is_checked(book['bookid'])}
                            />
                        </td>
                        <td className={LibStyles.data_cell}>{book['lc_classification']}</td>
                        <td className={LibStyles.data_cell}>{book['authorlastname']}, {book['authorfirstname']}</td>
                        <td className={LibStyles.data_cell}>{book['title']}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
        <div>Available Labels: {availableLabels}</div>
        <div>Preview:
            <button onClick={() => setHideBorders(!hideBorders)}
            >{hideBorders ? "Show Borders" : "Hide Borders"}</button>
            <button onClick={generate_actual}>Generate Actual</button>
            <button onClick={() => mark_as_printed(1)}>Mark as Printed</button>
            <button onClick={() => mark_as_printed(0)}>Mark as Not Printed</button>
        </div>
        <div className={LibStyles.label_sheet} id="label_sheet" ref={targetRef}>
            {labelRows.map((row, index) => (
                <div key={index} className={LibStyles.label_row}>
                    <div
                        className={`${LibStyles.label} ${labels[index * 2] === 'BANNED'
                            ? (hideBorders ? LibStyles.banned_label_no_background : LibStyles.banned_label) : 
                            (!hideBorders ? LibStyles.label_background_lightgray : '')}`}
                        onClick={() => toggle_banned_label(index * 2)}
                    >
                        <div className={LibStyles.label_row_1}>
                            <div className={LibStyles.master_category}>
                                {labels[index * 2] ? labels[index * 2].split('#')[1] : ''}
                            </div>
                            <div className={LibStyles.author_mini_label}>
                                {labels[index * 2] ? '(' + labels[index * 2].split('#')[0].substring(0, 3) + ')' : ''}
                            </div>
                        </div>
                        <div className={LibStyles.sub_label}>{labels[index * 2] ? labels[index * 2].split('#')[2] : ''}</div>
                        <div className={LibStyles.sub_label}>{labels[index * 2] ? labels[index * 2].split('#')[3] : ''}</div>
                    </div>
                    <div
                        className={`${LibStyles.label} ${labels[index * 2 + 1] === 'BANNED'
                            ? (hideBorders ? LibStyles.banned_label_no_background : LibStyles.banned_label) : 
                            (!hideBorders ? LibStyles.label_background_lightgray : '')}`}
                        onClick={() => toggle_banned_label(index * 2 + 1)}
                    >
                        <div className={LibStyles.label_row_1}>
                            <div className={LibStyles.master_category}>
                                {labels[index * 2 + 1] ? labels[index * 2 + 1].split('#')[1] : ''}
                            </div>
                            <div className={LibStyles.author_mini_label}>
                                {labels[index * 2 + 1] ? '(' + labels[index * 2 + 1].split('#')[0].substring(0, 3) + ')' : ''}
                            </div>
                        </div>
                        <div className={LibStyles.sub_label}>{labels[index * 2 + 1] ? labels[index * 2 + 1].split('#')[2] : ''}</div>
                        <div className={LibStyles.sub_label}>{labels[index * 2 + 1] ? labels[index * 2 + 1].split('#')[3] : ''}</div>
                    </div>
                </div>
            ))}
        </div>
        </>)

}
export default Print_Labels;