import React, {useEffect, useState} from "react";
import { Line } from "react-chartjs-2";
import { format } from 'date-fns';
import {load_fetch} from "../ofb_util";

const WeightLossChart = (props) => {
    const [dummy, setDummy] = useState(0);

    const buttonClicked = () => {
        setDummy(dummy + 1);
    }
    useEffect(() => {
    }, [props.chart_data]);

    if(props.chart_data) {
        return (
            <div>
                <Line data={props.chart_data} />
            </div>
        );
    } else {
        return (
            <>
                <div>
                    <button onClick={buttonClicked}>Click Me</button>
                    <div>{dummy}{props.chart_data}</div>
                </div>
                <div>
                    No Weight Loss Data Loaded
                </div>
            </>
        )
    }
}

export default WeightLossChart;