import TodayStyles from "../styles/today.module.css";
import GenStyles from "../styles/ofb_gen_styles.module.css";
import {useEffect, useState} from "react";
import AreYouSureModal from "../are_you_sure_modal";
import ToggleButtonGroup from "../toggle_button_group";


//TODO: Allow creation of new items

const TodoDailyEditBlock = (props) => {
    const [name, setName] = useState(props.item ? props.item.title : "");
    const [dailyId, setDailyId] = useState(props.item ? props.item.dailyid : 0);
    const [details, setDetails] = useState(props.item ? props.item.details : "");
    const [startDate, setStartDate] = useState(props.item ? props.item.startdate : null);
    const [endDate, setEndDate] = useState(props.item ? props.item.enddate : null);
    const [time, setTime] = useState(props.item ? props.item.time : null);
    const [duration, setDuration] = useState(props.item ? props.item.duration : null);
    const [otherDoable, setOtherDoable] = useState(props.item ?
        (props.item.doable_by_others == 1 ? true : false) : false);
    const [lastDayOfTheMonthRepetitions, setLastDayOfTheMonthRepetitions] = useState(props.item ?
        (props.item.lastdayofthemonthrepetitions == 1 ? true : false) : null);
    const [monthlyRepetitions, setMonthlyRepetitions] = useState(props.item ?
        (props.item.monthlyrepetitions ? props.item.monthlyrepetitions : 0) : 0);
    const [timeOfDayGroup, setTimeOfDayGroup] = useState(props.item ? props.item.timeofday : 0);
    const [areYouSureModalVisibility, setAreYouSureModalVisibility] = useState(false);
    const [addNewWeeklyRepetitions, setAddNewWeeklyRepetitions] = useState({});
    const [editingDetailsVisible, setEditingDetailsVisible] = useState(props.editingDetailsVisible);

    const handleSave = () => {
        //Assemble data into json object
        let saveData = {
            dailyId: dailyId,
            name: name,
            details: details,
            startDate: startDate,
            endDate: endDate,
            time: time,
            duration: duration,
            doable_by_others: otherDoable,
            timeofday: timeOfDayGroup,
            lastdayofthemonthrepetitions: lastDayOfTheMonthRepetitions,
            monthlyrepetitions: monthlyRepetitions,
        }
        if(props.type == "addNew") {
            saveData['weeklyrepetitions'] = addNewWeeklyRepetitions;
            props.handleSave(saveData);
            props.reloadItemsCall();
            setEditingDetailsVisible(false);
            return;
        } else {
            props.clickHandler(saveData);
        }
    }
    useEffect(() => {
        setEditingDetailsVisible(props.editingDetailsVisible);
        }, [props.editingDetailsVisible]);
    const handleDelete = () => {
        setAreYouSureModalVisibility(true);

    }
    const noButtonPressed = () => {
        setAreYouSureModalVisibility(false);
    }
    const yesButtonPressed = () => {
        setAreYouSureModalVisibility(false);
        props.handleDeleteItem(dailyId);
    }

    const addNewDaysOfWeekButtonGroupCallback = (props) => {
        let dayOfWeek = props.toString().replace("undefined_", "");
        let tempDOW = addNewWeeklyRepetitions;
        tempDOW[dayOfWeek] = !tempDOW[dayOfWeek];
        setAddNewWeeklyRepetitions(tempDOW);
    }
    const addNewDaysOfWeekButtonGroup = [
        {"id": 0, "text": "Su", "parentId": null, "initialState": false,
            "callback": addNewDaysOfWeekButtonGroupCallback},
        {"id": 1, "text": "Mo", "parentId": null, "initialState": false,
            "callback": addNewDaysOfWeekButtonGroupCallback},
        {"id": 2, "text": "Tu", "parentId": null, "initialState": false,
            "callback": addNewDaysOfWeekButtonGroupCallback},
        {"id": 3, "text": "We", "parentId": null, "initialState": false,
            "callback": addNewDaysOfWeekButtonGroupCallback},
        {"id": 4, "text": "Th", "parentId": null, "initialState": false,
            "callback": addNewDaysOfWeekButtonGroupCallback},
        {"id": 5, "text": "Fr", "parentId": null, "initialState": false,
            "callback": addNewDaysOfWeekButtonGroupCallback},
        {"id": 6, "text": "Sa", "parentId": null, "initialState": false,
            "callback": addNewDaysOfWeekButtonGroupCallback},
    ];


    if(editingDetailsVisible) {
        return (
            <>
                <AreYouSureModal
                    visible={areYouSureModalVisibility}
                    subText={props.item ? props.item.title : null}
                    yesButtonPressed={yesButtonPressed}
                    noButtonPressed={noButtonPressed}
                    mainText="Are you sure you want to delete this item?"
                />
                <div
                    className={props.editingDetailsVisible ? TodayStyles.details_editing_visible : GenStyles.hidden}>
                    <div className={TodayStyles.edit_form_element}>
                        <label>Title</label>
                        <input
                            type="text"
                            className={GenStyles.input}
                            onChange={event => setName(event.target.value)}
                            defaultValue={(props.item ? props.item.title : null)}
                        />
                    </div>
                    <div className={TodayStyles.edit_form_element}>
                        <label>Details</label>
                        <input
                            type="text"
                            className={GenStyles.input}
                            onChange={event => setDetails(event.target.value)}
                            defaultValue={props.item ? props.item.details : null}
                        />
                    </div>
                    <div className={(props.type == "addNew") ? TodayStyles.edit_form_element: GenStyles.hidden} >
                        <label>Days of the Week</label>
                        <div className={TodayStyles.day_of_the_week_selector}>
                            <ToggleButtonGroup items={addNewDaysOfWeekButtonGroup} />
                        </div>
                    </div>
                    <div className={TodayStyles.edit_form_element}>
                        <label>Start Date</label>
                        <input
                            type="date"
                            className={GenStyles.input}
                            onChange={event => setStartDate(event.target.value)}
                            defaultValue={props.item ? props.item.startdate : null}
                        />
                    </div>
                    <div className={TodayStyles.edit_form_element}>
                        <label>End Date</label>
                        <input
                            type="date"
                            className={GenStyles.input}
                            onChange={event => setEndDate(event.target.value)}
                            defaultValue={props.item ? props.item.enddate : null}
                        />
                    </div>
                    <div className={TodayStyles.edit_form_element}>
                        <label>Time</label>
                        <input
                            type="time"
                            className={GenStyles.input}
                            onChange={event => setTime(event.target.value)}
                            defaultValue={props.item ? props.item.starttime : null}
                        />
                    </div>
                    <div className={TodayStyles.edit_form_element}>
                        <label>Duration (optional)</label>
                        <input
                            type="number"
                            className={GenStyles.input}
                            onChange={event => setDuration(event.target.value)}
                            defaultValue={props.item ? props.item.duration : null}
                        />
                    </div>
                    <div className={TodayStyles.edit_form_element}>
                        <label>Time of Day Group</label>
                        <select
                            className={GenStyles.select}
                            onChange={event => setTimeOfDayGroup(event.target.value)}
                            defaultValue={timeOfDayGroup}
                        >
                            <option value="0">Any Time</option>
                            <option value="1">Early Morning</option>
                            <option value="2">Late Morning</option>
                            <option value="3">Noon</option>
                            <option value="4">Evening</option>
                            <option value="5">Before Bed</option>
                        </select>
                    </div>
                    <div className={TodayStyles.edit_form_element}>
                        <label>Doable by Others </label>
                        <input
                            type="checkbox"
                            className={GenStyles.check}
                            onChange={event => setOtherDoable(!otherDoable)}
                            defaultChecked={otherDoable}
                        />
                    </div>
                    <div className={TodayStyles.edit_form_element}>
                        <label>Monthly Repetitions</label>
                        <select
                            className={GenStyles.select}
                            onChange={event => setMonthlyRepetitions(event.target.value)}
                            defaultValue={monthlyRepetitions}
                        >
                            <option value="0">None</option>
                            <option value="1">1st</option>
                            <option value="2">2nd</option>
                            <option value="3">3rd</option>
                            <option value="4">4th</option>
                            <option value="5">5th</option>
                            <option value="6">6th</option>
                            <option value="7">7th</option>
                            <option value="8">8th</option>
                            <option value="9">9th</option>
                            <option value="10">10th</option>
                            <option value="11">11th</option>
                            <option value="12">12th</option>
                            <option value="13">13th</option>
                            <option value="14">14th</option>
                            <option value="15">15th</option>
                            <option value="16">16th</option>
                            <option value="17">17th</option>
                            <option value="18">18th</option>
                            <option value="19">19th</option>
                            <option value="20">20th</option>
                            <option value="21">21st</option>
                            <option value="22">22nd</option>
                            <option value="23">23rd</option>
                            <option value="24">24th</option>
                            <option value="25">25th</option>
                            <option value="26">26th</option>
                            <option value="27">27th</option>
                            <option value="28">28th</option>
                            <option value="29">29th</option>
                            <option value="30">30th</option>
                            <option value="31">31st</option>
                        </select>
                    </div>
                    <div className={TodayStyles.edit_form_element}>
                        <label>Repeat on Last Day of the Month </label>
                        <input
                            type="checkbox"
                            className={GenStyles.check}
                            onChange={event => setLastDayOfTheMonthRepetitions(!lastDayOfTheMonthRepetitions)}
                            defaultChecked={lastDayOfTheMonthRepetitions}
                        />
                    </div>
                    <div className={TodayStyles.edit_save_button}>
                        <button
                            className={`${GenStyles.button} ${GenStyles.black} ${GenStyles.font_white}`}
                            onClick={handleSave}
                        >Save</button>
                        <button
                            className={`${GenStyles.button} ${GenStyles.red} ${GenStyles.font_white} 
                            ${props.type=="addNew" ? GenStyles.hidden : ""}`} onClick={handleDelete}
                        >Delete</button>
                    </div>
                </div>
            </>
        );
    } else {
        return <></>;
    }
};

export default TodoDailyEditBlock;