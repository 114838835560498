import styles from './styles/nav_bar_top.module.css';
import LogoutButton from "./logout_button";
import NavBarTopItem from "./nav_bar_top_item";
import {useEffect, useState} from "react";

const NavBarTop = (props) => {

    const [currentTime, setCurrentTime] = useState(false);
    useEffect(() => {
        get_current_time();
    }, [])
    const handleMoreMenu = () => {
        const mmd = document.getElementById('moreMenuDiv');
        if(mmd.className.indexOf("w3-show") == -1) {
            mmd.className += " w3-show";
        } else {
            mmd.className = mmd.className.replace(" w3-show", "");
        }
    }
    function get_current_time() {
        let date = new Date();
        let hh = date.getHours();
        let mm = date.getMinutes();
        let ss = date.getSeconds();
        let session = "AM";

        if(hh == 0){
            hh = 12;
        }
        if(hh > 12){
            hh = hh - 12;
            session = "PM";
        }

        hh = (hh < 10) ? "0" + hh : hh;
        mm = (mm < 10) ? "0" + mm : mm;
        ss = (ss < 10) ? "0" + ss : ss;

        let time = hh + ":" + mm + ":" + ss + " " + session;

        document.getElementById("clock").innerText = time;
        setCurrentTime(time);
        let t = setTimeout(function(){ get_current_time() }, 1000);
    }


    if(window.location.pathname !== "/pulse") {

        return (
            <div className="w3-top">
                <div className="w3-bar w3-black w3-card">
                    <a href="/" className="w3-bar-item w3-button w3-padding-large">HOME</a>
                    <a href="/bible" className="w3-bar-item w3-button w3-padding-large">BIBLE</a>
                    <a href="/church" className="w3-bar-item w3-button w3-padding-large w3-hide-small">CHURCH</a>
                    <a href="/lang" className="w3-bar-item w3-button w3-padding-large w3-hide-small">LANGUAGE</a>
                    <NavBarTopItem
                        linkName="LIFE"
                        pageAnchor="navigation_blocks"
                        linkPage="newlife"
                        classNames="w3-bar-item w3-button w3-padding-large"
                        showIfUnauthenticated="false"
                    />
                    <div className="w3-dropdown-click w3-hide-large w3-right">
                        <button onClick={handleMoreMenu} className={`w3-black w3-padding-large ${styles.more_button}`}>&#9776;</button>
                        <div id="moreMenuDiv" className={`w3-black w3-dropdown-content w3-bar-block w3-card-4 ${styles.more_menu_div}`}>
                            <a href="/church" className="w3-bar-item w3-button">CHURCH</a>
                            <a href="/language" className="w3-bar-item w3-button">LANGUAGE</a>
                        </div>
                    </div>
                    <div
                        id="clock"
                        className="w3-bar-item w3-padding-large w3-hide-small"
                    >
                        CLOCK
                    </div>
                    <LogoutButton />
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default NavBarTop;