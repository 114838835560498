import TodayStyles from "../styles/today.module.css";
import GenStyles from "../styles/ofb_gen_styles.module.css";
import ToggleButton from "../toggle_button";
import {useEffect, useRef, useState} from "react";
import TodoDailyEditBlock from "./edit_block";
import {load_fetch} from "../ofb_util";

//TODO: When editing, make an item disappear if it's no longer supposed to be on that page.

const TodoDailyItem = (props) => {
    const [editingDetailsVisible, setEditingDetailsVisible] = useState(false);
    const [daysOfTheWeek, setDaysOfTheWeek] = useState(props.item.weeklyrepetitions);
    const [item, setItem] = useState(props.item);
    const isFirstRun = useRef(true);

    const checkBoxStyle = {
        display: "none"
    }

    const handleToggleEditingDetails = () => {
        setEditingDetailsVisible(!editingDetailsVisible);
    }

    const handleDelete = (dailyid) => {
        props.handleDeleteItem(dailyid);
        setEditingDetailsVisible(false);
    }

    const handleSaveEdit = (saveData) => {

        const postData = {
            operation: 'UPDATE_DAILY_ITEM',
            item: saveData,
        };

        const myCallback = (data) => {
            if(data && "error" in data) {
                console.log("ERROR???");
                console.log(data);
            } else if (data) {
                console.log("Nothing should be returned here (handleSaveEdit() in todo_daily_item.js");
                console.log(data);
            } else {
                let tempItem = item;
                tempItem.title = saveData.name;
                tempItem.details = saveData.details;
                tempItem.startdate = saveData.startDate;
                tempItem.enddate = saveData.endDate;
                tempItem.timeofday = saveData.timeofday;
                tempItem.starttime = saveData.time;
                tempItem.duration = saveData.duration;
                tempItem.doable_by_others = saveData.doable_by_others;
                tempItem.lastdayofthemonthrepetitions = saveData.lastdayofthemonthrepetitions;
                tempItem.monthlyrepetitions = saveData.monthlyrepetitions;

                //Recalculate isActive using javascript
                tempItem.isActive = isActive(saveData.startDate, saveData.endDate);

                setItem(tempItem);
                setEditingDetailsVisible(false);
            }
        }
        load_fetch('daily_handler.php', postData, myCallback);
    }

    function isActive(startDateString, endDateString) {
        const startDate = new Date(startDateString);
        const endDate = new Date(endDateString);
        const thisDate = new Date();

        if(((startDate.valueOf() < thisDate.valueOf()) || (!startDateString)) &&
            ((endDate.valueOf() > thisDate.valueOf()) || (!endDateString))) {
            return true;
        }
        return false;
    }

    const handleDayOfTheWeekToggleButtonPush = (args) => {
        let dayOfWeek = args.split("_")[1];
        if(daysOfTheWeek.indexOf(dayOfWeek) == -1) {
            setDaysOfTheWeek(daysOfTheWeek + dayOfWeek);
        } else {
            setDaysOfTheWeek(daysOfTheWeek.replace(dayOfWeek, ""));
        }
    }

    useEffect(() => {
        if(isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        const postData = {
            operation: 'UPDATE_DAYS_OF_THE_WEEK',
            dailyid: props.item.dailyid,
            weeklyrepetitions: daysOfTheWeek,
        };

        const myCallback = (data) => {
            if(data && "error" in data) {
            } else if (data) {
                console.log(data);
            } else {
            }
        }
        load_fetch('daily_handler.php', postData, myCallback);
        }, [daysOfTheWeek])



    if(props.editing) {
        return (
            <tr>
                <td>
                    <div className={TodayStyles.daily_item}>
                        <div
                            className={(item.isActive ? TodayStyles.daily_item_title :
                                TodayStyles.daily_item_title_inactive) + " " + (TodayStyles.daily_item_title_editing)}
                            onClick={handleToggleEditingDetails}
                        >{item.title}</div>
                        <div className={TodayStyles.day_of_the_week_selector}>
                            <ToggleButton
                                label_text="Su"
                                parentId={item.dailyid}
                                checked={(item.weeklyrepetitions && item.weeklyrepetitions.indexOf("Su") != -1) ?
                                    true : false}
                                handleToggleButtonPush={handleDayOfTheWeekToggleButtonPush}
                            />
                            <ToggleButton
                                label_text="Mo"
                                parentId={item.dailyid}
                                checked={(item.weeklyrepetitions && item.weeklyrepetitions.indexOf("Mo") != -1) ?
                                    true : false}
                                handleToggleButtonPush={handleDayOfTheWeekToggleButtonPush}
                            />
                            <ToggleButton
                                label_text="Tu"
                                parentId={item.dailyid}
                                checked={(item.weeklyrepetitions && item.weeklyrepetitions.indexOf("Tu") != -1) ?
                                    true : false}
                                handleToggleButtonPush={handleDayOfTheWeekToggleButtonPush}
                            />
                            <ToggleButton
                                label_text="We"
                                parentId={item.dailyid}
                                checked={(item.weeklyrepetitions && item.weeklyrepetitions.indexOf("We") != -1) ?
                                    true : false}
                                handleToggleButtonPush={handleDayOfTheWeekToggleButtonPush}
                            />
                            <ToggleButton
                                label_text="Th"
                                parentId={item.dailyid}
                                checked={(item.weeklyrepetitions && item.weeklyrepetitions.indexOf("Th") != -1) ?
                                    true : false}
                                handleToggleButtonPush={handleDayOfTheWeekToggleButtonPush}
                            />
                            <ToggleButton
                                label_text="Fr"
                                parentId={item.dailyid}
                                checked={(item.weeklyrepetitions && item.weeklyrepetitions.indexOf("Fr") != -1) ?
                                    true : false}
                                handleToggleButtonPush={handleDayOfTheWeekToggleButtonPush}
                            />
                            <ToggleButton
                                label_text="Sa"
                                parentId={item.dailyid}
                                checked={(item.weeklyrepetitions && item.weeklyrepetitions.indexOf("Sa") != -1) ?
                                    true : false}
                                handleToggleButtonPush={handleDayOfTheWeekToggleButtonPush}
                            />
                        </div>
                    </div>
                    <TodoDailyEditBlock
                        item={item}
                        clickHandler={handleSaveEdit}
                        editingDetailsVisible={editingDetailsVisible}
                        handleDeleteItem={handleDelete}
                    />

                </td>
            </tr>
        )
    } else {
        return (
            <tr>
                <td
                    onClick={() => {
                        props.clickEventHandler({'id': item.dailyid, 'title': item.title})
                    }} className={GenStyles.pointer}
                >
                    {item.title} <span className={GenStyles.font_small}>
                        (<span className={GenStyles.font_red}>{item.days_since_done}</span>)
                    </span>
                </td>
            </tr>
        );
    }
};

export default TodoDailyItem;