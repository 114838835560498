import PageHeader from "./page_header";
import styles from './styles/church.module.css';
import { Link } from 'react-router-dom';

const Church = (props) => {
    return (
        <>
            <PageHeader page_title="OUT FROM BABEL"/>

            <div style={{textAlign: "left"}}>
                <h3>Baptism</h3>
                <p className={styles.church_sub_item}>
                    <Link
                        to='../introduction-to-linguistics'
                        className={styles.church_link}
                    >
                        8 week course on baptism for families
                    </Link>
                </p>
                <h3>Bible Memory</h3>
                <p className={styles.church_sub_item}>
                    <a
                        href='http://outfrombabel.com/bible/mem'
                        className={styles.church_link}
                    >
                        An web app that children or adults can use to practice Bible Memory
                    </a>
                </p>
            </div>
        </>
    );
};

export default Church;