import React, {useState, useEffect} from "react";
import Styles from "../styles/ofbstyles.module.css";
import {load_fetch} from "../ofb_util";

const CleaningSubItemForm = (props) => {
    //DATA
    const [newItemData, setNewItemData] = useState({
        new_subitem_name: ''
    });

    //USEEFFECTS
    useEffect(() => {
        if(!props.is_shown) {
            clear_form();
        }
    }, [props.is_shown]);

    //AJAX FUNCTIONS
    const save_new_subitem = () => {
        let url = 'Cleaning_Handler.php';
        let postData = {
            operation: 'SAVE_NEW_CLEANING_SUBITEM',
            ...newItemData,
            parent_id:props.parent_id
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                //Clear the Form
                clear_form();

                //Send the signal up to close this and update the GUI
                props.on_save(data);

            } else {
                console.log("FAILED TO INSERT NEW SUBITEM");
                console.log(data);
            }
        });
    }

    //OTHER FUNCTIONS
    const clear_form = () => {
        setNewItemData({
            new_subitem_name: '',
            parent_id: props.parent_id,
            category: null,
            new_category: null,
            frequency:null
        });
        document.getElementById('category').value = '';
    }
    const update_data = (event) => {
        const {name, value} = event.target;
        setNewItemData(prevData => ({
            ...prevData,
            [name]: value
        }));
    }
    const handle_key_press = (event) => {
        if(event.charCode === 13) {
            save_new_subitem();
        }
    }
    const handle_category_change = (event) => {
        setNewItemData(prevData => ({
            ...prevData,
            category: event.target.value,
            new_category: ''
        }));
    }

    //RENDERING
    return (
        <div className={Styles.ofb_new_todo_form}>
            <div className={Styles.ofb_dashed_line_bottom}>
            </div>
            <div className={Styles.ofb_new_cleaning_form_row}>
                <div>New SubItem:</div>
                <div>
                    <input
                        type="text"
                        className={Styles.ofb_new_todo_form_text_input}
                        id="new_subitem_name"
                        name="new_subitem_name"
                        value={newItemData['new_subitem_name']}
                        placeholder="cleaning task name"
                        onChange={update_data}
                        onKeyPress={handle_key_press}
                    />
                </div>
                <div>
                    <input
                        type="text"
                        className={Styles.ofb_new_todo_form_text_input}
                        id="new_subitem_frequency"
                        name="new_subitem_frequency"
                        value={newItemData['new_subitem_frequency'] ?? ''}
                        placeholder="frequency (in days)"
                        onChange={update_data}
                        onKeyPress={handle_key_press}
                    />
                </div>
                <div>
                    <select
                        className={Styles.ofb_cleaning_category_select}
                        onChange={handle_category_change}
                        name="category"
                        id="category"
                    >
                        <option value="">NEW CATEGORY:</option>
                        {props.categories && props.categories.length > 0
                            && props.categories.map((item, index) => (
                            <option key={index} value={item}>{item}</option>
                        ))}
                    </select>
                    <input
                        type="text"
                        className={(newItemData['category'] && newItemData['category'].length > 0) ? Styles.ofb_hide : Styles.ofb_cleaning_text_input     }
                        id="new_category"
                        name="new_category"
                        value={newItemData['new_category'] ?? ''}
                        onChange={update_data}
                        onKeyPress={handle_key_press}
                        disabled = {(newItemData['category'] && newItemData['category'].length > 0) ? true : false}
                    />
                </div>
                <div>
                    <button
                        className={Styles.ofb_button}
                        onClick={save_new_subitem}
                    >Save</button>
                </div>
            </div>
        </div>
    )
}

export default CleaningSubItemForm;