import React, {useEffect, useState} from "react";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { format } from 'date-fns';

const WeightChart = (props) => {
    const [weights, setWeights] = useState(null);
    const [dates, setDates] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [truncatedWeights, setTruncatedWeights] = useState(null);
    const [truncatedDates, setTruncatedDates] = useState(null);
    const [truncatedChartData, setTruncatedChartData] = useState(null);
    const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;

    useEffect(() => {
        if(!props.data) {
            return;
        }
        let local_weights = [];
        let local_dates = [];
        let local_weights_recent = [];
        let local_dates_recent = [];
        const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
        const today = new Date();

        for(let i = props.data.length - 1; i >= 0; i--) {
            let weight_date = new Date(props.data[i]['weightdateandtime']);
            const timeDifference = today.getTime() - weight_date.getTime();

            //Decide whether to include this data point in the "most recent 30 days" array
            if(timeDifference <= thirtyDaysInMilliseconds) {
                local_dates_recent.push(format(weight_date, 'M/d/yy'));
                local_weights_recent.push(props.data[i]['weight']);
            }

            local_weights.push(props.data[i]['weight']);
            local_dates.push(format(weight_date, 'M/d/yy'));
        }
        setDates(local_dates);
        setWeights(local_weights);
        setTruncatedDates(local_dates_recent);
        setTruncatedWeights(local_weights_recent);

        const data = {
            labels: dates,
            datasets: [
                {
                    label: "Weight - All Time",
                    backgroundColor: "rgb(255, 99, 132)",
                    borderColor: "rgb(255, 99, 132)",
                    data: weights,
                },
            ],
        };
        setChartData(data);

        const truncated_data = {
            labels: truncatedDates,
            datasets: [
                {
                    label: "Weight - Last 30 Days",
                    backgroundColor: "rgb(255,99,132)",
                    borderColor: "rgb(255, 99, 132)",
                    data: truncatedWeights
                },
            ],
        };
        setTruncatedChartData(truncated_data);
    }, [props.data]);


    if(chartData) {
        if(props.range=='all') {
            return (
                <div>
                    <Line data={chartData}/>
                </div>
            )
        } else if(props.range == '30 days') {
            return (
                <div>
                    <Line data={truncatedChartData} />
                </div>
            )
        }
    } else {
        return <></>
    }
};

export default WeightChart;