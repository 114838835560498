import { useContext} from "react";
import { AuthContext } from './auth_context';
import Config from "./ofb_config.json";

const LogoutButton = (props) => {
    const [authStatus, setAuthStatus] = useContext(AuthContext);

    function logout() {
        let url = Config.api_url + "auth_handler.php";

        const options = {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({operation: 'LOGOUT'})
        };

        fetch(url, options)
            .then(result => result.json())
            .then(data => {
                if(!data['LOGIN_STATUS']) {
                    setAuthStatus(false);

                } else {


                }
            })
            .catch(error => {
                console.log('ERROR: ' + error);
            })
    }

    if(authStatus) {
        return (
            <div className="w3-right">
                <button className="w3-bar-item w3-button w3-padding-large" onClick={logout}>Log Out</button>
            </div>
        );
    } else {
        return (
            <div className="w3-right">
                <button className="w3-bar-item w3-button w3-padding-large">Sign In</button>
            </div>
        );

    }
};

export default LogoutButton;