import ToggleButton from "./toggle_button";

const ToggleButtonGroup = (props) => {
    return (
        <>
            {props.items.map((item) =>
                <ToggleButton
                    label_text={item.text}
                    parentId={props.parentId}
                    checked={item.initialState}
                    handleToggleButtonPush={item.callback}
                    key={item.id}
                />
            )}
        </>
    );
};

export default ToggleButtonGroup;