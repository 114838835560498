import React, {useState} from "react"
import Config from "../ofb_config.json";


const TodoQuick = (props) => {
    const [file, setFile] = useState('');
    const [title, setTitle] = useState('notitle');

    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const handleUploadClick = () => {
        let url = '../' + Config.api_url + 'todo_handler.php';
        var formData = new FormData();
        formData.append('file', file);
        formData.append('operation', 'file_upload');

        fetch(url, {
            // content-type header should not be specified!
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(success => {
                console.log(success);
                // Do something with the successful response
            })
            .catch(error => console.log(error)
            );
    }

    // const handleUploadClick = () => {
    //     var formData = new FormData();
    //     formData.append('title', title);
    //     formData.append('file_upload', file);
    //     formData.append('operation', 'UPLOAD_TODO_IMAGE');
    //
    //     console.log(formData);
    //
    //     if (!file) {
    //         return;
    //     }
    //
    //     let url = '../' + Config.api_url + 'todo_handler.php';
    //     console.log(url);
    //     fetch(url, {
    //         method: 'POST',
    //         body: JSON.stringify({'title':title, 'file': file, 'operation': 'UPLOAD_TODO_IMAGE'}),
    //     })
    //         .then((res) => res.json())
    //         .then((data) => {
    //                 console.log("HERE");
    //                 console.log(data);
    //             }
    //         )
    //         .catch((err) => console.error(err));
    // };

    return (
        <>
            <div>Hello</div>
            <input type="file" onChange={handleFileChange} />
            <input type="text" value={title} onChange={(event) => setTitle(event.target.value)} />
            <div>{file && `${file.name} - ${file.type}`}</div>
            <button onClick={handleUploadClick}>Upload</button>
        </>
    )
}
export default TodoQuick;
