import React, {useEffect, useState} from "react";
import {talk} from "../ofb_util.js";
import GenStyles from "../styles/ofb_gen_styles.module.css";
import TRStyles from "../styles/todo_rapid_entry_module.module.css";

const TODORapidEntryModule = (props) => {
    const [title, setTitle] = useState("");


    const process_new_todo_item = () => {
        if(title.trim().length === 0) {
            return;
        }

        let today = new Date();
        const offset = today.getTimezoneOffset();
        today = new Date(today.getTime() - (offset*60*1000));
        today = today.toISOString().split('T')[0];

        let postData = {
            title:title,
            details:'',
            parentid: -1,
            duedate: today,
            urgency:0,
            isproject:0,
            pinned:1
        }
        talk("todo_handler.php", "INSERT_NEW_ITEM", postData, process_new_todo_item_handler);

    }
    const process_new_todo_item_handler = (operation, sent_data, return_data) => {
        if(return_data['ERROR']) {
            console.log(return_data);
        } else {
            setTitle('');

            //Call Parent Handler if there is one
            if(props.handle_add_item) {
                props.handle_add_item();
            }
        }
    }



    const handle_key_press = (event) => {
        if(event.key === "Enter") {
            process_new_todo_item();
        }
    }

    return (
        <div className={TRStyles.container}>
        <div className={TRStyles.main}>
            <input
                className={TRStyles.title_entry}
                type="text"
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                onKeyDown={(event) => handle_key_press(event)}
            />
            <button
                className={TRStyles.submit_button}
                onClick={process_new_todo_item}
            >Add</button>
        </div>
        </div>
    )
}

export default TODORapidEntryModule;