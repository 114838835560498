import React from "react";
import Styles from "../styles/ofbstyles.module.css"
import {format_currency_new} from "../ofb_util";

const GivingReport = (props) => {
    console.log(props);
    if(props.data) {
        return (
            <div className={Styles.ofb_finance_giving_report}>
                <div className='w3-xlarge'>Giving</div>
                <div>Giving Target: {format_currency_new(props.data['GIVING_TARGET'])}</div>
                <div>Giving So Far: {format_currency_new(props.data['GIVING_SO_FAR'])}</div>
                <div>Difference (shortfall): {format_currency_new(props.data['GIVING_DIFFERENCE'])}</div>
            </div>
        )
    } else {
        return <></>
    }
}
export default GivingReport;