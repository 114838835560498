import React, {useEffect, useState} from "react";
import Styles from "../styles/ofbstyles.module.css";
import {load_fetch} from "../ofb_util";
import TrashIcon from "../images/trash_can.png";
import Checkmark from "../images/checkmark.png";
import Tomorrow from "../images/tomorrow.png";
import Sleep from "../images/sleep.png";
import Eye from "../images/view_eye.png";
import TodayStar from "../images/today_star.png";
import {useNavigate} from "react-router-dom";

const TODOManageOverdue = () => {
    //STATE VALUES
    const [overdueItems, setOverdueItems] = useState([]);
    const [specificDate, setSpecificDate] = useState(
        (new Date()).getFullYear() + "-" + ((new Date()).getMonth() + 1).toString().padStart(2, "0") +
        "-" + (new Date()).getDate().toString().padStart(2, "0")
    );
    const navigate = useNavigate();

    //USE EFFECTS
    useEffect(() => {
        if(overdueItems.length <= 0) {
            load_overdue_items();
        }
    }, []);

    //AJAX FUNCTIONS
    const load_overdue_items = () => {
        const postData = {
            operation: "GET_OVERDUE_ITEMS",
            duedate: specificDate
        }
        load_fetch('todo_handler.php', postData, function(data) {
            console.log(data);
            if(data && "ERROR" in data) {
                console.log("ERROR: FAILED TO LOAD ITEMS");
                console.log(data);
            } else if (data) {
                if(typeof data === 'object') {
                    setOverdueItems(data);
                }
            } else {
                console.log(data);
                console.log("Should return data, but doesn't. In load_overdue_items() in TODOManageOverdue.js");
            }
        });
    }
    const mark_item_done = (index, todoid) => {
        if(overdueItems[index].has_subitems) {
            alert("This has subitems and can't be marked complete");
            return;
        }

        //Mark Item Completed
        const postData = {
            operation: "MARK_ITEM_COMPLETED",
            todoid: todoid
        }

        load_fetch(
            'todo_handler.php',
            postData,
            function(data) {
                if(data && "ERROR" in data) {
                    console.log("ERROR: FAILED TO MARK ITEM COMPLETED");
                    console.log(data);
                } else if (data) {
                    load_overdue_items();
                } else {
                    console.log(data);
                }
            });
    }
    const delete_item = (index, todoid) => {
        if(overdueItems[index].has_subitems) {
            alert("This has subitems and can't be deleted");
            return;
        }

        //DELETE ITEM
        const postData = {
            operation: "DELETE_ITEM",
            todoid: todoid
        }

        load_fetch(
            'todo_handler.php',
            postData,
            function(data) {
                if(data && "ERROR" in data) {
                    console.log("ERROR: FAILED TO MARK ITEM COMPLETED");
                    console.log(data);
                } else if (data) {
                    load_overdue_items();
                } else {
                    console.log(data);
                }
            });
    }
    const move_item_to_today = (todoid) => {
        //MOVE ITEM TO TODAY
        const postData = {
            operation: "MOVE_ITEM_TO_TODAY",
            todoid: todoid
        }
        load_fetch(
            'todo_handler.php',
            postData,
            function(data) {
                console.log(data);
                load_overdue_items();
            });
    }
    const move_item_to_tomorrow = (todoid) => {
        //MOVE ITEM TO TOMORROW
        const postData = {
            operation: "MOVE_ITEM_TO_TOMORROW",
            todoid: todoid
        }
        load_fetch(
            'todo_handler.php',
            postData,
            function(data) {
                load_overdue_items();
            });
    }
    const remove_duedate = (todoid) => {
        //REMOVE DUEDATE
        const postData = {
            operation: "REMOVE_DUEDATE",
            todoid: todoid
        }
        load_fetch(
            'todo_handler.php',
            postData,
            function(data) {
                load_overdue_items();
            });
    }

    //BUTTONS
    const delete_item_button = (index, todoid) => {
        return <button
            title="Delete Item"
            className={`${Styles.ofb_button} ${Styles.ofb_red}`}
            onClick={() => delete_item(index, todoid)}
        ><img src={TrashIcon} /></button>;
    }
    const mark_item_done_button = (index, todoid) => {
        return <button
            title="Mark Completed"
            className={`${Styles.ofb_button} ${Styles.ofb_red}`}
            onClick={() => mark_item_done(index, todoid)}
        ><img src={Checkmark} /></button>;
    }
    const move_item_to_tomorrow_button = (todoid) => {
        return <button
            title="Move to Tomorrow"
            className={`${Styles.ofb_button} ${Styles.ofb_red}`}
            onClick={() => move_item_to_tomorrow(todoid)}
        ><img src={Tomorrow} /></button>;
    }
    const remove_duedate_button = (todoid) => {
        return <button
            title="Remove Due Date"
            className={`${Styles.ofb_button} ${Styles.ofb_red}`}
            onClick={() => remove_duedate(todoid)}
        ><img src={Sleep} /></button>;
    }
    const move_item_to_today_button = (todoid) => {
        return <button
            title="Move to Today"
            className={`${Styles.ofb_button} ${Styles.ofb_red}`}
            onClick={() => move_item_to_today(todoid)}
        ><img src={TodayStar} /></button>;
    }
    const view_item_button = (index, todoid) => {
        return <button
            title="View Item"
            className={`${Styles.ofb_button} ${Styles.ofb_red}`}
            onClick={() => navigate_to_todo_item(index, todoid)}
        ><img src={Eye} /></button>;
    }

    const navigate_to_todo_simple = () => {
        document.location.href='todo-active-simple';
    }
    const navigate_to_today = () => {
        document.location.href='today';
    }
    const navigate_to_todo_item = (index, todoid) => {
        navigate("/todo", {state: {focus_todo_item: overdueItems[index]}});
    }

    //RENDERING CODE
    if(overdueItems.length > 0) {
        return (<div>
            <button
                className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                onClick={navigate_to_todo_simple}
            >Back to TODO</button>
            <button
                className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                onClick={navigate_to_today}
            >Back to Today</button>
            <h3>Overdue Items</h3>
            <div className={Styles.ofb_todo_overdue_holder}>
            {overdueItems.map((item, index) => (
                <div className={`${Styles.ofb_todo_overdue_item} ${(index % 2 == 0) ? Styles.ofb_shaded : ''}`} key={index}>
                    <div className={Styles.ofb_overdue_item_title}>{item.title} ({item.duedate.substring(0, 10)})</div>
                    <div className={Styles.ofb_overdue_item_controls}>
                        <div>{delete_item_button(index, item.todoid)}</div>
                        <div>{mark_item_done_button(index, item.todoid)}</div>
                        <div>{move_item_to_tomorrow_button(item.todoid)}</div>
                        <div>{remove_duedate_button(item.todoid)}</div>
                        <div>{move_item_to_today_button(item.todoid)}</div>
                        <div>{view_item_button(index, item.todoid)}</div>
                    </div>
                </div>
            ))}
            </div>
        </div>)
    } else {
        return (<>
            <button
                className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                onClick={navigate_to_todo_simple}
            >Back to TODO</button>
            <div>No Overdue Items</div>
        </>)
    }

}

export default TODOManageOverdue;