import {Outlet} from "react-router-dom";
import SlideShowHeader from './slide_show_header';
import Footer from './footer';
import NavBarTop from "./nav_bar_top";
import styles from './styles/layout.module.css';
import React from 'react';
import Signin from "./signin";

const Layout = () => {

    if(window.location.pathname !== "/pulse") {
        return (
            <>
                <NavBarTop/>
                <div className={`w3-content ${styles.main_body_div}`}>
                    <SlideShowHeader/>
                    <div className={`w3-container w3-content w3-center w3-padding-64 ${styles.page_content_div}`}>
                        <Signin/>
                        <Outlet/>
                    </div>
                    <Footer/>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className={styles.pulse_header}>
                    <h1>PULSE <span className={styles.heart}>♥</span>️</h1></div>
                <div className={`w3-content ${styles.pulse_main_body}`}>
                    <div className={`w3-container w3-content w3-center w3-padding-64 ${styles.page_content_div}`}>
                        <Signin/>
                        <Outlet/>
                    </div>
                    <Footer />
                </div>
            </>
        );
    }
};
export default Layout;


