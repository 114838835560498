import React from "react";
import Styles from "../src/styles/ofbstyles.module.css";

const ConfirmModal = (props) => {

    //TODO: When a modal is shown, have it autofocus on the NO option... also, perhaps allow it to catch keyboard
    // input for clicking yes or no.
    return (
        <div className={props['show'] ? Styles.ofb_modal_shown : Styles.ofb_modal_hidden}>
            <div className={Styles.ofb_modal_content}>
                <div>{props['message'] ?? "Are you sure?"}</div>
                <div>
                    {props.data.buttons ? props.data.buttons.map((button, index) => (
                        <button
                            key={index}
                            className='w3-button'
                            id={button.id}
                            onClick={() => props['clickHandler'](button.id)}>{button.text}</button>
                    )) : (<>
                        <button
                            className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                            id='confirm_modal_true_button'
                            onClick={() => props['clickHandler'](true)}
                        >
                            {props['yes_text'] ?? "Yes"}
                        </button>
                        <button
                            className={`${Styles.ofb_button} ${Styles.ofb_black}`}
                            onClick={() => props['clickHandler'](false)}
                        >
                            {props['no_text'] ?? "No"}
                        </button>
                    </>)}
                </div>
            </div>
        </div>
    )
}
export default ConfirmModal;