import {load_fetch} from "./ofb_util";
import React, {useEffect, useState} from "react";
import StylesTDS from "./styles/todo_simple.module.css";
import Styles from "./styles/ofbstyles.module.css";
import * as ReactDOM from "react-dom";
import trash_can from "./images/trash_can.png";
import checkmark from "./images/checkmark.png";
import edit_icon from "./images/edit.png";
import insert_below_icon from "./images/insert_below.png";
import work_icon from "./images/work_30_by_30.png";
import {useLocation} from "react-router-dom";

const TodoSimple = (props) => {
    //Data
    const [items, set_items] = useState(false);
    const [urgency, setUrgency] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [showRecordWorkProgressForm, setShowRecordWorkProgressForm] = useState(false);
    const [recordProgressDateTime, setRecordProgressDateTime] = useState('');
    const [recordProgressDuration, setRecordProgressDuration] = useState('');
    const [recordProgressNotes, setRecordProgressNotes] = useState('');
    const [recordProgressErrorMessage, setRecordProgressErrorMessage] = useState('');
    const [selectedItem, setSelectedItem] = useState('');
    const [selectedItemProgressNotes, setSelectedItemProgressNotes] = useState([]);
    const {state} = useLocation();
    const today_date = (new Date()).getFullYear() + "-" + ((new Date()).getMonth() + 1).toString().padStart(2, "0") +
        "-" + (new Date()).getDate().toString().padStart(2, "0");

    //AJAX FUNCTIONS
    const load_items = () => {
        const postData = {
            operation: "GET_ALL_ITEMS"
        }
        const myCallback = (data) => {
            console.log(data);
            if (data) {
                console.log(data);
                //setItems(data);
                if(typeof data === 'object') {
                    set_items(data);
                    if(state && state['focus_todo_item']) {
                        console.log(state['focus_todo_item']);
                        handle_item_click(state.focus_todo_item);
                    }
                } else {
                    console.log(typeof data);
                }
            } else {
                console.log(data);
                console.log("Should return data, but doesn't. In loadItems() in todo.js");
            }
        }
        load_fetch('todo_handler.php', postData, myCallback);
    }

    //USE EFFECTS
    useEffect(() => {
        load_items();
    }, [props.authStatus]);
    useEffect(() => {
        //Check for a selected item (being pushed from another page)



    }, [])

    //OTHER FUNCTIONS
    const progress_notes_list = () => {
        return (
            <div>
                {selectedItemProgressNotes.map((item, index) => (
                    <div key={index}>Hello {index}</div>
                ))}
            </div>
        )
    }
    const load_progress_notes = (item) => {
        const postData = {
            operation: "GET_PROGRESS_NOTES",
            todo_id: item['todoid']
        }
        load_fetch('todo_handler.php', postData, function(data) {
            if(data && "ERROR" in data) {
                console.log("ERROR: FAILED TO GET PROGRESS NOTES");
            } else if (data) {
                //SUCCESS
                setSelectedItemProgressNotes(data);
                console.log(data);
                //Add Progress to the display (can reload progress items later)
            } else {
                //No Notes Found
                setSelectedItemProgressNotes([]);
            }
        });
    }
    const handle_item_click = (props) => {
        if(props['isproject'] == 1) {
            props['isproject'] = true;
        } else {
            props['isproject'] = false;
        }
        setSelectedItem(props);
        setIsActive(props['is_active']);
        load_progress_notes(props);

        //Add TODO ID & USER ID
        document.getElementById('todoid').value = props['todoid'];
        document.getElementById('userid').value = props['userid'];

        //Add Title
        ReactDOM.render(React.createElement("div", {className:StylesTDS.modal_item_title},
            props['title']), document.getElementById('todo_title'));

        //Add Due Date
        ReactDOM.render(React.createElement("div", {},
            <><span className={StylesTDS.modal_field_label}>DUE DATE:</span> {props['duedate']}</>
        ), document.getElementById('todo_duedate'));

        //Add Date Added
        ReactDOM.render(React.createElement("div", {},
            <><span className={StylesTDS.modal_field_label}>DATE ADDED:</span> {props['dateadded']}</>
        ), document.getElementById('todo_dateadded'));

        //Add Details
        ReactDOM.render(React.createElement("div", {},
            <><span className={StylesTDS.modal_field_label}>DETAILS:</span> {props['details']}</>
        ), document.getElementById('todo_details'));

        //Add Is Project
        // ReactDOM.render(React.createElement("div", {},
        //     <><span className={StylesTDS.modal_field_label}>IS PROJECT?</span> {props['isproject']}</>
        // ), document.getElementById('todo_isproject'));

        //Add Pinned?
        ReactDOM.render(React.createElement("div", {},
            <><span className={StylesTDS.modal_field_label}>PINNED?</span> {props['pinned']}</>
        ), document.getElementById('todo_pinned'));

        //Add Completed
        ReactDOM.render(React.createElement("div", {},
            <><span className={StylesTDS.modal_field_label}>COMPLETED?</span> {props['completed']}</>
        ), document.getElementById('todo_completed'));

        //Add PARENTID
        ReactDOM.render(React.createElement("div", {},
            <><span className={StylesTDS.modal_field_label}>PARENTID:</span> {props['parentid']}</>
        ), document.getElementById('todo_parentid'));
        document.getElementById('parentid').value = props['parentid'];

        //Add URGENCY
        //Remove all event listeners?
        let urgency_checkbox = document.getElementById('todo_urgency_checkbox');
        let clone = urgency_checkbox.cloneNode(true);
        urgency_checkbox = urgency_checkbox.replaceWith(clone);

        if(props['urgency']) {
            document.getElementById('todo_urgency_checkbox').checked = true;
        } else {
            document.getElementById('todo_urgency_checkbox').checked = false;
        }

        document.getElementById('todo_urgency_checkbox').addEventListener('click', function() {
            document.getElementById('todo_urgency_checkbox').disabled = true;
            edit_item({
                todoid: props['todoid'],
                which_element: 'urgency',
                new_value: !props['urgency'],
                data_type: "i"
            });
        });
            //TODO: Figure out how to vertically center the checkbox with its label
            //TODO: Space out vertically some of the todo item details in the modal


        //Add Subitems Count
        ReactDOM.render(React.createElement("div", {},
            <><span className={StylesTDS.modal_field_label}>SUBITEMS COUNT:</span> {props['subItems'].length ?? 0}</>
        ), document.getElementById('todo_subitems_count'));

        show_todo_item_modal();
        console.log(props);
    }
    const edit_item = (props) => {
        let postData = {
            operation: 'UPDATE_ITEM',
            which_element: props['which_element'],
            todoid: props['todoid'],
            new_value: props['new_value'],
            data_type: props['data_type']
        }

        const edit_callback = (data) => {
            if(data && "ERROR" in data) {
                console.log("ERROR: FAILED TO UPDATE ITEM");
                console.log(data);
            } else if (data) {
                console.log(data);
                //Remove the item from the GUI (and background data)
                load_items();

                //Close the modal
                hide_todo_item_modal();
                setSelectedItem('');

                //Re-enable the button
                document.getElementById('todo_urgency_checkbox').disabled = false;
                document.getElementById('todo_isproject_checkbox').disabled = false;
            } else {
                console.log(data);
                console.log("Should return data, but doesn't. In deleting todo item");
            }
        }
        load_fetch(
            'todo_handler.php',
            postData,
            edit_callback, 'text');
    }
    const toggle_is_active = () => {

        let url = 'todo_handler.php';
        let postData = {
            operation: 'TOGGLE_IS_ACTIVE',
            todoid: selectedItem['todoid'],
            is_active: isActive ? 0 : 1
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                setIsActive(!isActive);
                load_items();
            } else {
                alert('Failed to update is active');
                console.log(data);
            }
        });
    }
    const show_top_level_item = (item_id) => {
        let item = document.getElementById('item_holder_' + item_id);
        item.style.display = (item.style.display === 'block') ? 'none' : 'block';

    }
    const display_item = (item, depth) => {
        let classNames = "";
        //let style = {marginLeft:(10 * depth)};
        //let style = {marginLeft:20, padding:"0 5px"};
        if(depth === 0) {
            classNames = StylesTDS.top_level_item;
        }
        let holderClassNames = "";
        if(depth === 1) {
            holderClassNames = StylesTDS.second_level_holder;
        } else if (item['isproject']) {
            holderClassNames = StylesTDS.ofb_todo_project;
        }

        let buttonClassNames = `${StylesTDS.item_button}`;

        if(item['urgency']) {
            buttonClassNames += ` ${StylesTDS.active_item}`;
        }
        if(item['duedate']) {
            if (item['duedate'].substring(0, 10) < today_date) {
                buttonClassNames = ` ${Styles.ofb_highlight_red}`;
            } else if (item['duedate'].substring(0, 10) == today_date) {
                buttonClassNames = ` ${Styles.ofb_highlight_yellow}`;
            }
        }

        return (
            <div
                key={'todo_item_' + item['todoid']}
                id={'todo_item_id_' + item['todoid']}
                className={holderClassNames}
                //className={`${StylesTDS.top_level_item}`}
                style={{marginLeft: 20, padding: "0 5px"}}
            >
                <div
                    className={classNames}
                >
                    <button
                        className={buttonClassNames}
                        onClick={() => handle_item_click(item)}
                    >
                        <div className={StylesTDS.todo_simple_button}>
                            <div>{item['is_active'] ? '🔥 ' : ''} {item['title']}</div>
                            <div>{item['progress_duration'] ? `${item['last_date_of_work']} - [${item['progress_duration']} min.]` : ''}</div>
                        </div>
                    </button>
                    {depth === 0 ? (
                        <button
                            className={Styles.ofb_button}
                            onClick={() => show_top_level_item(item['todoid'])}
                        >Show/Hide
                        </button>) : ''}
                </div>

                <div id={`item_holder_` + item['todoid']} className={depth === 0 ? StylesTDS.top_level_holder : ''}>
                    {item['subItems'].length > 0 ? item['subItems'].map(item => (
                        display_item(item, depth + 1)
                    )) : <></>}
                </div>
            </div>
        )
    }
    const show_todo_item_modal = () => {
        document.getElementById('todo_item_modal').style.display = 'block';
    }
    const hide_todo_item_modal = () => {
        //Clear and hide the warning div
        document.getElementById('modal_message_holder').innerHTML = '';
        document.getElementById('modal_message_holder').classList.add('w3-hide');

        //If the add new section is displayed, hide it
        document.getElementById('insert_item_below_holder').classList.add('w3-hide');

        //Reset the insert item form
        document.getElementById('new_item_title_input').value = '';
        document.getElementById('parentid').value = '';
        document.getElementById('new_item_details_input').value = '';
        document.getElementById('new_event_date_picker').value = '';
        document.getElementById('is_project_check').checked = false;
        document.getElementById('pinned_check').checked = false;
        document.getElementById('urgent_check').checked = false;

        //Hide the Modal
        document.getElementById('todo_item_modal').style.display = 'none';
        clearAndHideRecordProgressForm();
    }
    const clearAndHideRecordProgressForm = () => {
        setRecordProgressDuration('');
        setRecordProgressDateTime('');
        setRecordProgressNotes('');
        setShowRecordWorkProgressForm(false);
    }
    const get_item_from_local_array = (todo_id, search_haystack) => {
        for(let i = 0; i < search_haystack.length; i++) {
            //console.log(search_haystack[i]['todoid'] + '===' + todo_id + '?');
            if(Number(search_haystack[i]['todoid']) === Number(todo_id)) {
                //console.log(search_haystack[i]);
                return search_haystack[i];
            } else {
                if(search_haystack[i]['subItems']) {
                    let result = get_item_from_local_array(todo_id, search_haystack[i]['subItems']);
                    if(result) {
                        return result;
                    }
                }
            }
        }
    }
    const remove_element_from_array = (index_to_remove, arr) => {
        return [
            ...arr.slice(0, index_to_remove),
            ...arr.slice(index_to_remove + 1)
        ]
    }
    const remove_todo_item_from_gui = (todoid) => {
        //Remove the HTML Element for this TODO Item
        let data_item_object = get_item_from_local_array(todoid, items);
        document.getElementById('todo_item_id_' + todoid).innerHTML = '';

        //Remove the data for this element from the parent? (so the parent won't be complete-blocked)
        let parent_id = data_item_object['parentid'];
        let parent_object = get_item_from_local_array(parent_id, items);
        for(let i = 0; i < parent_object['subItems'].length; i++) {
            if(Number(parent_object['subItems'][i]['todoid']) === Number(todoid)) {
                //Remove this object
                parent_object['subItems'] = remove_element_from_array(i, parent_object['subItems']);
                break;
            }
        }
    }
    const uncompleted_subitems_check = (todoid) => {
        let todo_object = get_item_from_local_array(todoid, items);
        if(todo_object['subItems'] && todo_object['subItems'].length > 0) {
            show_modal_message("This item has sub-items. You can't mark " +
                "it complete or delete it until child items are completed.");
            return false;
        }
        return true;
    }
    const show_modal_message = (message_text) => {
        document.getElementById('modal_message_holder').innerHTML =
            message_text;
        document.getElementById('modal_message_holder').classList.toggle('w3-hide');
    }
    const clear_modal_message = () => {
        document.getElementById('modal_message_holder').innerHTML = '';
        document.getElementById('modal_message_holder').classList.toggle('w3-hide');
    }
    const click_completed_handle = () => {
        //Block if there are uncompleted subitems
        let todoid = document.getElementById('todoid').value;
        if(!uncompleted_subitems_check(todoid)) {
            return;
        }
        const postData = {
            operation: "MARK_ITEM_COMPLETED",
            todoid: todoid
        }
        const completed_callback = (data) => {
            if(data && "ERROR" in data) {
                console.log("ERROR: FAILED TO MARK ITEM COMPLETED");
                console.log(data);
            } else if (data) {
                //Remove the item from the GUI (and background data)
                remove_todo_item_from_gui(todoid);

                //Close the modal
                hide_todo_item_modal();
            } else {
                console.log(data);
                console.log("Should return data, but doesn't. In loadItems() in todo.js");
            }
        }
        load_fetch(
            'todo_handler.php',
            postData,
            completed_callback);
    }
    const show_insert_item_below_form = () => {
        let holder = document.getElementById('insert_item_below_holder');
        holder.classList.toggle('w3-hide');
    }
    const click_submit_new_item_handle = () => {
        //VALIDATE DATA
        let title = document.getElementById('new_item_title_input').value;
        if(!title) {
            show_modal_message("No title entered.");
            return;
        }

        //Clear Modal Message Div
        clear_modal_message();

        //ASSEMBLE THE DATA TO POST
        const postData = {
            operation: "INSERT_NEW_ITEM",
            title: title,
            parentid: document.getElementById('todoid').value,
            details: document.getElementById('new_item_details_input').value,
            duedate: document.getElementById('new_event_date_picker').value,
            isproject: (document.getElementById('is_project_check').checked ? 1 : 0),
            pinned: (document.getElementById('pinned_check').checked ? 1 : 0),
            urgency: (document.getElementById('urgent_check').checked ? 1 : 0)
        }

        //Create Callback
        const completed_callback = (data) => {
            if(data && "ERROR" in data) {
                console.log("ERROR: FAILED TO MARK ITEM COMPLETED");
                console.log(data);
            } else if (data) {
                console.log(data);
                hide_todo_item_modal();
                load_items();
            } else {
                console.log(data);
                console.log("Should return data, but doesn't.");
            }
        }

        //Submit Ajax
        load_fetch(
            'todo_handler.php',
            postData,
            completed_callback);
    }
    const click_delete_item_handle = () => {
        //Block if there are uncompleted subitems
        let todoid = document.getElementById('todoid').value;
        if(!uncompleted_subitems_check(todoid)) {
            return;
        }
        const postData = {
            operation: "DELETE_ITEM",
            todoid: todoid
        }
        const completed_callback = (data) => {
            if(data && "ERROR" in data) {
                console.log("ERROR: FAILED TO DELETE ITEM");
                console.log(data);
            } else if (data) {
                //Remove the item from the GUI (and background data)
                remove_todo_item_from_gui(todoid);

                //Close the modal
                hide_todo_item_modal();
            } else {
                console.log(data);
                console.log("Should return data, but doesn't. In deleting todo item");
            }
        }
        load_fetch(
            'todo_handler.php',
            postData,
            completed_callback);
    }
    const show_work_progress_form = () => {
        if(!showRecordWorkProgressForm) {
            //Set datetime to now
            let now = new Date();
            now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
            now.setMilliseconds(null)
            //now.setSeconds(null)
           setRecordProgressDateTime(now.toISOString().slice(0, -1));
        }
        setShowRecordWorkProgressForm(!showRecordWorkProgressForm);
    }
    const click_record_progress_handle = () => {
        //Validate Data - Must have date/time, nothing else is necessary
        if(!recordProgressDateTime) {
            setRecordProgressErrorMessage("You must enter a date/time for the progress.");
            return;
        }
        setRecordProgressErrorMessage('');

        const postData = {
            operation: "RECORD_PROGRESS",
            work_date_time: recordProgressDateTime.slice(0, 19).replace('T', ' '),
            duration_in_minutes: recordProgressDuration,
            notes: recordProgressNotes,
            todo_id: selectedItem['todoid']
        }
        load_fetch('todo_handler.php', postData, function(data) {
            if(data && "ERROR" in data) {
                console.log("ERROR: FAILED TO RECORD PROGRESS");
                console.log(data);
            } else if (data) {
                //SUCCESS
                setRecordProgressDateTime('');
                setRecordProgressDuration('');
                setRecordProgressNotes('');
                setRecordProgressErrorMessage('');
                setShowRecordWorkProgressForm(false);
                load_progress_notes(selectedItem);
                console.log(data);
                //Add Progress to the display (can reload progress items later)
            } else {
                console.log(data);
                console.log("Should return data, but doesn't. In click_record_progress_handle() in todo.js");
            }
        });

    }
    const update_isproject = (new_value) => {
        //DISABLE THE CHECKBOX TO PREVENT SPAM CLICKING
        document.getElementById('todo_isproject_checkbox').disabled = true;

        //UPDATE IT IN THE DATABASE FIRST
        edit_item({
            which_element: 'isproject',
            todoid: selectedItem['todoid'],
            new_value: new_value,
            data_type: 'i'
        });
    }
    const navigate_to_projects = () => {
        document.location.href='projects';
    }

    const navigate_to_manage_overdue = () => {
        document.location.href='todo-manage-overdue';
    }
    const navigate_to_todo_quick = () => {
        document.location.href='todo-quick';
    }
    const navigate_to_todo_active = () => {
        document.location.href='todo-active-simple';
    }
    const toggle_expand_all = () => {
        let item = document.getElementById('item_holder_' + items[0]['todoid']);
        let new_visibility = item.style.display === 'block' ? 'none' : 'block';

        for(let i = 0; i < items.length; i++) {
            document.getElementById('item_holder_' + items[i]['todoid']).style.display = new_visibility;
        }
    }

    //RENDER SECTION
    if(items) {
        return (
            <>
                <button
                    className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                    onClick={navigate_to_projects}
                >
                    Projects Data
                </button>
                <button
                    className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                    onClick={navigate_to_manage_overdue}
                >
                    Manage Overdue Items
                </button>
                <button
                    className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                    onClick={navigate_to_todo_quick}
                >
                    Quick TODO
                </button>
                <button
                    className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                    onClick={toggle_expand_all}
                >
                    Show/Hide All
                </button>
                <button
                    className={`${Styles.ofb_button} ${Styles.ofb_red}`}
                    onClick={navigate_to_todo_active}
                >
                    TODO Active
                </button>
                <div style={{textAlign:"left"}}>
                    {
                        items.map(item => (
                            display_item(item, 0)
                        ))
                    }
                </div>
                <div id="todo_item_modal" className="w3-modal">
                    <div className="w3-modal-content">
                        <header className="w3-container w3-red">
                            <span onClick={hide_todo_item_modal} className="w3-button w3-display-topright">&times;</span>
                            <h3 id="show_more_header_date">TODO Item</h3>
                        </header>

                        <div className="w3-container" id="modal_events_holder">
                            <input type="hidden" id="todoid" />
                            <input type="hidden" id="userid" />
                            <input type="hidden" id="parentid" />
                            <div id="todo_title">TITLE</div>
                            <div id="todo_duedate" className={StylesTDS.modal_item}>DUEDATE</div>
                            <div id="todo_dateadded" className={StylesTDS.modal_item}>DATEADDED</div>
                            <div id="todo_details" className={StylesTDS.modal_item}>DETAILS</div>
                            <div id="todo_isproject" className={StylesTDS.modal_item}>
                                <span className={StylesTDS.modal_field_label}>IS PROJECT?</span>
                                <input
                                    type='checkbox'
                                    id='todo_isproject_checkbox'
                                    className={StylesTDS.ofb_check}
                                    checked={selectedItem['isproject'] ?? false}
                                    value={selectedItem['isproject']}
                                    onChange={(event) => update_isproject(event.target.checked)}
                                />
                            </div>
                            <div id="todo_pinned" className={StylesTDS.modal_item}>PINNED</div>
                            <div id="todo_completed" className={StylesTDS.modal_item}>COMPLETED</div>
                            <div id="todo_parentid" className={StylesTDS.modal_item}>PARENTID</div>
                            <div id="todo_urgency" className={StylesTDS.modal_item}>
                                <span className={StylesTDS.modal_field_label}>
                                    URGENCY:
                                    <input
                                        type='checkbox'
                                        id='todo_urgency_checkbox'
                                        className={StylesTDS.ofb_check} />
                                </span>
                            </div>

                            <div id="todo_is_active" className={StylesTDS.modal_item}>
                                <span className={StylesTDS.modal_field_label}>
                                    IS ACTIVE?:
                                    <input
                                        type='checkbox'
                                        id='todo_is_active_checkbox'
                                        className={StylesTDS.ofb_check}
                                        checked={isActive}
                                        onChange={toggle_is_active}
                                    />
                                </span>
                            </div>
                            <div id="todo_subitems_count" className={StylesTDS.modal_item}>SUBITEMS</div>
                            <div id="todo_progress_notes_holder" className={selectedItemProgressNotes.length > 0 ? StylesTDS.modal_item : StylesTDS.ofb_hide}>
                                <span className={StylesTDS.modal_field_label}>PROGRESS NOTES</span>
                                {selectedItemProgressNotes.map((item, index) => (
                                    <div key={index} className={StylesTDS.ofb_progress_note}>
                                        <span className={StylesTDS.ofb_progress_note_datetime}>{item['work_date_time']}</span>
                                        {item['duration_in_minutes'] ? <span className={StylesTDS.ofb_progress_note_duration}> [{item['duration_in_minutes']} min.]</span> : ''}
                                        {item['notes'] ? <span className={StylesTDS.ofb_progress_note_notes}> {item['notes']}</span> : ''}
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* RECORD WORK PROGRESS HOLDER FORM */}
                        <div
                            className={`w3-container ${showRecordWorkProgressForm ? StylesTDS.record_work_progress_holder : StylesTDS.ofb_hide}`}
                            id="record_work_progress_holder">
                            <div className={StylesTDS.modal_add_new_header}>RECORD PROGRESS</div>
                            <div className={recordProgressErrorMessage ? StylesTDS.ofb_error_message : StylesTDS.ofb_hide}>
                                {recordProgressErrorMessage}
                            </div>
                            <div className={StylesTDS.insert_item_below_form_cell}>
                                <div className={StylesTDS.ofb_label}>Work Time: </div>
                                <div className={StylesTDS.ofb_input_holder}>
                                    <input
                                        type="datetime-local"
                                        className={StylesTDS.ofb_text_input}
                                        id="progress_datetime_input"
                                        value={recordProgressDateTime}
                                        onChange={event => setRecordProgressDateTime(event.target.value)}
                                    />
                                </div>
                            </div>
                            <div className={StylesTDS.insert_item_below_form_cell}>
                                <div className={StylesTDS.ofb_label}>Duration: </div>
                                <div className={StylesTDS.ofb_input_holder}>
                                    <input
                                        type="number"
                                        className={StylesTDS.ofb_text_input}
                                        id="progress_duration_input"
                                        value={recordProgressDuration}
                                        onChange={event => setRecordProgressDuration(event.target.value)}
                                    />
                                </div>
                            </div>
                            <div className={StylesTDS.insert_item_below_form_cell}>
                                <div className={StylesTDS.ofb_label}>Notes: </div>
                                <div className={StylesTDS.ofb_input_holder}>
                                    <textarea
                                        className={StylesTDS.ofb_text_input}
                                        id="progress_notes_input"
                                        value={recordProgressNotes}
                                        onChange={event => setRecordProgressNotes(event.target.value)}
                                    />
                                </div>
                            </div>
                            <button
                                className={`w3-button w3-input ${StylesTDS.new_item_submit_button}`}
                                id="new_item_submit_button"
                                onClick={click_record_progress_handle}>Save Progress Data</button>

                        </div>

                        {/* INSERT ITEM BELOW HOLDER FORM */}
                        <div
                            className={`w3-container w3-hide ${StylesTDS.insert_item_below_holder}`}
                            id="insert_item_below_holder">
                            <div className={StylesTDS.modal_add_new_header}>ADD NEW SUBITEM</div>
                            <div className={StylesTDS.insert_item_below_form_cell}>
                                <div className={StylesTDS.ofb_label}>Item Title: </div>
                                <div className={StylesTDS.ofb_input_holder}>
                                    <input type="text" className={StylesTDS.ofb_text_input} id="new_item_title_input" />
                                </div>
                            </div>
                            <div className={StylesTDS.insert_item_below_form_cell}>
                                <div className={StylesTDS.ofb_label}>Item Details: </div>
                                <div className={StylesTDS.ofb_input_holder}>
                                    <textarea className="w3-input" id="new_item_details_input"/>
                                </div>
                            </div>
                            <div className={StylesTDS.insert_item_below_form_cell}>
                                <div className={StylesTDS.ofb_label}>Due Date: </div>
                                <div className={StylesTDS.ofb_input_holder}>
                                    <input type="date" id="new_event_date_picker" className="w3-input" />
                                </div>
                            </div>
                            <div className={StylesTDS.insert_item_below_form_cell}>
                                <div className={StylesTDS.ofb_label}>Is this a Project? </div>
                                <div className={StylesTDS.ofb_input_holder}><input
                                    type="checkbox"
                                    name="is_project_check"
                                    id="is_project_check"
                                    className={StylesTDS.ofb_check} /></div>
                            </div>
                            <div className={StylesTDS.insert_item_below_form_cell}>
                                <div className={StylesTDS.ofb_label}>Pin this Item? </div>
                                <div className={StylesTDS.ofb_input_holder}><input
                                    type="checkbox"
                                    name="pinned_check"
                                    id="pinned_check"
                                    className={StylesTDS.ofb_check} /></div>
                            </div>
                            <div className={StylesTDS.insert_item_below_form_cell}>
                                <div className={StylesTDS.ofb_label}>Urgent? </div>
                                <div className={StylesTDS.ofb_input_holder}><input
                                    type="checkbox"
                                    name="urgent_check"
                                    id="urgent_check"
                                    className={StylesTDS.ofb_check} /></div>
                            </div>

                            <button
                                className={`w3-button w3-input ${StylesTDS.new_item_submit_button}`}
                                id="new_item_submit_button"
                                onClick={click_submit_new_item_handle}>Submit</button>
                        </div>
                        <div className={`w3-hide w3-container ${StylesTDS.modal_message_holder}`} id="modal_message_holder">

                        </div>
                        <footer className="w3-container w3-red" id="modal_footer">
                            <button
                                className={StylesTDS.modal_button}
                                title="Delete Item"
                                onClick={click_delete_item_handle}>
                                <img src={trash_can} alt="Delete Item Button" />
                            </button>
                            <button
                                className={StylesTDS.modal_button}
                                title="Edit Item">
                                <img src={edit_icon} alt="Edit Item Button"/>
                            </button>
                            <button
                                className={StylesTDS.modal_button}
                                onClick={click_completed_handle}
                                title="Mark Completed">
                                <img src={checkmark} alt="Mark Completed Button" />
                            </button>
                            <button
                                className={StylesTDS.modal_button}
                                title="Insert New Item Below This One"
                                id="add_new_event_button"
                                onClick={show_insert_item_below_form}>
                                <img src={insert_below_icon} alt="Insert Item Below Button"/>
                            </button>
                            <button
                                className={StylesTDS.modal_button}
                                title="Record Work Done on this Task"
                                id="record_work_progress_button"
                                onClick={show_work_progress_form}>
                                <img src={work_icon} alt="Record Work Progress"/>
                            </button>
                        </footer>
                    </div>
                </div>
            </>
        )
    } else {
        return <div>Loading...</div>
    }
}

export default TodoSimple;