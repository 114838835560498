import NavSection from "./nav_section";
import PageHeader from "./page_header";
import {useContext} from "react";
import {AuthContext} from "./auth_context";

const Home = (props) => {
    const [authStatus, setAuthStatus] = useContext(AuthContext);
    console.log(authStatus);
    return (
        <>
            <PageHeader page_title="OUT FROM BABEL"/>
        </>
    );
};
export default Home;