import React, {useEffect, useState} from "react";
import {getDateInYYYYMMDD, load_fetch, talk} from "../ofb_util";
import GenStyles from "../styles/ofb_gen_styles.module.css";
import PrayerStyles from "../styles/prayer.module.css";
import TrashCan from "../images/trash_can.png";

const Prayer = (props) => {
    //DATA
    const [prayerItems, setPrayerItems] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const today = getDateInYYYYMMDD(new Date());
    const [editingTitle, setEditingTitle] = useState(false);
    const [deletingItem, setDeletingItem] = useState(false);
    const [addingNew, setAddingNew] = useState(false);
    const [newTitle, setNewTitle] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(false);

    //TODO: Add code to mark an item inactive (rather than just delete)

    //USEEFFECTS
    useEffect(() => {
        get_prayer_items();
    }, []);
    useEffect(() => {
        if(editingTitle) {
            return;
        }
        if(selectedItem) {
            let nst = document.getElementById('new_subitem_title');
            if(nst) {
                nst.focus();
            }
        }
    }, [selectedItem])
    useEffect(() => {
        if(props.authStatus) {
            get_prayer_items();
        } else {
            setPrayerItems(false);
        }
    }, [props.authStatus]);


    //AJAX FUNCTIONS
    const get_prayer_items = () => {
        talk("Prayer_Handler.php", "GET_PRAYER", null, get_prayer_items_handler);
    }
    const get_prayer_items_handler = (operation, sent_data, return_data) => {
        if(!return_data['ERROR']) {
            setPrayerItems(return_data);
        } else {
            setPrayerItems(false);
        }
        console.log(return_data);
    }
    const mark_completed = () => {
        let post_data = {
            prayer_id: selectedItem['prayer_id']
        };
        talk('Prayer_Handler.php', 'MARK_COMPLETED', post_data, mark_completed_handler);
    }
    const mark_completed_handler = (operation, sent_data, return_data) => {
        if(!return_data['ERROR'] && !return_data['error']) {
            console.log("HERE");
            setPrayerItems(return_data);
            close_modal();
        } else {
            alert("ERROR");
            console.log(return_data);
        }
    }
    const delete_selected_item = () => {
        talk('Prayer_Handler.php', 'DELETE_PRAYER_ITEM',
            {prayer_id: selectedItem['prayer_id']}, delete_selected_item_handler);
    }
    const delete_selected_item_handler = (operation, sent_data, return_data) => {
        if(return_data['ERROR']) {
            alert("Failed to delete");
            console.log(return_data);
            return;
        }
        setPrayerItems(return_data);
        setSelectedItem(false);
    }
    const save_edit_title = () => {
        let postData = {
            prayer_id: selectedItem['prayer_id'],
            prayer_text: editingTitle
        }
        talk('Prayer_Handler.php', 'UPDATE_PRAYER', postData, save_edit_title_handler);
    }
    const save_edit_title_handler = (operation, sent_data, return_data) => {
        if(return_data['ERROR']) {
            alert("FAILED TO UPDATE");
            console.log(return_data);
            return;
        }

        //Update the selected item
        let local_si = JSON.parse(JSON.stringify(selectedItem));
        local_si['prayer_text'] = editingTitle;
        setSelectedItem(local_si);
        setEditingTitle(false);

        //Update the Prayer Items
        console.log(return_data);
        setPrayerItems(return_data);
    }
    const save_new_prayer = () => {
        let postData = {
            prayer_text: newTitle,
            prayer_category_id: selectedCategory
        }
        talk('Prayer_Handler.php', 'INSERT_NEW_PRAYER', postData, save_new_prayer_handler);
    }
    const save_new_prayer_handler = (operation, sent_data, return_data) => {
        if(return_data['ERROR']) {
            alert('FAILED TO SAVE');
            console.log(return_data);
            return
        }
        setPrayerItems(return_data);
        hide_add_new_modal();
    }

    //OTHER FUNCTIONS
    const close_modal = () => {
        //Clear the Form
        setEditingTitle(false);
        setDeletingItem(false);

        //Close the Modal
        setSelectedItem(false);
    }
    const show_modal = (selected_item) => {
        setSelectedItem(selected_item);
    }
    const set_selected_item = (item) => {
        console.log(item);
        setSelectedItem(item);
    }
    const delete_selected_item_ays = () => {
        setDeletingItem(true);
    }
    const cancel_deleting = () => {
        setDeletingItem(false);
    }
    const edit_title = () => {
        setEditingTitle(selectedItem['prayer_text']);
    }
    const cancel_edit_title = (event) => {
        event.stopPropagation();
        setEditingTitle(false);
    }
    const show_add_new_modal = (prayer_category_id) => {
        setAddingNew(true);
        setSelectedCategory(prayer_category_id);
    }
    const hide_add_new_modal = () => {
        setAddingNew(false);
        setNewTitle('');
    }




    if(props.authStatus) {
        return (
            <>
                <div>
                    {prayerItems && prayerItems.map((item, index) => (
                        <div
                            key={'PCAT_' + item.prayer_category_id}
                            className={PrayerStyles.prayer_category}
                        >
                            <div
                                className={PrayerStyles.prayer_header}
                                onClick={() => show_add_new_modal(item.prayer_category_id)}
                            >{item.prayer_category}</div>
                            {item.prayers && item.prayers.map((prayer, index) => (
                                <div
                                    key={'PRAYER_' + prayer.prayer_id}
                                    className={PrayerStyles.prayer_item}
                                    onClick={() => show_modal(prayer)}
                                >{prayer.prayer_text}
                                    <span className={PrayerStyles.last_date_span}>
                                        {prayer.last_prayed_datetime ?
                                        "(" + prayer.last_prayed_datetime.substring(0, 10) + ")" : ""}</span>
                                </div>
                            ))}

                        </div>
                    ))}
                </div>

                {/* MODAL */}
                <div className={selectedItem ? GenStyles.modal_shown : GenStyles.modal_hidden}>
                    <div className={GenStyles.modal_content}>
                        <div className={GenStyles.modal_header}>
                            <div>Prayer Item</div>
                            <div><button
                                className={GenStyles.modal_close_button}
                                onClick={close_modal}
                            >X</button></div>
                        </div>




                        <div className={GenStyles.modal_body}>
                            {/* NAME AND EDIT NAME */}
                            <div
                                className={GenStyles.pointer}
                                onClick={edit_title}
                            >{editingTitle ?
                                    <>
                                    <input
                                        type="text"
                                        name="name"
                                        value={editingTitle}
                                        onChange={(event) =>
                                            setEditingTitle(event.target.value)}
                                        className={PrayerStyles.edit_title_input}
                                    />
                                    <button
                                        onClick={cancel_edit_title}
                                        >❌</button>
                                    <button
                                        onClick={save_edit_title}
                                    >💾</button>
                                    </>
                                : selectedItem['prayer_text']}</div>

                            {/* MARK COMPLETED BUTTONS */}
                            <div className={PrayerStyles.completed_row}>
                                <div className={PrayerStyles.mark_completed_button_div}>
                                    <button className={GenStyles.modal_gray_button}
                                            onClick={mark_completed}
                                        >
                                        Mark Completed
                                    </button>
                                </div>
                                {/* DELETE BUTTON */}
                                <div className={PrayerStyles.mark_completed_button_div}>
                                    <button
                                        className={GenStyles.modal_gray_button}
                                        onClick={delete_selected_item_ays}
                                    ><img src={TrashCan} /></button>
                                </div>
                            </div>
                            <div className={deletingItem ? GenStyles.are_you_sure : GenStyles.hidden}>
                                <div>Are you sure you want to delete this item?</div>
                                {(selectedItem['sub_items'] && selectedItem['sub_items'].length > 0) ? <div>It has subitems.</div> : ''}
                                <div>
                                    <button
                                        className={GenStyles.modal_gray_button}
                                        onClick={cancel_deleting}
                                    >Cancel</button>
                                    <button
                                        className={GenStyles.modal_gray_button}
                                        onClick={delete_selected_item}
                                    >Delete</button>
                                </div>
                            </div>



                            {/* NEW SUBITEM FORM */}
                            {/*    <PrayerSubItemForm*/}
                            {/*        is_shown={selectedItem ? true : false}*/}
                            {/*        parent_id={selectedItem['prayer_item_id']}*/}
                            {/*        //on_save={on_save_new_subitem}*/}
                            {/*        categories={prayerCategories}*/}
                            {/*    />*/}
                        </div>

                        {/* MODAL FOOTER WITH CLOSE BUTTON */}
                        <div className={GenStyles.modal_footer}>
                            <button
                                className={GenStyles.modal_button}
                                onClick={close_modal}
                            >Close
                            </button>
                        </div>
                    </div>
                </div>

                {/* ADD NEW MODAL */}
                <div className={addingNew ? GenStyles.modal_shown : GenStyles.modal_hidden}>
                    <div className={GenStyles.modal_content}>
                        <div className={GenStyles.modal_header}>
                            <div>Add Prayer Item</div>
                            <div><button
                                className={GenStyles.modal_close_button}
                                onClick={hide_add_new_modal}
                            >X</button></div>
                        </div>

                        <div className={GenStyles.modal_body}>
                            <input
                                type="text"
                                name="name"
                                value={newTitle}
                                onChange={(event) =>
                                    setNewTitle(event.target.value)}
                                className={PrayerStyles.edit_title_input}
                            />
                            <button
                                onClick={hide_add_new_modal}
                            >❌</button>
                            <button
                                onClick={save_new_prayer}
                            >💾</button>
                        </div>

                        {/* MODAL FOOTER WITH CLOSE BUTTON */}
                        <div className={GenStyles.modal_footer}>
                            <button
                                className={GenStyles.modal_button}
                                onClick={hide_add_new_modal}
                            >Close
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return <></>
    }
}
export default Prayer;